import { Table, Tag } from "antd";
import { Link } from "react-router-dom";

export default function PriceConfigsTable(props) {
    const { apartments, loading, selectedApartments, onSelectedApartmentsChange } = props;

    return (
        <Table
            columns={[
                {
                    title: 'Name',
                    render: apartment => (
                        <Link to={`/prices/${apartment.id}`}>
                            {apartment.name}
                        </Link>
                    )
                },
                {
                    title: 'Tags',
                    render: apartment => [...apartment.tags ?? []].sort().map(tag => (
                        <Tag
                            key={tag}
                        >
                            {tag}
                        </Tag>
                    )),
                },
            ]}
            dataSource={
                [...apartments ?? []]
                    .sort((a, b) => a.name.localeCompare(b.name))
            }
            loading={loading}
            rowKey="id"
            rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedApartments,
                onChange: selected => onSelectedApartmentsChange(selected),
                columnWidth: '64px',
            }}
            size="small"
            pagination={false}
        />
    )
}
import { Button, Col, PageHeader, Row, Space, Dropdown, Input, Select } from "antd";
import { GET_APARTMENT_PRICE_CONFIGS_QUERY } from "prices/graphql";
import { useQuery } from "@apollo/client";
import { DownOutlined } from "@ant-design/icons";
import { useAuth } from "auth";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import PriceConfigActionsMenu from "prices/PriceConfigActionsMenu";
import PriceConfigModificationModal from "prices/PriceConfigModificationModal";
import PriceConfigsTable from "prices/PriceConfigsTable";
import { fuzzySearch } from "common/common";
import PriceConfigRecalculateModal from "prices/PriceConfigRecalculateModal";
import PriceConfigPasteModal from "prices/PriceConfigPasteModal";
import { apartmentTagsFilterOr } from "apartments/common";

export default function PriceConfigsView() {
    const auth = useAuth();
    const navigate = useNavigate();

    const [isBulkModificationModalVisible, setIsBulkModificationModalVisible] = useState(false);
    const [isBulkRecalculateModalVisible, setIsBulkRecalculateModalVisible] = useState(false);
    const [isBulkPasteModalVisible, setIsBulkPasteModalVisible] = useState(false);
    const [selectedApartments, setSelectedApartments] = useState([]);
    const [apartmentNameSearch, setApartmentNameSearch] = useState();
    const [apartmentTagsSearch, setApartmentTagsSearch] = useState([]);

    const { data: priceConfigsData, loading: priceConfigsLoading } = useQuery(GET_APARTMENT_PRICE_CONFIGS_QUERY);

    function handlePriceConfigAction(action) {
        if (action === 'modifyPrices') {
            setIsBulkModificationModalVisible(true);
        }
        if (action === 'recalculate') {
            setIsBulkRecalculateModalVisible(true);
        }
        if (action === 'paste') {
            setIsBulkPasteModalVisible(true);
        }
    }

    return (
        <PageHeader
            title="Price configs"
            onBack={() => navigate(-1)}
            extra={[
                <Link
                    to="/prices/weekday-deltas"
                    key="weeydayDeltas"
                >
                    <Button>
                        Weekday deltas
                    </Button>
                </Link>,
                <Link
                    to="/prices/gap-penalties"
                    key="gapPenalties"
                >
                    <Button>
                        Gap penalties
                    </Button>
                </Link>,
            ]}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Row
                        gutter={[16, 16]}
                        align="space-between"
                    >
                        <Col>
                            <Dropdown
                                overlay={
                                    <PriceConfigActionsMenu
                                        onAction={action => handlePriceConfigAction(action)}
                                    />
                                }
                                disabled={selectedApartments.length === 0}
                            >
                                <Button
                                    icon={<DownOutlined />}
                                >
                                    Group actions
                                </Button>
                            </Dropdown>
                        </Col>
                        <Col>
                            <Space>
                                <Input
                                    placeholder="Search"
                                    style={{
                                        width: '300px',
                                    }}
                                    value={apartmentNameSearch}
                                    onChange={e => setApartmentNameSearch(e.target.value)}
                                />
                                <Select
                                    options={
                                        [...auth?.user?.organization?.apartmentTags ?? []]
                                            .map(tag => ({
                                                label: tag,
                                                value: tag,
                                            }))
                                    }
                                    value={apartmentTagsSearch}
                                    onChange={value => setApartmentTagsSearch(value)}
                                    placeholder="Tags"
                                    mode="multiple"
                                    style={{
                                        width: '200px',
                                    }}
                                />
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <PriceConfigsTable
                        apartments={
                            [...priceConfigsData?.apartments ?? []]
                                .filter(apartment => apartment.active)
                                .filter(apartment => !apartmentNameSearch || fuzzySearch(apartment.name, apartmentNameSearch))
                                .filter(apartment => apartmentTagsFilterOr([...apartment.tags ?? []], apartmentTagsSearch))
                        }
                        loading={priceConfigsLoading}
                        selectedApartments={selectedApartments}
                        onSelectedApartmentsChange={selected => setSelectedApartments(selected)}
                    />
                </Col>
            </Row>
            <PriceConfigModificationModal
                visible={isBulkModificationModalVisible}
                onClose={() => setIsBulkModificationModalVisible(false)}
                selectedApartments={selectedApartments}
            />
            <PriceConfigRecalculateModal
                visible={isBulkRecalculateModalVisible}
                onClose={() => setIsBulkRecalculateModalVisible(false)}
                selectedApartments={selectedApartments}
            />
            <PriceConfigPasteModal
                visible={isBulkPasteModalVisible}
                onClose={() => setIsBulkPasteModalVisible(false)}
                selectedApartments={selectedApartments}
            />
        </PageHeader>
    )
}
import { gql, useQuery } from "@apollo/client";
import { Alert, Checkbox, Col, Row, Skeleton } from "antd";
import { MOBILE_MENU_ITEMS } from "common/common";

const QUERY = gql`
    query GetUserGroupMobilebMenuForMobileMenuSelect {
        userGroups {
            id
            mobileMenu
        }
    }
`;

export default function UserMobileMenuSelect(props) {
    const {
        value,
        onChange,
        inheritMenuItemsFromGroupIds,
    } = props;

    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load user groups"
            />
        );
    }

    const userGroupMenuItems = [...data.userGroups ?? []]
        .filter(userGroup => [...inheritMenuItemsFromGroupIds ?? []].includes(userGroup.id))
        .map(userGroup => userGroup.mobileMenu?.items ?? [])
        .flat()
        .filter((item, index, mobileMenu) => mobileMenu.indexOf(item) === index);

    return (
        <Checkbox.Group
            value={value}
            onChange={value => onChange(value)}
        >
            <Row gutter={[8, 8]}>
                {MOBILE_MENU_ITEMS.map(item => (
                    <Col
                        key={item}
                        span={24}
                    >
                        {inheritMenuItemsFromGroupIds && userGroupMenuItems.includes(item) && (
                            <Checkbox
                                value={item}
                                disabled
                                defaultChecked={true}
                            >
                                {item}
                            </Checkbox>
                        )}
                        {(!inheritMenuItemsFromGroupIds || !userGroupMenuItems.includes(item)) && (
                            <Checkbox
                                value={item}
                            >
                                {item}
                            </Checkbox>
                        )}
                    </Col>
                ))}
            </Row>
        </Checkbox.Group>
    );
}
const TEMPLATES = [
    {
        label: 'Welcome message',
        predicates: [
            'Thank you for choosing our apartment! We will do our best to make your stay wonderful!',
            'On the day of your arrival, we\'ll send you a self check-in instruction in the morning, complete with the exact address and any other important details you may need.'
        ],
    },
    {
        label: 'Pre check-in message',
        predicates: [
            'We hope you\'re doing well and getting excited about your upcoming stay with us at SquareApartments!',
        ],
    },
    {
        label: 'Check-in instructions',
        predicates: [
            'Apartment will be ready for your arrival at the time you provided us through the airbnb message',
            'Below you’ll find your check-in instruction',
            'Below you will find check-in instruction',
        ],
    },
    {
        label: 'Check-out message',
        predicates: [
            'We will be very grateful if you leave a review on Airbnb after your stay.',
        ],
    },
    {
        label: 'After check-in message',
        predicates: [
            'Thank you for choosing our apartment. We are happy to have you as our guest and sincerely hope you enjoy your stay',
        ],
    },
    {
        label: 'Restaurants recommendations',
        predicates: [
            'Below you will find a list of places we recommend:',
        ],
    },
    {
        label: 'Locations recommendations',
        predicates: [
            'Below you will find our suggestions on what is worth to see in Cracow',
        ],
    },
    {
        label: 'Reminder week before arrival',
        predicates: [
            'We hope you\'re doing well and getting excited about your upcoming stay with us at squarestate!',
        ],
    },
];

export function getTemplateInfo(message) {
    return TEMPLATES.find(template => template.predicates.some(predicate => message.includes(predicate)));
}
import { TagOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { useAuth } from "auth";
import { useEffect, useMemo } from "react";

export default function JobTagsSelect(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    const { user } = useAuth();
    const jobTags = useMemo(() => [...user?.organization?.jobTags ?? []], [user]);

    useEffect(() => {
        if (jobTags.length > 0) {
            const inexistentKeys = [...value ?? []].filter(key => !jobTags.includes(key));
            if (inexistentKeys.length > 0) {
                onChange([...value].filter(key => !inexistentKeys.includes(key)));
            }
        }
    }, [jobTags, value, onChange]);

    return (
        <Select
            options={
                jobTags
                    .map(tag => ({
                        value: tag,
                        label: tag,
                    }))
            }
            value={value}
            onChange={value => onChange(value)}
            mode="multiple"
            showSearch={false}
            placeholder="Tags"
            suffixIcon={<TagOutlined />}
            style={{
                width: '100%',
            }}
            {...otherProps}
        />
    )
}
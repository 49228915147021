import { gql, useQuery } from "@apollo/client";
import { Alert, Skeleton } from "antd";
import CleaningGroup from "../cleaning-group/CleaningGroup";
import "./style.css";
import { formatISO } from "date-fns";

const QUERY = gql`
    query GetCleaningGroups($date: Date!) {
        cleaningGroups(filter: {dates: [$date]}) {
            id
            status
            startTime
            endTime
            note
            apartments {
                id
                checkinTime
                checkinTimeOverridenAt
                checkoutTime
                checkoutTimeOverridenAt
                numberOfGuests
                numberOfGuestsOverridenAt
                order
                tags
                note
                apartment {
                    id
                    name
                    increasePriceAbove
                    storage {
                        id
                        name
                        order
                    }
                    previousReservation(date: $date) {
                        id
                        endDate
                        checkoutTime
                        numberOfGuests
                    }
                    departingReservation(date: $date) {
                        id
                        endDate
                        checkoutTime
                        numberOfGuests
                        bookedAt
                    }
                    ongoingReservation(date: $date) {
                        id
                        numberOfGuests
                    }
                    arrivingReservation(date: $date) {
                        id
                        startDate
                        checkinTime
                        numberOfGuests
                        airbnbThreadId
                        bookedAt
                    }
                    nextReservation(date: $date) {
                        id
                        startDate
                        checkinTime
                        numberOfGuests
                        bookedAt
                    }
                    cleanings(filter: {dateLt: $date, order: "desc", limit: 1}) {
                        id
                        group {
                            id
                            date
                        }
                    }
                    jobsNew(filter: {dates: [$date]}) {
                        id
                    }
                }
                storage {
                    id
                    name
                    order
                }
                group {
                    id
                }
            }
            cleaners {
                id
                firstName
                lastName
            }
        }
    }
`;

export default function CleaningGroups(props) {
    const {
        date,
        selectedCleaningGroupApartmentIds,
        size,
        onSelectedCleaningGroupApartmentIdsChange,
        unassignedApartmentsSelected,
        onCleaningGroupClick,
        onCreateCleaningGroup,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            date: formatISO(date, { representation: 'date' }),
        },
    });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load cleaning groups"
            />
        );
    }

    function isMoveHereMode() {
        return unassignedApartmentsSelected || selectedCleaningGroupApartmentIds?.length > 0;
    }

    function handleMoveToGroup(cleaningGroupApartmentId) {
        onSelectedCleaningGroupApartmentIdsChange([cleaningGroupApartmentId]);
    }

    function handleMoveToNewGroup(cleaningGroupApartmentId) {
        onCreateCleaningGroup([cleaningGroupApartmentId]);
    }

    function sorter(a, b) {
        const aStorageOrder = a.apartments?.[0]?.storage?.order ?? 0;
        const bStorageOrder = b.apartments?.[0]?.storage?.order ?? 0;

        if (aStorageOrder === bStorageOrder) {
            return a.id - b.id;
        }

        return aStorageOrder - bStorageOrder;
    }

    const usedCleanerIds = [...data.cleaningGroups]
        .map(cleaningGroup => cleaningGroup.cleaners)
        .flat()
        .map(cleaner => cleaner.id);

    const cleaningGroups = [...data.cleaningGroups ?? []]
        .sort(sorter);

    return (
        <div className="cleaning-groups-container">
            {cleaningGroups.map(cleaningGroup => (
                <CleaningGroup
                    cleaningGroupId={cleaningGroup.id}
                    date={date}
                    selectedCleaningGroupApartmentIds={selectedCleaningGroupApartmentIds}
                    usedCleanerIds={usedCleanerIds}
                    moveHereMode={isMoveHereMode()}
                    size={size}
                    onClick={() => onCleaningGroupClick(cleaningGroup.id)}
                    onMoveToGroup={cleaningGroupApartmentId => handleMoveToGroup(cleaningGroupApartmentId)}
                    onMoveToNewGroup={cleaningGroupApartmentId => handleMoveToNewGroup(cleaningGroupApartmentId)}
                    key={cleaningGroup.id}
                />
            ))}
        </div>
    );
}
import { Input, Popover } from "antd";
import { useState } from "react";

export default function PriceConfigCalendarInplaceEdit(props) {
    const { value, onChange, children } = props;

    const [visible, setVisible] = useState(false);

    function handleOnClick(e) {
        e.stopPropagation();
        setVisible(true);
    }

    function handlePressEnter(e) {
        onChange(e.target.value);
        setVisible(false);
    }

    return (
        <Popover
            trigger="click"
            visible={visible}
            onVisibleChange={value => setVisible(value)}
            placement="right"
            destroyOnHide={true}
            content={
                <Input
                    defaultValue={value}
                    onPressEnter={e => handlePressEnter(e)}
                    size="small"
                    style={{
                        width: '70px',
                    }}
                />
            }
        >
            <div onClick={e => handleOnClick(e)}>
                {children}
            </div>
        </Popover>
    )
}
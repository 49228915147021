import { useMutation, useQuery } from "@apollo/client";
import { Button, Card, Col, Empty, message, PageHeader, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { CREATE_APARTMENT_PRICE_CONFIG_WEEKDAY_DELTA_MUTATION, DELETE_APARTMENT_PRICE_CONFIG_WEEKDAY_DELTA_MUTATION, GET_APARTMENT_PRICE_CONFIG_WEEKDAY_DELTAS_QUERY, UPDATE_APARTMENT_PRICE_CONFIG_WEEKDAY_DELTA_MUTATION } from "prices/graphql";
import WeekdayDelta from "prices/WeekdayDelta";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import errorHandler from "common/errorHandler";

export default function WeekdayDeltasView() {
    const navigate = useNavigate();

    const [isAdding, setIsAdding] = useState(false);

    const { data: weekdayDeltasData, loading: weekdayDeltasLoading } = useQuery(GET_APARTMENT_PRICE_CONFIG_WEEKDAY_DELTAS_QUERY);

    const [createApartmentPriceConfigWeekdayDelta] = useMutation(CREATE_APARTMENT_PRICE_CONFIG_WEEKDAY_DELTA_MUTATION, {
        onCompleted(response) {
            if (response.createApartmentPriceConfigWeekdayDelta.error) {
                errorHandler(response.createApartmentPriceConfigWeekdayDelta.error);
            }
            else {
                message.success('Weekday delta created');
            }
        },
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'apartmentPriceConfigWeekdayDeltas',
            })
        },
    });
    const [updateApartmentPriceConfigWeekdayDelta] = useMutation(UPDATE_APARTMENT_PRICE_CONFIG_WEEKDAY_DELTA_MUTATION, {
        onCompleted(response) {
            if (response.updateApartmentPriceConfigWeekdayDelta.error) {
                errorHandler(response.updateApartmentPriceConfigWeekdayDelta.error);
            }
            else {
                message.success('Weekday delta updated');
            }
        }
    });
    const [deleteApartmentPriceConfigWeekdayDelta] = useMutation(DELETE_APARTMENT_PRICE_CONFIG_WEEKDAY_DELTA_MUTATION, {
        onCompleted(response) {
            if (response.deleteApartmentPriceConfigWeekdayDelta.error) {
                errorHandler(response.deleteApartmentPriceConfigWeekdayDelta.error);
            }
            else {
                message.success('Weekday delta deleted');
            }
        },
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'apartmentPriceConfigWeekdayDeltas',
            })
        },
    })

    function handleAddNew() {
        setIsAdding(true);
    }

    function handleCreateWeekdayDelta(weekdayDelta) {
        createApartmentPriceConfigWeekdayDelta({
            variables: {
                input: {
                    name: weekdayDelta.name,
                    weekdayDeltas: weekdayDelta.weekdayDeltas,
                },
            },
        });
        setIsAdding(false);
    }

    function handleUpdateWeekdayDelta(apartmentPriceConfigWeekdayDeltaId, weekdayDelta) {
        updateApartmentPriceConfigWeekdayDelta({
            variables: {
                input: {
                    apartmentPriceConfigWeekdayDeltaId,
                    name: weekdayDelta.name,
                    weekdayDeltas: weekdayDelta.weekdayDeltas,
                },
            },
        });
    }

    function handleDeleteWeekdayDelta(apartmentPriceConfigWeekdayDeltaId) {
        deleteApartmentPriceConfigWeekdayDelta({
            variables: {
                input: {
                    apartmentPriceConfigWeekdayDeltaId,
                },
            },
        });
    }

    return (
        <PageHeader
            title="Weekday deltas"
            onBack={() => navigate(-1)}
            extra={[
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => handleAddNew()}
                    disabled={isAdding}
                    key="addNew"
                >
                    Add new
                </Button>
            ]}
        >
            <Card loading={weekdayDeltasLoading}>
                {[...weekdayDeltasData?.apartmentPriceConfigWeekdayDeltas ?? []].length === 0 && !isAdding && (
                    <Row align="center">
                        <Col>
                            <Empty />
                        </Col>
                    </Row>
                )}
                {([...weekdayDeltasData?.apartmentPriceConfigWeekdayDeltas ?? []].length > 0 || isAdding) && (
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                <Col span={2} offset={7}>
                                    Sun
                                </Col>
                                <Col span={2}>
                                    Mon
                                </Col>
                                <Col span={2}>
                                    Tue
                                </Col>
                                <Col span={2}>
                                    Wed
                                </Col>
                                <Col span={2}>
                                    Thu
                                </Col>
                                <Col span={2}>
                                    Fri
                                </Col>
                                <Col span={2}>
                                    Sat
                                </Col>
                            </Row>
                        </Col>
                        {isAdding && (
                            <Col span={24}>
                                <WeekdayDelta
                                    isCreating={true}
                                    onSave={value => handleCreateWeekdayDelta(value)}
                                    onCancel={() => setIsAdding(false)}
                                />
                            </Col>
                        )}
                        {[...weekdayDeltasData?.apartmentPriceConfigWeekdayDeltas ?? []]
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(weekdayDelta => (
                                <Col
                                    span={24}
                                    key={weekdayDelta.id}
                                >
                                    <WeekdayDelta
                                        weekdayDelta={weekdayDelta}
                                        onSave={value => handleUpdateWeekdayDelta(weekdayDelta.id, value)}
                                        onDelete={() => handleDeleteWeekdayDelta(weekdayDelta.id)}
                                    />
                                </Col>
                            ))}
                    </Row>
                )}
            </Card>
        </PageHeader>
    )
}
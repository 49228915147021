import { Tag } from "antd";

export default function Channels(props) {
    const {
        channels,
    } = props;

    return (
        <>
            {channels.map(channel => (
                <Tag key={channel.id}>
                    {channel.name}
                </Tag>
            ))}
        </>
    );
}
import { useQuery } from "@apollo/client";
import { Select } from "antd";
import { fuzzySearch } from "common/common";
import { gql } from "graphql.macro";

const QUERY = gql`
    query GetApartmentsForApartmentSelect {
        apartments(filter: {active: true}) {
            id
            name
            tags
        }
        organization(organizationId: "self") {
            id
            apartmentTags
        }
    }
`;

export default function ApartmentSelectWithTags(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Select
                loading
                style={{
                    width: '250px',
                }}
            />
        );
    }

    if (error) {
        return (
            <Select
                status="error"
                style={{
                    width: '250px',
                }}
            />
        );
    }

    function handleOnSelect(item) {
        if (item === 'selectAll') {
            return onChange(data.apartments.map(apartment => apartment.id));
        }
        if (item === 'deselectAll') {
            return onChange([]);
        }
        if (item.startsWith('tag:')) {
            const tag = item.replace('tag:', '');
            const apartmentIdsWithTag = [...data.apartments]
                .filter(apartment => apartment.tags.includes(tag))
                .map(apartment => apartment.id);
            onChange(
                [...value, ...apartmentIdsWithTag]
                    .filter(((value, index, array) => array.indexOf(value) === index))
            )
        }
        if (item.startsWith('apartment:')) {
            const apartmentId = item.replace('apartment:', '')
            onChange([...value, apartmentId]);
        }
    }

    function handleOnDeselect(item) {
        if (item.startsWith('tag:')) {
            const tag = item.replace('tag:', '');
            const apartmentIdsWithTag = [...data.apartments]
                .filter(apartment => apartment.tags.includes(tag))
                .map(apartment => apartment.id);
            onChange([...value].filter(apartmentId => !apartmentIdsWithTag.includes(apartmentId)));
        }
        if (item.startsWith('apartment:')) {
            const deselectedApartmentId = item.replace('apartment:', '');
            onChange([...value].filter(apartmentId => apartmentId !== deselectedApartmentId));
        }
    }

    function isTagDisabled(tag) {
        return [...data.apartments ?? []]
            .filter(apartment => apartment.tags.includes(tag))
            .length === 0;
    }

    function isAllSelected() {
        return value?.length === data.apartments.length;
    }

    function getValue() {
        const apartmentIds = [...value ?? []]
            .map(apartmentId => `apartment:${apartmentId}`);
        const selectedTags = [...data.organization.apartmentTags ?? []]
            .filter(tag => {
                const apartmentsWithTag = [...data.apartments ?? []]
                    .filter(apartment => apartment.tags.includes(tag));
                if (apartmentsWithTag.length === 0) {
                    return false;
                }
                return apartmentsWithTag
                    .every(apartment => [...value ?? []].includes(apartment.id));
            })
            .map(tag => `tag:${tag}`);

        return [...apartmentIds, ...selectedTags];
    }

    function getLabel() {
        if (value && value.length > 0) {
            return `${value.length} apartments`;
        }
        else {
            return 'No apartments';
        }
    }

    const options = [
        isAllSelected()
            ? {
                label: 'Deselect all',
                value: 'deselectAll',
            }
            : {
                label: 'Select all',
                value: 'selectAll',
            },
        {
            label: 'Tags',
            options: [...data.organization.apartmentTags ?? []]
                .sort((a, b) => a.localeCompare(b))
                .map(tag => ({
                    label: tag,
                    value: `tag:${tag}`,
                    disabled: isTagDisabled(tag),
                })),
        },
        {
            label: 'Apartments',
            options: [...data.apartments ?? []]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(apartment => ({
                    label: apartment.name,
                    value: `apartment:${apartment.id}`,
                })),
        },
    ];

    return (
        <Select
            options={options}
            value={getValue()}
            onSelect={value => handleOnSelect(value)}
            onDeselect={value => handleOnDeselect(value)}
            style={{
                width: '250px',
            }}
            mode="multiple"
            maxTagCount={0}
            maxTagPlaceholder={getLabel()}
            showSearch
            filterOption={(input, option) => fuzzySearch(option.label, input)}
            {...otherProps}
        />
    );
}
import { SaveOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, Col, Form, PageHeader, Row, Skeleton, Tabs, message } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserGeneralForm from "users/forms/UserGeneralForm";
import UserUserGroupsForm from "users/forms/UserUserGroupsForm";
import UserWebMenuForm from "users/forms/UserWebMenuForm";
import UserPasswordForm from "users/forms/UserPasswordForm";
import UserPermissionsForm from "users/forms/UserPermissionsForm";
import { formatPhone } from "common/common";
import JobsPresets from "users/components/jobs-presets/JobsPresets";
import JobsFilterPresets from "users/components/jobs-filter-presets/JobsFilterPresets";
import UserSessionsList from "users/components/user-sessions-list/UserSessionsList";
import UserAvailabilityForm from "users/forms/UserAvailabilityForm";
import UserRelationsList from "users/components/user-relations-list/UserRelationsList";
import UserDelete from "users/components/user-delete/UserDelete";
import UserMobileMenuForm from "users/forms/UserMobileMenuForm";

const FORM_COLUMNS = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const QUERY = gql`
    query GetUserForUpdate($userId: ID!) {
        user(userId: $userId) {
            id
            firstName
            lastName
            email
            phone
            permissions
            status
            webMenu
            mobileMenu
            language
            groups {
                id
            }
            availabilityNote
        }
    }
`;

const MUTATION = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            error {
                type
                message
            }
            user {
                id
                firstName
                lastName
                email
                phone
                permissions
                status
                webMenu
                mobileMenu
                language
                groups {
                    id
                }
                availabilityNote
            }
        }
    }
`;

export default function UpdateUserView() {
    const { userId } = useParams();
    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useState('general');

    const { data, loading, error } = useQuery(QUERY, { variables: { userId } });

    const [updateUserLoading, setUpdateUserLoading] = useState(false);
    const [updateUser] = useMutation(MUTATION);

    const [generalForm] = Form.useForm();
    const [passwordForm] = Form.useForm();
    const [groupsForm] = Form.useForm();
    const [permissionsForm] = Form.useForm();
    const [webMenuForm] = Form.useForm();
    const [mobileMenuForm] = Form.useForm();
    const [availabilityForm] = Form.useForm();

    const groupIds = Form.useWatch('groupIds', groupsForm);
    const selectedGroupIds = groupIds || [...data?.user?.groups ?? []].map(group => group.id);

    if (loading) {
        return (
            <PageHeader
                title={<Skeleton.Input active />}
            >
                <Card loading />
            </PageHeader>
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load user"
            />
        );
    }

    const forms = {
        general: generalForm,
        password: passwordForm,
        groups: groupsForm,
        permissions: permissionsForm,
        webMenu: webMenuForm,
        mobileMenu: mobileMenuForm,
        availability: availabilityForm,
    };

    const items = [
        {
            label: 'General',
            key: 'general',
            children: (
                <UserGeneralForm
                    form={forms.general}
                    user={data?.user}
                    {...FORM_COLUMNS}
                />
            )
        },
        {
            label: 'Password',
            key: 'password',
            children: (
                <UserPasswordForm
                    form={forms.password}
                    user={data?.user}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Groups',
            key: 'groups',
            children: (
                <UserUserGroupsForm
                    form={forms.groups}
                    user={data?.user}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Permissions',
            key: 'permissions',
            children: (
                <UserPermissionsForm
                    form={forms.permissions}
                    user={data?.user}
                    inheritPermissionsFromGroupIds={selectedGroupIds}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Web menu',
            key: 'webMenu',
            children: (
                <UserWebMenuForm
                    form={forms.webMenu}
                    user={data?.user}
                    inheritMenuItemsFromGroupIds={selectedGroupIds}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Mobile menu',
            key: 'mobileMenu',
            children: (
                <UserMobileMenuForm
                    form={forms.mobileMenu}
                    user={data?.user}
                    inheritMenuItemsFromGroupIds={selectedGroupIds}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Relations',
            key: 'relations',
            children: (
                <UserRelationsList
                    userId={userId}
                />
            ),
        },
        {
            label: 'Jobs presets',
            key: 'jobsPresets',
            children: (
                <JobsPresets
                    userId={userId}
                />
            ),
        },
        {
            label: 'Jobs filter presets',
            key: 'jobsFilterPresets',
            children: (
                <JobsFilterPresets
                    userId={userId}
                />
            ),
        },
        {
            label: 'Availability',
            key: 'availability',
            children: (
                <UserAvailabilityForm
                    form={forms.availability}
                    user={data?.user}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Sessions',
            key: 'sessions',
            children: (
                <UserSessionsList
                    userId={userId}
                />
            ),
        },
        {
            label: 'Delete',
            key: 'delete',
            children: (
                <UserDelete
                    userId={userId}
                />
            ),
        },
    ];

    const updateFunctions = {
        general: values => {
            return updateUser({
                variables: {
                    input: {
                        userId,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        phone: formatPhone(values.phone),
                        language: values.language,
                    },
                },
            });
        },
        password: values => {
            return updateUser({
                variables: {
                    input: {
                        userId,
                        password: values.password,
                    },
                },
            });
        },
        groups: values => {
            return updateUser({
                variables: {
                    input: {
                        userId,
                        groupIds: values.groupIds,
                    },
                },
            });
        },
        permissions: values => {
            return updateUser({
                variables: {
                    input: {
                        userId,
                        permissions: values.permissions ?? [],
                    },
                },
            });
        },
        webMenu: values => {
            return updateUser({
                variables: {
                    input: {
                        userId,
                        webMenu: values.webMenu ?? [],
                    },
                },
            });
        },
        mobileMenu: values => {
            return updateUser({
                variables: {
                    input: {
                        userId,
                        mobileMenu: {
                            initialRoute: values.initialRoute,
                            items: values.items,
                        },
                    },
                },
            });
        },
        availability: values => {
            return updateUser({
                variables: {
                    input: {
                        userId,
                        availabilityNote: values.availabilityNote,
                    },
                },
            });
        }
    }

    function handleSave() {
        setUpdateUserLoading(true);
        forms[currentTab]
            .validateFields()
            .then(values => updateFunctions[currentTab](values))
            .then(response => {
                const error = response.data.updateUser.error;
                if (error) {
                    if (error.type === 'already_exists') {
                        message.error("Another user with this email or phone number already exists.");
                    }
                    if (error.type === 'unauthorized') {
                        message.error("You are not authorized to update user data");
                    }
                    return Promise.reject();
                }
                message.success('User updated');
                return Promise.resolve();
            })
            .finally(() => setUpdateUserLoading(false));
    }

    function canSave() {
        return forms[currentTab] !== undefined;
    }

    const onBack = userId !== 'self'
        ? () => navigate('/users')
        : null;

    return (
        <PageHeader
            title={`${data.user.firstName} ${data.user.lastName}`}
            onBack={onBack}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Tabs
                            items={items}
                            activeKey={currentTab}
                            onChange={value => setCurrentTab(value)}
                            tabPosition="left"
                        />
                    </Col>
                    {canSave() && (
                        <Col span={24}>
                            <Row justify="end">
                                <Col>
                                    <Button
                                        type='primary'
                                        onClick={() => handleSave()}
                                        loading={updateUserLoading}
                                        icon={<SaveOutlined />}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
            </Card>
        </PageHeader>
    );
}
import { Link } from "react-router-dom";
import { Col, Row, Space, Typography } from "antd";
import { grey } from "@ant-design/colors";

const DEFAULT_CHECKOUT = '11:00';
const DEFAULT_CHECKIN = '15:00';

export default function JobReservationSituation(props) {
    const {
        endingReservation,
        currentReservation,
        startingReservation,
    } = props;

    if (!endingReservation && !currentReservation && !startingReservation) {
        return (
            <Typography.Text
                style={{
                    color: grey[0],
                }}
            >
                No reservation
            </Typography.Text>
        );
    }

    if (currentReservation) {
        return (
            <Link to={`/reservations/${currentReservation.id}`}>
                Ongoing reservation
            </Link>
        );
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                {endingReservation && (
                    <Space>
                        <Link to={`/reservations/${endingReservation.id}`}>
                            Ending reservation
                        </Link>
                        <Typography.Text>
                            Check-out at {endingReservation.checkoutTime || DEFAULT_CHECKOUT}
                        </Typography.Text>
                    </Space>
                )}
                {!endingReservation && (
                    <Typography.Text>
                        No ending reservation
                    </Typography.Text>
                )}
            </Col>
            <Col span={24}>
                {startingReservation && (
                    <Space>
                        <Link to={`/reservations/${startingReservation.id}`}>
                            Starting reservation
                        </Link>
                        <Typography.Text>
                            Check-in at {startingReservation.checkinTime || DEFAULT_CHECKIN}
                        </Typography.Text>
                    </Space>
                )}
                {!startingReservation && (
                    <Typography.Text>
                        No starting reservation
                    </Typography.Text>
                )}
            </Col>
        </Row>
    );
}
import classNames from "classnames";
import { format, isWeekend } from "date-fns";

export default function ColumnHeader(props) {
    const {
        date,
        renderExtra,
    } = props;

    return (
        <div className="availability-calendar-column-header-container">
            <div className="availability-calendar-column-header-day-month">
                {format(date, 'dd/M')}
            </div>
            <div
                className={classNames({
                    "availability-calendar-column-header-weekday": true,
                    "availability-calendar-column-header-weekday-weekend": isWeekend(date),
                })}
            >
                {format(date, 'iii')}
            </div>
            {renderExtra?.(date)}
        </div>
    );
}
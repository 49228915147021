import { useState } from "react";
import { Form } from "antd";
import classNames from "classnames";
import JobWatchersModal from "jobs-new/components/JobWatchersModal";
import "./styles.css";

export default function JobWatchersList(props) {
    const {
        job,
        editable,
        noWrap,
        onUpdate,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();

    function handleSubmit() {
        form
            .validateFields()
            .then(values => {
                onUpdate(values.watchers);
                setModalOpen(false);
            });
    }

    function isEmpty() {
        return job.watchers.length === 0 && job.watcherGroups.length === 0;
    }

    function linkText() {
        if (isEmpty()) {
            return 'No watchers';
        }
        return [
            ...job.watchers.map(watcher => `${watcher.firstName} ${watcher.lastName}`),
            ...job.watcherGroups.map(watcherGroup => watcherGroup.name),
        ].join(', ');
    }

    return (
        <>
            <div
                className={classNames({
                    'job-watchers-list-nowrap': noWrap,
                })}
            >
                <span
                    onClick={() => editable && setModalOpen(true)}
                    className={classNames({
                        'job-watchers-list-link': editable,
                        'job-watchers-list-empty': isEmpty(),
                    })}
                >
                    {linkText()}
                </span>
            </div>
            <JobWatchersModal
                open={modalOpen}
                form={form}
                job={job}
                onSubmit={() => handleSubmit()}
                onCancel={() => setModalOpen(false)}
            />
        </>
    );
}
import { Form, Switch } from "antd";
import { useEffect } from "react";
import UserWebMenuSelect from "users/components/user-web-menu-select/UserWebMenuSelect";

export default function UserWebMenuForm(props) {
    const {
        user,
        form,
        inheritMenuItemsFromGroupIds,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            customize: user?.webMenu?.length > 0,
            webMenu: user?.webMenu,
        });
    }, [user, form]);

    const customize = Form.useWatch('customize', form);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="customize"
                label="Customize menu"
                valuePropName="checked"
                tooltip="Menu of this user will be inherited from assigned user groups"
            >
                <Switch />
            </Form.Item>
            {customize && (
                <Form.Item
                    name="webMenu"
                    label="Menu"
                >
                    <UserWebMenuSelect
                        inheritMenuItemsFromGroupIds={inheritMenuItemsFromGroupIds}
                    />
                </Form.Item>
            )}
        </Form>
    )
}
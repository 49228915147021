import { Tooltip } from "antd";
import classNames from "classnames";
import { format, isAfter, isBefore, isEqual } from "date-fns";

export default function Microcalendar(props) {
    const {
        calendar,
        reservations,
        markDate,
    } = props;

    function isStartingReservation(reservation, date) {
        return isEqual(reservation.startDate, date);
    }

    function isOngoingReservation(reservation, date) {
        return isBefore(reservation.startDate, date) && isAfter(reservation.endDate, date);
    }

    function isEndingReservation(reservation, date) {
        return isEqual(reservation.endDate, date);
    }

    function getStartingReservation(date) {
        return reservations.find(reservation => isStartingReservation(reservation, date));
    }

    function getOngoingReservation(date) {
        return reservations.find(reservation => isOngoingReservation(reservation, date));
    }

    function getEndingReservation(date) {
        return reservations.find(reservation => isEndingReservation(reservation, date));
    }

    return (
        <div className="microcalendar-container">
            {calendar.map((item, i) => (
                <div
                    className={classNames({
                        "microcalendar-item": true,
                        "microcalendar-item-first": i === 0,
                    })}
                    key={i}
                >
                    <div className="microcalendar-item-date">
                        {format(item.date, 'MM/dd')}
                    </div>
                    {item.available && (
                        <div className="microcalendar-item-box microcalendar-item-available" />
                    )}
                    {!item.available && item.comment && (
                        <Tooltip title={item.comment}>
                            <div className="microcalendar-item-box microcalendar-item-unavailable" />
                        </Tooltip>
                    )}
                    {!item.available && !item.comment && (
                        <div className="microcalendar-item-box microcalendar-item-unavailable" />
                    )}
                    {item.available && !item.minStayAvailable && (
                        <>
                            <div className="microcalendar-item-box microcalendar-item-unavailable" />
                            <div className="microcalendar-item-min-stay-unavailable" />
                        </>
                    )}
                    {getStartingReservation(item.date) && (
                        <div className="microcalendar-item-reservation microcalendar-item-starting-reservation" />
                    )}
                    {getOngoingReservation(item.date) && (
                        <div className="microcalendar-item-reservation microcalendar-item-ongoing-reservation" />
                    )}
                    {getEndingReservation(item.date) && (
                        <div className="microcalendar-item-reservation microcalendar-item-ending-reservation" />
                    )}
                    {isEqual(item.date, markDate) && (
                        <div className="microcalendar-item-date-mark" />
                    )}
                </div>
            ))}
        </div>
    );
}
import { gql, useQuery } from "@apollo/client";
import { Alert, Card, Col, Row, Typography } from "antd";
import { startOfToday, startOfTomorrow } from "date-fns";
import { useNavigate } from "react-router-dom";

const QUERY = gql`
    query GetReservationsToCheck($today: Date!, $tomorrow: Date!) {
        allReservationsToCheckCount: reservationsCount(filter: {
            status: "confirmed",
            hasNewGuestMessagesSinceLastCheck: true,
            endDateGte: $today
        })
        allReservationsToCheck: reservations(filter: {
            status: "confirmed",
            hasNewGuestMessagesSinceLastCheck: true,
            endDateGte: $today,
            limit: 1
        }) {
            id
        }
        arrivingReservationsToCheckCount: reservationsCount(filter: {
            status: "confirmed",
            hasNewGuestMessagesSinceLastCheck: true,
            startDateGte: $tomorrow,
            startDateLte: $tomorrow
        })
        arrivingReservationsToCheck: reservations(filter: {
            status: "confirmed",
            hasNewGuestMessagesSinceLastCheck: true,
            startDateGte: $tomorrow,
            startDateLte: $tomorrow,
            limit: 1
        }) {
            id
        }
        departingReservationsToCheckCount: reservationsCount(filter: {
            status: "confirmed",
            hasNewGuestMessagesSinceLastCheck: true,
            endDateGte: $tomorrow,
            endDateLte: $tomorrow
        })
        departingReservationsToCheck: reservations(filter: {
            status: "confirmed",
            hasNewGuestMessagesSinceLastCheck: true,
            endDateGte: $tomorrow,
            endDateLte: $tomorrow,
            limit: 1
        }) {
            id
        }
        ongoingReservationsToCheckCount: reservationsCount(filter: {
            status: "confirmed",
            hasNewGuestMessagesSinceLastCheck: true,
            startDateLte: $today,
            endDateGte: $today
        })
        ongoingReservationsToCheck: reservations(filter: {
            status: "confirmed",
            hasNewGuestMessagesSinceLastCheck: true,
            startDateLte: $today,
            endDateGte: $today,
            limit: 1
        }) {
            id
        }
    }
`;

export default function ReservationsCard() {
    const navigate = useNavigate();

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            today: startOfToday(),
            tomorrow: startOfTomorrow(),
        },
    });

    function handleAllReservationsToCheck() {
        const reservationId = data.allReservationsToCheck[0].id;
        navigate(`/reservations/check/${reservationId}`, {
            state: {
                filter: {
                    endDateGte: startOfToday(),
                },
            },
        });
    }

    function handleArrivingReservationsToCheck() {
        const reservationId = data.arrivingReservationsToCheck[0].id;
        navigate(`/reservations/check/${reservationId}`, {
            state: {
                filter: {
                    startDateGte: startOfTomorrow(),
                    startDateLte: startOfTomorrow(),
                },
            },
        });
    }

    function handleDepartingReservationsToCheck() {
        const reservationId = data.departingReservationsToCheck[0].id;
        navigate(`/reservations/check/${reservationId}`, {
            state: {
                filter: {
                    endDateGte: startOfTomorrow(),
                    endDateLte: startOfTomorrow(),
                },
            },
        });
    }

    function handleOngoingReservationsToCheck() {
        const reservationId = data.ongoingReservationsToCheck[0].id;
        navigate(`/reservations/check/${reservationId}`, {
            state: {
                filter: {
                    startDateLte: startOfToday(),
                    endDateGte: startOfToday(),
                },
            },
        });
    }

    return (
        <Card
            title="Reservations"
            loading={loading}
        >
            {error && (
                <Alert
                    type="error"
                    showIcon
                    message="Failed to load data"
                />
            )}
            {data && (
                <Row gutter={[0, 8]}>
                    <Col span={24}>
                        <Row gutter={[16, 0]}>
                            <Col flex={1}>
                                All reservations to check
                            </Col>
                            <Col flex={0}>
                                {data.allReservationsToCheckCount}
                            </Col>
                            <Col flex={0}>
                                <Typography.Link
                                    onClick={() => handleAllReservationsToCheck()}
                                    disabled={data.allReservationsToCheckCount === 0}
                                >
                                    View
                                </Typography.Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16, 0]}>
                            <Col flex={1}>
                                Ongoing
                            </Col>
                            <Col flex={0}>
                                {data.ongoingReservationsToCheckCount}
                            </Col>
                            <Col flex={0}>
                                <Typography.Link
                                    onClick={() => handleOngoingReservationsToCheck()}
                                    disabled={data.ongoingReservationsToCheckCount === 0}
                                >
                                    View
                                </Typography.Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16, 0]}>
                            <Col flex={1}>
                                Arriving tomorrow
                            </Col>
                            <Col flex={0}>
                                {data.arrivingReservationsToCheckCount}
                            </Col>
                            <Col flex={0}>
                                <Typography.Link
                                    onClick={() => handleArrivingReservationsToCheck()}
                                    disabled={data.arrivingReservationsToCheckCount === 0}
                                >
                                    View
                                </Typography.Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16, 0]}>
                            <Col flex={1}>
                                Departing tomorrow
                            </Col>
                            <Col flex={0}>
                                {data.departingReservationsToCheckCount}
                            </Col>
                            <Col flex={0}>
                                <Typography.Link
                                    onClick={() => handleDepartingReservationsToCheck()}
                                    disabled={data.departingReservationsToCheckCount === 0}
                                >
                                    View
                                </Typography.Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </Card>
    );
}
import { SaveOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, Col, Form, PageHeader, Row, Skeleton, Tabs } from "antd";
import { taskPromise } from "common/task";
import OrganizationGeneralForm from "organizations/forms/OrganizationGeneralForm";
import OrganizationApartmentTagsForm from "organizations/forms/OrganizationApartmentTagsForm";
import OrganizationJobTagsForm from "organizations/forms/OrganizationJobTagsForm";
// import OrganizationDefaultsForm from "organizations/forms/OrganizationDefaultsForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CalendarRestrictionsList from "organizations/components/calendar-restrictions-list/CalendarRestrictionsList";
import { useAuth } from "auth";
import JobsPresets from "organizations/components/jobs-presets/JobsPresets";
import JobsFilterPresets from "organizations/components/jobs-filter-presets/JobsFilterPresets";
import OrganizationAvailability from "organizations/components/organization-availability/OrganizationAvailability";
import OrganizationReservationTagsForm from "organizations/forms/OrganizationReservationTagsForm";
import OrganizationCleaningForm from "organizations/forms/OrganizationCleaningForm";
import ApartmentExpenseCategories from "organizations/components/apartment-expense-categories/ApartmentExpenseCategories";

const FORM_COLUMNS = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const QUERY = gql`
    query GetOrganization($organizationId: ID!) {
        organization(organizationId: $organizationId) {
            id
            name
            config
            timezone
            currency
            apartmentTags
            reservationTags
            jobTags
            cleaningDirtyDuringUnavailable
            cleaningDirtyDuringUnavailableForRent
            cleaningDirtyAfterUnavailable
            cleaningUserGroups {
                id
            }
        }
    }
`;

const MUTATION = gql`
    mutation UpdateOrganization($input: UpdateOrganizationInput!) {
        updateOrganization(input: $input) {
            error {
                type
                message
            }
            organization {
                id
                name
                config
                timezone
                currency
                apartmentTags
                reservationTags
                jobTags
                cleaningDirtyDuringUnavailable
                cleaningDirtyDuringUnavailableForRent
                cleaningDirtyAfterUnavailable
                cleaningUserGroups {
                    id
                }
            }
            syncOrganizationUpTask {
                id
            }
        }
    }
`;

export default function UpdateOrganizationView() {
    const { organizationId } = useParams();
    const navigate = useNavigate();

    const { user } = useAuth();

    const [currentTab, setCurrentTab] = useState('general');

    const { data, loading, error } = useQuery(QUERY, { variables: { organizationId } });

    const [updateOrganizationLoading, setUpdateOrganizationLoading] = useState(false);
    const [updateOrganization] = useMutation(MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Organization',
                    id: organizationId,
                }),
            });
        },
    });

    const [generalForm] = Form.useForm();
    const [cleaningForm] = Form.useForm();
    const [apartmentTagsForm] = Form.useForm();
    const [reservationTagsForm] = Form.useForm();
    const [jobTagsForm] = Form.useForm();
    // const [defaultsForm] = Form.useForm();

    if (loading) {
        return (
            <PageHeader
                title={<Skeleton.Input active />}
            >
                <Card loading />
            </PageHeader>
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load organization"
            />
        );
    }

    const forms = {
        general: generalForm,
        cleaning: cleaningForm,
        apartmentTags: apartmentTagsForm,
        reservationTags: reservationTagsForm,
        jobTags: jobTagsForm,
        // defaults: defaultsForm,
    };

    const items = [
        {
            key: 'general',
            label: 'General',
            children: (
                <OrganizationGeneralForm
                    form={forms.general}
                    organization={data?.organization}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            key: 'cleaning',
            label: 'Cleaning',
            children: (
                <OrganizationCleaningForm
                    form={forms.cleaning}
                    organization={data?.organization}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            key: 'apartmentTags',
            label: 'Apartment tags',
            children: (
                <OrganizationApartmentTagsForm
                    form={forms.apartmentTags}
                    organization={data?.organization}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            key: 'apartmentExpenseCategories',
            label: 'Apartment expenses',
            children: (
                <ApartmentExpenseCategories
                    organizationId={user?.organization?.id}
                />
            ),
        },
        {
            key: 'reservationTags',
            label: 'Reservation tags',
            children: (
                <OrganizationReservationTagsForm
                    form={forms.reservationTags}
                    organization={data?.organization}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            key: 'jobTags',
            label: 'Job tags',
            children: (
                <OrganizationJobTagsForm
                    form={forms.jobTags}
                    organization={data?.organization}
                    {...FORM_COLUMNS}
                />
            ),
        },
        // {
        //     key: 'defaults',
        //     label: 'Defaults',
        //     children: (
        //         <OrganizationDefaultsForm
        //             form={defaultsForm}
        //             organization={data?.organization}
        //             currency={data?.organization?.currency}
        //             {...FORM_COLUMNS}
        //         />
        //     ),
        // },
        {
            key: 'calendarRestrictions',
            label: 'Calendar restrictions',
            children: (
                <CalendarRestrictionsList />
            ),
        },
        {
            key: 'jobsPresets',
            label: 'Jobs presets',
            children: (
                <JobsPresets />
            ),
        },
        {
            key: 'jobsFilterPresets',
            label: 'Jobs filters presets',
            children: (
                <JobsFilterPresets />
            ),
        },
        {
            key: 'availability',
            label: 'Availability',
            children: (
                <OrganizationAvailability />
            ),
        },
    ];

    function handleSave() {
        setUpdateOrganizationLoading(true);
        forms[currentTab]
            .validateFields()
            .then(values => {
                return updateOrganization({
                    variables: {
                        input: {
                            organizationId: organizationId === 'self' ? user.organization.id : organizationId,
                            ...values,
                        }
                    }
                })
            })
            .then(response => taskPromise(response.data.updateOrganization?.syncOrganizationUpTask?.id))
            .finally(() => setUpdateOrganizationLoading(false));
    }

    function canSave() {
        return forms[currentTab] !== undefined;
    }

    const onBack = organizationId !== 'self'
        ? () => navigate('/users')
        : null;

    return (
        <PageHeader
            title={data?.organization?.name}
            onBack={onBack}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Tabs
                            items={items}
                            activeKey={currentTab}
                            onChange={value => setCurrentTab(value)}
                            tabPosition="left"
                        />
                    </Col>
                    {canSave() && (
                        <Col span={24}>
                            <Row justify="end">
                                <Col>
                                    <Button
                                        type="primary"
                                        onClick={() => handleSave()}
                                        icon={<SaveOutlined />}
                                        loading={updateOrganizationLoading}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
            </Card>
        </PageHeader>
    )
}
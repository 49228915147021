import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, Col, Form, PageHeader, Popconfirm, Row, message } from "antd";
import ApartmentExpenseForm from "expenses/forms/ApartmentExpenseForm";
import { useNavigate, useParams } from "react-router-dom";

const QUERY = gql`
    query GetApartmentExpense($apartmentExpenseId: ID!) {
        apartmentExpense(apartmentExpenseId: $apartmentExpenseId) {
            id
            apartment {
                id
            }
            category {
                id
            }
            amountNet
            amountGross
            dateFrom
            dateTo
            description
        }
    }
`;

const UPDATE_MUTATION = gql`
    mutation UpdateApartmentExpense($input: UpdateApartmentExpenseInput!) {
        updateApartmentExpense(input: $input) {
            error {
                type
                message
            }
            apartmentExpense {
                id
                apartment {
                    id
                }
                category {
                    id
                }
                amountNet
                amountGross
                dateFrom
                dateTo
                description
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation DeleteApartmentExpense($input: DeleteApartmentExpenseInput!) {
        deleteApartmentExpense(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function UpdateApartmentExpenseView() {
    const { apartmentExpenseId } = useParams();
    const navigate = useNavigate();

    const { data, loading, error } = useQuery(QUERY, { variables: { apartmentExpenseId } });
    const [updateApartmentExpense, { loading: updateLoading }] = useMutation(UPDATE_MUTATION);
    const [deleteApartmentExpense, { loading: deleteLoading }] = useMutation(DELETE_MUTATION, {
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'apartmentExpenses',
            });
        },
    });
    const [form] = Form.useForm();

    function handleUpdate() {
        form
            .validateFields()
            .then(values => {
                updateApartmentExpense({
                    variables: {
                        input: {
                            apartmentExpenseId,
                            apartmentId: values.apartmentId,
                            apartmentExpenseCategoryId: values.apartmentExpenseCategoryId,
                            amountNet: values.amountNet,
                            amountGross: values.amountGross,
                            dateFrom: values.dateFrom,
                            dateTo: values.dateTo,
                            description: values.description,
                        },
                    },
                })
                    .then(response => {
                        if (response.data.updateApartmentExpense.error) {
                            message.error('Failed to update expense');
                        }
                        else {
                            message.success('Updated expense');
                        }
                    });
            })
    }

    function handleDelete() {
        deleteApartmentExpense({
            variables: {
                input: {
                    apartmentExpenseId,
                },
            },
        })
            .then(response => {
                if (response.data.deleteApartmentExpense.error) {
                    message.error("Failed to delete apartment expense");
                }
                else {
                    navigate('/expenses');
                }
            });
    }

    return (
        <PageHeader
            title="Edit expense"
            onBack={() => navigate('/expenses')}
        >
            {loading && (
                <Card loading />
            )}
            {error && (
                <Alert
                    type="error"
                    showIcon
                    message="Failed to load apartment expense"
                />
            )}
            {data && (
                <Card>
                    <Row
                        gutter={[16, 16]}
                        justify="end"
                    >
                        <Col span={24}>
                            <ApartmentExpenseForm
                                form={form}
                                apartmentExpense={data.apartmentExpense}
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 8,
                                }}
                            />
                        </Col>
                        <Col>
                            <Popconfirm
                                title="Are you sure?"
                                okText="Delete"
                                okButtonProps={{ danger: true }}
                                onConfirm={() => handleDelete()}
                            >
                                <Button
                                    danger
                                    loading={deleteLoading}
                                    icon={<DeleteOutlined />}
                                >
                                    Delete
                                </Button>
                            </Popconfirm>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                onClick={() => handleUpdate()}
                                loading={updateLoading}
                                icon={<SaveOutlined />}
                            >
                                Update
                            </Button>
                        </Col>
                    </Row>
                </Card>
            )}
        </PageHeader>
    )
}
import { gql } from '@apollo/client';


export const GET_APARTMENT_PRICE_CONFIGS_QUERY = gql`
    query GetApartmentPriceConfigs {
        apartments {
            id
            name
            tags
            active
        }
    }
`;

export const GET_APARTMENT_PRICE_CONFIG_CHANGES_QUERY = gql`
    query GetApartmentPriceConfigChanges($apartmentPriceConfigId: ID!) {
        apartmentPriceConfig(apartmentPriceConfigId: $apartmentPriceConfigId) {
            id
            date
            changes {
                minPrice
                delta
                slope
                gapPenalties
                changedAt
            }
        }
    }
`;

export const GET_APARTMENT_PRICE_CONFIG_QUERY = gql`
    query GetApartmentPriceConfig($apartmentId: ID!, $dateFrom: Date, $dateTo: Date) {
        apartment(apartmentId: $apartmentId) {
            id
            name
            tags
            priceConfigs(filter: { dateFrom: $dateFrom, dateTo: $dateTo }) {
                id
                date
                minPrice
                delta
                slope
                gapPenalties
            }
            calendar(filter: { dateFrom: $dateFrom, dateTo: $dateTo }) {
                id
                date
                price
            }
        }
        apartmentPriceConfigWeekdayDeltas {
            id
            name
            weekdayDeltas
        }
        apartmentPriceConfigGapPenalties {
            id
            name
            gapPenalties
        }
    }
`;

export const GET_APARTMENT_PRICE_CONFIGS_FOR_MODIFICATION_QUERY = gql`
    query GetApartmentPriceConfigsForModification($apartmentIds: [ID], $dateFrom: Date, $dateTo: Date) {
        apartments(filter: {apartmentIds: $apartmentIds}) {
            id
            priceConfigs(filter: { dateFrom: $dateFrom, dateTo: $dateTo }) {
                id
                date
                minPrice
                delta
                slope
            }
        }
    }
`;

export const GET_APARTMENT_PRICE_CONFIGS_FOR_RECALCULATION_QUERY = gql`
    query GetApartmentPriceConfigsForRecalculation($apartmentIds: [ID]) {
        apartments(filter: {apartmentIds: $apartmentIds}) {
            id
        }
    }
`;

export const GET_APARTMENT_PRICE_CONFIGS_FOR_PASTE_QUERY = gql`
    query GetApartmentPriceConfigsForPaste($apartmentIds: [ID], $dateFrom: Date, $dateTo: Date) {
        apartments(filter: {apartmentIds: $apartmentIds}) {
            id
            priceConfigs(filter: { dateFrom: $dateFrom, dateTo: $dateTo }) {
                id
                date
                minPrice
                delta
                slope
            }
        }
    }
`;

export const GET_APARTMENT_PRICE_CONFIG_WEEKDAY_DELTAS_QUERY = gql`
    query GetApartmentPriceConfigWeekdayDeltas {
        apartmentPriceConfigWeekdayDeltas {
            id
            name
            weekdayDeltas
        }
    }
`;

export const CREATE_APARTMENT_PRICE_CONFIG_MUTATION = gql`
    mutation CreateApartmentPriceConfig($input: CreateApartmentPriceConfigInput!) {
        createApartmentPriceConfig(input: $input) {
            error {
                type
                message
            }
            apartmentPriceConfig {
                id
                apartment {
                    id
                }
                date
                minPrice
                delta
                slope
                gapPenalties
            }
        }
    }
`;

export const CREATE_APARTMENT_PRICE_CONFIGS_MUTATION = gql`
    mutation CreateApartmentPriceConfigs($input: CreateApartmentPriceConfigsInput!) {
        createApartmentPriceConfigs(input: $input) {
            error {
                type
                message
            }
            apartmentPriceConfigs {
                id
                apartment {
                    id
                }
                date
                minPrice
                delta
                slope
                gapPenalties
            }
        }
    }
`;

export const UPDATE_APARTMENT_PRICE_CONFIG_MUTATION = gql`
    mutation UpdateApartmenPriceConfig($input: UpdateApartmentPriceConfigInput!) {
        updateApartmentPriceConfig(input: $input) {
            error {
                type
                message
            }
            apartmentPriceConfig {
                id
                minPrice
                delta
                slope
                gapPenalties
            }
        }
    }
`;

export const UPDATE_APARTMENT_PRICE_CONFIGS_MUTATION = gql`
    mutation UpdateApartmentPriceConfigs($input: UpdateApartmentPriceConfigsInput!) {
        updateApartmentPriceConfigs(input: $input) {
            error {
                type
                message
            }
            apartmentPriceConfigs {
                id
                date
                minPrice
                delta
                slope
            }
        }
    }
`;

export const COPY_APARTMENT_PRICE_CONFIGS_MUTATION = gql`
    mutation CopyApartmentPriceConfigs($input: CopyApartmentPriceConfigsInput!) {
        copyApartmentPriceConfigs(input: $input) {
            error {
                type
                message
            }
            apartmentPriceConfigs {
                id
                date
                minPrice
                delta
                slope
            }
        }
    }
`;

export const CREATE_APARTMENT_PRICE_CONFIG_WEEKDAY_DELTA_MUTATION = gql`
    mutation CreateApartmenPriceConfigWeekdayDelta($input: CreateApartmentPriceConfigWeekdayDeltaInput!) {
        createApartmentPriceConfigWeekdayDelta(input: $input) {
            error {
                type
                message
            }
            apartmentPriceConfigWeekdayDelta {
                id
                name
                weekdayDeltas
            }
        }
    }
`;

export const CREATE_APARTMENT_PRICE_CONFIG_GAP_PENALTY_MUTATION = gql`
    mutation CreateApartmenPriceConfigGapPenalty($input: CreateApartmentPriceConfigGapPenaltyInput!) {
        createApartmentPriceConfigGapPenalty(input: $input) {
            error {
                type
                message
            }
            apartmentPriceConfigGapPenalty {
                id
                name
                gapPenalties
            }
        }
    }
`;

export const UPDATE_APARTMENT_PRICE_CONFIG_WEEKDAY_DELTA_MUTATION = gql`
    mutation UpdateApartmenPriceConfigWeekdayDelta($input: UpdateApartmentPriceConfigWeekdayDeltaInput!) {
        updateApartmentPriceConfigWeekdayDelta(input: $input) {
            error {
                type
                message
            }
            apartmentPriceConfigWeekdayDelta {
                id
                name
                weekdayDeltas
            }
        }
    }
`;

export const UPDATE_APARTMENT_PRICE_CONFIG_GAP_PENALTY_MUTATION = gql`
    mutation UpdateApartmenPriceConfigGapPenalty($input: UpdateApartmentPriceConfigGapPenaltyInput!) {
        updateApartmentPriceConfigGapPenalty(input: $input) {
            error {
                type
                message
            }
            apartmentPriceConfigGapPenalty {
                id
                name
                gapPenalties
            }
        }
    }
`;

export const DELETE_APARTMENT_PRICE_CONFIG_WEEKDAY_DELTA_MUTATION = gql`
    mutation DeleteApartmenPriceConfigWeekdayDelta($input: DeleteApartmentPriceConfigWeekdayDeltaInput!) {
        deleteApartmentPriceConfigWeekdayDelta(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export const DELETE_APARTMENT_PRICE_CONFIG_GAP_PENALTY_MUTATION = gql`
    mutation DeleteApartmenPriceConfigGapPenalty($input: DeleteApartmentPriceConfigGapPenaltyInput!) {
        deleteApartmentPriceConfigGapPenalty(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export const CREATE_ADJUST_PRICES_TASK = gql`
    mutation CreateAdjustApartmentPricesTask($input: CreateAdjustPricesTaskInput!) {
        createAdjustPricesTask(input: $input) {
            error {
                type
                message
            }
            task {
                id
            }
        }
    }
`;

import { gql, useQuery } from "@apollo/client";
import { Alert, Col, Row, Skeleton } from "antd";

const QUERY = gql`
    query GetReservationForExternalLinks($reservationId: ID!) {
        reservation(reservationId: $reservationId) {
            id
            airbnbConfirmationCode
            airbnbThreadId
        }
    }
`;

export default function ReservationExternalLinks(props) {
    const {
        reservationId,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            reservationId,
        },
    });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load reservation"
            />
        );
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <a
                    href={`https://www.airbnb.pl/hosting/inbox/folder/all/thread/${data.reservation.airbnbThreadId}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Go to conversation
                </a>
            </Col>
            <Col span={24}>
                <a
                    href={`https://www.airbnb.pl/hosting/reservations/all?confirmationCode=${data.reservation.airbnbConfirmationCode}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Go to reservation
                </a>
            </Col>
        </Row>
    );
}
import { QuestionOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Alert, Button, Card, Modal, PageHeader, Skeleton } from "antd";
import ApartmentMapping from "apartments/components/apartment-mapping/ApartmentMapping";
import MappingHelp from "apartments/components/apartment-mapping/MappingHelp";
import { useNavigate, useParams } from "react-router-dom";

const QUERY = gql`
    query GetApartmentForMapApartmentView($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            name
        }
    }
`;

export default function MapApartmentView() {
    const { apartmentId } = useParams();
    const navigate = useNavigate();

    const { data, loading, error } = useQuery(QUERY, { variables: { apartmentId } });

    if (loading) {
        return (
            <Skeleton
                style={{
                    margin: '24px',
                }}
            />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to get apartment"
            />
        );
    }

    function handleHelp() {
        Modal.info({
            title: 'Help',
            content: <MappingHelp />,
        });
    }

    return (
        <PageHeader
            title={data?.apartment?.name}
            onBack={() => navigate(`/apartments/${apartmentId}`)}
            extra={
                <Button
                    onClick={() => handleHelp()}
                    icon={<QuestionOutlined />}
                >
                    Help
                </Button>
            }
        >
            <Card>
                <ApartmentMapping
                    apartmentId={apartmentId}
                />
            </Card>
        </PageHeader>
    )
}
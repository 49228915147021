import { gql, useQuery } from "@apollo/client";
import { Alert, Col, Row, Skeleton } from "antd";
import { formatISO } from "date-fns";

const QUERY = gql`
    query GetCleaningInfo($date: Date!) {
        apartmentsForCleaning(date: $date) {
            id
        }
        cleaningGroups(filter: {dates: [$date]}) {
            id
            apartments {
                id
            }
        }
        organization(organizationId: "self") {
            id
            cleaningUserGroups {
                id
                users {
                    id
                    availability(filter: {dateFrom: $date, dateTo: $date}) {
                        id
                        availability
                    }
                }
                userAvailabilityTypes {
                    id
                    availability
                    label
                    color
                }
            }
            userAvailabilityTypes {
                id
                availability
                label
                color
            }
        }
    }
`;

export default function CleaningInfo(props) {
    const {
        date,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            date: formatISO(date, { representation: 'date' }),
        },
    });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load cleaning info"
            />
        );
    }

    const cleaningGroups = data.cleaningGroups.length;
    const unassignedApartmentsForCleaning = data.apartmentsForCleaning.length;
    const assignedApartmentsForCleaning = data.cleaningGroups.map(cleaningGroup => cleaningGroup.apartments.length).reduce((a, b) => a + b, 0);

    const cleaners = [...data.organization.cleaningUserGroups]
        .map(userGroup => userGroup.users)
        .flat()
        .filter((user, index, users) => users.findIndex(u => u.id === user.id) === index);

    const organizationAvailabilityTypes = data.organization.userAvailabilityTypes;
    const userGroupAvailabilityTypes = data.organization.cleaningUserGroups
        .map(userGroup => userGroup.userAvailabilityTypes)
        .flat();

    const availabilityTypes = [
        ...organizationAvailabilityTypes,
        ...userGroupAvailabilityTypes,
    ];

    const cleanerAvailabilities = availabilityTypes.map(availabilityType => ({
        label: availabilityType.label,
        cleaners: cleaners.filter(cleaner => cleaner.availability?.[0]?.availability === availabilityType.availability),
    }))

    function apartmentsPerCleaner(cleanersCount) {
        if (cleanersCount === 0) {
            return '-';
        }
        return Math.round(((unassignedApartmentsForCleaning + assignedApartmentsForCleaning) / cleanersCount) * 10) / 10;
    }

    return (
        <Row gutter={[8, 8]}>
            <Col span={12}>
                Cleaning groups
            </Col>
            <Col span={12}>
                {cleaningGroups}
            </Col>
            <Col span={12}>
                Unassigned apartments for cleaning
            </Col>
            <Col span={12}>
                {unassignedApartmentsForCleaning}
            </Col>
            <Col span={12}>
                Assigned apartments for cleaning
            </Col>
            <Col span={12}>
                {assignedApartmentsForCleaning}
            </Col>
            {cleanerAvailabilities.map((cleanerAvailability, index) => (
                <Col
                    span={24}
                    key={index}
                >
                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            Cleaners with status {cleanerAvailability.label}
                        </Col>
                        <Col span={12}>
                            {cleanerAvailability.cleaners.length} cleaners, average {apartmentsPerCleaner(cleanerAvailability.cleaners.length)} apartments per cleaner
                        </Col>
                    </Row>
                </Col>
            ))}
        </Row>
    );
}
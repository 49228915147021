export const DISPLAYED_COLUMNS_KEY = 'usersTable:displayedColumns';
export const DEFAULT_DISPLAYED_COLUMNS = ['groups'];

export function getStoredDisplayedColumns() {
    const storedDisplayedColumns = window.localStorage.getItem(DISPLAYED_COLUMNS_KEY);
    if (storedDisplayedColumns) {
        return JSON.parse(storedDisplayedColumns);
    }
    return [];
}

export function setStoredDisplayedColumns(displayedColumns) {
    window.localStorage.setItem(
        DISPLAYED_COLUMNS_KEY,
        JSON.stringify(displayedColumns),
    )
}
import { Alert, Skeleton } from "antd";
import { gql, useQuery } from "@apollo/client";
import Unmapped from "./Unmapped";
import Mappend from "./Mapped";

const QUERY = gql`
    query GetApartmentChannels($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            channels {
                id
            }
        }
    }
`;

export default function ApartmentChannels(props) {
    const {
        apartmentId,
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { apartmentId } });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartment channels"
            />
        );
    }

    if (data.apartment.channels.length === 0) {
        return (
            <Unmapped
                apartmentId={apartmentId}
            />
        );
    }
    else {
        return (
            <Mappend
                apartmentId={apartmentId}
                channelsCount={data.apartment.channels.length}
            />
        );
    }
}
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";

export default function OrganizationReservationTagsForm(props) {
    const {
        form,
        organization,
        ...otherProps
    } = props;

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                reservationTags: organization?.reservationTags ?? [],
            });
        }
    }, [form, organization]);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form
                    form={form}
                    {...otherProps}
                >
                    <Form.List
                        name="reservationTags"
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(field => (
                                    <Form.Item
                                        label="Tag"
                                        {...field}
                                        rules={[{ required: true, message: 'Tag can not be empty' }]}
                                    >
                                        <Input
                                            addonAfter={
                                                <DeleteOutlined
                                                    onClick={() => remove(field.name)}
                                                />
                                            }
                                        />
                                    </Form.Item>
                                ))}
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                    }}
                                >
                                    <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => add()}
                                    >
                                        Add tag
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Col>
        </Row>
    )
}
import { TableOutlined } from "@ant-design/icons";
import { Button, Card, PageHeader } from "antd";
import { Link } from "react-router-dom";
import StayqlQueriesList from "stayql/components/stayql-queries-list/StayqlQueriesList";

export default function ListStayqlQueriesView() {
    return (
        <PageHeader
            title="StayQL"
            extra={[
                <Link
                    to="/stayql/create"
                    key="testButton"
                >
                    <Button
                        type="primary"
                        icon={<TableOutlined />}
                    >
                        New query
                    </Button>
                </Link>
            ]}
        >
            <Card
                title="Queries"
                size="small"
            >
                <StayqlQueriesList />
            </Card>
        </PageHeader>
    );
}
import { useQuery } from "@apollo/client";
import { Col, Row, Select, Typography } from "antd";
import { gql } from "graphql.macro";
import haversine from 'haversine';

const QUERY = gql`
    query GetStorages {
        storages {
            id
            name
            latitude
            longitude
            order
        }
    }
`;

export default function StorageSelect(props) {
    const {
        value,
        onChange,
        sortBy,
        showDistance,
        distancePoint,
        ...otherProps
    } = props;

    const { data: storagesData, loading: storagesLoading } = useQuery(QUERY);

    function nameSorter(a, b) {
        return a.name.localeCompare(b.name);
    }

    function orderSorter(a, b) {
        return a.order - b.order;
    }

    function distanceSorter(a, b) {
        return haversine(a, distancePoint) - haversine(b, distancePoint);
    }

    function sortingFunction(a, b) {
        if (sortBy === 'name') {
            return nameSorter(a, b);
        }
        else if (sortBy === 'order') {
            return orderSorter(a, b);
        }
        else if (sortBy === 'distance' && distancePoint?.latitude && distancePoint?.longitude) {
            return distanceSorter(a, b);
        }
        else {
            return orderSorter(a, b);
        }
    }

    function optionLabel(storage) {
        if (showDistance && distancePoint?.latitude && distancePoint?.longitude) {
            return (
                <Row justify="space-between">
                    <Col>
                        {storage.name}
                    </Col>
                    <Col>
                        <Typography.Text disabled>
                            {Math.round(haversine(storage, distancePoint, { unit: 'meter' }))} m
                        </Typography.Text>
                    </Col>
                </Row>
            )
        }

        return storage.name;
    }

    return (
        <Select
            options={
                [...storagesData?.storages ?? []]
                    .sort((a, b) => sortingFunction(a, b))
                    .map(storage => ({
                        label: optionLabel(storage),
                        value: storage.id,
                    }))
            }
            loading={storagesLoading}
            value={value}
            onChange={value => onChange(value)}
            style={{
                width: '100%',
            }}
            {...otherProps}
        />
    );
}
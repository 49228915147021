import { useEffect } from "react";
import { Form, Select } from "antd";
import ApartmentSelect from "apartments/components/apartment-select/ApartmentSelect";
import StorageSelect from "storages/components/storage-select/StorageSelect";

const APARTMENT_RELATIONS = [
    {
        label: 'Main cleaner',
        value: 'cleaner_main',
    },
    {
        label: 'Cleaner',
        value: 'cleaner',
    },
];

const STORAGE_RELATIONS = [
    {
        label: 'Main cleaner',
        value: 'cleaner_main',
    },
    {
        label: 'Cleaner',
        value: 'cleaner',
    },
];

function getTypeFromRelation(relation) {
    if (relation?.apartment) {
        return 'apartment';
    }
    if (relation?.storage) {
        return 'storage';
    }
}

function getRelationsForType(type) {
    if (type === 'apartment') {
        return APARTMENT_RELATIONS;
    }
    if (type === 'storage') {
        return STORAGE_RELATIONS;
    }
}

export default function UserRelationForm(props) {
    const {
        userRelation,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            type: getTypeFromRelation(userRelation),
            apartmentId: userRelation?.apartment?.id,
            storageId: userRelation?.storage?.id,
            relation: userRelation?.relation,
        });
    }, [form, userRelation]);

    const type = Form.useWatch('type', form);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: 'Type is required' }]}
            >
                <Select
                    options={[
                        {
                            label: 'Apartment',
                            value: 'apartment',
                        },
                        {
                            label: 'Storage',
                            value: 'storage',
                        },
                    ]}
                    style={{
                        width: '200px',
                    }}
                />
            </Form.Item>
            {type === 'apartment' && (
                <Form.Item
                    name="apartmentId"
                    label="Apartment"
                    rules={[{ required: true, message: 'Apartment is required' }]}
                >
                    <ApartmentSelect />
                </Form.Item>
            )}
            {type === 'storage' && (
                <Form.Item
                    name="storageId"
                    label="Storage"
                    rules={[{ required: true, message: 'Storage is required' }]}
                >
                    <StorageSelect />
                </Form.Item>
            )}
            {type && (
                <Form.Item
                    name="relation"
                    label="Relation"
                    rules={[{ required: true, message: 'Relation is required' }]}
                >
                    <Select
                        options={getRelationsForType(type)}
                        style={{
                            width: '200px',
                        }}
                    />
                </Form.Item>
            )}
        </Form>
    )
}
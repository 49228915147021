import { gql, useQuery } from "@apollo/client";
import { Alert, Skeleton } from "antd";
import { compareAsc, isAfter } from "date-fns";
import ReservationMessage from "./ReservationMessage";
import "./style.css";

const QUERY = gql`
    query GetReservationMessages($reservationId: ID!) {
        reservation(reservationId: $reservationId) {
            id
            messages {
                id
                author
                content
                timestamp
                attachments {
                    id
                    fileName
                    getUrl
                }
                translation(language: "en") {
                    id
                    content
                }
            }
        }
    }
`;

export default function ReservationMessagesList(props) {
    const {
        reservationId,
        translate,
        markMessagesAfterTimestamp,
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { reservationId } });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load messages"
            />
        );
    }

    const messages = [...data.reservation.messages]
        .sort((a, b) => compareAsc(a.timestamp, b.timestamp));

    return (
        <div className="messages-container">
            {messages.length === 0 && (
                <Alert
                    type="info"
                    showIcon
                    message="There are no messages"
                />
            )}
            {messages.map(message => (
                <ReservationMessage
                    message={message}
                    translate={translate}
                    mark={markMessagesAfterTimestamp && message.author === 'guest' && isAfter(message.timestamp, markMessagesAfterTimestamp)}
                    key={message.id}
                />
            ))}
        </div>
    )
}
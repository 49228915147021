import { Form, message, Modal } from "antd";

import { SaveOutlined } from "@ant-design/icons";
import JobModalContent from "./JobModalContent";
import { useMutation } from "@apollo/client";
import { CREATE_JOB_MUTATION, UPDATE_JOB_MUTATION } from "./graphql";
import errorHandler from "common/errorHandler";
import { ATTACH_TO_MAPPING_TO_API, CATEGORY_MAPPING_TO_API, RESERVATION_DATE_MAPPING_TO_API } from "./common";
import { formatISO } from "date-fns";

export default function JobModal(props) {
    const { job, visible, title, onClose } = props;

    const [form] = Form.useForm();

    const [updateJob] = useMutation(UPDATE_JOB_MUTATION, {
        onCompleted(response) {
            if (response.updateJob.error) {
                errorHandler(response.updateJob.error);
            }
            else {
                message.success('Job updated');
            }
        }
    });

    const [createJob] = useMutation(CREATE_JOB_MUTATION, {
        onCompleted(response) {
            if (response.createJob.error) {
                errorHandler(response.createJob.error);
            }
            else {
                message.success('Job created');
            }
        },
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'jobs',
            });
        }
    });

    function handleJobSubmit(values) {
        if (job) {
            updateJob({
                variables: {
                    input: {
                        jobId: job.id,
                        attachTo: ATTACH_TO_MAPPING_TO_API[values.attachTo],
                        apartmentId: values.apartmentId,
                        date: values.date ? values.date : null,
                        reservationId: values.reservationId,
                        reservationDate: RESERVATION_DATE_MAPPING_TO_API[values.reservationDate] ?? null,
                        category: CATEGORY_MAPPING_TO_API[values.category],
                        description: values.description,
                    },
                },
            });
        }
        else {
            createJob({
                variables: {
                    input: {
                        attachTo: ATTACH_TO_MAPPING_TO_API[values.attachTo],
                        apartmentId: values.apartmentId,
                        date: values.date ? values.date : null,
                        reservationId: values.reservationId,
                        reservationDate: RESERVATION_DATE_MAPPING_TO_API[values.reservationDate] ?? null,
                        category: CATEGORY_MAPPING_TO_API[values.category],
                        description: values.description,
                    },
                },
            });
        }
        onClose();
    }

    return (
        <Modal
            visible={visible}
            title={title}
            okText="Save"
            onOk={() => form.submit()}
            okButtonProps={{
                icon: <SaveOutlined />
            }}
            onCancel={() => onClose()}
            destroyOnClose={true}
        >
            <JobModalContent
                job={{
                    ...job,
                    apartmentId: job?.apartment?.id,
                    reservationId: job?.reservation?.id,
                }}
                form={form}
                onSubmit={values => handleJobSubmit(values)}
            />
        </Modal>
    );
}
import { gql, useMutation } from "@apollo/client";
import { List, Typography } from "antd";
import { relationName } from "./common";

const DELETE_APARTMENT_RELATION_MUTATION = gql`
    mutation DeleteApartmentRelation($input: DeleteUserApartmentRelationInput!) {
        deleteUserApartmentRelation(input: $input) {
            error {
                type
                message
            }
            user {
                id
                apartmentRelations {
                    id
                    apartment {
                        id
                    }
                    relation
                }
            }
        }
    }
`;

const DELETE_STORAGE_RELATION_MUTATION = gql`
    mutation DeleteStorageRelation($input: DeleteUserStorageRelationInput!) {
        deleteUserStorageRelation(input: $input) {
            error {
                type
                message
            }
            user {
                id
                storageRelations {
                    id
                    storage {
                        id
                    }
                    relation
                }
            }
        }
    }
`;

export default function Item(props) {
    const {
        relation,
    } = props;

    const [deleteApartmentRelation] = useMutation(DELETE_APARTMENT_RELATION_MUTATION);
    const [deleteStorageRelation] = useMutation(DELETE_STORAGE_RELATION_MUTATION);

    function handleDelete() {
        if (relation.apartment) {
            deleteApartmentRelation({
                variables: {
                    input: {
                        userApartmentRelationId: relation.id,
                    },
                },
            });
        }
        if (relation.storage) {
            deleteStorageRelation({
                variables: {
                    input: {
                        userStorageRelationId: relation.id,
                    },
                },
            });
        }
    }

    return (
        <List.Item
            actions={[
                <Typography.Link onClick={() => handleDelete()}>
                    Delete
                </Typography.Link>
            ]}
        >
            <List.Item.Meta
                title={relation.apartment?.name ?? relation.storage?.name}
                description={relationName(relation.relation)}
            />
        </List.Item>
    );
}
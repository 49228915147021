import { differenceInDays } from "date-fns";
import "./style.css";

export default function ReservationValue(props) {
    const {
        value,
        reservation,
    } = props;

    const reservationLength = differenceInDays(reservation.endDate, reservation.startDate);
    const style = {
        maxWidth: `${reservationLength * 50 - 25}px`,
    };

    function getValue() {
        if (value === 'guestName') {
            return `${reservation.guestFirstName} ${reservation.guestLastName}`;
        }
        if (value === 'numberOfGuests') {
            return `${reservation.numberOfGuests} guests`;
        }
        if (value === 'price') {
            return reservation.priceAccommodation;
        }
        if (value === 'bookingWindow') {
            return differenceInDays(reservation.startDate, reservation.bookedAt);
        }
    }

    return (
        <div
            className="calendar-reservation-value-container"
            style={style}
        >
            {getValue()}
        </div>
    );
}
import { Link } from 'react-router-dom';
import { List } from 'antd';

export default function StatisticsViewsList(props) {
    const { statisticsViews, loading } = props;

    return (
        <List
            dataSource={statisticsViews}
            loading={loading}
            renderItem={statisticsView => (
                <List.Item>
                    <Link to={`/statistics/${statisticsView.id}`}>
                        {statisticsView.name}
                    </Link>
                </List.Item>
            )}
        />
    )
}
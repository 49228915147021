import { geekblue, green, purple } from "@ant-design/colors";

export const RESERVATION_DATES = [
    { label: 'Start date', value: 'startDate' },
    { label: 'End date', value: 'endDate' },
];

export const JOB_CATEGORIES = [
    { label: 'Manager', value: 'manager' },
    { label: 'Technician', value: 'technician' },
    { label: 'Cleaner', value: 'cleaner' },
];

export const ATTACH_TO_MAPPING_FROM_API = {
    apartment: 'apartment',
    reservation: 'reservation',
};

export const ATTACH_TO_MAPPING_TO_API = {
    apartment: 'apartment',
    reservation: 'reservation',
}

export const RESERVATION_DATE_MAPPING_FROM_API = {
    start_date: 'startDate',
    end_date: 'endDate',
};

export const RESERVATION_DATE_MAPPING_TO_API = {
    startDate: 'start_date',
    endDate: 'end_date',
};

export const CATEGORY_MAPPING_FROM_API = {
    manager: 'manager',
    technician: 'technician',
    cleaner: 'cleaner',
}

export const CATEGORY_MAPPING_TO_API = {
    manager: 'manager',
    technician: 'technician',
    cleaner: 'cleaner',
}

export const JOBS_CATEGORY_COLOR = {
    Managerowie: purple[3],
    Sprzątające: green[5],
    Techniczni: geekblue[3],
};
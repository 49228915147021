import { geekblue, green, grey, purple } from "@ant-design/colors";

export default function JobBox(props) {
    const { job, onClick } = props;

    function categoryColor(watcherGroups) {
        if (watcherGroups.some(group => group.name === 'Managerowie')) {
            return purple[3];
        }
        if (watcherGroups.some(group => group.name === 'Sprzątające')) {
            return green[5];
        }
        if (watcherGroups.some(group => group.name === 'Techniczni')) {
            return geekblue[3];
        }
        return grey[3];
    }

    function shortenApartmentName(name) {
        const regex = /^(.*) ([0-9a-zA-Z]+\/[0-9a-zA-Z]+)$/;
        const match = name.match(regex);

        if (match && match.length > 0) {
            return match[1].slice(0, 3) + ' ' + match[2];
        }
        else {
            return name;
        }
    }

    return (
        <div
            onClick={onClick}
            style={{
                padding: '8px 0 8px 16px',
                backgroundColor: categoryColor(job.watcherGroups),
                color: 'white',
                borderRadius: '4px',
                cursor: 'pointer',
            }}
        >
            <div
                style={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                }}
            >
                {shortenApartmentName(job.apartment?.name || job.storage?.name).toUpperCase()}
            </div>
            <div
                style={{
                    fontSize: '12px',
                }}
            >
                {job.title}
            </div>
        </div>
    );
}
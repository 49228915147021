import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Input, InputNumber, Result, Row, Space, Switch, Typography } from "antd";
import { useDebounce } from "common/debounce";
import GeocodingList from "components/GeocodingList";
import Map from "components/Map";
import { useEffect, useState } from "react";

export default function LocationForm(props) {
    const {
        coordinates,
        form,
        initialQuery,
        ...otherProps
    } = props;

    const [query, setQuery] = useState(initialQuery);
    const queryDebounced = useDebounce(query, 500)
    const [validationError, setValidationError] = useState(false);
    const [draggable, setDraggable] = useState(false);
    const latitude = Form.useWatch('latitude', form);
    const longitude = Form.useWatch('longitude', form);

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                latitude: coordinates?.latitude,
                longitude: coordinates?.longitude,
            });
        }
    }, [coordinates, form]);

    return (
        <Row gutter={[16, 16]}>
            <Form
                form={form}
                {...otherProps}
            >
                <Form.Item
                    name="latitude"
                    hidden
                    rules={[
                        { required: true },
                        () => ({
                            validator(_, value) {
                                if (!value) {
                                    setValidationError(true);
                                    return Promise.reject();
                                }
                                else {
                                    setValidationError(false);
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    name="longitude"
                    hidden
                    rules={[
                        { required: true },
                        () => ({
                            validator(_, value) {
                                if (!value) {
                                    setValidationError(true);
                                    return Promise.reject();
                                }
                                else {
                                    setValidationError(false);
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}
                >
                    <InputNumber />
                </Form.Item>
            </Form>
            <Col span={14}>
                {(latitude && longitude) && (
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <div
                                style={{
                                    width: '100%',
                                    aspectRatio: '1/1',
                                }}
                            >
                                <Map
                                    markers={[{
                                        coordinates: [latitude, longitude],
                                        draggable,
                                    }]}
                                    onMarkersChange={markers => {
                                        form.setFieldValue('latitude', markers[0].coordinates.lat);
                                        form.setFieldValue('longitude', markers[0].coordinates.lng);
                                    }}
                                    defaultZoom={18}
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </Col>
                        <Col span={24}>
                            <Space>
                                <Switch
                                    checked={draggable}
                                    onChange={value => {
                                        setDraggable(value);
                                    }}
                                />
                                <Typography.Text>
                                    Make marker draggable
                                </Typography.Text>
                            </Space>
                        </Col>
                    </Row>
                )}
                {!(latitude && longitude) && !validationError && (
                    <Result
                        icon={<SearchOutlined />}
                        title="Search address"
                        subTitle="Search for the address and choose result from the list"
                    />
                )}
                {!(latitude && longitude) && validationError && (
                    <Result
                        icon={<SearchOutlined />}
                        status="error"
                        title="Search address"
                        subTitle="Search for the address and choose result from the list"
                    />
                )}
            </Col>
            <Col span={10}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Input.Search
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            placeholder="Address"
                        />
                    </Col>
                    <Col span={24}>
                        <GeocodingList
                            query={queryDebounced}
                            onChoose={item => {
                                form.setFieldValue('latitude', item.coordinates.latitude);
                                form.setFieldValue('longitude', item.coordinates.longitude);
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
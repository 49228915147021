import { format } from "date-fns";

export default function JobStatusChange(props) {
    const {
        statusChange,
    } = props;

    return (
        <div className="log-container">
            <div className="log-header">
                <div className="log-author">
                    {statusChange.updater.firstName} {statusChange.updater.lastName}
                </div>
                <div className="log-timestamp">
                    {format(statusChange.updatedAt, 'yyyy-MM-dd HH:mm')}
                </div>
            </div>
            <div className="log-content status-change">
                Status changed to {statusChange.status}
            </div>
        </div>
    );
}
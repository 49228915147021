import { gql, useQuery } from "@apollo/client";
import { Dropdown } from "antd";
import UserAvailabilityStatus from "../user-availability-status/UserAvailabilityStatus";
import { intersection } from "common/common";

const QUERY = gql`
    query GetAvailabilityTypesForControls {
        organization(organizationId: "self") {
            id
            userAvailabilityTypes {
                id
                availability
            }
            userGroups {
                id
                users {
                    id
                }
                userAvailabilityTypes {
                    id
                    availability
                }
            }
        }
    }
`;

export default function ContextMenu(props) {
    const {
        selectedDays,
        setAvailabilityDisabled,
        clearAvailabilityDisabled,
        addCommentDisabled,
        clearCommentDisabled,
        disabled,
        trigger,
        onAction,
        children,
    } = props;

    const { data } = useQuery(QUERY);

    const userIds = selectedDays.map(([userId, _]) => userId);

    const organizationUserAvailabilities = [...data?.organization?.userAvailabilityTypes ?? []]
        .map(item => item.availability);
    const userUserGroupAvailabilities = userIds.map(userId => {
        return [...data?.organization?.userGroups ?? []]
            .filter(userGroup => userGroup.users.some(user => user.id === userId))
            .map(userGroup => [...userGroup.userAvailabilityTypes].map(availabilityType => availabilityType.availability))
            .flat();
    });

    const commonUserGroupAvailabilities = userUserGroupAvailabilities.length > 0
        ? userUserGroupAvailabilities.reduce((a, b) => intersection(a, b))
        : [];

    const availableAvailabilities = [...organizationUserAvailabilities, ...commonUserGroupAvailabilities]
        .filter((item, index, array) => array.indexOf(item) === index)
        .sort((a, b) => a.localeCompare(b));

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        key: 'setAvailability',
                        label: 'Set availability',
                        disabled: setAvailabilityDisabled,
                        children: availableAvailabilities.map(availability => ({
                            key: availability,
                            label: (
                                <UserAvailabilityStatus
                                    availability={availability}
                                    shape="circle"
                                    size={16}
                                    showLabel
                                />
                            )
                        })),
                    },
                    {
                        key: 'clearAvailability',
                        label: 'Clear availability',
                        disabled: clearAvailabilityDisabled,
                    },
                    {
                        key: 'addComment',
                        label: 'Add comment',
                        disabled: addCommentDisabled,
                    },
                    {
                        key: 'clearComment',
                        label: 'Clear comment',
                        disabled: clearCommentDisabled,
                    },
                ],
                onClick: ({ keyPath }) => onAction([...keyPath].reverse()),
            }}
            trigger={trigger}
            disabled={disabled}
        >
            {children}
        </Dropdown>
    )
}
import { Form, Input } from "antd";
import { useEffect } from "react";

export default function UserOnboardingForm(props) {
    const {
        user,
        form,
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            firstName: user.firstName,
            lastName: user.lastName,
        })
    }, [user, form]);

    return (
        <Form
            form={form}
        >
            <Form.Item
                name="firstName"
                label="First name"
                rules={[{ required: true, message: 'First name is required' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="lastName"
                label="Last name"
                rules={[{ required: true, message: 'Last name is required' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Password is required' }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                name="passwordConfirm"
                label="Confirm password"
                dependencies={['password']}
                hasFeedback
                rules={[
                    { required: true, message: 'Confirm password is required' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Password does not match'));
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>
        </Form>
    );
}
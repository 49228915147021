import { DeleteOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { List, message, Popconfirm } from "antd";
import { Link } from "react-router-dom";

const MUTATION = gql`
    mutation DeleteStayqlQuery($input: DeleteStayqlQueryInput!) {
        deleteStayqlQuery(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        stayqlQuery,
    } = props;

    const [deleteStayqlQuery, { loading }] = useMutation(MUTATION, {
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'stayqlQueries',
            });
        },
    });

    function handleDelete() {
        deleteStayqlQuery({
            variables: {
                input: {
                    stayqlQueryId: stayqlQuery.id,
                },
            },
        })
            .then(response => {
                if (response.data.deleteStayqlQuery.error) {
                    message.error("Failed to delte query");
                }
                else {
                    message.success("Query deleted");
                }
            })
            .catch(() => {
                message.error("Failed to delete query");
            });
    }

    return (
        <List.Item
            actions={[
                <Popconfirm
                    title="Do you want to delete this query?"
                    okText="Delete"
                    okButtonProps={{
                        danger: true,
                        loading,
                    }}
                    icon={<DeleteOutlined />}
                    placement="right"
                    onConfirm={() => handleDelete()}
                >
                    <DeleteOutlined />
                </Popconfirm>
            ]}
        >
            <Link to={`/stayql/${stayqlQuery.id}`}>
                {stayqlQuery.name}
            </Link>
        </List.Item>
    );
}
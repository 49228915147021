import { Col, Popconfirm, Row, Space, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export default function JobPopoverTitle(props) {
    const { job, onDelete } = props;

    const navigate = useNavigate();

    return (
        <Row
            justify="space-between"
            align="middle"
        >
            <Col>
                <Typography.Title
                    level={5}
                    style={{
                        padding: '8px 0',
                        margin: '0'
                    }}
                >
                    {job.apartment?.name ?? job.storage?.name}
                </Typography.Title>
            </Col>
            <Col>
                <Space>
                    <Popconfirm
                        title="Do you want to delete this job?"
                        onConfirm={() => onDelete()}
                        okText="Yes"
                    >
                        <DeleteOutlined />
                    </Popconfirm>
                    <EditOutlined
                        onClick={() => navigate(`/jobs-new/${job.id}/edit`)}
                    />
                </Space>
            </Col>
        </Row>
    );
}
import { useState } from "react";
import { blue, grey } from "@ant-design/colors";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function JobPriority(props) {
    const {
        priority,
        editable,
        onChange,
    } = props;

    const [hoverPriority, setHoverPriority] = useState();

    function handleClick(value) {
        if (!editable) {
            return;
        }

        onChange(value);
    }

    function color(value) {
        if (editable && hoverPriority) {
            return hoverPriority >= value ? blue.primary : grey[0];
        }

        return priority >= value ? blue.primary : grey[0];
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <ExclamationCircleOutlined
                onClick={() => handleClick(1)}
                onMouseEnter={() => setHoverPriority(1)}
                onMouseLeave={() => setHoverPriority()}
                style={{
                    color: color(1),
                    paddingRight: '3px',
                }}
            />
            <ExclamationCircleOutlined
                onClick={() => handleClick(2)}
                onMouseEnter={() => setHoverPriority(2)}
                onMouseLeave={() => setHoverPriority()}
                style={{
                    color: color(2),
                    paddingRight: '3px',
                }}
            />
            <ExclamationCircleOutlined
                onClick={() => handleClick(3)}
                onMouseEnter={() => setHoverPriority(3)}
                onMouseLeave={() => setHoverPriority()}
                style={{
                    color: color(3),
                    paddingRight: '3px',
                }}
            />
            <ExclamationCircleOutlined
                onClick={() => handleClick(4)}
                onMouseEnter={() => setHoverPriority(4)}
                onMouseLeave={() => setHoverPriority()}
                style={{
                    color: color(4),
                    paddingRight: '3px',
                }}
            />
            <ExclamationCircleOutlined
                onClick={() => handleClick(5)}
                onMouseEnter={() => setHoverPriority(5)}
                onMouseLeave={() => setHoverPriority()}
                style={{
                    color: color(5),
                }}
            />
        </div>
    );
}
import { gql, useMutation } from "@apollo/client";
import { Button, Card, Col, Form, PageHeader, Row, Tabs } from "antd";
import { taskPromise } from "common/task";
import OrganizationGeneralForm from "organizations/forms/OrganizationGeneralForm";
import OrganizationApartmentTagsForm from "organizations/forms/OrganizationApartmentTagsForm";
import OrganizationJobTagsForm from "organizations/forms/OrganizationJobTagsForm";
import OrganizationDefaultsForm from "organizations/forms/OrganizationDefaultsForm";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import OrganizationReservationTagsForm from "organizations/forms/OrganizationReservationTagsForm";

const FORM_COLUMNS = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const MUTATION = gql`
    mutation CreateOrganization($input: CreateOrganizationInput!) {
        createOrganization(input: $input) {
            error {
                type
                message
            }
            organization {
                id
                name
                config
                timezone
                currency
                apartmentTags
                jobTags
                reservationTags
            }
            syncOrganizationUpTask {
                id
            }
        }
    }
`;

export default function CreateOrganizationView() {
    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useState('general');
    const [organization, setOrganization] = useState();
    const [loading, setLoading] = useState(false);

    const [createOrganization] = useMutation(MUTATION);

    const [generalForm] = Form.useForm();
    const [apartmentTagsForm] = Form.useForm();
    const [jobTagsForm] = Form.useForm();
    const [reservationTagsForm] = Form.useForm();
    const [defaultsForm] = Form.useForm();

    const forms = {
        general: generalForm,
        apartmentTags: apartmentTagsForm,
        jobTags: jobTagsForm,
        reservationTags: reservationTagsForm,
        defaults: defaultsForm,
    };

    const items = [
        {
            key: 'general',
            label: 'General',
            children: (
                <OrganizationGeneralForm
                    form={forms.general}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            key: 'apartmentTags',
            label: 'Apartment tags',
            children: (
                <OrganizationApartmentTagsForm
                    form={forms.apartmentTags}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            key: 'reservationTags',
            label: 'Reservation tags',
            children: (
                <OrganizationReservationTagsForm
                    form={forms.reservationTags}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            key: 'jobTags',
            label: 'Job tags',
            children: (
                <OrganizationJobTagsForm
                    form={forms.jobTags}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            key: 'defaults',
            label: 'Defaults',
            children: (
                <OrganizationDefaultsForm
                    form={forms.defaults}
                    currency={organization?.currency}
                    {...FORM_COLUMNS}
                />
            ),
        },
    ];

    const isLastTab = currentTab === items[items.length - 1].key;

    function handleNext() {
        const nextTab = items[items.findIndex(item => item.key === currentTab) + 1];
        forms[currentTab]
            .validateFields()
            .then(values => {
                setOrganization({
                    ...organization,
                    ...values,
                });
                setCurrentTab(nextTab.key);
            });
    }


    function handleCreate() {
        setLoading(true);
        items
            .reduce((promise, item) => {
                return promise
                    .then(organization => {
                        return forms[item.key]
                            .validateFields()
                            .then(values => {
                                return {
                                    ...organization,
                                    ...values,
                                };
                            })
                            .catch(() => {
                                setCurrentTab(item.key);
                                return Promise.reject();
                            });
                    })
            }, Promise.resolve({}))
            .then(organization => {
                return createOrganization({
                    variables: {
                        input: {
                            name: organization.name,
                            timezone: organization.timezone,
                            currency: organization.currency,
                            apartmentTags: organization.apartmentTags,
                            jobTags: organization.jobTags,
                            reservationTags: organization.reservationTags,
                            config: JSON.stringify({}),
                        },
                    },
                })
            })
            .then(response => {
                const organizationId = response.data.createOrganization.organization.id;
                const taskId = response.data.createOrganization.syncOrganizationUpTask?.id;
                return taskPromise(taskId)
                    .then(() => navigate(`/organizations/${organizationId}`));
            })
            .catch(() => { })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <PageHeader
            title="Create oranization"
            onBack={() => navigate("/organizations")}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Tabs
                            items={items}
                            activeKey={currentTab}
                            onChange={value => setCurrentTab(value)}
                            tabPosition="left"
                        />
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                {!isLastTab && (
                                    <Button
                                        type="primary"
                                        onClick={() => handleNext()}
                                        loading={loading}
                                    >
                                        Next
                                    </Button>
                                )}
                                {isLastTab && (
                                    <Button
                                        type="primary"
                                        onClick={() => handleCreate()}
                                        loading={loading}
                                    >
                                        Create
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    );
}
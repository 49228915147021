import { Tabs, Typography } from "antd";

export default function MappingHelp() {
    const items = [
        {
            label: 'Airbnb',
            key: 'airbnb',
            children: (
                <ol>
                    <li>
                        Locate your Airbnb channel. You should have as many Airbnb channels as you have Airbnb hosting accounts.
                    </li>
                    <li>
                        Click <Typography.Text strong>Actions</Typography.Text> on the row with your channel and choose <Typography.Text strong>Edit</Typography.Text>.
                    </li>
                    <li>
                        In the <Typography.Text strong>General Settings</Typography.Text> tab find <Typography.Text strong>Affected properties</Typography.Text> section and select the apartment you want to map
                    </li>
                    <li>
                        In the <Typography.Text strong>Mapping</Typography.Text> tab use search bar to find your apartment. The search bar supports fuzzy searching, so you can type only part of the name.
                    </li>
                    <li>
                        Click on <Typography.Text strong>Not mapped</Typography.Text> link next to the apartment you want to connect and in <Typography.Text strong>Please select rate</Typography.Text> choose your apartment in first two columns and in the third column choose option with one guest.
                    </li>
                    <li>
                        Click <Typography.Text strong>Save</Typography.Text> in the <Typography.Text strong>Map rate</Typography.Text> window
                    </li>
                    <li>
                        Click <Typography.Text strong>Save</Typography.Text> in the <Typography.Text strong>Edit Channel</Typography.Text> pane
                    </li>
                    <li>
                        Your apartment is mapped
                    </li>
                </ol>
            ),
        },
        {
            label: 'Booking.com',
            key: 'bookingCom',
            children: (
                <Typography.Paragraph>
                    This is not implemented yet
                </Typography.Paragraph>
            )
        }
    ];

    return (
        <>
            <Typography.Paragraph>
                To perform the mapping, please wait for the below window to load and follow instructions in the tab of the channel you want to connect.
            </Typography.Paragraph>
            <Tabs
                items={items}
            />
        </>
    )
}
import { List } from "antd";

export default function StorageApartmentsList(props) {
    const {
        apartments,
    } = props;

    return (
        <List
            dataSource={apartments}
            renderItem={apartment => (
                <List.Item>
                    <List.Item.Meta
                        title={apartment.name}
                    />
                </List.Item>
            )}
        />
    );
}
import { ApolloClient } from '@apollo/client';
import { HttpLink } from '@apollo/client/link/http'
import { InMemoryCache } from '@apollo/client/cache';
import { formatISO, parseISO } from 'date-fns';
import { setContext } from '@apollo/client/link/context';
import { schema } from 'schema';
import { ScalarApolloLink } from 'apollo-link-scalars';

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
    const token = window.localStorage.getItem('authToken');
    return {
        headers: {
            ...headers,
            ...token ? { Authorization: `Bearer ${token}` } : {},
        },
    };
});

const typesMap = {
    Date: {
        serialize: value => value instanceof Date ? formatISO(value, { representation: 'date' }) : value,
        parseValue: value => parseISO(value),
    },
    Time: {
        serialize: value => value,
        parseValue: value => value,
    },
    DateTime: {
        serialize: value => formatISO(value),
        parseValue: value => parseISO(value),
    },
    JSON: {
        serialize: value => JSON.stringify(value),
        parseValue: value => JSON.parse(value),
    },
    Money: {
        serialize: value => value.toString(),
        parseValue: value => parseFloat(value),
    },
};

const scalarsLink = new ScalarApolloLink({
    schema,
    typesMap,
});

export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                apartment: {
                    read(_, { args, toReference }) {
                        return toReference({
                            __typename: 'Apartment',
                            id: args.apartmentId,
                        });
                    },
                },
                cleaningGroup: {
                    read(_, { args, toReference }) {
                        return toReference({
                            __typename: 'CleaningGroup',
                            id: args.cleaningGroupId,
                        });
                    },
                },
                cleaningGroupApartment: {
                    read(_, { args, toReference }) {
                        return toReference({
                            __typename: 'CleaningGroupApartment',
                            id: args.cleaningGroupApartmentId,
                        });
                    },
                },
            },
        },
        CleaningGroup: {
            fields: {
                apartments: {
                    merge(_, newValue) {
                        return newValue;
                    },
                },
                cleaners: {
                    merge(_, newValue) {
                        return newValue;
                    },
                },
            },
        },
        JobNew: {
            fields: {
                assigners: {
                    merge(_, newValue) {
                        return newValue;
                    },
                },
                watchers: {
                    merge(_, newValue) {
                        return newValue;
                    },
                },
                watcherGroups: {
                    merge(_, newValue) {
                        return newValue;
                    },
                },
            },
        },
    },
});

export const apolloClient = new ApolloClient({
    link: authLink.concat(scalarsLink.concat(httpLink)),
    cache,
});


import { Select } from "antd";
import { JOB_DATE_TYPE_CLOSEST_AVAILABLE, JOB_DATE_TYPE_FIXED, JOB_DATE_TYPE_RESERVATION_END, JOB_DATE_TYPE_RESERVATION_START } from "jobs-new/common";

export default function JobDateTypeSelect(props) {
    const {
        location,
        value,
        onChange,
    } = props;

    const dateTypeOptions = [
        {
            value: JOB_DATE_TYPE_FIXED,
            label: 'Fixed date',
        },
        {
            value: JOB_DATE_TYPE_CLOSEST_AVAILABLE,
            label: 'Next available date',
            disabled: !location,
        },
        {
            value: JOB_DATE_TYPE_RESERVATION_START,
            label: 'Reservation start',
            disabled: !location?.apartmentId,
        },
        {
            value: JOB_DATE_TYPE_RESERVATION_END,
            label: 'Reservation end',
            disabled: !location?.apartmentId,
        },
    ];

    return (
        <Select
            options={dateTypeOptions}
            value={value}
            onChange={value => onChange(value)}
            style={{
                width: '100%',
            }}
        />
    )
}
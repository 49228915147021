import { gql, useQuery } from "@apollo/client";
import { Alert, Col, Row, Table } from "antd";
import { Link } from "react-router-dom";

const QUERY = gql`
    query GetUserGroups {
        userGroups {
            id
            name
            users {
                id
                firstName
                lastName
            }
        }
    }
`

export default function UserGroupsList() {

    const { data, loading, error } = useQuery(QUERY);

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load user groups"
            />
        );
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Table
                    loading={loading}
                    dataSource={
                        [...data?.userGroups ?? []]
                            .sort((a, b) => a.name.localeCompare(b.name))
                    }
                    columns={[
                        {
                            title: 'Group',
                            render: userGroup => (
                                <Link to={`/user-groups/${userGroup.id}`}>
                                    {userGroup.name}
                                </Link>
                            ),
                        },
                    ]}
                    rowKey="id"
                    size="small"
                    pagination={false}
                />
            </Col>
        </Row>
    );
}
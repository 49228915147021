import { format } from "date-fns";
import Thumbnail from "components/attachments/Thumbnail";

export default function JobComment(props) {
    const {
        comment,
    } = props;

    return (
        <div className="log-container">
            <div className="log-header">
                <div className="log-author">
                    {comment.author.firstName} {comment.author.lastName}
                </div>
                <div className="log-timestamp">
                    {format(comment.createdAt, 'yyyy-MM-dd HH:mm')}
                </div>
            </div>
            <div className="log-content">
                <div className="log-content-text">
                    {comment.description}
                </div>
                {comment.attachments?.length > 0 && (
                    <div className="log-attachments">
                        {comment.attachments.map(attachment => (
                            <div
                                className="log-attachment"
                                key={attachment.id}
                            >
                                <Thumbnail
                                    file={attachment}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
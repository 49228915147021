import { LoadingOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export default function LoadingThumbnail(props) {
    const {
        file
    } = props;

    return (
        <div className="thumbnail-box">
            <Tooltip title={file.name}>
                <LoadingOutlined />
            </Tooltip>
        </div>
    );
}
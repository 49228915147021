import { PlusOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Button, Card, Col, Form, PageHeader, Row, Space, Switch, Typography, message } from "antd";
import ApartmentExpenseForm from "expenses/forms/ApartmentExpenseForm";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const MUTATION = gql`
    mutation CreateApartmentExpense($input: CreateApartmentExpenseInput!) {
        createApartmentExpense(input: $input) {
            error {
                type
                message
            }
            apartmentExpense {
                id
                apartment {
                    id
                    name
                }
                category {
                    id
                    name
                }
                amountNet
                amountGross
                dateFrom
                dateTo
                description
            }
        }
    }
`

export default function CreateApartmentExpenseView() {
    const navigate = useNavigate();

    const [multipleApartments, setMultipleApartments] = useState(false);

    const [createApartmentExpense, { loading: createLoading }] = useMutation(MUTATION, {
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'apartmentExpenses',
            });
        }
    });

    const [form] = Form.useForm();

    function handleCreate() {
        form
            .validateFields()
            .then(values => {
                if (multipleApartments) {
                    return Promise.all(
                        values.apartmentIds.map(apartmentId => {
                            return createApartmentExpense({
                                variables: {
                                    input: {
                                        apartmentId: apartmentId,
                                        apartmentExpenseCategoryId: values.apartmentExpenseCategoryId,
                                        amountNet: Math.round((values.amountNet / values.apartmentIds.length) * 100) / 100,
                                        amountGross: Math.round((values.amountGross / values.apartmentIds.length) * 100) / 100,
                                        dateFrom: values.dateFrom,
                                        dateTo: values.dateTo,
                                        description: values.description,
                                    },
                                },
                            })
                        })
                    )
                        .then(responses => {
                            if (responses.some(response => response.data.createApartmentExpense.error)) {
                                message.error('Failed to create expenses');
                                return false;
                            }

                            return true;
                        })
                        .catch(() => {
                            message.error('Failed to create expenses');
                            return false;
                        });
                }
                else {
                    return createApartmentExpense({
                        variables: {
                            input: {
                                apartmentId: values.apartmentId,
                                apartmentExpenseCategoryId: values.apartmentExpenseCategoryId,
                                amountNet: values.amountNet,
                                amountGross: values.amountGross,
                                dateFrom: values.dateFrom,
                                dateTo: values.dateTo,
                                description: values.description,
                            },
                        },
                    })
                        .then(response => {
                            if (response.data.createApartmentExpense.error) {
                                message.error('Failed to create expense');
                                return false;
                            }

                            return true;
                        })
                        .catch(() => {
                            message.error('Failed to create expense');
                            return false;
                        });
                }
            })
            .then(success => {
                if (success) {
                    message.success('Expense created');
                    form.resetFields([
                        'amountNet',
                        'amountGross',
                        'description',
                    ]);
                }
            });
    }

    return (
        <PageHeader
            title="Create expense"
            onBack={() => navigate('/expenses')}
            extra={
                <Space>
                    <Switch
                        checked={multipleApartments}
                        onChange={value => setMultipleApartments(value)}
                    />
                    <Typography.Text>
                        Multiple apartments
                    </Typography.Text>
                </Space>
            }
        >
            <Card>
                <Row
                    gutter={[16, 16]}
                    justify="end"
                >
                    <Col span={24}>
                        <ApartmentExpenseForm
                            form={form}
                            allowMultipleApartments={multipleApartments}
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 8,
                            }}
                        />
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            onClick={() => handleCreate()}
                            loading={createLoading}
                            icon={<PlusOutlined />}
                        >
                            Create
                        </Button>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    );
}
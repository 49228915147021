import { Typography } from "antd";

export default function JobDescription(props) {
    const {
        description,
    } = props;

    return (
        <Typography.Paragraph
            style={{
                whiteSpace: 'pre-line',
            }}
        >
            {description}
        </Typography.Paragraph>
    )
}
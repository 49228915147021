import { Modal } from "antd";

export default function ICalModal(props) {
    const { visible, onCancel } = props;

    return (
        <Modal
            title="iCal link"
            visible={visible}
            onCancel={() => onCancel()}
        >

        </Modal>
    )
}
import { useState } from "react";
import { Alert, Button, Col, Form, List, Modal, Row, Skeleton } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import UserRelationForm from "users/forms/UserRelationForm";
import Item from "./Item";

const QUERY = gql`
    query GetUserRelations($userId: ID!) {
        user(userId: $userId) {
            id
            apartmentRelations {
                id
                apartment {
                    id
                    name
                }
                relation
            }
            storageRelations {
                id
                storage {
                    id
                    name
                }
                relation
            }
        }
    }
`;

const CREATE_APARTMENT_RELATION_MUTATION = gql`
    mutation CreateApartmentRelation($input: CreateUserApartmentRelationInput!) {
        createUserApartmentRelation(input: $input) {
            error {
                type
                message
            }
            user {
                id
                apartmentRelations {
                    id
                    apartment {
                        id
                        name
                    }
                    relation
                }
            }
        }
    }
`;

const CREATE_STORAGE_RELATION_MUTATION = gql`
    mutation CreateStorageRelation($input: CreateUserStorageRelationInput!) {
        createUserStorageRelation(input: $input) {
            error {
                type
                message
            }
            user {
                id
                storageRelations {
                    id
                    storage {
                        id
                        name
                    }
                    relation
                }
            }
        }
    }
`;

export default function UserRelationsList(props) {
    const {
        userId,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();

    const { data, loading, error } = useQuery(QUERY, { variables: { userId } });
    const [createApartmentRelation, { loading: createApartmentRelationLoading }] = useMutation(CREATE_APARTMENT_RELATION_MUTATION);
    const [createStorageRelation, { loading: createStorageRelationLoading }] = useMutation(CREATE_STORAGE_RELATION_MUTATION);

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load relations"
            />
        );
    }

    function handleCreate() {
        form
            .validateFields()
            .then(values => {
                if (values.type === 'apartment') {
                    return createApartmentRelation({
                        variables: {
                            input: {
                                userId,
                                apartmentId: values.apartmentId,
                                relation: values.relation,
                            },
                        },
                    })
                }
                if (values.type === 'storage') {
                    return createStorageRelation({
                        variables: {
                            input: {
                                userId,
                                storageId: values.storageId,
                                relation: values.relation,
                            },
                        },
                    })
                }
            })
            .then(() => {
                setModalOpen(false);
            })
    }

    const relations = [
        ...[...data.user.apartmentRelations ?? []],
        ...[...data.user.storageRelations ?? []],
    ]

    return (
        <List
            dataSource={relations}
            renderItem={item => (
                <Item relation={item} />
            )}
            rowKey="id"
            footer={
                <Row justify="end">
                    <Col>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setModalOpen(true)}
                        >
                            Create relation
                        </Button>
                        <Modal
                            title="Create relation"
                            open={modalOpen}
                            destroyOnClose
                            onCancel={() => setModalOpen(false)}
                            onOk={() => handleCreate()}
                            okText="Create"
                            okButtonProps={{
                                icon: <PlusOutlined />,
                                loading: createApartmentRelationLoading || createStorageRelationLoading,
                            }}
                        >
                            <UserRelationForm
                                form={form}
                                labelCol={{
                                    span: 6,
                                }}
                                wrapperCol={{
                                    span: 18,
                                }}
                                preserve={false}
                            />
                        </Modal>
                    </Col>
                </Row>
            }
        />
    );
}
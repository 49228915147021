export default function Value(props) {
    const {
        calendarItem,
        value,
    } = props;

    if (value === 'price') {
        return (
            <div
                className="calendar-value-container"
            >
                {calendarItem?.price}
            </div>
        );
    }

    if (value === 'minStay') {
        return (
            <div
                className="calendar-value-container"
            >
                {calendarItem?.minStay}
            </div>
        )
    }
}
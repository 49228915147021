import { EditOutlined, SaveOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Alert, Button, Card, Form, Modal, PageHeader, Result } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { variableToString } from "stayql/common";
import StayqlTable from "stayql/components/stayql-table/StayqlTable";
import QueryVariableValuesForm from "stayql/forms/QueryVariableValuesForm";

const QUERY_GET_QUERY = gql`
    query GetStayqlQuery($stayqlQueryId: ID!) {
        stayqlQuery(stayqlQueryId: $stayqlQueryId) {
            id
            name
            query
            tags
            variables {
                name
                type
            }
        }
    }
`;

const QUERY_GET_RESULTS = gql`
    query GetStayqlResults($stayqlQueryId: ID!, $variables: [StayqlQueryVariableInput]!) {
        runStayqlQuery(input: {stayqlQueryId: $stayqlQueryId, variables: $variables}) {
            columns {
                name
                type
            }
            rows {
                values
            }
            error {
                message
            }
        }
    }
`;

export default function ShowStayqlQueryView() {
    const { stayqlQueryId } = useParams();
    const navigate = useNavigate();

    const [hasVariables, setHasVariables] = useState(false);
    const [variablesModalOpen, setVariablesModalOpen] = useState(hasVariables);
    const [variables, setVariables] = useState();

    const [variablesForm] = Form.useForm();

    const { data: queryData, loading: queryLoading, error: queryError } = useQuery(QUERY_GET_QUERY, {
        variables: {
            stayqlQueryId,
        },
    });

    const { data: resultsData, loading: resultsLoading, error: resultsError } = useQuery(QUERY_GET_RESULTS, {
        variables: {
            stayqlQueryId,
            variables: [...queryData?.stayqlQuery?.variables ?? []].map(variable => ({
                name: variable.name,
                type: variable.type,
                value: variableToString(variable.type, variables?.[variable.name]),
            })),
        },
        skip: (hasVariables || !queryData || queryData?.stayqlQuery?.variables?.length > 0) && !variables,
    });

    useEffect(() => {
        if (queryData?.stayqlQuery?.variables?.length > 0) {
            setHasVariables(true);
            setVariablesModalOpen(true);
        }
    }, [queryData]);

    function handleVariablesSave() {
        variablesForm
            .validateFields()
            .then(values => {
                setVariables(values);
                setVariablesModalOpen(false);
            });
    }

    if (queryError) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load query"
            />
        );
    }

    if (resultsError) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load query results"
            />
        );
    }

    return (
        <PageHeader
            title={queryData?.stayqlQuery?.name}
            onBack={() => navigate(-1)}
            extra={[
                <Button
                    onClick={() => setVariablesModalOpen(true)}
                    icon={<UnorderedListOutlined />}
                    key="variablesButton"
                >
                    Variables
                </Button>,
                <Button
                    type="primary"
                    onClick={() => navigate(`/stayql/${stayqlQueryId}/edit`)}
                    icon={<EditOutlined />}
                    key="editButton"
                >
                    Edit query
                </Button>
            ]}
        >
            {hasVariables && !variables && (
                <Result
                    status="info"
                    title="Set variables"
                    subTitle="Set variables in order to run this query"
                />
            )}
            {(queryLoading || queryLoading) && (
                <Card loading />
            )}
            {resultsData?.runStayqlQuery?.error && (
                <Alert
                    type="error"
                    showIcon
                    message={resultsData.runStayqlQuery.error.message}
                />
            )}
            {!(queryLoading || resultsLoading) && !resultsData?.runStayqlQuery?.error && resultsData && (
                <StayqlTable
                    columns={resultsData.runStayqlQuery.columns}
                    rows={resultsData.runStayqlQuery.rows}
                />
            )}
            <Modal
                open={variablesModalOpen}
                title="Variables"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />
                }}
                onOk={() => handleVariablesSave()}
                onCancel={() => setVariablesModalOpen(false)}
                destroyOnClose
            >
                <QueryVariableValuesForm
                    variables={queryData?.stayqlQuery?.variables}
                    variableValues={variables}
                    form={variablesForm}
                    preserve={false}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                />
            </Modal>
        </PageHeader>
    );
}
import { format } from "date-fns";

export default function JobCreation(props) {
    const {
        job,
    } = props;

    return (
        <div className="log-container">
            <div className="log-header">
                <div className="log-author">
                    {job.author.firstName} {job.author.lastName}
                </div>
                <div className="log-timestamp">
                    {format(job.createdAt, 'yyyy-MM-dd HH:mm')}
                </div>
            </div>
            <div className="log-content status-change">
                Job has been created
            </div>
        </div>
    )
}
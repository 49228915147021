import { DeleteOutlined, EditOutlined, EyeOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Form, List, message, Modal } from "antd";
import { useAuth } from "auth";
import JobsFilterPresetForm from "jobs-new/forms/JobsFilterPresetForm";
import { useState } from "react";

const UPDATE_MUTATION = gql`
    mutation UpdateOrganizationJobsFilterPreset($input: UpdateOrganizationJobsFilterPresetInput!) {
        updateOrganizationJobsFilterPreset(input: $input) {
            error {
                type
                message
            }
            organizationJobsFilterPreset {
                id
                name
                preset
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation DeleteOrganizationJobsFilterPreset($input: DeleteOrganizationJobsFilterPresetInput!) {
        deleteOrganizationJobsFilterPreset(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        organizationJobsFilterPreset,
    } = props;

    const { user, permissions } = useAuth();
    const canEdit = permissions.includes('organization_jobs_filter_preset:set');

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateForm] = Form.useForm();

    const [updateOrganizationJobsFilterPreset, { loading: updateLoading }] = useMutation(UPDATE_MUTATION);
    const [deleteOrganizationJobsFilterPreset, { loading: deleteLoading }] = useMutation(DELETE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Organization',
                    id: user.organization.id,
                }),
                fieldName: 'jobsFilterPresets',
            });
        }
    });

    function handleUpdate() {
        updateForm
            .validateFields()
            .then(values => {
                updateOrganizationJobsFilterPreset({
                    variables: {
                        input: {
                            organizationJobsFilterPresetId: organizationJobsFilterPreset.id,
                            name: values.name,
                            preset: {
                                dateFrom: values.dateFrom,
                                dateTo: values.dateTo,
                                statuses: values.statuses,
                                tags: values.tags,
                                authors: values.authors,
                                createdByMe: values.createdByMe,
                                watchers: values.watchers,
                                watchedByMe: values.watchedByMe,
                                watchedByMyGroups: values.watchedByMyGroups,
                                assignerIds: values.assignerIds,
                                assignedToMe: values.assignedToMe,
                                locations: values.locations,
                            },
                        },
                    },
                })
                    .then(response => {
                        if (response.data.updateOrganizationJobsFilterPreset.error) {
                            if (response.data.updateOrganizationJobsFilterPreset.error.type === 'already_exists') {
                                message.error("Filter preset with this name already exists");
                            }
                            else {
                                message.error("Failed updating jobs filter preset");
                            }
                        }
                        else {
                            message.success("Jobs filter preset updated");
                        }
                        setUpdateModalOpen(false);
                    })
                    .catch(() => {
                        message.error("Network error");
                        setUpdateModalOpen(false);
                    });
            });
    }

    function handleDelete() {
        Modal.confirm({
            title: 'Do you want to delete this jobs filter preset?',
            okText: 'Delete',
            okButtonProps: {
                danger: true,
                icon: <DeleteOutlined />,
                loading: deleteLoading,
            },
            onOk: () => {
                deleteOrganizationJobsFilterPreset({
                    variables: {
                        input: {
                            organizationJobsFilterPresetId: organizationJobsFilterPreset.id,
                        },
                    },
                })
                    .then(response => {
                        if (response.data.deleteOrganizationJobsFilterPreset.error) {
                            message.error("Failed deleting jobs filter preset");
                        }
                        else {
                            message.success("Jobs filter preset deleted");
                        }
                    })
            },
        })
    }

    return (
        <List.Item
            actions={
                canEdit
                    ? [
                        <EditOutlined
                            onClick={() => setUpdateModalOpen(true)}
                        />,
                        <DeleteOutlined
                            onClick={() => handleDelete()}
                        />
                    ]
                    : [
                        <EyeOutlined
                            onClick={() => setUpdateModalOpen(true)}
                        />
                    ]
            }
        >
            <List.Item.Meta
                title={organizationJobsFilterPreset.name}
            />
            <Modal
                title="Update jobs filter preset"
                width={800}
                open={updateModalOpen}
                destroyOnClose
                onCancel={() => setUpdateModalOpen(false)}
                onOk={() => handleUpdate()}
                okText="Update"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateLoading,
                    disabled: !canEdit,
                }}
            >
                <JobsFilterPresetForm
                    form={updateForm}
                    jobsFilterPreset={organizationJobsFilterPreset}
                    disabled={!canEdit}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                />
            </Modal>
        </List.Item>
    );
}
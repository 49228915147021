import JobPopover from "./JobPopover";
import { format } from "date-fns";

export default function JobsCalendarColumn(props) {
    const { date, jobs } = props;

    return (
        <div
            style={{
                backgroundColor: '#fff',
                minHeight: '300px',
            }}
        >
            <div
                style={{
                    textAlign: 'center',
                    fontSize: '12px',
                    marginBottom: '16px',
                }}
            >
                <span>
                    {format(date, 'eee').toUpperCase()}
                </span>
                <div
                    style={{
                        fontSize: '20px',
                    }}
                >
                    {format(date, 'd')}
                </div>
            </div>
            <div
                style={{
                    padding: '10px',
                    display: 'grid',
                    gridRowGap: '8px',
                }}
            >
                {jobs.map(job => (
                    <JobPopover
                        job={job}
                        key={job.id}
                    />
                ))}
            </div>
        </div>
    )
}
import { gql } from "@apollo/client";

export const GET_FILES_QUERY = gql`
    query GetFiles($filter: FilesFilter!) {
        files(filter: $filter) {
            id
            fileName
            originalFileName
            mimeType
            createdAt
            getUrl
        }
    }
`;

export const GENERATE_FILE_UPLOAD_URL_MUTATION = gql`
    mutation GenerateFileUploadUrl($input: GenerateFileUploadUrlInput!) {
        generateFileUploadUrl(input: $input) {
            error {
                type
                message
            }
            fileName
            putUrl
        }
    }
`;

export const CREATE_FILE_MUTATION = gql`
    mutation CreateFile($input: CreateFileInput!) {
        createFile(input: $input) {
            error {
                type
                message
            }
            file {
                id
                fileName
                originalFileName
                mimeType
                createdAt
                getUrl
            }
        }
    }
`;

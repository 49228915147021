import { Form, Input, Select } from "antd";
import useDeepCompareEffect from "use-deep-compare-effect";

export default function QueryParamsForm(props) {
    const {
        form,
        stayqlQuery,
        ...otherProps
    } = props;

    useDeepCompareEffect(() => {
        form?.setFieldValue('name', stayqlQuery?.name);
        form?.setFieldValue('tags', stayqlQuery?.tags);
    }, [form, stayqlQuery]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name is required' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="tags"
                label="Tags"
            >
                <Select mode="multiple" />
            </Form.Item>
        </Form>
    );
}
import { Drawer } from "antd";
import { addDays, formatISO, isEqual, startOfToday, subDays } from "date-fns";
import { useCallback, useEffect, useState } from 'react';
import ApartmentsCalendar from "calendar/components/apartments-calendar/ApartmentsCalendar";
import CalendarControls from "calendar/components/calendar-controls/CalendarControls";
import DayDetails from "calendar/components/day-details/DayDetails";
import SelectedDaysControls from "calendar/components/selected-days-controls/SelectedDaysControls";
import { toggleSelect, toggleSelectMultiple } from "common/common";

function selectedDayPredicate(a, b) {
    return a[0] === b[0] && isEqual(a[1], b[1]);
}

export default function CalendarView() {
    const [filterApartmentIds, setFilterApartmentIds] = useState();
    const [selectedDays, setSelectedDays] = useState([]);
    const [settings, setSettings] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        if (selectedDays.length > 0) {
            setDrawerOpen(true);
        }
        else {
            setDrawerOpen(false);
        }
    }, [selectedDays]);

    const handleOnSelect = useCallback(value => {
        setSelectedDays(prevSelectedDays => toggleSelect(prevSelectedDays, value, selectedDayPredicate));
    }, []);

    function handleDeselect(key) {
        const newSelectedDays = selectedDays.filter(selectedDay => selectedDayPredicate(selectedDay, key));
        setSelectedDays(newSelectedDays);
        if (newSelectedDays.length === 0) {
            setDrawerOpen(false);
        }
    }

    const handleColumnHeaderClick = useCallback(values => {
        setSelectedDays(prevSelectedDays => toggleSelectMultiple(prevSelectedDays, values, selectedDayPredicate))
    }, [setSelectedDays]);

    function handleDeselectAll() {
        setSelectedDays([]);
        setDrawerOpen(false);
    }

    return (
        <div
            style={{
                padding: '24px',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
            }}
        >
            <div
                style={{
                    flex: 0,
                }}
            >
                <CalendarControls
                    settings={settings}
                    onApartmentIdsChange={setFilterApartmentIds}
                    onSettingsChange={setSettings}
                />
            </div>
            <div
                style={{
                    flex: 1,
                    minHeight: 0,
                }}
            >
                <ApartmentsCalendar
                    dateFrom={subDays(startOfToday(), 500)}
                    dateTo={addDays(startOfToday(), 500)}
                    apartmentIds={filterApartmentIds}
                    selectedDays={selectedDays}
                    dayValue={settings?.dayValue}
                    hideDayValueOnReservation={settings?.hideDayValueOnReservation}
                    hideDayValueOnUnavailable={settings?.hideDayValueOnUnavailable}
                    reservationValue={settings?.reservationValue}
                    onSelect={handleOnSelect}
                    onColumnHeaderClick={handleColumnHeaderClick}
                />
            </div>
            <Drawer
                open={drawerOpen}
                title="Details"
                mask={false}
                maskClosable={false}
                width={300}
                onClose={() => setDrawerOpen(false)}
            >
                <SelectedDaysControls
                    selectedDays={selectedDays}
                    onDeselectAll={() => handleDeselectAll()}
                />
                {selectedDays.map(([apartmentId, date]) => (
                    <DayDetails
                        apartmentId={apartmentId}
                        date={date}
                        onClose={() => handleDeselect({ apartmentId, date })}
                        key={`${apartmentId}-${formatISO(date, { representation: 'date' })}`}
                    />
                ))}
            </Drawer>
        </div>
    )
}
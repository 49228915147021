import { gql } from '@apollo/client';

export const GET_STATISTICS_BY_ACCOMMODATION_DATE_QUERY = gql`
    query GetStatisticsByAccommodationDate($filter: StatisticsFilter!) {
        statisticsByAccommodationDate(filter: $filter) {
            interval
            apartmentGroupName
            totalDays
            bookedDays
            availableDays
            bookingRate
            bookingWindowMean
            numberOfGuestsMean
            numberOfGuestsArriving
            numberOfGuestsDeparting
            accommodationPriceTotal
            dailyAccommodationPriceMean
            basePriceTotal
            dailyBasePriceMean
            cleaningPriceTotal
            dailyCleaningPriceMean
            commissionTotal
            dailyCommissionMean
            revenueNetTotal
            revenueGrossTotal
            vatAmountTotal
            dailyRevenueNetMean
            dailyRevenueGrossMean
            revenueGrossAfterCommissionTotal
            dailyRevenueGrossAfterCommissionMean
            expensesNetTotal
            expensesGrossTotal
            incomeNetTotal
            incomeGrossTotal
        }
    }
`;


export const GET_STATISTICS_BY_BOOKED_DATE_QUERY = gql`
    query GetStatisticsByBookedDate($filter: StatisticsFilter!) {
        statisticsByBookedDate(filter: $filter) {
            interval
            apartmentGroupName
            reservationsCount
            reservationLengthTotal
            reservationLengthMean
            bookingWindowMean
            numberOfGuestsTotal
            numberOfGuestsMean
            accommodationPriceTotal
            accommodationPriceMean
            dailyAccommodationPriceMean
            basePriceTotal
            basePriceMean
            dailyBasePriceMean
            cleaningPriceTotal
            cleaningPriceMean
            dailyCleaningPriceMean
            commissionTotal
            commissionMean
            dailyCommissionMean
            revenueNetTotal
            revenueGrossTotal
            vatAmountTotal
            revenueNetMean
            revenueGrossMean
            dailyRevenueNetMean
            dailyRevenueGrossMean
        }
    }
`;

export const GET_STATISTICS_VIEW_QUERY = gql`
    query GetStatisticsView($statisticsViewId: ID!) {
        statisticsView(statisticsViewId: $statisticsViewId) {
            id
            name
            settings
        }
    }
`;

export const GET_STATISTICS_VIEWS_QUERY = gql`
    query GetStatisticsViews {
        statisticsViews {
            id
            name
            settings
        }
    }
`;

export const GET_APARTMENT_TAGS_QUERY = gql`
    query GetApartmentTags {
        apartments {
            id
            name
            tags
        }
    }
`;

export const CREATE_STATISTICS_VIEW_MUTATION = gql`
    mutation CreateStatisticsView($input: CreateStatisticsViewInput!) {
        createStatisticsView(input: $input) {
            error {
                type
                message
            }
            statisticsView {
                id
                name
                settings
            }
        }
    }
`;

export const UPDATE_STATISTICS_VIEW_MUTATION = gql`
    mutation UpdateStatisticsView($input: UpdateStatisticsViewInput!) {
        updateStatisticsView(input: $input) {
            error {
                type
                message
            }
            statisticsView {
                id
                name
                settings
            }
        }
    }
`;

export const DELETE_STATISTICS_VIEW_MUTATION = gql`
    mutation DeleteStatisticsView($input: DeleteStatisticsViewInput!) {
        deleteStatisticsView(input: $input) {
            error {
                type
                message
            }
        }
    }
`;
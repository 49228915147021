import { useState } from "react";
import { Form, List, Modal } from "antd";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import ApartmentExpenseCategoryForm from "expenses/forms/ApartmentExpenseCategoryForm";

const UPDATE_MUTATION = gql`
    mutation UpdateApartmentExpenseCategory($input: UpdateApartmentExpenseCategoryInput!) {
        updateApartmentExpenseCategory(input: $input) {
            error {
                type
                message
            }
            apartmentExpenseCategory {
                id
                name
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation DeleteApartmentExpenseCategory($input: DeleteApartmentExpenseCategoryInput!) {
        deleteApartmentExpenseCategory(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        apartmentExpenseCategory,
        organizationId,
    } = props;

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateForm] = Form.useForm();

    const [updateApartmentExpenseCategory, { loading: updateLoading }] = useMutation(UPDATE_MUTATION);
    const [deleteApartmentExpenseCategory, { loading: deleteLoading }] = useMutation(DELETE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Organization',
                    id: organizationId,
                }),
                fieldName: 'apartmentExpenseCategories',
            });
        }
    });

    function handleUpdate() {
        updateForm
            .validateFields()
            .then(values => {
                updateApartmentExpenseCategory({
                    variables: {
                        input: {
                            apartmentExpenseCategoryId: apartmentExpenseCategory.id,
                            name: values.name,
                        },
                    },
                })
                    .then(() => {
                        setUpdateModalOpen(false);
                    });
            });
    }

    function handleDelete() {
        Modal.confirm({
            title: 'Do you want to delete this apartment expense category?',
            okText: 'Delete',
            okButtonProps: {
                danger: true,
                icon: <DeleteOutlined />,
                loading: deleteLoading,
            },
            onOk: () => {
                deleteApartmentExpenseCategory({
                    variables: {
                        input: {
                            apartmentExpenseCategoryId: apartmentExpenseCategory.id,
                        },
                    },
                });
            },
        })
    }

    return (
        <List.Item
            actions={[
                <EditOutlined
                    onClick={() => setUpdateModalOpen(true)}
                />,
                <DeleteOutlined
                    onClick={() => handleDelete()}
                />
            ]}
        >
            <List.Item.Meta
                title={apartmentExpenseCategory.name}
            />
            <Modal
                title="Update user availability type"
                width={800}
                open={updateModalOpen}
                destroyOnClose
                onCancel={() => setUpdateModalOpen(false)}
                onOk={() => handleUpdate()}
                okText="Update"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateLoading,
                }}
            >
                <ApartmentExpenseCategoryForm
                    form={updateForm}
                    apartmentExpenseCategory={apartmentExpenseCategory}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                />
            </Modal>
        </List.Item>
    );
}
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Form, List, Modal, message } from "antd";
import { formatISO } from "date-fns";
import ApartmentApartmentCalendarRestrictionForm from "apartments/components/calendar-restrictions-list/ModalForm";
import { useState } from "react";
import { taskPromise } from "common/task";

const UPDATE_MUTATION = gql`
    mutation UpdateApartmentCalendarRestriction($input: UpdateApartmentCalendarRestrictionInput!) {
        updateApartmentCalendarRestriction(input: $input) {
            error {
                type
                message
            }
            apartmentCalendarRestriction {
                id
                date
                minStay
            }
            recalculateApartmentCalendarTask {
                id
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation DeleteApartmentCalendarRestriction($input: DeleteApartmentCalendarRestrictionInput!) {
        deleteApartmentCalendarRestriction(input: $input) {
            error {
                type
                message
            }
            recalculateApartmentCalendarTask {
                id
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        apartmentCalendarRestriction,
        apartmentId,
    } = props;

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateForm] = Form.useForm();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [updateApartmentCalendarRestriction] = useMutation(UPDATE_MUTATION);
    const [deleteApartmentCalendarRestriction] = useMutation(DELETE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Apartment',
                    id: apartmentId,
                }),
                fieldName: 'calendarRestrictions',
            });
        }
    });

    function handleUpdate() {
        setUpdateLoading(true);
        updateForm
            .validateFields()
            .then(values => {
                updateApartmentCalendarRestriction({
                    variables: {
                        input: {
                            apartmentCalendarRestrictionId: apartmentCalendarRestriction.id,
                            date: values.date,
                            minStay: values.minStay,
                        },
                    },
                })
                    .then(response => {
                        const error = response.data.updateApartmentCalendarRestriction.error;
                        if (error) {
                            message.error('Failed to update calendar restriction');
                            setUpdateLoading(false);
                        }
                        else {
                            taskPromise(response.data.updateApartmentCalendarRestriction.recalculateApartmentCalendarTask.id)
                                .then(() => {
                                    message.success('Calendar restriction updated');
                                    setUpdateLoading(false);
                                    setUpdateModalOpen(false);
                                })
                        }
                    })
            });
    }

    function handleDelete() {
        Modal.confirm({
            title: 'Do you want to delete this calendar restriction?',
            okText: 'Delete',
            okButtonProps: {
                danger: true,
                icon: <DeleteOutlined />,
                loading: deleteLoading,
            },
            onOk: () => {
                setDeleteLoading(true);
                return deleteApartmentCalendarRestriction({
                    variables: {
                        input: {
                            apartmentCalendarRestrictionId: apartmentCalendarRestriction.id,
                        },
                    },
                })
                    .then(response => {
                        const error = response.data.deleteApartmentCalendarRestriction.error;
                        if (error) {
                            message.error('Failed to delete calendar restriction');
                            setDeleteLoading(false);
                        }
                        else {
                            return taskPromise(response.data.deleteApartmentCalendarRestriction.recalculateApartmentCalendarTask.id)
                                .then(() => {
                                    message.success('Calendar restriction deleted');
                                    setDeleteLoading(false);
                                    return Promise.resolve();
                                })
                        }
                    })
            },
        })
    }

    return (
        <List.Item
            actions={[
                <EditOutlined
                    onClick={() => setUpdateModalOpen(true)}
                />,
                <DeleteOutlined
                    onClick={() => handleDelete()}
                />
            ]}
        >
            <List.Item.Meta
                title={formatISO(apartmentCalendarRestriction.date, { representation: 'date' })}
                description={`Min stay: ${apartmentCalendarRestriction.minStay} days`}
            />
            <Modal
                title="Update calendar restriction"
                open={updateModalOpen}
                destroyOnClose
                onCancel={() => setUpdateModalOpen(false)}
                onOk={() => handleUpdate()}
                okText="Update"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateLoading,
                }}
            >
                <ApartmentApartmentCalendarRestrictionForm
                    form={updateForm}
                    apartmentCalendarRestriction={apartmentCalendarRestriction}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 8,
                    }}
                />
            </Modal>
        </List.Item>
    );
}
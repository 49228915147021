import { Col, Modal, Row } from "antd";

export default function PreviewModal(props) {
    const {
        open,
        file,
        onClose,
    } = props;

    return (
        <Modal
            open={open}
            maskClosable
            onCancel={() => onClose()}
            footer={null}
            closable={false}
            bodyStyle={{
                padding: '24px',
            }}
            width={800}
            centered
            destroyOnClose
        >
            <Row
                justify="center"
                align="middle"
            >
                <Col>
                    {file.mimeType.startsWith('image/') && (
                        <img
                            alt={file?.name}
                            src={file?.getUrl}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '70vh',
                            }}
                        />
                    )}
                    {file.mimeType.startsWith('video/') && (
                        <video
                            controls
                            autoPlay
                            loop
                            style={{
                                maxWidth: '100%',
                                maxHeight: '70vh',
                            }}
                        >
                            <source
                                src={file?.getUrl}
                                type={file?.mimeType}
                            />
                        </video>
                    )}
                </Col>
            </Row>
        </Modal>
    )
}
import { Link } from "react-router-dom";
import { Alert, List, Skeleton } from "antd";
import { gql, useQuery } from "@apollo/client";

const QUERY = gql`
    query GetOrganizationsForOrganizationsList {
        organizations {
            id
            name
        }
    }
`;

export default function OrganizationsList(props) {
    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load organizations"
            />
        );
    }

    return (
        <List
            dataSource={[...data.organizations ?? []]}
            renderItem={item => (
                <List.Item>
                    <Link to={`/organizations/${item.id}`}>
                        {item.name}
                    </Link>
                </List.Item>
            )}
        />
    );
}
import { Link } from "react-router-dom";
import { Space, Typography } from "antd";
import JobTagsList from "jobs-new/components/JobTagsList";
import JobDate from "jobs-new/components/JobDate";
import JobPriority from "jobs-new/components/JobPriority";

export default function JobCardTitle(props) {
    const {
        job,
        loading,
    } = props;

    function Divider() {
        return (
            <Typography.Text
                style={{
                    color: '#D0D0D0',
                }}
            >
                /
            </Typography.Text>
        )
    }

    if (loading) {
        return null;
    }

    return (
        <Space>
            <Typography.Text>
                {job.title}
            </Typography.Text>
            {job?.calculatedDate && (
                <>
                    <Divider />
                    <Typography.Text>
                        <JobDate
                            date={job?.calculatedDate}
                        />
                    </Typography.Text>
                </>
            )}
            {job?.apartment && (
                <>
                    <Divider />
                    <Link to={`/apartments/${job?.apartment?.id}`}>
                        {job?.apartment?.name}
                    </Link>
                </>
            )}
            {job?.storage && (
                <>
                    <Divider />
                    <Link to={`/storages/${job?.storage?.id}`}>
                        {job?.storage?.name}
                    </Link>
                </>
            )}
            {job?.tags?.length > 0 && (
                <>
                    <Divider />
                    <JobTagsList
                        tags={job?.tags}
                    />
                </>
            )}
            <Divider />
            <JobPriority
                priority={job?.priority}
            />
        </Space>
    );
}
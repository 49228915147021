import { DeleteOutlined, UndoOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Result, Skeleton } from "antd";
import { USER_STATUS_ACTIVE, USER_STATUS_DELETED, USER_STATUS_ONBOARDING } from "users/common";

const QUERY = gql`
    query GetUserForUserDelete($userId: ID!) {
        user(userId: $userId) {
            id
            status
        }
    }
`;

const MUTATION = gql`
    mutation UpdateUserStatus($input: UpdateUserInput!) {
        updateUser(input: $input) {
            error {
                type
                message
            }
            user {
                id
                status
            }
        }
    }
`;

export default function UserDelete(props) {
    const {
        userId,
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { userId } });
    const [updateUserStatus, { loading: updateUserStatusLoading }] = useMutation(MUTATION);

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to get user"
            />
        );
    }

    function handleDelete() {
        updateUserStatus({
            variables: {
                input: {
                    userId,
                    status: USER_STATUS_DELETED,
                },
            },
        });
    }

    function handleUndelete() {
        updateUserStatus({
            variables: {
                input: {
                    userId,
                    status: USER_STATUS_ACTIVE,
                },
            },
        });
    }

    if (data.user.status === USER_STATUS_ACTIVE || data.user.status === USER_STATUS_ONBOARDING) {
        return (
            <Result
                status="warning"
                title="Delete user"
                subTitle="Deleting user makes them inactive in whole system. The operation is reversible."
                extra={[
                    <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete()}
                        loading={updateUserStatusLoading}
                        key="button"
                    >
                        Delete
                    </Button>
                ]}
            />
        );
    }
    if (data.user.status === USER_STATUS_DELETED) {
        return (
            <Result
                status="info"
                title="Undelete user"
                subTitle="Undeleting user makes them back active in whole system."
                extra={[
                    <Button
                        icon={<UndoOutlined />}
                        onClick={() => handleUndelete()}
                        loading={updateUserStatusLoading}
                        key="button"
                    >
                        Undelete
                    </Button>
                ]}
            />
        );
    }
}
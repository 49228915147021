import { PlusOutlined } from "@ant-design/icons";
import { Button, PageHeader } from "antd";
import { Link } from "react-router-dom";
import StoragesTable from "storages/components/storages-table/StoragesTable";

export default function ListStoragesView() {
    return (
        <PageHeader
            title="Storages"
            extra={
                <Link to="/storages/create">
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                    >
                        Create storage
                    </Button>
                </Link>
            }
        >
            <StoragesTable
                displayReorder
            />
        </PageHeader>
    )
}
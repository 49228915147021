import { ApiOutlined } from "@ant-design/icons";
import { Button, Col, Result, Row } from "antd";
import { Link } from "react-router-dom";

export default function Unmapped(props) {
    const {
        apartmentId,
    } = props;

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Result
                    status="info"
                    title="No channels mapped"
                    subTitle="This apartment is not mapped to any channel. Use the button below to map it."
                    extra={
                        <Link to={`/apartments/${apartmentId}/mapping`}>
                            <Button
                                type="primary"
                                icon={<ApiOutlined />}

                            >
                                Setup mapping
                            </Button>
                        </Link>
                    }
                />
            </Col>
        </Row>
    );
}
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Card, Col, Form, PageHeader, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { taskPromise } from "common/task";
import ReservationForm from "reservations/forms/ReservationForm";
import { CREATE_RESERVATION_MUTATION } from "reservations/graphql";

const FORM_COLUMNS = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
}

export default function CreateReservationView() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const [createReservation] = useMutation(CREATE_RESERVATION_MUTATION);

    function handleCreate() {
        setLoading(true);
        form
            .validateFields()
            .then(values => {
                const phone = `+${values.guestPhone.countryCode}${values.guestPhone.areaCode}${values.guestPhone.phoneNumber}`;
                return createReservation({
                    variables: {
                        input: {
                            apartmentId: values.apartmentId,
                            startDate: values.dateRange[0],
                            endDate: values.dateRange[1],
                            bookedAt: new Date(),
                            numberOfGuests: values.numberOfGuests,
                            guestFirstName: values.guestFirstName,
                            guestLastName: values.guestLastName,
                            guestPhone: phone,
                            priceAccommodation: values.priceAccommodation,
                            priceCleaning: values.priceCleaning,
                            checkinTime: values.checkinTime,
                            checkoutTime: values.checkoutTime,
                        },
                    },
                });
            })
            .then(response => {
                const reservationId = response.data.createReservation.reservation.id;
                const taskId = response.data.createReservation.syncApartmentCalendarUpTask?.id;

                return taskPromise(taskId)
                    .then(() => navigate(`/reservations/${reservationId}`));
            })
            .finally(() => setLoading(false))
    }

    return (
        <PageHeader
            title="Create reservation"
            onBack={() => navigate(-1)}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <ReservationForm
                            form={form}
                            {...FORM_COLUMNS}
                        />
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => handleCreate()}
                                    loading={loading}
                                    icon={<PlusOutlined />}
                                >
                                    Create
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    )
}
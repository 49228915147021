import { DeleteOutlined, EditOutlined, EyeOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Form, List, message, Modal } from "antd";
import { useAuth } from "auth";
import JobsPresetForm from "jobs-new/forms/JobsPresetForm";
import { useState } from "react";

const UPDATE_MUTATION = gql`
    mutation UpdateOrganizationJobsPreset($input: UpdateOrganizationJobsPresetInput!) {
        updateOrganizationJobsPreset(input: $input) {
            error {
                type
                message
            }
            organizationJobsPreset {
                id
                name
                preset
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation DeleteOrganizationJobsPreset($input: DeleteOrganizationJobsPresetInput!) {
        deleteOrganizationJobsPreset(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        organizationJobsPreset,
    } = props;

    const { user, permissions } = useAuth();
    const canEdit = permissions.includes('organization_jobs_preset:set');

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateForm] = Form.useForm();

    const [updateOrganizationJobsPreset, { loading: updateLoading }] = useMutation(UPDATE_MUTATION);
    const [deleteOrganizationJobsPreset, { loading: deleteLoading }] = useMutation(DELETE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Organization',
                    id: user.organization.id,
                }),
                fieldName: 'jobsPresets',
            });
        }
    });

    function handleUpdate() {
        updateForm
            .validateFields()
            .then(values => {
                updateOrganizationJobsPreset({
                    variables: {
                        input: {
                            organizationJobsPresetId: organizationJobsPreset.id,
                            name: values.name,
                            preset: {
                                title: values.title,
                                hideTitle: values.hideTitle,
                                description: values.description,
                                hideDescription: values.hideDescription,
                                tags: values.tags,
                                hideTags: values.hideTags,
                                priority: values.priority,
                                hidePriority: values.hidePriority,
                                watchers: values.watchers,
                                hideWatchers: values.hideWatchers,
                                assignerIds: values.assignerIds,
                                hideAssigners: values.hideAssigners,
                                dateType: values.dateType,
                                date: values.date,
                                hideDate: values.hideDate,
                                location: values.location,
                                hideLocation: values.hideLocation,
                                hideAttachments: values.hideAttachments,
                            },
                        },
                    },
                })
                    .then(response => {
                        if (response.data.updateOrganizationJobsPreset.error) {
                            if (response.data.updateOrganizationJobsPreset.error.type === 'already_exists') {
                                message.error("Preset with this name already exists");
                            }
                            else {
                                message.error("Error updating jobs preset");
                            }
                        }
                        else {
                            message.success("Jobs preset updated");
                        }
                        setUpdateModalOpen(false);
                    })
                    .catch(() => {
                        message.error("Network error");
                        setUpdateModalOpen(false);
                    })
            });
    }

    function handleDelete() {
        Modal.confirm({
            title: 'Do you want to delete this jobs preset?',
            okText: 'Delete',
            okButtonProps: {
                danger: true,
                icon: <DeleteOutlined />,
                loading: deleteLoading,
            },
            onOk: () => {
                deleteOrganizationJobsPreset({
                    variables: {
                        input: {
                            organizationJobsPresetId: organizationJobsPreset.id,
                        },
                    },
                })
                    .then(response => {
                        if (response.data.deleteOrganizationJobsPreset.error) {
                            message.error("Failed deleting jobs preset");
                        }
                        else {
                            message.success("Jobs preset deleted");
                        }
                    })
                    .catch(() => {
                        message.error("Network error");
                    });
            },
        })
    }

    return (
        <List.Item
            actions={
                canEdit
                    ? [
                        <EditOutlined
                            onClick={() => setUpdateModalOpen(true)}
                        />,
                        <DeleteOutlined
                            onClick={() => handleDelete()}
                        />
                    ]
                    : [
                        <EyeOutlined
                            onClick={() => setUpdateModalOpen(true)}
                        />
                    ]
            }
        >
            <List.Item.Meta
                title={organizationJobsPreset.name}
            />
            <Modal
                title="Update jobs preset"
                width={800}
                open={updateModalOpen}
                destroyOnClose
                onCancel={() => setUpdateModalOpen(false)}
                onOk={() => handleUpdate()}
                okText="Update"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateLoading,
                    disabled: !canEdit,
                }}
            >
                <JobsPresetForm
                    form={updateForm}
                    jobsPreset={organizationJobsPreset}
                    disabled={!canEdit}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                />
            </Modal>
        </List.Item>
    );
}
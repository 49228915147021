export const JOB_DATE_TYPE_CLOSEST_AVAILABLE = 'closestAvailable';
export const JOB_DATE_TYPE_RESERVATION = 'reservation';
export const JOB_DATE_TYPE_RESERVATION_START = 'reservationStart';
export const JOB_DATE_TYPE_RESERVATION_END = 'reservationEnd';
export const JOB_DATE_TYPE_FIXED = 'fixed';

export const JOB_PRESET_DATE_TYPE_CLOSEST_AVAILABLE = "closestAvailable";
export const JOB_PRESET_DATE_TYPE_FIXED_DATE = "fixed";
export const JOB_PRESET_DATE_TYPE_RESERVATION_ENDING_ON_FIXED_DATE = "reservationEnding";
export const JOB_PRESET_DATE_TYPE_RESERVATION_STARTING_ON_FIXED_DATE = "reservationStarting";

export const JOB_STATUS_UNASSIGNED = 'unassigned';
export const JOB_STATUS_ASSIGNED = 'assigned';
export const JOB_STATUS_IN_PROGRESS = 'in_progress';
export const JOB_STATUS_BLOCKED = 'blocked';
export const JOB_STATUS_DONE = 'done';

export function formToJob(values) {
    const relationsApartmentClosestAvailable = [];
    const relationsApartmentDate = [];
    const relationsStorage = [];
    const relationsStorageDate = [];
    const relationsReservation = [];
    const relationsReservationStartDate = [];
    const relationsReservationEndDate = [];
    const relationsDate = [];

    if (values.location?.apartmentId && values.dateType === JOB_DATE_TYPE_CLOSEST_AVAILABLE) {
        relationsApartmentClosestAvailable.push({
            apartmentId: values.location.apartmentId,
            afterDate: values.afterDate,
        });
    }
    if (values.location?.apartmentId && values.dateType === JOB_DATE_TYPE_FIXED) {
        relationsApartmentDate.push({
            apartmentId: values.location.apartmentId,
            date: values.date,
        });
    }
    if (values.location?.storageId && values.dateType === JOB_DATE_TYPE_CLOSEST_AVAILABLE) {
        relationsStorage.push({
            storageId: values.location.storageId,
        });
    }
    if (values.location?.storageId && values.dateType === JOB_DATE_TYPE_FIXED) {
        relationsStorageDate.push({
            storageId: values.location.storageId,
            date: values.date,
        });
    }
    if (values.location?.apartmentId && values.dateType === JOB_DATE_TYPE_RESERVATION) {
        relationsReservation.push({
            reservationId: values.reservationId,
        });
    }
    if (values.location?.apartmentId && values.dateType === JOB_DATE_TYPE_RESERVATION_START) {
        relationsReservationStartDate.push({
            reservationId: values.reservationId,
        });
    }
    if (values.location?.apartmentId && values.dateType === JOB_DATE_TYPE_RESERVATION_END) {
        relationsReservationEndDate.push({
            reservationId: values.reservationId,
        });
    }
    if (!values.location && values.dateType === JOB_DATE_TYPE_FIXED) {
        relationsDate.push({
            date: values.date,
        });
    }

    const watcherIds = [...values?.watchers ?? []]
        .filter(watcher => watcher.userId)
        .map(watcher => watcher.userId);

    const watcherGroupIds = [...values?.watchers ?? []]
        .filter(watcher => watcher.userGroupId)
        .map(watcher => watcher.userGroupId);

    return {
        status: values.status,
        title: values.title,
        description: values.description,
        tags: values.tags,
        priority: values.priority,
        watcherIds: watcherIds,
        watcherGroupIds: watcherGroupIds,
        assignerIds: values.assignerIds,
        attachmentIds: values.attachmentIds,
        relationsApartmentClosestAvailable,
        relationsApartmentDate,
        relationsStorage,
        relationsStorageDate,
        relationsReservation,
        relationsReservationStartDate,
        relationsReservationEndDate,
        relationsDate,
    };
}

export function jobToForm(job) {
    let location = null;
    let dateType = null;
    let date = null;
    let afterDate = null;
    let reservationId = null;

    if (job.relationsApartmentClosestAvailable?.length > 0) {
        location = { apartmentId: job.relationsApartmentClosestAvailable[0].apartment.id };
        dateType = JOB_DATE_TYPE_CLOSEST_AVAILABLE;
        afterDate = job.relationsApartmentClosestAvailable[0].afterDate;
    }
    if (job.relationsApartmentDate?.length > 0) {
        location = { apartmentId: job.relationsApartmentDate[0].apartment.id };
        date = job.relationsApartmentDate[0].date;
        dateType = JOB_DATE_TYPE_FIXED;
    }
    if (job.relationsStorage?.length > 0) {
        location = { storageId: job.relationsStorage[0].storage.id };
        dateType = JOB_DATE_TYPE_CLOSEST_AVAILABLE;
    }
    if (job.relationsStorageDate?.length > 0) {
        location = { storageId: job.relationsStorageDate[0].storage.id };
        date = job.relationsStorageDate[0].date;
        dateType = JOB_DATE_TYPE_FIXED;
    }
    if (job.relationsReservation?.length > 0) {
        location = { apartmentId: job.relationsReservation[0].reservation.apartment.id };
        reservationId = job.relationsReservation[0].reservation.id;
        dateType = JOB_DATE_TYPE_RESERVATION;
    }
    if (job.relationsReservationStartDate?.length > 0) {
        location = { apartmentId: job.relationsReservationStartDate[0].reservation.apartment.id };
        reservationId = job.relationsReservationStartDate[0].reservation.id;
        dateType = JOB_DATE_TYPE_RESERVATION_START;
    }
    if (job.relationsReservationEndDate?.length > 0) {
        location = { apartmentId: job.relationsReservationEndDate[0].reservation.apartment.id };
        reservationId = job.relationsReservationEndDate[0].reservation.id;
        dateType = JOB_DATE_TYPE_RESERVATION_END;
    }
    if (job.relationsDate?.length > 0) {
        date = job.relationsDate[0].date;
        dateType = JOB_DATE_TYPE_FIXED;
    }

    const watchers = [
        ...[...job.watchers ?? []].map(watcher => ({ userId: watcher.id })),
        ...[...job.watcherGroups ?? []].map(watcherGroup => ({ userGroupId: watcherGroup.id })),
    ];

    return {
        status: job.status,
        title: job.title,
        description: job.description,
        tags: job.tags ?? [],
        priority: job.priority,
        watchers,
        assignerIds: [...job.assigners ?? []].map(assigner => assigner.id),
        attachmentIds: [...job.attachments ?? []].map(attachment => attachment.id),
        location,
        dateType,
        date,
        afterDate,
        reservationId,
    }
}

export function getJobApartment(job) {
    if (job?.relationsApartmentClosestAvailable?.length > 0) {
        return job.relationsApartmentClosestAvailable[0].apartment;
    }
    if (job?.relationsApartmentDate?.length > 0) {
        return job.relationsApartmentDate[0].apartment;
    }
    if (job?.relationsReservationStartDate?.length > 0) {
        return job.relationsReservationStartDate[0].reservation.apartment;
    }
    if (job?.relationsReservationEndDate?.length > 0) {
        return job.relationsReservationEndDate[0].reservation.apartment;
    }

    return null;
}

export function getJobStorage(job) {
    if (job?.relationsStorage?.length > 0) {
        return job.relationsStorage[0].storage;
    }
    if (job?.relationsStorageDate?.length > 0) {
        return job.relationsStorageDate[0].storage;
    }

    return null;
}

export function getJobReservation(job) {
    if (job?.relationsReservation?.length > 0) {
        return job.relationsReservation[0].reservation;
    }
    if (job?.relationsReservationStartDate?.length > 0) {
        return job.relationsReservationStartDate[0].reservation;
    }
    if (job?.relationsReservationEndDate?.length > 0) {
        return job.relationsReservationEndDate[0].reservation;
    }

    return null;
}


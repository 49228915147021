import { Tag } from "antd";

export default function Tags(props) {
    const {
        tags,
    } = props;

    return (
        <>
            {[...tags ?? []].map(tag => (
                <Tag key={tag}>
                    {tag}
                </Tag>
            ))}
        </>
    );
}
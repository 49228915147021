import { gql, useQuery } from "@apollo/client";
import { Alert, List, Skeleton } from "antd";
import Item from "./Item";

const QUERY = gql`
    query GetUserSessions($userId: ID!) {
        user(userId: $userId) {
            id
            authTokens {
                id
                validTo
                sessionInfo
                user {
                    id
                }
            }
        }
    }
`

export default function UserSessionsList(props) {
    const {
        userId,
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { userId } });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load sessions"
            />
        );
    }

    return (
        <List
            dataSource={[...data?.user?.authTokens ?? []]}
            renderItem={item => (
                <Item authToken={item} />
            )}
            rowKey="id"
        />
    );
}
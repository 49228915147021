export function apartmentTagsFilterAnd(apartmentTags, searchTags) {
    if (searchTags.length === 0) {
        return true;
    }

    return searchTags.every(tag => apartmentTags.includes(tag));
}

export function apartmentTagsFilterOr(apartmentTags, searchTags) {
    if (searchTags.length === 0) {
        return true;
    }

    return searchTags.some(tag => apartmentTags.includes(tag));
}

export function apartmentAddressParts(address) {
    if (!address) {
        return null;
    }

    const regex = /^(.*) ([0-9]+[a-zA-Z]*)\/([0-9]+[a-zA-Z]*)$/;
    const result = address.match(regex);

    if (!result) {
        return null;
    }

    return [result[1], result[2], result[3]];
}
import { Form, Input } from "antd";
import { useEffect } from "react";
import UserSelect from "users/components/user-select/UserSelect";

export default function UserGroupGeneralForm(props) {
    const {
        userGroup,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            name: userGroup?.name,
            userIds: [...userGroup?.users ?? []].map(user => user.id),
        });
    }, [form, userGroup])

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name is required' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="userIds"
                label="Users"
            >
                <UserSelect
                    active
                    mode="multiple"
                    placeholder="Users"
                />
            </Form.Item>
        </Form>
    )
}
import { Form, Input, InputNumber } from "antd";
import ApartmentSelect from "apartments/components/apartment-select/ApartmentSelect";
import DatePicker from "components/DatePicker";
import ApartmentExpenseCategorySelect from "expenses/components/apartment-expense-category-select/ApartmentExpenseCategorySelect";
import { useEffect } from "react";

export default function ApartmentExpenseForm(props) {
    const {
        apartmentExpense,
        form,
        allowMultipleApartments,
        ...otherProps
    } = props;

    useEffect(() => {
        form.setFieldsValue({
            apartmentId: apartmentExpense?.apartment?.id,
            apartmentExpenseCategoryId: apartmentExpense?.category?.id,
            amountNet: apartmentExpense?.amountNet,
            amountGross: apartmentExpense?.amountGross,
            dateFrom: apartmentExpense?.dateFrom,
            dateTo: apartmentExpense?.dateTo,
            description: apartmentExpense?.description,
        });
    }, [form, apartmentExpense]);

    const decimalSeparator = 1.1.toLocaleString().substring(1, 2);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            {!allowMultipleApartments && (
                <Form.Item
                    name="apartmentId"
                    label="Apartment"
                    rules={[{ required: true, message: 'Apartment is required' }]}
                >
                    <ApartmentSelect />
                </Form.Item>
            )}
            {allowMultipleApartments && (
                <Form.Item
                    name="apartmentIds"
                    label="Apartments"
                    rules={[{ required: true, message: 'Apartments are required' }]}
                >
                    <ApartmentSelect
                        mode="multiple"
                        allowClear
                    />
                </Form.Item>
            )}
            <Form.Item
                name="apartmentExpenseCategoryId"
                label="Category"
                rules={[{ required: true, message: 'Category is required' }]}
            >
                <ApartmentExpenseCategorySelect />
            </Form.Item>
            <Form.Item
                name="amountNet"
                label="Amount net"
                rules={[{ required: true, message: 'Amount net is required' }]}
            >
                <InputNumber
                    precision={2}
                    decimalSeparator={decimalSeparator}
                />
            </Form.Item>
            <Form.Item
                name="amountGross"
                label="Amount gross"
                rules={[{ required: true, message: 'Amount gross is required' }]}
            >
                <InputNumber
                    precision={2}
                    decimalSeparator={decimalSeparator}
                />
            </Form.Item>
            <Form.Item
                name="dateFrom"
                label="Date from"
                rules={[{ required: true, message: 'Date from is required' }]}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                name="dateTo"
                label="Date to"
                rules={[{ required: true, message: 'Date to is required' }]}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                name="description"
                label="Description"
            >
                <Input.TextArea />
            </Form.Item>
        </Form>
    );
}
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { message, Modal, Space, Typography } from "antd";
import { parseISO } from "date-fns";
import { UPDATE_APARTMENT_PRICE_CONFIGS_MUTATION } from "prices/graphql";
import { useState } from "react";

export default function PriceConfigPasteModal(props) {
    const { visible, onClose, selectedApartments } = props;

    const [loading, setLoading] = useState(false);
    const [updateApartmentPriceConfigs] = useMutation(UPDATE_APARTMENT_PRICE_CONFIGS_MUTATION);

    function handlePriceConfigPaste() {
        const copiedPriceConfigs = JSON.parse(window.localStorage.getItem('copiedPriceConfigs'))
            .map(copiedPriceConfig => ({
                id: copiedPriceConfig.id,
                date: parseISO(copiedPriceConfig.date),
                minPrice: copiedPriceConfig.minPrice,
                delta: copiedPriceConfig.delta,
                slope: copiedPriceConfig.slope,
            }));

        setLoading(true);
        Promise.all(
            selectedApartments
                .map(apartmentId => {
                    const changes = copiedPriceConfigs
                        .map(priceConfig => ({
                            date: priceConfig.date,
                            delta: priceConfig.delta,
                            minPrice: priceConfig.minPrice,
                            slope: priceConfig.slope,
                        }));
                    return updateApartmentPriceConfigs({
                        variables: {
                            input: {
                                apartmentId,
                                changes,
                            },
                        },
                    });
                })
        )
            .then(responses => {
                if (responses.every(response => !response.data.error)) {
                    message.success('Prices pasted');
                }
                else {
                    message.error('Error while pasting');
                }
                setLoading(false);
                onClose();
            });
    }

    return (
        <Modal
            visible={visible}
            title="Modify prices"
            okText="Modify"
            onOk={() => handlePriceConfigPaste()}
            okButtonProps={{
                loading,
            }}
            onCancel={() => onClose()}
            destroyOnClose={true}
            cancelButtonProps={{
                disabled: loading,
            }}
        >
            <Space>
                <QuestionCircleOutlined />
                <Typography.Text>
                    Do you want to paste for {selectedApartments.length} price configs?
                </Typography.Text>
            </Space>
        </Modal>
    );
}
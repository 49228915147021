import { Form, Radio, Select } from "antd";
import ApartmentSelect from "apartments/components/apartment-select/ApartmentSelect";
import ApartmentTagsSelect from "apartments/components/apartment-select/ApartmentTagsSelect";
import { useEffect } from "react";

export default function ApartmentsFilterForm(props) {
    const {
        form,
        apartmentsFilter,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            filter: apartmentsFilter?.filter,
            tags: apartmentsFilter?.tags,
            tagsMode: apartmentsFilter?.tagsMode,
            apartmentIds: apartmentsFilter?.apartmentIds,
        });
    }, [form, apartmentsFilter]);

    const filter = Form.useWatch('filter', form);

    useEffect(() => {
        if (filter === 'tags') {
            form.resetFields(['apartmentIds']);
        }
        if (filter === 'apartments') {
            form.resetFields(['tags', 'tagsMode']);
        }
    }, [form, filter]);

    function handleFilterClear() {
        form.resetFields(['apartmentIds', 'tags', 'tagsMode']);
    }

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="filter"
                label="Filter"
            >
                <Select
                    options={[
                        {
                            label: 'Tags',
                            value: 'tags',
                        },
                        {
                            label: 'Apartments',
                            value: 'apartments'
                        }
                    ]}
                    allowClear
                    onClear={() => handleFilterClear()}
                    style={{
                        width: '150px',
                    }}
                />
            </Form.Item>
            {filter === 'tags' && (
                <>
                    <Form.Item
                        name="tags"
                        label="Tags"
                        rules={[{ required: true, message: 'You need to specify tags' }]}
                    >
                        <ApartmentTagsSelect
                            mode="multiple"
                        />
                    </Form.Item>
                    <Form.Item
                        name="tagsMode"
                        label="Mode"
                        initialValue="and"
                    >
                        <Select
                            options={[
                                {
                                    label: 'And',
                                    value: 'and',
                                },
                                {
                                    label: 'Or',
                                    value: 'or',
                                },
                            ]}
                            style={{
                                width: '150px',
                            }}
                        />
                    </Form.Item>
                </>
            )}
            {filter === 'apartments' && (
                <Form.Item
                    name="apartmentIds"
                    label="Apartments"
                >
                    <ApartmentSelect
                        mode="multiple"
                    />
                </Form.Item>
            )}
        </Form>
    );
}
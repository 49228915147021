import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Button, Card, Col, Form, PageHeader, Result, Row, message } from "antd";
import { useAuth } from "auth";
import { contains } from "common/common";
import { calculateDate, calculateOffset } from "components/relative-date-picker/common";
import { JOB_DATE_TYPE_CLOSEST_AVAILABLE, JOB_DATE_TYPE_FIXED, JOB_DATE_TYPE_RESERVATION, JOB_PRESET_DATE_TYPE_CLOSEST_AVAILABLE, JOB_PRESET_DATE_TYPE_FIXED_DATE, JOB_PRESET_DATE_TYPE_RESERVATION_ENDING_ON_FIXED_DATE, JOB_PRESET_DATE_TYPE_RESERVATION_STARTING_ON_FIXED_DATE, formToJob } from "jobs-new/common";
import JobForm from "jobs-new/forms/JobForm";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RESERVATION_STATUS_CONFIRMED } from "reservations/common";

const MUTATION = gql`
    mutation CreateJobNewFromPreset($input: CreateJobNewInput!) {
        createJobNew(input: $input) {
            error {
                type
                message
            }
            jobNew {
                id
                status
                statusChanges {
                    id
                    status
                    updater {
                        id
                        firstName
                        lastName
                    }
                    updatedAt
                }
                author {
                    id
                    firstName
                    lastName
                }
                createdAt
                title
                description
                calculatedDate
                comments {
                    id
                    author {
                        id
                        firstName
                        lastName
                    }
                    createdAt
                    description
                    attachments {
                        id
                        fileName
                        originalFileName
                        mimeType
                        createdAt
                        getUrl
                    }
                }
                watchers {
                    id
                    firstName
                    lastName
                }
                watcherGroups {
                    id
                    name
                }
                attachments {
                    id
                    fileName
                    originalFileName
                    mimeType
                    createdAt
                    getUrl
                }
                relationsApartmentClosestAvailable {
                    id
                    apartment {
                        id
                        name
                    }
                    closestAvailableDate
                }
                relationsApartmentDate {
                    id
                    apartment {
                        id
                        name
                    }
                    date
                }
                relationsReservationStartDate {
                    id
                    reservation {
                        id
                        startDate
                    }
                }
                relationsReservationEndDate {
                    id
                    reservation {
                        id
                        endDate
                    }
                }
                relationsDate {
                    date
                }
            }
        }
    }
`;

const RESERVATION_QUERY = gql`
    query GetReservationForCreateJobFromPreset($filter: ReservationsFilter!) {
        reservations(filter: $filter) {
            id
            startDate
            endDate
        }
    }
`;

export default function CreateJobFromPresetView() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const { permissions } = useAuth();

    const [jobPreset, setJobPreset] = useState({});
    const [createJob, { loading }] = useMutation(MUTATION);
    const [getReservations] = useLazyQuery(RESERVATION_QUERY);

    const [form] = Form.useForm();

    useEffect(() => {
        const result = {
            title: state?.preset?.title,
            description: state?.preset?.description,
            tags: state?.preset?.tags,
            priority: state?.preset?.priority,
            watchers: state?.preset?.watchers,
            assignerIds: state?.preset?.assignerIds,
            location: state?.preset?.location,
            attachmentIds: state?.preset?.attachmentIds,
        };

        if (state?.preset?.dateType === JOB_PRESET_DATE_TYPE_CLOSEST_AVAILABLE) {
            result.dateType = JOB_DATE_TYPE_CLOSEST_AVAILABLE;
        }
        if (state?.preset?.dateType === JOB_PRESET_DATE_TYPE_FIXED_DATE) {
            result.dateType = JOB_DATE_TYPE_FIXED;
            result.date = calculateOffset(calculateDate(state?.preset?.date?.preset), state?.preset?.date?.offset);
        }
        if (state?.preset?.dateType === JOB_PRESET_DATE_TYPE_RESERVATION_STARTING_ON_FIXED_DATE) {
            if (!state?.preset?.location?.apartmentId) {
                message.error('This preset requires apartment to be specified');
            }
            else {
                const apartmentId = state?.preset?.location?.apartmentId;
                const date = calculateDate(state?.preset?.date?.preset);
                getReservations({
                    variables: {
                        filter: {
                            apartmentId,
                            startDateLte: date,
                            startDateGte: date,
                            status: RESERVATION_STATUS_CONFIRMED,
                        },
                    },
                })
                    .then(response => {
                        const reservations = response.data.reservations;
                        if (reservations.length === 0) {
                            message.error("There is no reservation starting on given date");
                        }
                        else {
                            const newResult = { ...result };
                            newResult.dateType = JOB_DATE_TYPE_RESERVATION;
                            newResult.reservationId = reservations[0].id;

                            setJobPreset(newResult);
                        }
                    });
            }

        }
        if (state?.preset?.dateType === JOB_PRESET_DATE_TYPE_RESERVATION_ENDING_ON_FIXED_DATE) {
            if (!state?.preset?.location?.apartmentId) {
                message.error('This preset requires apartment to be specified');
            }
            else {
                const apartmentId = state?.preset?.location?.apartmentId;
                const date = calculateDate(state?.preset?.date?.preset);
                getReservations({
                    variables: {
                        filter: {
                            apartmentId,
                            endDateLte: date,
                            endDateGte: date,
                            status: RESERVATION_STATUS_CONFIRMED,
                        },
                    },
                })
                    .then(response => {
                        const reservations = response.data.reservations;
                        if (reservations.length === 0) {
                            message.error("There is no reservation ending on given date");
                        }
                        else {
                            const newResult = { ...result };
                            newResult.dateType = JOB_DATE_TYPE_RESERVATION;
                            newResult.reservationId = reservations[0].id;

                            setJobPreset(newResult);
                        }
                    });
            }
        }

        setJobPreset(result);
    }, [state.preset, getReservations]);

    function handleCreate() {
        form
            .validateFields()
            .then(values => {
                const input = formToJob(values);
                return createJob({
                    variables: {
                        input,
                    },
                });
            })
            .then(response => navigate(`/jobs-new/${response.data.createJobNew.jobNew.id}`, { replace: true }));
    }

    if (!state.preset) {
        navigate('/jobs-new/create');
    }

    if (!contains(permissions, ['user:get:self', 'user:get:other', 'user_group:get', 'apartment:get', 'storage:get', 'reservation:get', 'job:create'])) {
        return (
            <Result
                status="error"
                title="No permissions"
                subTitle="You don't have permissions to create jobs"
            />
        );
    }

    return (
        <PageHeader
            title={state?.label ?? 'Create job'}
            onBack={() => navigate(-1)}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <JobForm
                            form={form}
                            job={jobPreset}
                            hideTitle={state?.preset?.hideTitle}
                            hideDescription={state?.preset?.hideDescription}
                            hideTags={state?.preset?.hideTags}
                            hidePriority={state?.preset?.hidePriority}
                            hideWatchers={state?.preset?.hideWatchers}
                            hideAssigners={state?.preset?.hideAssigners}
                            hideLocation={state?.preset?.hideLocation}
                            hideDate={state?.preset?.hideDate}
                            hideAttachments={state?.preset?.hideAttachments}
                            availableReservations={state?.preset?.availableReservations}
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 12,
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => handleCreate()}
                                    loading={loading}
                                >
                                    Create
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    )
}
import { Menu } from "antd";
import { useAuth } from "auth";
import { WEB_MENU_ITEMS } from "common/common";
import { useLocation, useNavigate } from "react-router-dom";

export default function MainMenu(props) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    function reduceMenu(menu, item) {
        if (item.children) {
            const children = item.children.reduce(reduceMenu, []);
            if (children.length > 0) {
                menu.push({
                    key: item.link,
                    title: item.label,
                    label: item.label,
                    children,
                });
            }
        }
        else {
            const userMenuItems = [...user?.webMenu ?? []];
            const userGroupsMenuItems = [...(user?.groups ?? []).map(group => group.webMenu).flat()];
            const menuItems = [...userMenuItems, ...userGroupsMenuItems].filter((item, index, array) => array.indexOf(item) === index);
            if (menuItems.includes(item.link)) {
                menu.push({
                    key: item.link,
                    title: item.label,
                    label: item.label,
                })
            }
        }

        return menu;
    }

    const menuItems = WEB_MENU_ITEMS.reduce(reduceMenu, []);

    function pathnameToSelectedKey() {
        if (location.pathname.indexOf(1, '/') === -1) {
            return location.pathname;
        }
        return location.pathname.slice(0, location.pathname.indexOf(1, '/') - 1);
    }

    return (
        <Menu
            items={menuItems}
            theme="light"
            mode="inline"
            selectedKeys={[pathnameToSelectedKey()]}
            onClick={({ key }) => navigate(key)}
        />
    )
}
import { Rate } from "antd";
import { blue } from "@ant-design/colors";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function JobPrioritySelect(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    return (
        <Rate
            value={value}
            onChange={value => onChange(value)}
            character={<ExclamationCircleOutlined />}
            style={{
                color: blue.primary,
            }}
            {...otherProps}
        />
    )
}
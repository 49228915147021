import { CloseCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import "components/upload/styles.scss";

export default function ErrorThumbnail(props) {
    const {
        file,
        onRemove,
    } = props;

    return (
        <div className="thumbnail-box">
            <Tooltip title={file.name}>
                <CloseOutlined
                    style={{
                        fontSize: '48px',
                    }}
                />
                <CloseCircleTwoTone
                    style={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        fontSize: '24px',
                    }}
                    onClick={() => onRemove()}
                />
            </Tooltip>
        </div>
    );
}
import { PlusOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Upload as AntUpload, Space, Typography } from "antd";
import { CREATE_FILE_MUTATION, GENERATE_FILE_UPLOAD_URL_MUTATION, GET_FILES_QUERY } from "components/upload/graphql";
import { generateThumbnail } from "components/upload/common";
import Thumbnail from "components/upload/Thumbnail";
import { useEffect, useState } from "react";
import PreviewModal from "./PreviewModal";

export default function Upload(props) {
    const {
        value,
        onChange,
    } = props;

    const [fileList, setFileList] = useState([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewFile, setPreviewFile] = useState();

    const [getFiles] = useLazyQuery(GET_FILES_QUERY)
    const [generateFileUploadUrl] = useMutation(GENERATE_FILE_UPLOAD_URL_MUTATION);
    const [createFile] = useMutation(CREATE_FILE_MUTATION);

    useEffect(() => {
        if (!value || value.length === 0) {
            return;
        }

        setFileList(prevFileList => {
            const missingFileIds = value.filter(fileId => !prevFileList.find(prevFile => prevFile?.externalFile?.id === fileId));

            if (missingFileIds.length === 0) {
                return prevFileList;
            }

            const newFiles = missingFileIds
                .map(missingFileId => ({
                    name: '',
                    status: 'loading',
                    uid: missingFileId,
                    type: '',
                    externalFile: {
                        id: missingFileId,
                    }
                }));

            getFiles({
                variables: {
                    filter: {
                        fileIds: missingFileIds,
                    }
                },
            })
                .then(response => {
                    const files = response.data.files;

                    setFileList(prevFileList => {
                        return prevFileList.map(prevFile => {
                            const file = files.find(file => file.id === prevFile?.externalFile?.id);

                            if (file) {
                                prevFile.status = 'done';
                                prevFile.name = file.originalFileName;
                                prevFile.url = file.getUrl;
                                prevFile.type = file.mimeType;
                                prevFile.externalFile = file;
                            }

                            return prevFile;
                        });
                    });
                });

            return [...prevFileList, ...newFiles];
        });

    }, [value, getFiles]);

    function handleOnChange(value) {
        setFileList(value);
        onChange(
            value
                .filter(file => file.externalFile)
                .map(file => file.externalFile.id)
        );
    }

    function handleCustomRequest({ file, onError, onProgress, onSuccess }) {
        const originalFileName = file.name;
        const extension = file.name.split('.').pop();
        const mimeType = file.type;

        generateFileUploadUrl({
            variables: {
                input: {
                    extension,
                    mimeType,
                },
            },
        })
            .then(response => {
                const fileName = response.data.generateFileUploadUrl.fileName;
                const signedPutUrl = response.data.generateFileUploadUrl.putUrl;

                const xhr = new XMLHttpRequest();

                xhr.upload.onprogress = e => {
                    if (e.lengthComputable) {
                        onProgress({ percent: (e.loaded / e.total) * 100 }, file);
                    }
                };
                xhr.upload.onloadend = () => {
                    createFile({
                        variables: {
                            input: {
                                fileName,
                                originalFileName,
                                mimeType,
                            },
                        },
                    })
                        .then(response => {
                            file.externalFile = response.data.createFile.file;
                            file.url = response.data.createFile.file.getUrl;
                            const body = null;
                            onSuccess(body, file);
                        })

                };
                xhr.upload.onerror = () => {
                    const error = null;
                    const body = null;
                    onError(error, body, file);
                };

                xhr.open("PUT", signedPutUrl);
                xhr.setRequestHeader("Content-Type", mimeType);

                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                fileReader.onload = () => {
                    xhr.send(fileReader.result)
                }
            });
    }

    function handlePreview(file) {
        setPreviewFile(file);
        setIsPreviewOpen(true);
    }

    return (
        <>
            <AntUpload
                fileList={fileList}
                onChange={({ fileList }) => handleOnChange(fileList)}
                customRequest={context => handleCustomRequest(context)}
                multiple
                listType="picture-card"
                previewFile={file => generateThumbnail(file)}
                itemRender={(originNode, file, fileList, actions) => (
                    <Thumbnail
                        originNode={originNode}
                        file={file}
                        fileList={fileList}
                        actions={actions}
                    />
                )}
                onPreview={file => handlePreview(file)}
            >
                <Space direction="vertical">
                    <PlusOutlined
                        style={{
                            fontSize: 24,
                        }}
                    />
                    <Typography.Text>
                        Upload
                    </Typography.Text>
                </Space>
            </AntUpload>
            <PreviewModal
                open={isPreviewOpen}
                file={previewFile}
                onCancel={() => setIsPreviewOpen(false)}
            />
        </>
    );
}
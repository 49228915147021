import { useQuery } from "@apollo/client";
import { Form, Input, Radio, Select } from "antd";
import DatePicker from "components/DatePicker";
import { compareAsc, formatISO, startOfToday } from "date-fns";
import { useState } from "react";
import { GET_RESERVATIONS_QUERY } from "reservations/graphql";
import { JOB_CATEGORIES, RESERVATION_DATES } from "./common";
import { useEffect } from "react";
import ApartmentSelect from "apartments/components/apartment-select/ApartmentSelect";

export default function JobModalContent(props) {
    const { job, form, onSubmit } = props;

    const [apartmentId, setApartmentId] = useState(job?.apartmentId);
    const [attachTo, setAttachTo] = useState(job?.attachTo);

    const { data: reservationsData, loading: reservationsLoading } = useQuery(GET_RESERVATIONS_QUERY, {
        variables: {
            filter: {
                apartmentId,
                endDateGte: startOfToday(),
                status: 'confirmed',
            },
        },
        skip: !apartmentId,
    });

    useEffect(() => form.resetFields(), [form]);

    return (
        <Form
            form={form}
            initialValues={job}
            onFinish={values => onSubmit(values)}
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            preserve={false}
        >
            <Form.Item
                label="Apartment"
                name="apartmentId"
                rules={[{ required: true, message: 'Apartment is required' }]}
            >
                <ApartmentSelect
                    onChange={value => setApartmentId(value)}
                />
            </Form.Item>
            <Form.Item
                label="Attach to"
                name="attachTo"
            >
                <Radio.Group
                    options={[
                        { label: 'Apartment', value: 'apartment' },
                        { label: 'Reservation', value: 'reservation' },
                    ]}
                    optionType="button"
                    onChange={e => setAttachTo(e.target.value)}
                />
            </Form.Item>
            {attachTo === 'reservation' && (
                <>
                    <Form.Item
                        label="Reservation"
                        name="reservationId"
                        rules={[{ required: true, message: 'Reservation is required' }]}
                    >
                        <Select
                            options={
                                [...reservationsData?.reservations ?? []]
                                    .sort((a, b) => compareAsc(a.startDate, b.startDate))
                                    .map(reservation => ({
                                        label: `${formatISO(reservation.startDate, { representation: 'date' })} - ${formatISO(reservation.endDate, { representation: 'date' })}`,
                                        value: reservation.id,
                                    }))
                            }
                            disabled={!apartmentId}
                            loading={reservationsLoading}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Date"
                        name="reservationDate"
                    >
                        <Radio.Group
                            options={RESERVATION_DATES}
                            optionType="button"
                        />
                    </Form.Item>
                </>
            )}
            {attachTo === 'apartment' && (
                <>
                    <Form.Item
                        label="Date"
                        name="date"
                        rules={[{ required: true, message: 'Date is required' }]}
                    >
                        <DatePicker />
                    </Form.Item>
                </>
            )}
            <Form.Item
                label="Category"
                name="category"
                rules={[{ required: true, message: 'Category is required' }]}
            >
                <Select
                    options={JOB_CATEGORIES}
                />
            </Form.Item>
            <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: 'Description is required' }]}
            >
                <Input.TextArea />
            </Form.Item>
        </Form>
    );
}
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Button, Dropdown, Form, Modal, Space, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { addDays, max, startOfToday } from "date-fns";
import JobsListActionAssignerForm from "jobs-new/forms/JobsListActionAssignerForm";
import JobsListActionStatusForm from "jobs-new/forms/JobsListActionStatusForm";
import JobsListActionWatcherForm from "jobs-new/forms/JobsListActionWatcherForm";

const CREATE_ASSIGNER_MUTATION = gql`
    mutation CreateAssigner($input: CreateJobNewAssignerInput!) {
        createJobNewAssigner(input: $input) {
            jobNew {
                id
                assigners {
                    id
                    firstName
                    lastName
                }
                status
            }
            error {
                type
                message
            }
        }
    }
`;

const DELETE_ASSIGNER_MUTATION = gql`
    mutation DeleteAssigner($input: DeleteJobNewAssignerInput!) {
        deleteJobNewAssigner(input: $input) {
            jobNew {
                id
                assigners {
                    id
                    firstName
                    lastName
                }
                status
            }
            error {
                type
                message
            }
        }
    }
`;

const CREATE_WATCHER_MUTATION = gql`
    mutation CreateWatcher($input: CreateJobNewWatcherInput!) {
        createJobNewWatcher(input: $input) {
            jobNew {
                id
                watchers {
                    id
                    firstName
                    lastName
                }
            }
            error {
                type
                message
            }
        }
    }
`;

const DELETE_WATCHER_MUTATION = gql`
    mutation DeleteWatcher($input: DeleteJobNewWatcherInput!) {
        deleteJobNewWatcher(input: $input) {
            jobNew {
                id
                watchers {
                    id
                    firstName
                    lastName
                }
            }
            error {
                type
                message
            }
        }
    }
`;

const CREATE_WATCHER_GROUP_MUTATION = gql`
    mutation CreateWatcherGroup($input: CreateJobNewWatcherGroupInput!) {
        createJobNewWatcherGroup(input: $input) {
            jobNew {
                id
                watcherGroups {
                    id
                    name
                }
            }
            error {
                type
                message
            }
        }
    }
`;

const DELETE_WATCHER_GROUP_MUTATION = gql`
    mutation DeleteWatcherGroup($input: DeleteJobNewWatcherGroupInput!) {
        deleteJobNewWatcherGroup(input: $input) {
            jobNew {
                id
                watcherGroups {
                    id
                    name
                }
            }
            error {
                type
                message
            }
        }
    }
`;

const UPDATE_STATUS_MUTATION = gql`
    mutation UpdateStatus($input: UpdateJobNewInput!) {
        updateJobNew(input: $input) {
            jobNew {
                id
                status
            }
            error {
                type
                message
            }
        }
    }
`;

const MOVE_TO_NEXT_DATE_MUTATION = gql`
    mutation MoveToNextDate($input: UpdateJobNewInput!) {
        updateJobNew(input: $input) {
            error {
                type
                message
            }
            jobNew {
                id
                calculatedDate
                relationsApartmentClosestAvailable {
                    id
                    afterDate
                }
            }
        }
    }
`;

export default function JobsListActions(props) {
    const {
        selectedJobs,
        clearSelectedJobs,
    } = props;

    const [addAssignerModalOpen, setAddAssignerModalOpen] = useState(false);
    const [removeAssignerModalOpen, setRemoveAssignerModalOpen] = useState(false);
    const [addWatcherModalOpen, setAddWatcherModalOpen] = useState(false);
    const [removeWatcherModalOpen, setRemoveWatcherModalOpen] = useState(false);
    const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false);

    const [addAssignerLoading, setAddAssignerLoading] = useState(false);
    const [removeAssignerLoading, setRemoveAssignerLoading] = useState(false);
    const [addWatcherLoading, setAddWatcherLoading] = useState(false);
    const [removeWatcherLoading, setRemoveWatcherLoading] = useState(false);
    const [changeStatusLoading, setChangeStatusLoading] = useState(false);

    const [assignerForm] = Form.useForm();
    const [watcherForm] = Form.useForm();
    const [statusForm] = Form.useForm();

    const [createAssigner] = useMutation(CREATE_ASSIGNER_MUTATION);
    const [deleteAssigner] = useMutation(DELETE_ASSIGNER_MUTATION);
    const [createWatcher] = useMutation(CREATE_WATCHER_MUTATION);
    const [deleteWatcher] = useMutation(DELETE_WATCHER_MUTATION);
    const [createWatcherGroup] = useMutation(CREATE_WATCHER_GROUP_MUTATION);
    const [deleteWatcherGroup] = useMutation(DELETE_WATCHER_GROUP_MUTATION);
    const [updateStatus] = useMutation(UPDATE_STATUS_MUTATION);
    const [moveToNextDate] = useMutation(MOVE_TO_NEXT_DATE_MUTATION);

    function handleAction(action) {
        if (action === 'addAssigner') {
            setAddAssignerModalOpen(true);
        }
        if (action === 'removeAssigner') {
            setRemoveAssignerModalOpen(true);
        }
        if (action === 'addWatcher') {
            setAddWatcherModalOpen(true);
        }
        if (action === 'removeWatcher') {
            setRemoveWatcherModalOpen(true);
        }
        if (action === 'changeStatus') {
            setChangeStatusModalOpen(true);
        }
        if (action === 'moveToNextDate') {
            handleMoveToNextDate();
        }
    }

    function handleAddAssigner() {
        assignerForm
            .validateFields()
            .then(values => {
                setAddAssignerLoading(true);
                Promise.all(
                    selectedJobs.map(selectedJob => {
                        return createAssigner({
                            variables: {
                                input: {
                                    jobNewId: selectedJob.id,
                                    assignerId: values.assignerId,
                                },
                            },
                        });
                    })
                )
                    .then(() => {
                        setAddAssignerLoading(false);
                        setAddAssignerModalOpen(false);
                    });
            });
    }

    function handleRemoveAssigner() {
        assignerForm
            .validateFields()
            .then(values => {
                setRemoveAssignerLoading(true);
                Promise.all(
                    selectedJobs.map(selectedJob => {
                        return deleteAssigner({
                            variables: {
                                input: {
                                    jobNewId: selectedJob.id,
                                    assignerId: values.assignerId,
                                },
                            },
                        });
                    })
                )
                    .then(() => {
                        setRemoveAssignerLoading(false);
                        setRemoveAssignerModalOpen(false);
                    });
            });
    }

    function handleAddWatcher() {
        watcherForm
            .validateFields()
            .then(values => {
                setAddWatcherLoading(true);
                Promise.all(
                    selectedJobs.map(selectedJob => {
                        if (values.watcher.userId) {
                            return createWatcher({
                                variables: {
                                    input: {
                                        jobNewId: selectedJob.id,
                                        watcherId: values.watcher.userId,
                                    },
                                },
                            });
                        }
                        else if (values.watcher.userGroupId) {
                            return createWatcherGroup({
                                variables: {
                                    input: {
                                        jobNewId: selectedJob.id,
                                        watcherGroupId: values.watcher.userGroupId,
                                    },
                                },
                            });
                        }

                        return Promise.resolve();
                    })
                )
                    .then(() => {
                        setAddWatcherLoading(false);
                        setAddWatcherModalOpen(false);
                    })
            });
    }

    function handleRemoveWatcher() {
        watcherForm
            .validateFields()
            .then(values => {
                setRemoveWatcherLoading(true);
                Promise.all(
                    selectedJobs.map(selectedJob => {
                        if (values.watcher.userId) {
                            return deleteWatcher({
                                variables: {
                                    input: {
                                        jobNewId: selectedJob.id,
                                        watcherId: values.watcher.userId,
                                    },
                                },
                            });
                        }
                        else if (values.watcher.userGroupId) {
                            return deleteWatcherGroup({
                                variables: {
                                    input: {
                                        jobNewId: selectedJob.id,
                                        watcherGroupId: values.watcher.userGroupId,
                                    },
                                },
                            });
                        }

                        return Promise.resolve();
                    })
                )
                    .then(() => {
                        setRemoveWatcherLoading(false);
                        setRemoveWatcherModalOpen(false);
                    })
            });
    }

    function handleChangeStatus() {
        statusForm
            .validateFields()
            .then(values => {
                setChangeStatusLoading(true);
                Promise.all(
                    selectedJobs.map(selectedJob => {
                        return updateStatus({
                            variables: {
                                input: {
                                    jobNewId: selectedJob.id,
                                    status: values.status,
                                },
                            },
                        });
                    })
                )
                    .then(() => {
                        setChangeStatusLoading(false);
                        setChangeStatusModalOpen(false);
                    })
            })
    }

    function handleMoveToNextDate() {
        Promise.all(
            selectedJobs.map(selectedJob => {
                const relation = selectedJob.relationsApartmentClosestAvailable[0];
                const afterDate = relation.afterDate
                    ? max([selectedJob.calculatedDate, addDays(relation.afterDate, 1)])
                    : startOfToday();

                return moveToNextDate({
                    variables: {
                        input: {
                            jobNewId: selectedJob.id,
                            relationsApartmentClosestAvailable: [{
                                apartmentId: selectedJob.apartment.id,
                                afterDate,
                            }],
                        },
                    },
                });
            })
        )
            .then(() => {
                clearSelectedJobs();
            });
    }

    function canMoveToNextDate() {
        return selectedJobs.every(selectedJob => selectedJob.relationsApartmentClosestAvailable?.length > 0);
    }

    return (
        <>
            <Dropdown
                menu={{
                    items: [
                        {
                            label: 'Add assigner',
                            key: 'addAssigner',
                            disabled: selectedJobs.length === 0,
                        },
                        {
                            label: 'Remove assigner',
                            key: 'removeAssigner',
                            disabled: selectedJobs.length === 0,
                        },
                        {
                            label: 'Add watcher',
                            key: 'addWatcher',
                            disabled: selectedJobs.length === 0,
                        },
                        {
                            label: 'Remove watcher',
                            key: 'removeWatcher',
                            disabled: selectedJobs.length === 0,
                        },
                        {
                            label: 'Change status',
                            key: 'changeStatus',
                            disabled: selectedJobs.length === 0,
                        },
                        {
                            label: 'Move to next date',
                            key: 'moveToNextDate',
                            disabled: selectedJobs.length === 0 || !canMoveToNextDate(),
                        },
                    ],
                    onClick: ({ key }) => handleAction(key),
                }}
            >
                <Button>
                    <Space>
                        <Typography.Text>
                            Action
                        </Typography.Text>
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
            <Modal
                title="Add assigner"
                open={addAssignerModalOpen}
                destroyOnClose
                onCancel={() => setAddAssignerModalOpen(false)}
                onOk={() => handleAddAssigner()}
                okText="Add assigner"
                okButtonProps={{
                    type: 'primary',
                    loading: addAssignerLoading,
                }}
            >
                <JobsListActionAssignerForm
                    form={assignerForm}
                    preserve={false}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                />
            </Modal>
            <Modal
                title="Remove assigner"
                open={removeAssignerModalOpen}
                destroyOnClose
                onCancel={() => setRemoveAssignerModalOpen(false)}
                onOk={() => handleRemoveAssigner()}
                okText="Remove assigner"
                okButtonProps={{
                    type: 'primary',
                    loading: removeAssignerLoading,
                }}
            >
                <JobsListActionAssignerForm
                    form={assignerForm}
                    preserve={false}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                />
            </Modal>
            <Modal
                title="Add watcher"
                open={addWatcherModalOpen}
                destroyOnClose
                onCancel={() => setAddWatcherModalOpen(false)}
                onOk={() => handleAddWatcher()}
                okText="Add watcher"
                okButtonProps={{
                    type: 'primary',
                    loading: addWatcherLoading,
                }}
            >
                <JobsListActionWatcherForm
                    form={watcherForm}
                    preserve={false}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                />
            </Modal>
            <Modal
                title="Remove watcher"
                open={removeWatcherModalOpen}
                destroyOnClose
                onCancel={() => setRemoveWatcherModalOpen(false)}
                onOk={() => handleRemoveWatcher()}
                okText="Remove watcher"
                okButtonProps={{
                    type: 'primary',
                    loading: removeWatcherLoading,
                }}
            >
                <JobsListActionWatcherForm
                    form={watcherForm}
                    preserve={false}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                />
            </Modal>
            <Modal
                title="Change status"
                open={changeStatusModalOpen}
                destroyOnClose
                onCancel={() => setChangeStatusModalOpen(false)}
                onOk={() => handleChangeStatus()}
                okText="Change status"
                okButtonProps={{
                    type: 'primary',
                    loading: changeStatusLoading,
                }}
            >
                <JobsListActionStatusForm
                    form={statusForm}
                    preserve={false}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                />
            </Modal>
        </>
    );
}
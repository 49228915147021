import { Col, Row } from "antd";
import ApartmentSelect from "apartments/components/apartment-select/ApartmentSelect";
import DatePicker from "components/DatePicker";
import ApartmentExpenseCategorySelect from "../apartment-expense-category-select/ApartmentExpenseCategorySelect";

export default function ApartmentExpensesFilter(props) {
    const {
        dateRange,
        onDateRangeChange,
        apartmentIds,
        onApartmentIdsChange,
        apartmentExpenseCategoryIds,
        onApartmentExpenseCategoryIdsChange,
    } = props;

    return (
        <Row gutter={[16, 16]}>
            <Col>
                <DatePicker.RangePicker
                    value={dateRange}
                    onChange={value => onDateRangeChange(value)}
                />
            </Col>
            <Col>
                <ApartmentSelect
                    value={apartmentIds}
                    onChange={value => onApartmentIdsChange(value)}
                    mode="multiple"
                    placeholder="Apartments"
                />
            </Col>
            <Col>
                <ApartmentExpenseCategorySelect
                    value={apartmentExpenseCategoryIds}
                    onChange={value => onApartmentExpenseCategoryIdsChange(value)}
                    mode="multiple"
                    placeholder="Categories"
                />
            </Col>
        </Row>
    );
}
import { Form, Input } from "antd";
import { useEffect } from "react";

export default function UserAvailabilityForm(props) {
    const {
        user,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            availabilityNote: user?.availabilityNote,
        });
    }, [form, user])

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="availabilityNote"
                label="Availability note"
            >
                <Input.TextArea
                    autoSize
                />
            </Form.Item>
        </Form>
    )
}
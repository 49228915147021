import { useEffect } from "react";
import { Form, Modal } from "antd";
import UserUserGroupSelect from "users/components/user-user-group-select/UserUserGroupSelect";

export default function JobWatchersModal(props) {
    const {
        open,
        form,
        job,
        onSubmit,
        onCancel,
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            watchers: [
                ...[...job?.watchers ?? []].map(watcher => ({ userId: watcher.id })),
                ...[...job?.watcherGroups ?? []].map(watcherGroup => ({ userGroupId: watcherGroup.id })),
            ],
        });
    }, [open, form, job?.watchers, job?.watcherGroups]);

    return (
        <Modal
            open={open}
            title={job?.title ?? 'Watchers'}
            onOk={() => onSubmit()}
            onCancel={() => onCancel()}
            destroyOnClose
        >
            <Form
                form={form}
                preserve={false}
            >
                <Form.Item
                    name="watchers"
                    label="Watchers"
                >
                    <UserUserGroupSelect
                        active
                        mode="multiple"
                        placeholder="Watchers"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
import classNames from "classnames";

const DEFAULT_CHECKIN_TIME = "unknown";

export default function CheckinTime(props) {
    const {
        checkinTime,
        difference,
        overriden,
        className,
        ...otherProps
    } = props;

    if (!checkinTime) {
        return (
            <span
                className={classNames({
                    "cleaning-apartment-no-time": true,
                    "difference": difference,
                    "overriden": overriden,
                    [className]: true,
                })}
                {...otherProps}
            >
                {DEFAULT_CHECKIN_TIME}
            </span>
        )
    }

    return (
        <span
            className={classNames({
                "difference": difference,
                "overriden": overriden,
                [className]: true,
            })}
            {...otherProps}
        >
            {checkinTime}
        </span>
    );
}
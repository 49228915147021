import { gql } from '@apollo/client';

export const CREATE_JOB_MUTATION = gql`
    mutation CreateJob($input: CreateJobInput!) {
        createJob(input: $input) {
            error {
                type
                message
            }
            job {
                id
                attachTo
                apartment {
                    id
                    name
                }
                currentReservation {
                    id
                    guestFirstName
                    guestLastName
                    guestPhone
                    numberOfGuests
                }
                endingReservation {
                    id
                    checkoutTime
                    guestFirstName
                    guestLastName
                    guestPhone
                    numberOfGuests
                }
                startingReservation {
                    id
                    checkinTime
                    guestFirstName
                    guestLastName
                    guestPhone
                    numberOfGuests
                }
                date
                reservation {
                    id
                    checkinTime
                    guestPhone
                }
                reservationDate
                calculatedDate
                category
                description
            }
        }
    }
`;

export const UPDATE_JOB_MUTATION = gql`
    mutation UpdateJob($input: UpdateJobInput!) {
        updateJob(input: $input) {
            error {
                type
                message
            }
            job {
                id
                attachTo
                apartment {
                    id
                    name
                }
                currentReservation {
                    id
                    guestFirstName
                    guestLastName
                    guestPhone
                    numberOfGuests
                }
                endingReservation {
                    id
                    checkoutTime
                    guestFirstName
                    guestLastName
                    guestPhone
                    numberOfGuests
                }
                startingReservation {
                    id
                    checkinTime
                    guestFirstName
                    guestLastName
                    guestPhone
                    numberOfGuests
                }
                date
                reservation {
                    id
                    checkinTime
                    guestPhone
                }
                reservationDate
                calculatedDate
                category
                description
            }
        }
    }
`;


export const DELETE_JOB_MUTATION = gql`
    mutation DeleteJob($input: DeleteJobInput!) {
        deleteJob(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import "./style.css";

export default function RowHeader(props) {
    const {
        user,
        renderExtra,
    } = props;

    return (
        <div className="availability-calendar-row-header-container">
            <div>
                {user.firstName} {user.lastName}
            </div>
            {user.availabilityNote && (
                <div>
                    <Popover
                        content={user.availabilityNote}
                        placement="right"
                    >
                        <InfoCircleOutlined />
                    </Popover>
                </div>
            )}
            {renderExtra?.(user)}
        </div>
    );
}
import { Button, PageHeader, Space } from "antd";
import { SaveOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { addEdge, Controls, MiniMap, Panel, ReactFlow, useEdgesState, useNodesState } from "@xyflow/react";
import { createNodeType, NODE_TYPES as GENERIC_NODE_TYPES, TRIGGER_NODE_TYPES } from "automation/components/automation-node/common";
import "@xyflow/react/dist/style.css";
import "../components/automation-node/style.css";
import NodesPanel from "automation/components/nodes-panel/NodesPanel";
import ExtractNode from "automation/components/automation-node/ExtractNode";
import CombineNode from "automation/components/automation-node/CombineNode";
import StringValueNode from "automation/components/automation-node/StringValueNode";
import NumberValueNode from "automation/components/automation-node/NumberValueNode";
import DateValueNode from "automation/components/automation-node/DateValueNode";
import DateTimeValueNode from "automation/components/automation-node/DateTimeValueNode";


const NODE_TYPES = {
    ...Object.fromEntries(
        GENERIC_NODE_TYPES.map(nodeDefinition => ([
            nodeDefinition.type, createNodeType(nodeDefinition),
        ]))
    ),
    extract: ExtractNode,
    combine: CombineNode,
    string: StringValueNode,
    number: NumberValueNode,
    date: DateValueNode,
    datetime: DateTimeValueNode,
}


export default function UpdateAutomationView(props) {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    function handleNodeOnChange(nodeId, value) {
        setNodes(prevNodes => prevNodes.map(prevNode => {
            if (prevNode.id === nodeId) {
                return {
                    ...prevNode,
                    data: {
                        ...(prevNode?.data ?? {}),
                        ...value,
                    },
                };
            }

            return prevNode;
        }));
    }

    function handleNodeOnDelete(nodeId) {
        setNodes(prevNodes => prevNodes.filter(prevNode => prevNode.id !== nodeId));
    }

    function handleAddNode(type) {
        const nodeId = crypto.randomUUID();
        setNodes(prevNodes => ([
            ...prevNodes,
            {
                id: nodeId,
                type,
                data: {
                    onChange: (value) => handleNodeOnChange(nodeId, value),
                    onDelete: () => handleNodeOnDelete(nodeId),
                },
                position: {
                    x: 100,
                    y: 100,
                },
            },
        ]));
    }

    function handleOnConnect(connection) {
        setEdges(prevEdges => addEdge(connection, prevEdges));
    }

    function handleRun() {

    }

    function handleSave() {
        const triggers = nodes
            .filter(node => TRIGGER_NODE_TYPES.includes(node.type))
            .map(node => node.type);

        const output = {
            nodes: nodes.map(node => ({
                id: node.id,
                type: node.type,
                props: Object.fromEntries(Object.entries(node.data).filter(([_, value]) => typeof value !== 'function')),
            })),
            edges: edges.map(edge => ({
                source: edge.source,
                source_handle: edge.sourceHandle,
                target: edge.target,
                target_handle: edge.targetHandle,
            })),
            triggers,
        };

        console.log(output);
    }

    return (
        <PageHeader
            title="Automation"
            extra={
                <Space>
                    <Button
                        onClick={() => handleRun()}
                        icon={<ThunderboltOutlined />}
                    >
                        Run
                    </Button>
                    <Button
                        onClick={() => handleSave()}
                        icon={<SaveOutlined />}
                        type="primary"
                    >
                        Save
                    </Button>
                </Space>
            }
        >
            <div
                style={{
                    width: '100%',
                    height: '600px',
                    border: '1px solid #e0e0e0',
                    backgroundColor: '#ffffff',
                }}
            >
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={NODE_TYPES}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={handleOnConnect}
                    fitView
                    panOnScroll
                    maxZoom={1}
                    minZoom={1}
                    snapToGrid
                    snapGrid={[24, 24]}
                >
                    <Panel position="top-right">
                        <NodesPanel
                            onClick={nodeType => handleAddNode(nodeType)}
                        />
                    </Panel>
                    <Controls />
                    <MiniMap />
                </ReactFlow>
            </div>
        </PageHeader>
    );
}
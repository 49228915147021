import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import { formatISO } from "date-fns";
import { taskPromise } from "common/task";
import "./style.css";

const MUTATION = gql`
    mutation UpdateApartmentCalendar($input: UpdateApartmentCalendarInput!) {
        updateApartmentCalendar(input: $input) {
            error {
                type
                message
            }
            apartmentCalendar {
                id
                available
                comment
                updater {
                    id
                    firstName
                    lastName
                }
            }
            syncApartmentCalendarUpTask {
                id
            }
        }
    }
`

export default function SelectedDaysControls(props) {
    const {
        selectedDays,
        onDeselectAll,
    } = props;

    const [makeAvailableLoading, setMakeAvailableLoading] = useState(false);
    const [makeUnavailableLoading, setMakeUnavailableLoading] = useState(false);
    const [makeUnavailableModalOpen, setMakeUnavailableModalOpen] = useState(false);
    const [commentForm] = Form.useForm();
    const [updateApartmentCalendar] = useMutation(MUTATION);

    function handleMakeAvailable() {
        setMakeAvailableLoading(true);
        const updates = {};
        selectedDays.forEach(([apartmentId, date]) => {
            if (!(apartmentId in updates)) {
                updates[apartmentId] = [];
            }
            updates[apartmentId].push(date);
        });

        Promise.all(
            Object.entries(updates).map(([apartmentId, dates]) => {
                return updateApartmentCalendar({
                    variables: {
                        input: {
                            apartmentId,
                            changes: dates.map(date => ({
                                date: formatISO(date, { representation: 'date' }),
                                available: true,
                                comment: null,
                            })),
                        },
                    },
                });
            })
        )
            .then(responses => {
                return Promise.all(
                    responses.map(response => {
                        return taskPromise(response.data.updateApartmentCalendar.syncApartmentCalendarUpTask.id);
                    })
                );
            })
            .then(() => setMakeAvailableLoading(false));
    }

    function handleMakeUnavailable() {
        commentForm
            .validateFields()
            .then(values => {
                setMakeUnavailableLoading(true);
                const updates = {};
                selectedDays.forEach(([apartmentId, date]) => {
                    if (!(apartmentId in updates)) {
                        updates[apartmentId] = [];
                    }
                    updates[apartmentId].push(date);
                });

                Promise.all(
                    Object.entries(updates).map(([apartmentId, dates]) => {
                        return updateApartmentCalendar({
                            variables: {
                                input: {
                                    apartmentId,
                                    changes: dates.map(date => ({
                                        date: formatISO(date, { representation: 'date' }),
                                        available: false,
                                        comment: values.comment,
                                    })),
                                },
                            },
                        });
                    })
                )
                    .then(responses => {
                        return Promise.all(
                            responses.map(response => {
                                return taskPromise(response.data.updateApartmentCalendar.syncApartmentCalendarUpTask.id);
                            })
                        );
                    })
                    .then(() => {
                        setMakeUnavailableLoading(false);
                        setMakeUnavailableModalOpen(false);
                    });
            })
    }

    return (
        <div className="selected-days-controls-container">
            <Button
                onClick={() => handleMakeAvailable()}
                loading={makeAvailableLoading}
            >
                Make selected days available
            </Button>
            <Button
                onClick={() => setMakeUnavailableModalOpen(true)}
                loading={makeUnavailableLoading}
            >
                Make selected days unavailable
            </Button>
            <Button
                onClick={() => onDeselectAll()}
            >
                Deselect all days
            </Button>
            <Modal
                open={makeUnavailableModalOpen}
                title="Set unavailable?"
                onOk={() => handleMakeUnavailable()}
                onCancel={() => setMakeUnavailableModalOpen(false)}
                okButtonProps={{
                    loading: makeUnavailableLoading,
                }}
                destroyOnClose
            >
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Typography.Text>
                            Selected days will become unavailable and below comment will be added
                        </Typography.Text>
                    </Col>
                    <Col span={24}>
                        <Form
                            form={commentForm}
                            layout="horizontal"
                            preserve={false}
                        >
                            <Form.Item
                                name="comment"
                                rules={[{ required: true, message: 'Comment is required' }]}
                            >
                                <Input.TextArea
                                    placeholder="Comment"
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
}
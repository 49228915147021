import { Col, InputNumber, Row, Select } from "antd";

const DEFAULT_OFFSET = 0;
const DEFAULT_PERIOD = 'day';

export default function OffsetPicker(props) {
    const {
        value,
        onChange,
        disabled,
    } = props;

    return (
        <Row gutter={[8, 8]}>
            <Col>
                <InputNumber
                    value={value?.offset}
                    defaultValue={0}
                    onChange={offset => onChange({ offset, period: value?.period ?? DEFAULT_PERIOD })}
                    placeholder="Number"
                    disabled={disabled}
                />
            </Col>
            <Col>
                <Select
                    options={[
                        {
                            label: value?.offset === 1 ? 'Day' : 'Days',
                            value: 'day',
                        },
                        {
                            label: value?.offset === 1 ? 'Week' : 'Weeks',
                            value: 'week',
                        },
                        {
                            label: value?.offset === 1 ? 'Month' : 'Months',
                            value: 'month',
                        },
                        {
                            label: value?.offset === 1 ? 'Year' : 'Years',
                            value: 'year',
                        },
                    ]}
                    value={value?.period}
                    defaultValue={DEFAULT_PERIOD}
                    onChange={period => onChange({ offset: value?.offset ?? DEFAULT_OFFSET, period })}
                    placeholder="Period"
                    style={{
                        width: '100px',
                    }}
                    disabled={disabled}
                />
            </Col>
        </Row>
    )
}
import { Checkbox } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { format, formatISO } from "date-fns";
import LocationList from "./LocationList";
import UserGroupList from "./UserGroupList";

export default function DateList(props) {
    const {
        date,
        jobs,
        display,
        expandedItems,
        onExpandChange,
        selectedJobs,
        onSelect,
    } = props;

    const jobIds = [...jobs ?? []].map(job => job.id);
    const allJobsAreSelected = jobIds.every(jobId => [...selectedJobs ?? []].find(selectedJob => selectedJob.id === jobId));
    const someJobsAreSelected = jobIds.some(jobId => [...selectedJobs ?? []].find(selectedJob => selectedJob.id === jobId));

    function handleCheckboxClick() {
        if (allJobsAreSelected) {
            onSelect([...selectedJobs].filter(selectedJob => !jobIds.includes(selectedJob.id)));
        }
        else {
            onSelect([...selectedJobs, ...jobs].filter((job, index, array) => array.findIndex(otherJob => otherJob.id === job.id) === index));
        }
    }

    function locationSorter(a, b) {
        if (a.key === 'noLocation') {
            return 1;
        }
        if (b.key === 'noLocation') {
            return -1;
        }

        return a.name.localeCompare(b.name);
    }

    function jobsByLocation(location) {
        if (location.apartmentId) {
            return [...jobs ?? []]
                .filter(job => job.apartment?.id === location.apartmentId);
        }
        if (location.storageId) {
            return [...jobs ?? []]
                .filter(job => job.storage?.id === location.storageId);
        }
        return [...jobs ?? []]
            .filter(job => !job.storage && !job.apartment);
    }

    function jobsByUserGroup(userGroup) {
        return [...jobs ?? []]
            .filter(job => job.watcherGroups.some(watcherGroup => watcherGroup.id === userGroup.userGroupId));
    }

    const locations = [...jobs ?? []]
        .map(job => {
            if (job.apartment) {
                return {
                    key: `apartment:${job.apartment.id}`,
                    name: job.apartment.name,
                    storageName: job.apartment.storage.name,
                    storageOrder: job.apartment.storage.order,
                    apartmentId: job.apartment.id,
                    endingReservation: job.endingReservation,
                    currentReservation: job.currentReservation,
                    startingReservation: job.startingReservation,
                };
            }
            if (job.storage) {
                return {
                    key: `storage:${job.storage.id}`,
                    name: job.storage.name,
                    storageOrder: job.storage.order,
                    storageId: job.storage.id,
                };
            }
            return {
                key: 'noLocation',
                name: 'No location',
            };
        })
        .filter((item, index, array) => array.findIndex(i => i.key === item.key) === index)
        .sort((a, b) => locationSorter(a, b));

    const userGroups = [...jobs ?? []]
        .map(job => {
            return job.watcherGroups.map(userGroup => ({
                key: `userGroup:${userGroup.id}`,
                name: userGroup.name,
                userGroupId: userGroup.id,
            }));
        })
        .flat()
        .filter((item, index, array) => array.findIndex(i => i.key === item.key) === index)
        .sort((a, b) => a.name.localeCompare(b.name));

    const allKeys = display?.grouping === 'locations'
        ? locations.map(location => `${location.key}-${formatISO(date, { representation: 'date' })}`)
        : userGroups.map(userGroup => `${userGroup.key}-${formatISO(date, { representation: 'date' })}`);

    function handleExpandAll() {
        const keysToExpand = allKeys.filter(key => ![...expandedItems ?? []].includes(key));
        onExpandChange([...expandedItems ?? [], ...keysToExpand]);
    }

    function handleCollapseAll() {
        const keysToCollapse = allKeys.filter(key => [...expandedItems ?? []].includes(key));
        onExpandChange([...expandedItems ?? []].filter(key => !keysToCollapse.includes(key)));
    }

    return (
        <div className="jobs-date-list-container">
            <div className="jobs-date-list-header">
                <div className="jobs-date-list-checkbox">
                    <Checkbox
                        checked={allJobsAreSelected}
                        indeterminate={someJobsAreSelected && !allJobsAreSelected}
                        onClick={() => handleCheckboxClick()}
                    />
                </div>
                <div className="jobs-date-list-label">
                    {format(date, 'yyyy-MM-dd')}
                </div>
                <div>
                    <DownOutlined onClick={() => handleExpandAll()} />
                </div>
                <div>
                    <UpOutlined onClick={() => handleCollapseAll()} />
                </div>
            </div>
            <div className="jobs-date-list-jobs">
                {display?.grouping === 'locations' && locations.map(item => (
                    <LocationList
                        key={item.key}
                        location={item}
                        date={date}
                        jobs={jobsByLocation(item)}
                        display={display}
                        expandedItems={expandedItems}
                        onExpandChange={value => onExpandChange(value)}
                        selectedJobs={selectedJobs}
                        onSelect={value => onSelect(value)}
                    />
                ))}
                {display?.grouping === 'userGroups' && userGroups.map(item => (
                    <UserGroupList
                        key={item.key}
                        userGroup={item}
                        date={date}
                        jobs={jobsByUserGroup(item)}
                        display={display}
                        expandedItems={expandedItems}
                        onExpandChange={value => onExpandChange(value)}
                        selectedJobs={selectedJobs}
                        onSelect={value => onSelect(value)}
                    />
                ))}
            </div>
        </div>
    );
}
import { Form } from "antd";
import UserUserGroupSelect from "users/components/user-user-group-select/UserUserGroupSelect";

export default function JobsListActionWatcherForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="watcher"
                label="Watcher"
            >
                <UserUserGroupSelect
                    active
                    placeholder="Watcher"
                />
            </Form.Item>
        </Form>
    );
}
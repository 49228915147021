import { Card, PageHeader, Result } from "antd";
import { useAuth } from "auth";
import { contains } from "common/common";
import DateSelectWithArrows from "components/date-select-with-arrows/DateSelectWithArrows";
import { formatISO, parseISO, startOfToday } from "date-fns";
import RecentJobsList from "jobs-new/components/recent-jobs-list/RecentJobsList";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function ListRecentJobsView() {
    const { date: dateStr } = useParams();

    const navigate = useNavigate();

    const { permissions } = useAuth();

    const [display, setDisplay] = useState();

    useEffect(() => {
        const storedDisplayStr = localStorage.getItem('recentJobsDisplay');
        if (storedDisplayStr) {
            const storedDisplay = JSON.parse(storedDisplayStr);
            setDisplay(storedDisplay);
        }
        else {
            setDisplay(['storage', 'status', 'assigners', 'priority']);
        }
    }, []);

    useEffect(() => {
        if (!display) {
            return;
        }

        localStorage.setItem(
            'recentJobsDisplay',
            JSON.stringify(display),
        );
    }, [display]);

    function handleDateChange(date) {
        navigate(`/jobs-new/recent/${formatISO(date, { representation: 'date' })}`);
    }

    if (!dateStr) {
        handleDateChange(startOfToday());
        return null;
    }

    const date = parseISO(dateStr);

    if (!contains(permissions, ['user:get:self', 'user:get:other', 'user_group:get', 'apartment:get', 'storage:get', 'reservation:get', 'job:get'])) {
        return (
            <Result
                status="error"
                title="No permissions"
                subTitle="You don't have permissions to view job"
            />
        );
    }

    return (
        <PageHeader
            title="Recent jobs"
            extra={[
                <DateSelectWithArrows
                    value={date}
                    onChange={value => handleDateChange(value)}
                />
            ]}
        >
            <Card size="small">
                <RecentJobsList
                    date={date}
                />
            </Card>
        </PageHeader>
    );
}
import { gql, useMutation } from "@apollo/client";
import { List, Typography } from "antd";
import { formatDistanceToNow } from "date-fns";

const MUTATION = gql`
    mutation DeleteAuthToken($input: DeleteAuthTokenInput!) {
        deleteAuthToken(input: $input) {
            error {
                type
                message
            }
        }
    }
`

export default function Item(props) {
    const {
        authToken,
    } = props;

    const [deleteAuthToken] = useMutation(MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'User',
                    id: authToken.user.id,
                }),
                fieldName: 'authTokens',
            });
        },
    });

    function handleDelete() {
        deleteAuthToken({
            variables: {
                input: {
                    authTokenId: authToken.id,
                },
            },
        });
    }

    return (
        <List.Item
            actions={[
                <Typography.Link onClick={() => handleDelete()}>
                    Delete
                </Typography.Link>
            ]}
        >
            <List.Item.Meta
                title={authToken.sessionInfo || 'Unknown session'}
                description={`Expires ${formatDistanceToNow(authToken.validTo, { addSuffix: true })}`}
            />
        </List.Item>
    );
}
import DocumentThumbnail from "components/upload/DocumentThumbnail";
import ErrorThumbnail from "components/upload/ErrorThumbnail";
import ImageThumbnail from "components/upload/ImageThumbnail";
import UploadingThumbnail from "components/upload/UploadingThumbnail";
import LoadingThumbnail from "components/upload/LoadingThumbnail";
import VideoThumbnail from "components/upload/VideoThumbnail";

export default function Thumbnail(props) {
    const {
        file,
        actions,
    } = props;

    if (file.status === 'uploading') {
        return (
            <UploadingThumbnail
                file={file}
            />
        );
    }

    if (file.status === 'loading') {
        return (
            <LoadingThumbnail
                file={file}
            />
        );
    }

    if (file.status === 'error') {
        return (
            <ErrorThumbnail
                file={file}
                onRemove={() => actions.remove()}
            />
        );
    }

    if (file.status === 'done') {
        if (file.type.startsWith('image/')) {
            return (
                <ImageThumbnail
                    file={file}
                    onPreview={() => actions.preview()}
                    onRemove={() => actions.remove()}
                />
            );
        }
        else if (file.type.startsWith('video/')) {
            return (
                <VideoThumbnail
                    file={file}
                    onPreview={() => actions.preview()}
                    onRemove={() => actions.remove()}
                />
            )
        }
        else {
            return (
                <DocumentThumbnail
                    file={file}
                    onDownload={() => actions.download()}
                    onRemove={() => actions.remove()}
                />
            );
        }
    }
}
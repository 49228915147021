import { Badge, Button, Dropdown, Space, Typography } from "antd";
import { CLEANING_GROUP_STATUS_CONFIRMED, CLEANING_GROUP_STATUS_FINISHED, CLEANING_GROUP_STATUS_STARTED } from "cleaning-new/common";

export default function StatusButton(props) {
    const {
        status,
        disabled,
        onStatusChange,
    } = props;

    const statusColors = {
        [CLEANING_GROUP_STATUS_CONFIRMED]: '#d9d9d9',
        [CLEANING_GROUP_STATUS_STARTED]: '#1890ff',
        [CLEANING_GROUP_STATUS_FINISHED]: '#52c41a',
    };

    const statusLabels = {
        [CLEANING_GROUP_STATUS_CONFIRMED]: 'Confirmed',
        [CLEANING_GROUP_STATUS_STARTED]: 'Started',
        [CLEANING_GROUP_STATUS_FINISHED]: 'Finished',
    };

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        label: 'Confirmed',
                        key: CLEANING_GROUP_STATUS_CONFIRMED,
                    },
                    {
                        label: 'Started',
                        key: CLEANING_GROUP_STATUS_STARTED,
                    },
                    {
                        label: 'Finished',
                        key: CLEANING_GROUP_STATUS_FINISHED,
                    },
                ],
                selectedKeys: [status],
                onClick: ({ key }) => onStatusChange(key)
            }}
            disabled={disabled}
            trigger="click"
        >
            <Button>
                <Space>
                    <Badge
                        color={statusColors[status]}
                    />
                    <Typography.Text>
                        {statusLabels[status]}
                    </Typography.Text>
                </Space>
            </Button>
        </Dropdown>
    )
}
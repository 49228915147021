import { gql, useQuery } from "@apollo/client";
import { Alert, List, Skeleton } from "antd";
import ListItem from "./ListItem";

const QUERY = gql`
    query GetDataForStayqlQueriesList($filter: StayqlQueriesFilter = {}) {
        stayqlQueries(filter: $filter) {
            id
            name
            tags
            query
        }
    }
`;

export default function StayqlQueriesList(props) {
    const { tags } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            filter: {
                tags,
            },
        },
    });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed loading StayQL queries"
            />
        );
    }

    const stayqlQueries = [...data.stayqlQueries]
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <List
            dataSource={stayqlQueries}
            renderItem={stayqlQuery => (
                <ListItem
                    stayqlQuery={stayqlQuery}
                />
            )}
            size="small"
            rowKey="id"
        />
    )
}
import { Select } from "antd";
import { fuzzySearch } from "common/common";

export default function TimezoneSelect(props) {
    const {
        value,
        onChange,
        placeholder,
        searchable,
    } = props;

    return (
        <Select
            options={
                Intl.supportedValuesOf('timeZone')
                    .sort()
                    .map(timezone => ({
                        label: timezone,
                        value: timezone,
                    }))
            }
            value={value}
            onChange={value => onChange(value)}
            showSearch={searchable}
            filterOption={(inputValue, item) => fuzzySearch(item.label, inputValue)}
            placeholder={placeholder}
            style={{
                width: '100%',
            }}
        />
    )
}
import { gql, useQuery } from "@apollo/client";
import { Alert, Card } from "antd";
import { compareAsc, isBefore } from "date-fns";
import Item from "./Item";
import "./style.css";
import { CLEANING_GROUP_STATUS_SUBMITTED } from "cleaning-new/common";

const QUERY = gql`
    query GetCleaningGroupStatusChanges($cleaningGroupApartmentId: ID!) {
        cleaningGroupApartment(cleaningGroupApartmentId: $cleaningGroupApartmentId) {
            group {
                id
                date
                apartments {
                    id
                    apartment {
                        id
                        name
                    }
                    statusChanges {
                        id
                        status
                        updatedAt
                        updater {
                            id
                            firstName
                            lastName
                        }
                    }
                }
                statusChanges {
                    id
                    status
                    updatedAt
                    updater {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

export default function CleaningStatusChanges(props) {
    const {
        cleaningGroupApartmentId,
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { cleaningGroupApartmentId } });

    if (loading) {
        return (
            <Card loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load cleaning status changes"
            />
        )
    }

    const apartmentStatusChanges = [...data.cleaningGroupApartment.group.apartments ?? []]
        .map(item =>
            item.statusChanges.map(statusChange => ({
                key: `apartment:${statusChange.id}`,
                cleaningGroupApartmentStatusChangeId: statusChange.id,
                timestamp: statusChange.updatedAt,
                status: statusChange.status,
                updater: statusChange.updater,
                apartmentName: item.apartment.name,
            })
            ))
        .flat();

    const groupStatusChanges = [...data.cleaningGroupApartment.group.statusChanges ?? []]
        .map(statusChange => ({
            key: `group:${statusChange.id}`,
            cleaningGroupStatusChangeId: statusChange.id,
            timestamp: statusChange.updatedAt,
            status: statusChange.status,
            updater: statusChange.updater,
        }));

    const firstSubmit = [...data.cleaningGroupApartment.group.statusChanges ?? []]
        .sort((a, b) => compareAsc(a.updatedAt, b.updatedAt))
        .find(item => item.status === CLEANING_GROUP_STATUS_SUBMITTED);

    const statusChanges = [...apartmentStatusChanges, ...groupStatusChanges]
        .filter(item => !firstSubmit || !isBefore(item.timestamp, firstSubmit.updatedAt))
        .sort((a, b) => compareAsc(a.timestamp, b.timestamp))

    return (
        <Card title="Status changes">
            <div className="cleaning-status-changes-container">
                {statusChanges.map(statusChange => (
                    <Item
                        statusChange={statusChange}
                        cleaningGroupDate={data.cleaningGroupApartment.group.date}
                        key={statusChange.key}
                    />
                ))}
            </div>
        </Card>
    );
}
import { Button, Col, Empty, Row } from "antd";
import { addDays, compareAsc, differenceInDays, format, getDay, isEqual, isSunday, isWithinInterval, max, min, previousSunday } from "date-fns";
import "prices/styles.scss";
import PriceConfigCalendarInplaceEdit from "prices/PriceConfigCalendarInplaceEdit";
import { PlusOutlined } from "@ant-design/icons";

export default function PriceConfigCalendar(props) {
    const {
        priceConfigs,
        calendar,
        selectedDays,
        changedMinPrices,
        changedDeltas,
        changedSlopes,
        onDaysSelected,
        onAction,
    } = props;

    const dateFrom = min(priceConfigs.map(priceConfig => priceConfig.date));
    const dateTo = max(priceConfigs.map(priceConfig => priceConfig.date));
    const numberOfWeeks = Math.ceil(differenceInDays(dateTo, dateFrom) / 7);
    const offset = isSunday(dateFrom)
        ? 0
        : differenceInDays(dateFrom, previousSunday(dateFrom));
    const weeks = Array.from({ length: numberOfWeeks }, (_, i) => i)
        .map(i => addDays(dateFrom, (i * 7) - offset));

    function handleWeekdaySelect(weekday) {
        const daysSelected = priceConfigs
            .map(priceConfig => priceConfig.date)
            .filter(date => getDay(date) === weekday);

        onDaysSelected(daysSelected);
    }

    function handleWeekSelect(week) {
        const daysSelected = priceConfigs
            .map(priceConfig => priceConfig.date)
            .filter(date => isWithinInterval(date, { start: week, end: addDays(week, 6) }));

        onDaysSelected(daysSelected);
    }

    function handleDaySelect(day) {
        onDaysSelected([day]);
    }

    function isWeekSelected(week) {
        const weekDates = Array.from({ length: 7 }, (_, i) => i).map(i => addDays(week, i));
        return weekDates.every(day => selectedDays.some(selectedDay => isEqual(selectedDay, day)));
    }

    function isDaySelected(day) {
        return selectedDays.some(selectedDay => isEqual(selectedDay, day));
    }

    function isMinPriceChanged(priceConfigId) {
        return changedMinPrices.some(changedPriceConfigId => changedPriceConfigId === priceConfigId);
    }

    function isDeltaChanged(priceConfigId) {
        return changedDeltas.some(changedPriceConfigId => changedPriceConfigId === priceConfigId);
    }

    function isSlopeChanged(priceConfigId) {
        return changedSlopes.some(changedPriceConfigId => changedPriceConfigId === priceConfigId);
    }

    function handleMinPriceUpdateInplace(priceConfigId, date, value) {
        onAction({
            action: 'minPriceSetInplace',
            amount: parseInt(value),
            priceConfigId,
            date,
        });
    }

    function handleDeltaUpdateInplace(priceConfigId, date, value) {
        onAction({
            action: 'deltaSetInplace',
            amount: parseInt(value),
            priceConfigId,
            date,
        });
    }

    function handleSlopeUpdateInplace(priceConfigId, date, value) {
        onAction({
            action: 'slopeSetInplace',
            amount: parseInt(value),
            priceConfigId,
            date,
        });
    }

    function handleAddPrices() {
        onAction({
            action: 'addPriceConfigs',
        });
    }

    return (
        <Row gutter={[16, 16]}>
            {priceConfigs.length === 0 && (
                <Col span={24}>
                    <Row justify="center">
                        <Col>
                            <Empty />
                        </Col>
                    </Row>
                </Col>
            )}
            {priceConfigs.length > 0 && (
                <Col span={24}>
                    <Row>
                        <Col
                            offset={1}
                            span={23}
                        >
                            <div className="price-config-weekday-container">
                                <div
                                    className="price-config-weekday"
                                    onClick={() => handleWeekdaySelect(0)}
                                >
                                    SUN
                                </div>
                                <div
                                    className="price-config-weekday"
                                    onClick={() => handleWeekdaySelect(1)}
                                >
                                    MON
                                </div>
                                <div
                                    className="price-config-weekday"
                                    onClick={() => handleWeekdaySelect(2)}
                                >
                                    TUE
                                </div>
                                <div
                                    className="price-config-weekday"
                                    onClick={() => handleWeekdaySelect(3)}
                                >
                                    WED
                                </div>
                                <div
                                    className="price-config-weekday"
                                    onClick={() => handleWeekdaySelect(4)}
                                >
                                    THU
                                </div>
                                <div
                                    className="price-config-weekday"
                                    onClick={() => handleWeekdaySelect(5)}
                                >
                                    FRI
                                </div>
                                <div
                                    className="price-config-weekday"
                                    onClick={() => handleWeekdaySelect(6)}
                                >
                                    SAT
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={1}>
                            {weeks.map(week => (
                                <Col
                                    span={24}
                                    onClick={() => handleWeekSelect(week)}
                                    key={week}
                                >
                                    <div
                                        className={
                                            'price-config-week ' +
                                            (isWeekSelected(week) ? ' price-config-week-selected' : '')
                                        }
                                    >
                                        {format(week, 'MMM')}
                                    </div>
                                </Col>
                            ))}
                        </Col>
                        <Col span={23}>
                            <div className="price-config-day-container">
                                {[...Array(offset).keys()]
                                    .map(i => (
                                        <div
                                            className={'price-config-day'}
                                            key={`offset-${i}`}
                                        />
                                    ))}
                                {priceConfigs
                                    .sort((a, b) => compareAsc(a.date, b.date))
                                    .map(priceConfig => (
                                        <div
                                            className={
                                                'price-config-day ' +
                                                (isDaySelected(priceConfig.date) ? 'price-config-day-selected' : '')
                                            }
                                            onClick={() => handleDaySelect(priceConfig.date)}
                                            key={priceConfig.id}
                                        >
                                            <div className="price-config-day-date">
                                                {format(priceConfig.date, 'dd/MM/yy')}
                                            </div>
                                            <div className="price-config-day-delta">
                                                <div className="price-config-day-param-label">
                                                    D
                                                </div>
                                                <PriceConfigCalendarInplaceEdit
                                                    value={priceConfig.delta}
                                                    onChange={value => handleDeltaUpdateInplace(priceConfig.id, priceConfig.date, value)}
                                                >

                                                    <div
                                                        className={
                                                            'price-config-day-param-name ' +
                                                            (isDeltaChanged(priceConfig.id) ? 'price-config-day-param-changed' : '')
                                                        }
                                                    >
                                                        {priceConfig.delta}
                                                    </div>
                                                </PriceConfigCalendarInplaceEdit>
                                            </div>
                                            <div className="price-config-day-min-price">
                                                <div className="price-config-day-param-label">
                                                    MP
                                                </div>
                                                <PriceConfigCalendarInplaceEdit
                                                    value={priceConfig.minPrice}
                                                    onChange={value => handleMinPriceUpdateInplace(priceConfig.id, priceConfig.date, value)}
                                                >
                                                    <div
                                                        className={
                                                            'price-config-day-param-name ' +
                                                            (isMinPriceChanged(priceConfig.id) ? 'price-config-day-param-changed' : '')
                                                        }
                                                    >
                                                        {priceConfig.minPrice}
                                                    </div>
                                                </PriceConfigCalendarInplaceEdit>
                                            </div>
                                            <div className="price-config-day-slope">
                                                <div className="price-config-day-param-label">
                                                    SL
                                                </div>
                                                <PriceConfigCalendarInplaceEdit
                                                    value={priceConfig.slope}
                                                    onChange={value => handleSlopeUpdateInplace(priceConfig.id, priceConfig.date, value)}
                                                >
                                                    <div
                                                        className={
                                                            'price-config-day-param-name ' +
                                                            (isSlopeChanged(priceConfig.id) ? 'price-config-day-param-changed' : '')
                                                        }
                                                    >
                                                        {priceConfig.slope}
                                                    </div>
                                                </PriceConfigCalendarInplaceEdit>
                                            </div>
                                            <div className="price-config-day-price">
                                                {calendar.find(item => isEqual(item.date, priceConfig.date))?.price || '?'}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </Col>
                    </Row>
                </Col>
            )}
            <Col span={24}>
                <Button
                    onClick={() => handleAddPrices()}
                    icon={<PlusOutlined />}
                    block
                >
                    Add price configs
                </Button>
            </Col>
        </Row>
    );
}
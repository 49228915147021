import { Select } from "antd";
import { JOB_STATUS_ASSIGNED, JOB_STATUS_BLOCKED, JOB_STATUS_DONE, JOB_STATUS_IN_PROGRESS, JOB_STATUS_UNASSIGNED } from "jobs-new/common";

export default function JobStatusSelect(props) {
    const {
        value,
        disabledOptions,
        onChange,
        ...otherProps
    } = props;

    const options = [];
    if (!disabledOptions || !disabledOptions.includes(JOB_STATUS_UNASSIGNED)) {
        options.push({
            label: 'Unassigned',
            value: JOB_STATUS_UNASSIGNED,
        });
    }
    if (!disabledOptions || !disabledOptions.includes(JOB_STATUS_ASSIGNED)) {
        options.push({
            label: 'Assigned',
            value: JOB_STATUS_ASSIGNED,
        });
    }
    if (!disabledOptions || !disabledOptions.includes(JOB_STATUS_IN_PROGRESS)) {
        options.push({
            label: 'In progress',
            value: JOB_STATUS_IN_PROGRESS,
        });
    }
    if (!disabledOptions || !disabledOptions.includes(JOB_STATUS_BLOCKED)) {
        options.push({
            label: 'Blocked',
            value: JOB_STATUS_BLOCKED,
        });
    }
    if (!disabledOptions || !disabledOptions.includes(JOB_STATUS_DONE)) {
        options.push({
            label: 'Done',
            value: JOB_STATUS_DONE,
        });
    }

    return (
        <Select
            options={options}
            value={value}
            onChange={value => onChange(value)}
            {...otherProps}
        />
    );
}
import { isAfter } from "date-fns";

export const CLEANING_GROUP_STATUS_PLANNED = 'planned';
export const CLEANING_GROUP_STATUS_SUBMITTED = 'submitted';
export const CLEANING_GROUP_STATUS_CONFIRMED = 'confirmed';
export const CLEANING_GROUP_STATUS_STARTED = 'started';
export const CLEANING_GROUP_STATUS_FINISHED = 'finished';

export const CLEANING_APARTMENT_STATUS_PLANNED = 'planned';
export const CLEANING_APARTMENT_STATUS_IN_PROGRESS = 'in_progress';
export const CLEANING_APARTMENT_STATUS_FINISHED = 'finished';


export function isApartmentCleaningToFix(cleaningGroupApartment) {
    if (cleaningGroupApartment.apartment.ongoingReservation) {
        return cleaningGroupApartment.apartment.ongoingReservation.numberOfGuests !== cleaningGroupApartment.numberOfGuests;
    }

    let numberOfGuestsToFix = false;
    if (cleaningGroupApartment.apartment.nextReservation && !cleaningGroupApartment.apartment.arrivingReservation) {
        numberOfGuestsToFix = cleaningGroupApartment.numberOfGuests !== cleaningGroupApartment.apartment.nextReservation.numberOfGuests && !(cleaningGroupApartment.numberOfGuestsOverridenAt && isAfter(cleaningGroupApartment.numberOfGuestsOverridenAt, cleaningGroupApartment.apartment.nextReservation.bookedAt));
    }
    if (cleaningGroupApartment.apartment.arrivingReservation) {
        numberOfGuestsToFix = cleaningGroupApartment.numberOfGuests !== cleaningGroupApartment.apartment.arrivingReservation.numberOfGuests && !(cleaningGroupApartment.numberOfGuestsOverridenAt && isAfter(cleaningGroupApartment.numberOfGuestsOverridenAt, cleaningGroupApartment.apartment.arrivingReservation.bookedAt))
    }

    let checkoutTimeToFix = false;
    if (!cleaningGroupApartment.apartment.previousReservation) {
        checkoutTimeToFix = cleaningGroupApartment.checkoutTime && !cleaningGroupApartment.checkoutTimeOverridenAt;
    }
    if (cleaningGroupApartment.apartment.previousReservation && !cleaningGroupApartment.apartment.departingReservation) {
        checkoutTimeToFix = cleaningGroupApartment.checkoutTime && !cleaningGroupApartment.checkoutTimeOverridenAt;
    }
    if (cleaningGroupApartment.apartment.departingReservation) {
        checkoutTimeToFix = cleaningGroupApartment.checkoutTime !== cleaningGroupApartment.apartment.departingReservation.checkoutTime && !(cleaningGroupApartment.checkoutTimeOverridenAt && isAfter(cleaningGroupApartment.checkoutTimeOverridenAt, cleaningGroupApartment.apartment.departingReservation.bookedAt));
    }

    let checkinTimeToFix = false;
    if (!cleaningGroupApartment.apartment.nextReservation) {
        checkinTimeToFix = cleaningGroupApartment.checkinTime && !cleaningGroupApartment.checkinTimeOverridenAt;
    }
    if (cleaningGroupApartment.apartment.nextReservation && !cleaningGroupApartment.apartment.arrivingReservation) {
        checkinTimeToFix = cleaningGroupApartment.checkinTime && !cleaningGroupApartment.checkinTimeOverridenAt;
    }
    if (cleaningGroupApartment.apartment.arrivingReservation) {
        checkinTimeToFix = cleaningGroupApartment.checkinTime !== cleaningGroupApartment.apartment.arrivingReservation.checkinTime && !(cleaningGroupApartment.checkinTimeOverridenAt && isAfter(cleaningGroupApartment.checkinTimeOverridenAt, cleaningGroupApartment.apartment.arrivingReservation.bookedAt))
    }

    const cleaningToFix = checkoutTimeToFix || checkinTimeToFix || numberOfGuestsToFix;

    return cleaningToFix;
}


export function fixCleaningApartment(cleaningGroupApartment) {
    if (cleaningGroupApartment.apartment.ongoingReservation) {
        return {
            numberOfGuests: cleaningGroupApartment.apartment.ongoingReservation.numberOfGuests,
        };
    }

    let numberOfGuestsToFix = false;
    if (cleaningGroupApartment.apartment.nextReservation && !cleaningGroupApartment.apartment.arrivingReservation) {
        numberOfGuestsToFix = cleaningGroupApartment.numberOfGuests !== cleaningGroupApartment.apartment.nextReservation.numberOfGuests && !(cleaningGroupApartment.numberOfGuestsOverridenAt && isAfter(cleaningGroupApartment.numberOfGuestsOverridenAt, cleaningGroupApartment.apartment.nextReservation.bookedAt));
    }
    if (cleaningGroupApartment.apartment.arrivingReservation) {
        numberOfGuestsToFix = cleaningGroupApartment.numberOfGuests !== cleaningGroupApartment.apartment.arrivingReservation.numberOfGuests && !(cleaningGroupApartment.numberOfGuestsOverridenAt && isAfter(cleaningGroupApartment.numberOfGuestsOverridenAt, cleaningGroupApartment.apartment.arrivingReservation.bookedAt))
    }

    let checkoutTimeToFix = false;
    if (!cleaningGroupApartment.apartment.previousReservation) {
        checkoutTimeToFix = cleaningGroupApartment.checkoutTime && !cleaningGroupApartment.checkoutTimeOverridenAt;
    }
    if (cleaningGroupApartment.apartment.previousReservation && !cleaningGroupApartment.apartment.departingReservation) {
        checkoutTimeToFix = cleaningGroupApartment.checkoutTime && !cleaningGroupApartment.checkoutTimeOverridenAt;
    }
    if (cleaningGroupApartment.apartment.departingReservation) {
        checkoutTimeToFix = cleaningGroupApartment.checkoutTime !== cleaningGroupApartment.apartment.departingReservation.checkoutTime && !(cleaningGroupApartment.checkoutTimeOverridenAt && isAfter(cleaningGroupApartment.checkoutTimeOverridenAt, cleaningGroupApartment.apartment.departingReservation.bookedAt));
    }

    let checkinTimeToFix = false;
    if (!cleaningGroupApartment.apartment.nextReservation) {
        checkinTimeToFix = cleaningGroupApartment.checkinTime && !cleaningGroupApartment.checkinTimeOverridenAt;
    }
    if (cleaningGroupApartment.apartment.nextReservation && !cleaningGroupApartment.apartment.arrivingReservation) {
        checkinTimeToFix = cleaningGroupApartment.checkinTime && !cleaningGroupApartment.checkinTimeOverridenAt;
    }
    if (cleaningGroupApartment.apartment.arrivingReservation) {
        checkinTimeToFix = cleaningGroupApartment.checkinTime !== cleaningGroupApartment.apartment.arrivingReservation.checkinTime && !(cleaningGroupApartment.checkinTimeOverridenAt && isAfter(cleaningGroupApartment.checkinTimeOverridenAt, cleaningGroupApartment.apartment.arrivingReservation.bookedAt))
    }

    const numberOfGuests = numberOfGuestsToFix
        ? cleaningGroupApartment.apartment.nextReservation?.numberOfGuests ?? cleaningGroupApartment.apartment.increasePriceAbove ?? 2
        : cleaningGroupApartment.numberOfGuests;

    const checkinTime = checkinTimeToFix
        ? cleaningGroupApartment.apartment.arrivingReservation?.checkinTime ?? null
        : cleaningGroupApartment.checkinTime;

    const checkoutTime = checkoutTimeToFix
        ? cleaningGroupApartment.apartment.departingReservation?.checkoutTime ?? null
        : cleaningGroupApartment.checkoutTime;

    return {
        numberOfGuests,
        checkoutTime,
        checkinTime,
    };
}
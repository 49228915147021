import { gql, useMutation } from "@apollo/client";
import { Button, Col, Row } from "antd";
import Iframe from "apartments/components/apartment-mapping/Iframe";
import { taskPromise } from "common/task";
import { useNavigate } from "react-router-dom";
import { cache } from 'apollo';
import { useState } from "react";

const MUTATION = gql`
    mutation CreateSyncApartmentChannelsDownTask($input: CreateSyncApartmentChannelsDownTaskInput!) {
        createSyncApartmentChannelsDownTask(input: $input) {
            error {
                type
                message
            }
            task {
                id
            }
        }
    }
`;

export default function ApartmentMapping(props) {
    const {
        apartmentId,
    } = props;

    const navigate = useNavigate();

    const [channelsUpdateLoading, setChannelsUpdateLoading] = useState(false);

    const [createSyncApartmentChannelsDownTask] = useMutation(MUTATION);

    function handleFinish() {
        setChannelsUpdateLoading(true);
        createSyncApartmentChannelsDownTask({
            variables: {
                input: {
                    apartmentId,
                },
            },
        })
            .then(response => taskPromise(response.data.createSyncApartmentChannelsDownTask.task.id))
            .then(() => {
                cache.evict({
                    fieldName: 'channels',
                    id: cache.identify({
                        __typename: 'Apartment',
                        id: apartmentId,
                    }),
                });
                navigate(`/apartments/${apartmentId}`)
            })
            .finally(() => setChannelsUpdateLoading(false));
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Iframe
                    apartmentId={apartmentId}
                />
            </Col>
            <Col span={24}>
                <Row justify="end">
                    <Col>
                        <Button
                            type="primary"
                            onClick={() => handleFinish()}
                            loading={channelsUpdateLoading}
                        >
                            Finish
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
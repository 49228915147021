import { SearchOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Alert, Card, Input, PageHeader, Result, Space, Switch, Typography } from "antd";
import { formatISO, startOfToday } from "date-fns";
import { gql } from "graphql.macro";
import { useState } from "react";
import ReservationsTable from "reservations/components/reservations-table/ReservationsTable";

const QUERY = gql`
    query SearchReservations($filter: ReservationsFilter!) {
        reservations(filter: $filter) {
            id
            apartment {
                id
                name
            }
            bookedAt
            canceledAt
            checkinTime
            checkoutTime
            endDate
            guestFirstName
            guestLastName
            guestPhone
            numberOfGuests
            priceAccommodation
            priceCleaning
            priceCommission
            startDate
            status
        }
    }
`;

export default function SearchReservationsView() {
    const [query, setQuery] = useState();
    const [onlyCurrentReservations, setOnlyCurrentReservations] = useState(false);
    const [searchQuery, setSearchQuery] = useState();

    const today = startOfToday();

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            filter: {
                search: searchQuery,
                sortBy: 'search_score',
                sortOrder: 'desc',
                limit: 20,
                ...onlyCurrentReservations && {
                    startDateLte: formatISO(today, { representation: 'date' }),
                    endDateGt: formatISO(today, { representation: 'date' }),
                    status: 'confirmed',
                },
            },
        },
        skip: !searchQuery,
    });

    return (
        <PageHeader
            title="Reservations"
            extra={
                <Space>
                    <Space>
                        <Switch
                            title="Show only current reservations"
                            checked={onlyCurrentReservations}
                            onChange={value => setOnlyCurrentReservations(value)}
                        />
                        <Typography.Text>
                            Only current reservations
                        </Typography.Text>
                    </Space>
                    <Input.Search
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                        onSearch={value => setSearchQuery(value)}
                        loading={loading}
                        placeholder="Search"
                    />
                </Space>
            }
        >
            {loading && (
                <Card loading />
            )}
            {error && (
                <Card>
                    <Alert
                        type="error"
                        showIcon
                        message="Failed to load search results"
                    />
                </Card>
            )}
            {!searchQuery && (
                <Card>
                    <Result
                        icon={<SearchOutlined />}
                        title="Search"
                        subTitle="Plese use the search bar above to find reservations"
                    />
                </Card>
            )}
            {data && (
                <ReservationsTable
                    reservations={data.reservations}
                    displayedColumns={[
                        'apartment',
                        'date',
                        'guestName',
                        'priceAccommodation',
                        'priceCleaning',
                    ]}
                />
            )}
        </PageHeader>
    )
}
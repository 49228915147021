import { Progress } from "antd";
import "components/upload/styles.scss";

export default function UploadingThumbnail(props) {
    const {
        file,
    } = props;

    return (
        <div className="thumbnail-box">
            <Progress
                type="circle"
                percent={file.percent}
                showInfo={false}
                width={48}
            />
        </div>
    );
}
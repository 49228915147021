import { Col, Form, Input, Row } from "antd";
import { useEffect } from "react";

export default function StorageGeneralForm(props) {
    const {
        form,
        storage,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            name: storage?.name,
        });
    }, [form, storage]);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form
                    form={form}
                    {...otherProps}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Name is required' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
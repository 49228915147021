import { ClockCircleOutlined } from "@ant-design/icons";
import { Select } from "antd";

const CHECKIN_TIMES = [
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
    '00:00',
]

export default function ReservationCheckinTimeSelect(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    const options = CHECKIN_TIMES.map(checkinTime => ({
        value: checkinTime,
        label: checkinTime,
    }));

    return (
        <Select
            options={options}
            value={value}
            onChange={value => onChange(value)}
            suffixIcon={<ClockCircleOutlined />}
            {...otherProps}
        />
    )
}
import { Button, PageHeader, Space, Table } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { addDays, formatISO, isEqual, startOfToday, subDays } from "date-fns";

import { GET_LAUNDRY_QUERY } from "./graphql";
import { useQuery } from "@apollo/client";
import { useState } from 'react';

export default function LaundryView() {
    const [currentDate, setCurrentDate] = useState(startOfToday());

    const dates = [
        addDays(currentDate, 0),
        addDays(currentDate, 1),
        addDays(currentDate, 2),
        addDays(currentDate, 3),
    ];

    const { data: laundryData, loading: laundryLoading } = useQuery(GET_LAUNDRY_QUERY, {
        variables: {
            dateFrom: dates[0],
            dateTo: dates[3],
        }
    });

    function processData() {
        if (!laundryData) {
            return [];
        }

        const storages = [...laundryData.reservations]
            .map(reservation => reservation.apartment.storage)
            .filter((storage, index, storages) => storages.findIndex(otherStorage => otherStorage.id === storage.id) === index)
            .sort((a, b) => a.order - b.order);

        const data = [];
        storages.forEach(storage => {
            const rowDirty = {
                storage: `${storage.name} brudne`,
            };
            const rowClean = {
                storage: `${storage.name} czyste`,
            }

            dates.forEach(date => {
                const sumDirty = laundryData.reservations
                    .filter(reservation => reservation.apartment.storage.id === storage.id)
                    .filter(reservation => isEqual(reservation.endDate, date))
                    .reduce((count, reservation) => reservation.numberOfGuests + count, 0);

                const sumClean = laundryData.reservations
                    .filter(reservation => reservation.apartment.storage.id === storage.id)
                    .filter(reservation => isEqual(reservation.endDate, date))
                    .reduce((count, reservation) => (reservation.nextConfirmedReservation?.numberOfGuests ?? 0) + count, 0);

                rowDirty[formatISO(date, { representation: 'date' })] = sumDirty;
                rowClean[formatISO(date, { representation: 'date' })] = sumClean;
            });

            data.push(rowDirty);
            data.push(rowClean);
        });

        return data;
    }

    return (
        <PageHeader
            title="Pralnia"
            extra={
                <Space key="navigation">
                    <Button
                        icon={<LeftOutlined />}
                        onClick={() => setCurrentDate(subDays(currentDate, 1))}
                    />
                    <Button
                        onClick={() => setCurrentDate(startOfToday())}
                    >
                        Dzisiaj
                    </Button>
                    <Button
                        icon={<RightOutlined />}
                        onClick={() => setCurrentDate(addDays(currentDate, 1))}
                    />
                </Space>
            }
        >
            <Table
                dataSource={processData()}
                loading={laundryLoading}
                columns={[
                    {
                        title: 'Kantorek',
                        dataIndex: 'storage',
                    },
                    {
                        title: formatISO(dates[0], { representation: 'date' }),
                        dataIndex: formatISO(dates[0], { representation: 'date' }),
                        align: 'right',
                    },
                    {
                        title: formatISO(dates[1], { representation: 'date' }),
                        dataIndex: formatISO(dates[1], { representation: 'date' }),
                        align: 'right',
                    },
                    {
                        title: formatISO(dates[2], { representation: 'date' }),
                        dataIndex: formatISO(dates[2], { representation: 'date' }),
                        align: 'right',
                    },
                    {
                        title: formatISO(dates[3], { representation: 'date' }),
                        dataIndex: formatISO(dates[3], { representation: 'date' }),
                        align: 'right',
                    },
                ]}
                pagination={false}
                sticky={true}
            />
        </PageHeader>
    )
}
import { CloseCircleTwoTone, PlayCircleOutlined } from "@ant-design/icons";

export default function VideoThumbnail(props) {
    const {
        file,
        onPreview,
        onRemove,
    } = props;

    return (
        <div className="thumbnail-box">
            <video
                src={file.thumbUrl || file.url}
                style={{
                    width: '100%',
                    height: '100%',
                    userSelect: 'none',
                }}
                onClick={() => onPreview()}
            />
            <PlayCircleOutlined
                style={{
                    position: 'absolute',
                    top: 'calc(50% - 20px)',
                    left: 'calc(50% - 20px)',
                    color: 'white',
                    fontSize: '40px',
                    opacity: 0.8,
                    pointerEvents: 'none',
                }}
            />
            <CloseCircleTwoTone
                style={{
                    position: 'absolute',
                    top: '4px',
                    right: '4px',
                    fontSize: '24px',
                }}
                onClick={() => onRemove()}
            />
        </div>
    );
}
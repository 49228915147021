import { Dropdown } from "antd";

export default function ContextMenu(props) {
    const {
        onClick,
        children,
    } = props;

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        label: 'Delete',
                        key: 'delete',
                        danger: true,
                    },
                ],
                onClick: ({ key }) => onClick(key),
            }}
            trigger={["contextMenu"]}
        >
            {children}
        </Dropdown>
    )
}
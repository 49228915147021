import { Col, Row } from "antd";
import PresetPicker from "./PresetPicker";
import OffsetPicker from "./OffsetPicker";

export default function RelativeDatePicker(props) {
    const {
        value,
        onChange,
        disabled,
    } = props;

    return (
        <Row
            gutter={[8, 8]}
            align="middle"
        >
            <Col>
                <PresetPicker
                    value={value?.preset}
                    onChange={preset => onChange({ preset, offset: value?.offset })}
                    disabled={disabled}
                />
            </Col>
            <Col>
                +
            </Col>
            <Col>
                <OffsetPicker
                    value={value?.offset}
                    onChange={offset => onChange({ preset: value?.preset, offset })}
                    disabled={disabled}
                />
            </Col>
        </Row>
    )
}
import { PlusOutlined } from "@ant-design/icons";
import { Button, PageHeader, Space } from "antd";
import ApartmentActions from "apartments/components/apartment-actions/ApartmentActions";
import ApartmentDisplay from "apartments/components/apartment-display/ApartmentDisplay";
import ApartmentsTable from "apartments/components/apartments-table/ApartmentsTable";
import { getStoredDisplayedColumns, setStoredDisplayedColumns } from "apartments/components/apartments-table/common";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ListApartmentsView() {
    const [selectedApartments, setSelectedApartments] = useState([]);
    const [displayedColumns, setDisplayedColumns] = useState(getStoredDisplayedColumns());
    const [showInactiveApartments, setShowInactiveApartments] = useState(false);

    useEffect(() => setStoredDisplayedColumns(displayedColumns), [displayedColumns]);

    return (
        <PageHeader
            title="Apartments"
            extra={
                <Space key="space">
                    <ApartmentActions
                        selectedApartments={selectedApartments}
                    />
                    <ApartmentDisplay
                        showInactiveApartments={showInactiveApartments}
                        onShowInactiveApartmentsChange={value => setShowInactiveApartments(value)}
                        displayedColumns={displayedColumns}
                        onDisblayedColumnsChange={value => setDisplayedColumns(value)}
                    />
                    <Link to="/apartments/create">
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                        >
                            Create apartment
                        </Button>
                    </Link>
                </Space>
            }
        >
            <ApartmentsTable
                onlyActive={!showInactiveApartments}
                displayedColumns={displayedColumns}
                onSelected={value => setSelectedApartments(value)}
            />
        </PageHeader>
    )
}
import { green, grey, red } from "@ant-design/colors";
import { CheckCircleFilled, CloseCircleOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Col, Row, Skeleton, Space, Tooltip, Typography } from "antd";
import { formatCurrency } from "common/common";
import { taskPromise } from "common/task";
import { format, formatDistanceToNow, formatISO } from "date-fns";
import { useState } from "react";

const QUERY = gql`
    query GetReservationForInformaiton($reservationId: ID!) {
        reservation(reservationId: $reservationId) {
            id
            apartment {
                id
                name
            }
            startDate
            endDate
            status
            bookedAt
            canceledAt
            guestFirstName
            guestLastName
            guestPhone
            priceAccommodation
            priceCleaning
            priceCommission
            airbnbConfirmationCode
            numberOfGuests
            checkinTime
            checkoutTime
            isInternal
        }
    }
`;

const MUTATION = gql`
    mutation CancelReservation($input: CancelReservationInput!) {
        cancelReservation(input: $input) {
            error {
                type
                message
            }
            reservation {
                id
                status
                canceledAt
            }
            syncApartmentCalendarUpTask {
                id
            }
        }
    }
`

export default function ReservationInformation(props) {
    const {
        reservationId,
    } = props;

    const [cancelLoading, setCancelLoading] = useState(false);

    const { data, loading, error } = useQuery(QUERY, { variables: { reservationId } });
    const [cancelReservation] = useMutation(MUTATION);

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load reservation"
            />
        );
    }

    function totalPrice(reservation) {
        const priceAccommodation = parseInt(reservation.priceAccommodation ?? 0);
        const priceCleaning = parseInt(reservation.priceCleaning ?? 0);
        const priceCommission = parseInt(reservation.priceCommission ?? 0);
        return priceAccommodation + priceCleaning - priceCommission;
    }

    function bookedAtString(reservation) {
        const dateStr = format(reservation.bookedAt, 'yyyy-MM-dd HH:mm');
        const distanceStr = formatDistanceToNow(reservation.bookedAt, { addSuffix: true, includeSeconds: false });

        return `Booked at ${dateStr} (${distanceStr})`;
    }

    function canceledAtString(reservation) {
        const dateStr = format(reservation.canceledAt, 'yyyy-MM-dd HH:mm');
        const distanceStr = formatDistanceToNow(reservation.canceledAt, { addSuffix: true, includeSeconds: false });

        return `Booked at ${dateStr} (${distanceStr})`;
    }

    function handleCancelReservation() {
        setCancelLoading(true)
        cancelReservation({
            variables: {
                input: {
                    reservationId,
                },
            },
        })
            .then(response => taskPromise(response.data.cancelReservation.syncApartmentCalendarUpTask?.id))
            .finally(() => setCancelLoading(false));
    }

    function PriceTooltip(props) {
        const { reservation } = props;
        return (
            <Row>
                <Col span={24}>
                    <Row justify="space-between">
                        <Col>
                            Accommodation
                        </Col>
                        <Col>
                            {formatCurrency(reservation.priceAccommodation)}
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row justify="space-between">
                        <Col>
                            Cleaning
                        </Col>
                        <Col>
                            {formatCurrency(reservation.priceCleaning)}
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row justify="space-between">
                        <Col>
                            Commission
                        </Col>
                        <Col>
                            {formatCurrency(reservation.priceCommission)}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={8}>
                <Row justify="end">
                    <Col>
                        Apartment
                    </Col>
                </Row>
            </Col>
            <Col span={16}>
                {data.reservation.apartment.name}
            </Col>
            <Col span={8}>
                <Row justify="end">
                    <Col>
                        Date range
                    </Col>
                </Row>
            </Col>
            <Col span={16}>
                {formatISO(data.reservation.startDate, { representation: 'date' })} &mdash; {formatISO(data.reservation.endDate, { representation: 'date' })}
            </Col>
            <Col span={8}>
                <Row justify="end">
                    <Col>
                        Status
                    </Col>
                </Row>
            </Col>
            <Col span={16}>
                <Space>
                    {data.reservation.status === 'confirmed' && (
                        <Tooltip title={bookedAtString(data.reservation)}>
                            <Space>
                                <CheckCircleFilled
                                    style={{
                                        color: green.primary,
                                    }}
                                />
                                <Typography.Text>
                                    Confirmed
                                </Typography.Text>
                            </Space>
                        </Tooltip>
                    )}
                    {data.reservation.status === 'canceled' && (
                        <Tooltip title={canceledAtString(data.reservation)}>
                            <Space>
                                <CloseCircleOutlined
                                    style={{
                                        color: red.primary,
                                    }}
                                />
                                <Typography.Text>
                                    Canceled
                                </Typography.Text>
                            </Space>
                        </Tooltip>
                    )}
                </Space>
            </Col>
            {data.reservation.airbnbConfirmationCode && (
                <>
                    <Col span={8}>
                        <Row justify="end">
                            <Col>
                                Confirmation code
                            </Col>
                        </Row>
                    </Col>
                    <Col span={16}>
                        {data.reservation.airbnbConfirmationCode}
                    </Col>
                </>
            )}
            <Col span={8}>
                <Row justify="end">
                    <Col>
                        Number of guests
                    </Col>
                </Row>
            </Col>
            <Col span={16}>
                {data.reservation.numberOfGuests}
            </Col>
            <Col span={8}>
                <Row justify="end">
                    <Col>
                        Guest name
                    </Col>
                </Row>
            </Col>
            <Col span={16}>
                {data.reservation.guestFirstName} {data.reservation.guestLastName}
            </Col>
            <Col span={8}>
                <Row justify="end">
                    <Col>
                        Guest phone
                    </Col>
                </Row>
            </Col>
            <Col span={16}>
                <a href={`tel:${data.reservation.guestPhone}`}>
                    {data.reservation.guestPhone}
                </a>
            </Col>
            <Col span={8}>
                <Row justify="end">
                    <Col>
                        Total price
                    </Col>
                </Row>
            </Col>
            <Col span={16}>
                <Space>
                    <Typography.Text>
                        {formatCurrency(totalPrice(data.reservation))}
                    </Typography.Text>
                    <Tooltip
                        title={
                            <PriceTooltip
                                reservation={data.reservation}
                            />
                        }
                    >
                        <QuestionCircleOutlined />
                    </Tooltip>
                </Space>
            </Col>
            <Col span={8}>
                <Row justify="end">
                    <Col>
                        Check-in time
                    </Col>
                </Row>
            </Col>
            <Col span={16}>
                {data.reservation.checkinTime && (
                    <Typography.Text>
                        {data.reservation.checkinTime}
                    </Typography.Text>
                )}
                {!data.reservation.checkinTime && (
                    <Typography.Text
                        style={{
                            color: grey[0],
                        }}
                    >
                        Unknown
                    </Typography.Text>
                )}

            </Col>
            <Col span={8}>
                <Row justify="end">
                    <Col>
                        Check-out time
                    </Col>
                </Row>
            </Col>
            <Col span={16}>
                {data.reservation.checkoutTime && (
                    <Typography.Text>
                        {data.reservation.checkoutTime}
                    </Typography.Text>
                )}
                {!data.reservation.checkoutTime && (
                    <Typography.Text
                        style={{
                            color: grey[0],
                        }}
                    >
                        Unknown
                    </Typography.Text>
                )}

            </Col>
            {data.reservation.isInternal && (
                <Col span={24}>
                    <Row justify="end">
                        <Col>
                            <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => handleCancelReservation()}
                                loading={cancelLoading}
                            >
                                Cancel reservation
                            </Button>
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    )
}
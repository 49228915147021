import { format, getDay, isToday } from "date-fns";
import classNames from "classnames";
import "./style.css";

export default function DateCell(props) {
    const { date } = props;

    const weekday = getDay(date);
    const isWeekend = weekday === 5 || weekday === 6;

    return (
        <div
            className={classNames({
                "calendar-date-cell-container": true,
                "calendar-date-cell-weekend": isWeekend,
                "calendar-date-cell-today": isToday(date),
            })}
        >
            <span>
                {format(date, 'd MMM')}
            </span>
        </div>
    );
}
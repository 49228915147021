import { Form, InputNumber } from "antd";
import DatePicker from "components/DatePicker";
import { useEffect } from "react";

export default function ModalForm(props) {
    const {
        form,
        apartmentCalendarRestriction,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            date: apartmentCalendarRestriction?.date,
            minStay: apartmentCalendarRestriction?.minStay,
        });
    }, [form, apartmentCalendarRestriction]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="date"
                label="Date"
                rules={[{ required: true, message: 'Date is required' }]}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                name="minStay"
                label="Min stay"
                rules={[{ required: true, message: 'Min stay is required' }]}
            >
                <InputNumber
                    min={1}
                    max={10}
                    addonAfter="days"
                />
            </Form.Item>
        </Form>
    );
}
import { Popover } from "antd";
import { format, formatDistanceToNow } from "date-fns";
import "./styles.css"

export default function JobAuthor(props) {
    const {
        job,
        dateFormat,
    } = props;

    function formatDate(date) {
        if (dateFormat === 'relative') {
            return formatDistanceToNow(date, { addSuffix: true });
        }
        if (dateFormat === 'hourOnly') {
            return format(date, 'HH:mm');
        }

        return format(date, 'yyyy-MM-dd HH:mm');
    }

    return (
        <Popover
            content={(
                <div className="job-author-container">
                    <div className="job-author-container-row">
                        <div className="job-author-container-label">
                            Name
                        </div>
                        <div className="job-author-container-value">
                            {job.author.firstName} {job.author.lastName}
                        </div>
                    </div>
                    <div className="job-author-container-row">
                        <div className="job-author-container-label">
                            Phone
                        </div>
                        <div className="job-author-container-value">
                            <a href={`tel:${job.author.phone}`}>
                                {job.author.phone}
                            </a>
                        </div>
                    </div>
                    <div className="job-author-container-row">
                        <div className="job-author-container-label">
                            Created at
                        </div>
                        <div className="job-author-container-value">
                            {format(job.createdAt, 'yyyy-MM-dd HH:mm')}
                        </div>
                    </div>
                </div>
            )}
            trigger="click"
        >
            <span className="job-author-link">
                {job.author.firstName} {job.author.lastName} &#183; {formatDate(job.createdAt)}
            </span>
        </Popover>
    );
}
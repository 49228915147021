import { PlusOutlined } from "@ant-design/icons";
import { Button, PageHeader, Space } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserDisplay from "users/components/user-display/UserDisplay";
import UsersTable from "users/components/users-table/UsersTable";
import { getStoredDisplayedColumns, setStoredDisplayedColumns } from "users/components/users-table/common";

export default function UsersListView() {
    const [displayedColumns, setDisplayedColumns] = useState(getStoredDisplayedColumns());
    const [showInactiveUsers, setShowInactiveUsers] = useState(false);

    useEffect(() => setStoredDisplayedColumns(displayedColumns), [displayedColumns]);

    return (
        <PageHeader
            title="Users"
            extra={
                <Space key="space">
                    <UserDisplay
                        showInactiveUsers={showInactiveUsers}
                        onShowInactiveUsersChange={value => setShowInactiveUsers(value)}
                        displayedColumns={displayedColumns}
                        onDisblayedColumnsChange={value => setDisplayedColumns(value)}
                    />
                    <Link to="/users/create">
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                        >
                            Create user
                        </Button>
                    </Link>
                </Space>
            }
        >
            <UsersTable
                onlyActive={!showInactiveUsers}
                displayedColumns={displayedColumns}
            />
        </PageHeader>
    )
}
import { gql, useMutation } from "@apollo/client";
import { Button, Col, Input, message, Popover, Row } from "antd";
import { CLEANING_APARTMENT_STATUS_FINISHED, CLEANING_APARTMENT_STATUS_IN_PROGRESS, CLEANING_APARTMENT_STATUS_PLANNED, CLEANING_GROUP_STATUS_CONFIRMED, CLEANING_GROUP_STATUS_FINISHED, CLEANING_GROUP_STATUS_PLANNED, CLEANING_GROUP_STATUS_STARTED, CLEANING_GROUP_STATUS_SUBMITTED } from "cleaning-new/common";
import { format, isSameDay, isValid, parse } from "date-fns";
import { SaveOutlined } from "@ant-design/icons";
import { useState } from "react";
import "./style.css";
import { useAuth } from "auth";

const CLEANING_GROUP_MUTATION = gql`
    mutation UpdateCleaningGroupStatusTimestamp($input: UpdateCleaningGroupStatusTimestampInput!) {
        updateCleaningGroupStatusTimestamp(input: $input) {
            cleaningGroupStatusChange {
                id
                updatedAt
            }
            error {
                type
                message
            }
        }
    }
`;

const CLEANING_GROUP_APARTMENT_MUTATION = gql`
    mutation UpdateCleaningGroupApartmentStatusTimestamp($input: UpdateCleaningGroupApartmentStatusTimestampInput!) {
        updateCleaningGroupApartmentStatusTimestamp(input: $input) {
            cleaningGroupApartmentStatusChange {
                id
                updatedAt
            }
            error {
                type
                message
            }
        }
    }
`;


export default function Item(props) {
    const {
        statusChange,
        cleaningGroupDate,
    } = props;

    const { permissions } = useAuth();
    const canEdit = permissions.includes('cleaning:set:other');

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [timestamp, setTimestamp] = useState(format(statusChange.timestamp, 'HH:mm'));
    const [timestampStatus, setTimestampStatus] = useState();

    const [updateCleaningGroupStatusTimestamp, { loading: cleaningGroupLoading }] = useMutation(CLEANING_GROUP_MUTATION);
    const [updateCleaningGroupApartmentStatusTimestamp, { loading: cleaningGroupApartmentLoading }] = useMutation(CLEANING_GROUP_APARTMENT_MUTATION);

    function getStatusChangeDescription(statusChange) {
        if (statusChange.apartmentName) {
            if (statusChange.status === CLEANING_APARTMENT_STATUS_PLANNED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} set ${statusChange.apartmentName} to planned`;
            }
            if (statusChange.status === CLEANING_APARTMENT_STATUS_IN_PROGRESS) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} started ${statusChange.apartmentName}`;
            }
            if (statusChange.status === CLEANING_APARTMENT_STATUS_FINISHED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} finished ${statusChange.apartmentName}`;
            }
        }
        else {
            if (statusChange.status === CLEANING_GROUP_STATUS_SUBMITTED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} submitted plan`;
            }
            if (statusChange.status === CLEANING_GROUP_STATUS_CONFIRMED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} confirmed plan`;
            }
            if (statusChange.status === CLEANING_GROUP_STATUS_STARTED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} started work`;
            }
            if (statusChange.status === CLEANING_GROUP_STATUS_FINISHED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} finished work`;
            }
            if (statusChange.status === CLEANING_GROUP_STATUS_PLANNED) {
                return `${statusChange.updater.firstName} ${statusChange.updater.lastName} withdrawned plan`;
            }
        }
    }

    function getStatusChangeTime(statusChange) {
        if (isSameDay(statusChange.timestamp, cleaningGroupDate)) {
            return format(statusChange.timestamp, 'HH:mm');
        }
        else {
            return format(statusChange.timestamp, 'yyyy-MM-dd HH:mm');
        }
    }

    function statusChangeEditable(statusChange) {
        return canEdit && [CLEANING_GROUP_STATUS_STARTED, CLEANING_GROUP_STATUS_FINISHED, CLEANING_APARTMENT_STATUS_IN_PROGRESS, CLEANING_APARTMENT_STATUS_FINISHED].includes(statusChange.status);
    }

    const description = getStatusChangeDescription(statusChange);
    const time = getStatusChangeTime(statusChange);
    const isEditable = statusChangeEditable(statusChange);

    function handleUpdateTimestamp() {
        const newTimestamp = parse(timestamp, 'HH:mm', statusChange.timestamp);

        if (!isValid(newTimestamp)) {
            setTimestampStatus("error");
            return;
        }

        if (statusChange.cleaningGroupStatusChangeId) {
            return updateCleaningGroupStatusTimestamp({
                variables: {
                    input: {
                        cleaningGroupStatusChangeId: statusChange.cleaningGroupStatusChangeId,
                        timestamp: newTimestamp,
                    },
                },
            })
                .then(response => {
                    if (response.data.updateCleaningGroupStatusTimestamp.error) {
                        message.error("Failed to update time");
                    }
                    else {
                        message.success("Time updated");
                        setPopoverOpen(false);
                    }
                })
                .catch(() => {
                    message.error("Network error");
                });
        }
        if (statusChange.cleaningGroupApartmentStatusChangeId) {
            return updateCleaningGroupApartmentStatusTimestamp({
                variables: {
                    input: {
                        cleaningGroupApartmentStatusChangeId: statusChange.cleaningGroupApartmentStatusChangeId,
                        timestamp: newTimestamp,
                    },
                },
            })
                .then(response => {
                    if (response.data.updateCleaningGroupApartmentStatusTimestamp.error) {
                        message.error("Failed to update time");
                    }
                    else {
                        message.success("Time updated");
                        setPopoverOpen(false);
                    }
                })
                .catch(e => {
                    message.error("Network error");
                });
        }
    }

    function handlePopoverOpenChange(value) {
        if (!value) {
            setTimestamp(format(statusChange.timestamp, 'HH:mm'));
            setTimestampStatus();
        }
        setPopoverOpen(value);
    }

    return (
        <div
            className="cleaning-status-changes-item"
            key={statusChange.key}
        >
            <div className="cleaning-status-changes-item-description">
                {description}
            </div>
            {!isEditable && (
                <div className="cleaning-status-changes-item-timestamp">
                    {time}
                </div>
            )}
            {isEditable && (
                <Popover
                    content={
                        <Row gutter={[8, 8]}>
                            <Col>
                                <Input
                                    value={timestamp}
                                    onChange={e => setTimestamp(e.target.value)}
                                    status={timestampStatus}
                                />
                            </Col>
                            <Col>
                                <Button
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    onClick={() => handleUpdateTimestamp()}
                                    loading={cleaningGroupLoading || cleaningGroupApartmentLoading}
                                />
                            </Col>
                        </Row>
                    }
                    placement="left"
                    trigger="click"
                    open={popoverOpen}
                    onOpenChange={value => handlePopoverOpenChange(value)}
                    destroyTooltipOnHide
                >
                    <div className="cleaning-status-changes-item-timestamp cleaning-status-changes-item-timestamp-editable">
                        {time}
                    </div>
                </Popover>
            )}
        </div>
    );
}
import { CLEANING_APARTMENT_STATUS_FINISHED, CLEANING_APARTMENT_STATUS_IN_PROGRESS, CLEANING_APARTMENT_STATUS_PLANNED } from "cleaning-new/common";
import { format, formatDistance, isAfter, subHours } from "date-fns";
import { useEffect, useState } from "react";

export default function StatusInfo(props) {
    const {
        cleaningGroupApartment,
    } = props;

    const [currentTimestamp, setCurrentTimestamp] = useState(new Date());
    useEffect(() => {
        const intervalId = window.setInterval(() => setCurrentTimestamp(new Date()));
        return () => window.clearInterval(intervalId);
    }, []);

    if (cleaningGroupApartment.status === CLEANING_APARTMENT_STATUS_PLANNED) {
        return null;
    }

    if (cleaningGroupApartment.status === CLEANING_APARTMENT_STATUS_IN_PROGRESS) {
        const startedAt = cleaningGroupApartment.lastStatusChange.updatedAt;
        const timestampStr = isAfter(startedAt, subHours(currentTimestamp, 1))
            ? formatDistance(startedAt, currentTimestamp, { addSuffix: true })
            : format(startedAt, 'yyyy-MM-dd HH:mm');

        return (
            <div className="cleaning-information-apartment-status-info">
                Started {timestampStr}
            </div>
        );
    }

    if (cleaningGroupApartment.status === CLEANING_APARTMENT_STATUS_FINISHED) {
        const finishedAt = cleaningGroupApartment.lastStatusChange.updatedAt;
        const timestampStr = isAfter(finishedAt, subHours(currentTimestamp, 1))
            ? formatDistance(finishedAt, currentTimestamp, { addSuffix: true })
            : format(finishedAt, 'yyyy-MM-dd HH:mm');

        return (
            <div className="cleaning-information-apartment-status-info">
                Finished {timestampStr}
            </div>
        );
    }
}
import { useCallback, useEffect, useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Button, Dropdown, Form, Modal } from "antd";
import { useAuth } from "auth";
import { calculateDate, calculateOffset } from "components/relative-date-picker/common";
import JobsFilterForm from "jobs-new/forms/JobsFilterForm";

const QUERY = gql`
    query GetJobsFilterPresets {
        organization(organizationId: "self") {
            id
            jobsFilterPresets {
                id
                name
                preset
            }
        }
        user(userId: "self") {
            id
            jobsFilterPresets {
                id
                name
                preset
            }
            groups {
                id
                jobsFilterPresets {
                    id
                    name
                    preset
                }
            }
        }
    }
`;

export default function Filter(props) {
    const {
        value,
        initialValue,
        onChange,
    } = props;

    const { data, loading, error } = useQuery(QUERY);

    const { user } = useAuth();

    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();

    const calculateFilter = useCallback(preset => {
        const filter = {};
        if (preset.dateFrom && preset.dateTo) {
            filter.dateFrom = calculateOffset(calculateDate(preset.dateFrom.preset), preset.dateFrom.offset);
            filter.dateTo = calculateOffset(calculateDate(preset.dateTo.preset), preset.dateTo.offset);
        }
        if (preset.statuses) {
            filter.statuses = preset.statuses;
        }
        if (preset.tags) {
            filter.tags = preset.tags;
        }
        if (preset.authors) {
            filter.authorIds = preset.authors
                .filter(author => author.userId)
                .map(author => author.userId);
            filter.authorGroupIds = preset.authors
                .filter(author => author.userGroupId)
                .map(author => author.userGroupId);
        }
        if (preset.createdByMe) {
            filter.authorIds = [...filter.authorIds ?? [], user.id];
        }
        if (preset.watchers) {
            filter.watcherIds = preset.watchers
                .filter(watcher => watcher.userId)
                .map(watcher => watcher.userId);
            filter.watcherGroupIds = preset.watchers
                .filter(watcher => watcher.userGroupId)
                .map(watcher => watcher.userGroupId);
        }
        if (preset.watchedByMe) {
            filter.watcherIds = [...filter.watcherIds ?? [], user.id];
        }
        if (preset.watchedByMyGroups) {
            filter.watcherGroupIds = [...filter.watcherGroupIds ?? [], ...user.groups.map(group => group.id)];
        }
        if (preset.assignerIds) {
            filter.assignerIds = preset.assignerIds;
        }
        if (preset.assignedToMe) {
            filter.assignerIds = [...filter.assignerIds ?? [], user.id];
        }
        if (preset.locations) {
            filter.apartmentIds = preset.locations
                .filter(location => location.apartmentId)
                .map(location => location.apartmentId);
            filter.storageIds = preset.locations
                .filter(location => location.storageId)
                .map(location => location.storageId);
        }

        return filter;
    }, [user]);

    const organizationFilterPresets = useMemo(() =>
        [...data?.organization?.jobsFilterPresets ?? []]
            .map(filterPreset => ({
                label: filterPreset.name,
                key: `organization:${filterPreset.id}`,
                filter: calculateFilter(filterPreset.preset),
            })),
        [data, calculateFilter]);

    const userFilterPresets = useMemo(() =>
        [...data?.user?.jobsFilterPresets ?? []]
            .map(filterPreset => ({
                label: filterPreset.name,
                key: `user:${filterPreset.id}`,
                filter: calculateFilter(filterPreset.preset),
            })),
        [data, calculateFilter]);

    const userGroupsFilterPresets = useMemo(() =>
        [...data?.user?.groups ?? []]
            .map(group => group.jobsFilterPresets)
            .flat()
            .map(filterPreset => ({
                label: filterPreset.name,
                key: `userGroup:${filterPreset.id}`,
                filter: calculateFilter(filterPreset.preset),
            })),
        [data, calculateFilter]);

    const filterPresets = useMemo(() =>
        [
            ...[
                ...organizationFilterPresets,
                ...userFilterPresets,
                ...userGroupsFilterPresets,
            ]
                .sort((a, b) => a.label.localeCompare(b.label)),
            {
                label: 'Other',
                key: 'other',
            },
        ],
        [organizationFilterPresets, userFilterPresets, userGroupsFilterPresets]);

    useEffect(() => {
        if (!value && initialValue && initialValue.key) {
            if (initialValue.key === 'other') {
                onChange(initialValue);
            }
            else {
                onChange(filterPresets.find(filterPreset => filterPreset.key === initialValue.key));
            }
        }
    }, [value, initialValue, filterPresets, onChange]);

    if (loading) {
        return (
            <Button loading>
                Filter
            </Button>
        );
    }

    if (error) {
        return (
            <Button danger>
                Filter
            </Button>
        );
    }

    function handleSelect(key) {
        if (key === 'other') {
            setModalOpen(true);
        }
        else {
            onChange(filterPresets.find(filterPreset => filterPreset.key === key));
        }
    }

    function handleSubmit() {
        form
            .validateFields()
            .then(values => {
                onChange({
                    label: 'Other',
                    key: 'other',
                    filter: {
                        dateFrom: values.dateRange?.[0],
                        dateTo: values.dateRange?.[1],
                        statuses: values.statuses,
                        tags: values.tags,
                        authorIds: [...values.authors ?? []].filter(author => author.userId).map(author => author.userId),
                        authorGroupIds: [...values.authors ?? []].filter(author => author.userGroupId).map(author => author.userGroupId),
                        watcherIds: [...values.watchers ?? []].filter(watcher => watcher.userId).map(watcher => watcher.userId),
                        watcherGroupIds: [...values.watchers ?? []].filter(watcher => watcher.userGroupId).map(watcher => watcher.userGroupId),
                        assignerIds: values.assignerIds,
                        apartmentIds: [...values.locations ?? []].filter(location => location.apartmentId).map(location => location.apartmentId),
                        storageIds: [...values.locations ?? []].filter(location => location.storageId).map(location => location.storageId),
                    },
                });
                setModalOpen(false);
            });
    }

    function buttonLabel() {
        if (!value || value.key === 'other') {
            return 'Filter';
        }

        return filterPresets.find(filterPreset => filterPreset.key === value.key).label;
    }

    return (
        <div>
            <Dropdown
                menu={{
                    items: filterPresets,
                    selectedKeys: [value?.key],
                    onClick: ({ key }) => handleSelect(key),
                }}
            >
                <Button>
                    {buttonLabel()}
                </Button>
            </Dropdown>
            <Modal
                open={modalOpen}
                onOk={() => handleSubmit()}
                onCancel={() => setModalOpen(false)}
                destroyOnClose
                width="800px"
            >
                <JobsFilterForm
                    form={form}
                    filter={value?.filter}
                    preserve={false}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                />
            </Modal>
        </div>
    );
}
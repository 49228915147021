import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, PageHeader, Row } from "antd";
import { formatISO, parseISO } from "date-fns";
import ApartmentExpensesFilter from "expenses/components/apartment-expenses-filter/ApartmentExpensesFilter";
import ApartmentExpensesTable from "expenses/components/apartment-expenses-table/ApartmentExpensesTable";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const EXPENSES_STORED_FILTERS_KEY = 'expensesFilter';

export default function ListApartmentExpensesView() {
    const [apartmentIds, setApartmentIds] = useState([]);
    const [apartmentExpenseCategoryIds, setApartmentExpenseCategoryIds] = useState([]);
    const [dateRange, setDateRange] = useState([]);

    useEffect(() => {
        const storedFilter = window.localStorage.getItem(EXPENSES_STORED_FILTERS_KEY);
        if (storedFilter) {
            const filter = JSON.parse(storedFilter);
            setApartmentIds(filter?.apartmentIds ?? []);
            setApartmentExpenseCategoryIds(filter?.apartmentExpenseCategoryIds ?? []);
            setDateRange([
                filter?.dateFrom ? parseISO(filter.dateFrom) : null,
                filter?.dateTo ? parseISO(filter.dateTo) : null,
            ]);
        }
    }, []);

    useEffect(() => {
        const filter = {
            apartmentIds,
            apartmentExpenseCategoryIds,
            dateFrom: (dateRange?.length > 0 && dateRange[0]) ? formatISO(dateRange[0], { representation: 'date' }) : null,
            dateTo: (dateRange?.length > 1 && dateRange[1]) ? formatISO(dateRange[1], { representation: 'date' }) : null,
        };
        window.localStorage.setItem(EXPENSES_STORED_FILTERS_KEY, JSON.stringify(filter));
    }, [apartmentIds, apartmentExpenseCategoryIds, dateRange]);

    return (
        <PageHeader
            title="Expenses"
            extra={
                <Link to="/expenses/create">
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                    >
                        Create expense
                    </Button>
                </Link>
            }
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ApartmentExpensesFilter
                        dateRange={dateRange}
                        onDateRangeChange={value => setDateRange(value)}
                        apartmentIds={apartmentIds}
                        onApartmentIdsChange={value => setApartmentIds(value)}
                        apartmentExpenseCategoryIds={apartmentExpenseCategoryIds}
                        onApartmentExpenseCategoryIdsChange={value => setApartmentExpenseCategoryIds(value)}
                    />
                </Col>
                <Col span={24}>
                    <ApartmentExpensesTable
                        apartmentExpenseCategoryIds={apartmentExpenseCategoryIds}
                        apartmentIds={apartmentIds}
                        dateRange={dateRange}
                    />
                </Col>
            </Row>
        </PageHeader>
    );
}
import Editor from "react-simple-code-editor";
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css';
import "./style.css";

export default function CodeEditor(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Editor
            value={value}
            onValueChange={value => onChange(value)}
            highlight={code => code && highlight(code, languages.sql)}
            tabSize={4}
            padding={8}
            className="stayql-code-editor"
        />
    );
}
import { Alert, Card, Space, Table, Tag } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useAuth } from 'auth';
import Tags from './columns/Tags';
import Channels from './columns/Channels';
import BaseGuestCount from './columns/BaseGuestCount';
import ExtraGuestFee from './columns/ExtraGuestFee';
import { compareAsc, parse, startOfToday } from 'date-fns';

const QUERY = gql`
    query GetApartmentsForApartmentsTable {
        apartments {
            id
            active
            name
            tags
            storage {
                id
                name
            }
            cleaningPrice
            maxGuestCount
            increasePriceAbove
            increasePriceBy
            calendarLength
            cutOffTime
            defaultPriceConfigMinPrice
            defaultPriceConfigDelta
            defaultPriceConfigSlope
            channels {
                id
                name
            }
        }
    }
`;

export default function ApartmentsTable(props) {
    const {
        apartmentIds,
        onlyActive,
        displayedColumns,
        selectedApartments,
        onSelected,
    } = props;

    const { user } = useAuth();
    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Card loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartments"
            />
        );
    }

    function filterApartmentIds(apartment) {
        if (apartmentIds !== undefined) {
            return apartmentIds.includes(apartment.id);
        }
        return true;
    }

    function filterOnlyActive(apartment) {
        if (onlyActive !== undefined && onlyActive !== false) {
            return apartment.active;
        }
        return true;
    }

    const columns = [
        {
            title: 'Tags',
            key: 'tags',
            render: apartment => <Tags tags={apartment.tags} />,
            filters: user.organization.apartmentTags.map(tag => ({
                text: tag,
                value: tag,
            })),
            onFilter: (tag, apartment) => apartment.tags.includes(tag),
        },
        {
            title: 'Storage',
            key: 'storage',
            render: apartment => apartment.storage.name,
            sorter: (a, b) => a.storage.name.localeCompare(b.storage.name),
        },
        {
            title: 'Cleaning price',
            key: 'cleaningPrice',
            render: apartment => `${apartment.cleaningPrice} ${user.organization.currency}`,
            sorter: (a, b) => a.cleaningPrice - b.cleaningPrice,
            align: 'right',
        },
        {
            title: 'Maximum guest count',
            key: 'maxGuestCount',
            render: apartment => `${apartment.maxGuestCount} guests`,
            sorter: (a, b) => a.maxGuestCount - b.maxGuestCount,
            align: 'right',
        },
        {
            title: 'Base guest count',
            key: 'increasePriceAbove',
            render: apartment => (
                <BaseGuestCount
                    increasePriceAbove={apartment.increasePriceAbove}
                />
            ),
            sorter: (a, b) => a.increasePriceAbove - b.increasePriceAbove,
            align: 'right',
        },
        {
            title: 'Extra guest fee',
            key: 'increasePriceBy',
            render: apartment => (
                <ExtraGuestFee
                    increasePriceBy={apartment.increasePriceBy}
                />
            ),
            sorter: (a, b) => a.increasePriceBy - b.increasePriceBy,
            align: 'right',
        },
        {
            title: 'Calendar length',
            key: 'calendarLength',
            render: apartment => `${apartment.calendarLength} days`,
            sorter: (a, b) => a.calendarLength - b.calendarLength,
            align: 'right',
        },
        {
            title: 'Cut off time',
            key: 'cutOffTime',
            render: apartment => apartment.cutOffTime,
            sorter: (a, b) => compareAsc(parse(a.cutOffTime || '00:00', 'HH:mm', startOfToday()), parse(b.cutOffTime || '00:00', 'HH:mm', startOfToday())),
            align: 'right',
        },
        {
            title: 'Default price config min price',
            key: 'defaultPriceConfigMinPrice',
            render: apartment => apartment.defaultPriceConfigMinPrice,
            sorter: (a, b) => a.defaultPriceConfigMinPrice - b.defaultPriceConfigMinPrice,
            align: 'right',
        },
        {
            title: 'Default price config delta',
            key: 'defaultPriceConfigDelta',
            render: apartment => apartment.defaultPriceConfigDelta,
            sorter: (a, b) => a.defaultPriceConfigDelta - b.defaultPriceConfigDelta,
            align: 'right',
        },
        {
            title: 'Default price config slope',
            key: 'defaultPriceConfigSlope',
            render: apartment => apartment.defaultPriceConfigSlope,
            sorter: (a, b) => a.defaultPriceConfigSlope - b.defaultPriceConfigSlope,
            align: 'right',
        },
        {
            title: 'Channels',
            key: 'channels',
            render: apartment => <Channels channels={apartment.channels} />,
        },
    ];

    return (
        <Table
            columns={[
                {
                    title: 'Name',
                    key: 'name',
                    render: apartment => (
                        <Space>
                            <Link to={`/apartments/${apartment.id}`}>
                                {apartment.name}
                            </Link>
                            {!apartment.active && (
                                <Tag>
                                    inactive
                                </Tag>
                            )}
                        </Space>
                    ),
                    sorter: (a, b) => a.name.localeCompare(b.name),
                    defaultSortOrder: 'ascend',
                },
                ...columns.filter(column => displayedColumns.includes(column.key)),
            ]}
            dataSource={
                [...data?.apartments ?? []]
                    .filter(filterApartmentIds)
                    .filter(filterOnlyActive)
            }
            rowKey="id"
            rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedApartments,
                onChange: value => onSelected(value),
            }}
            size="small"
            pagination={false}
        />
    );
}
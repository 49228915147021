import { SaveOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, Col, Form, PageHeader, Row, Skeleton } from "antd";
import { taskPromise } from "common/task";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReservationForm from "reservations/forms/ReservationForm";

const FORM_COLUMNS = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
}

const QUERY = gql`
    query GetReservationForUpdateReservation($reservationId: ID!) {
        reservation(reservationId: $reservationId) {
            id
            apartment {
                id
            }
            startDate
            endDate
            guestFirstName
            guestLastName
            guestPhone
            priceAccommodation
            priceCleaning
            numberOfGuests
            checkinTime
            checkoutTime
            isInternal
        }
    }
`;

const MUTATION = gql`
    mutation UpdateReservation($input: UpdateReservationInput!) {
        updateReservation(input: $input) {
            error {
                type
                message
            }
            reservation {
                id
                apartment {
                    id
                }
                startDate
                endDate
                guestFirstName
                guestLastName
                guestPhone
                priceAccommodation
                priceCleaning
                numberOfGuests
                checkinTime
                checkoutTime
            }
            syncApartmentCalendarUpTasks {
                id
            }
        }
    }
`;

export default function UpdateReservationView() {
    const { reservationId } = useParams();
    const navigate = useNavigate();

    const [updateLoading, setUpdateLoading] = useState(false);
    const [form] = Form.useForm();

    const { data, loading, error } = useQuery(QUERY, { variables: { reservationId } });
    const [updateReservation] = useMutation(MUTATION);

    if (loading) {
        return (
            <Skeleton
                style={{
                    margin: '24px',
                }}
            />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load reservation"
            />
        );
    }

    function handleSave() {
        setUpdateLoading(true);
        form
            .validateFields()
            .then(values => {
                const phone = `+${values.guestPhone.countryCode}${values.guestPhone.areaCode}${values.guestPhone.phoneNumber}`;
                const input = data.reservation.isInternal
                    ? {
                        reservationId,
                        apartmentId: values.apartmentId,
                        startDate: values.dateRange[0],
                        endDate: values.dateRange[1],
                        numberOfGuests: values.numberOfGuests,
                        guestFirstName: values.guestFirstName,
                        guestLastName: values.guestLastName,
                        guestPhone: phone,
                        priceAccommodation: values.priceAccommodation,
                        priceCleaning: values.priceCleaning,
                        checkinTime: values.checkinTime ?? null,
                        checkoutTime: values.checkoutTime ?? null,
                    }
                    : {
                        reservationId,
                        checkinTime: values.checkinTime ?? null,
                        checkoutTime: values.checkoutTime ?? null,
                    }
                    ;

                return updateReservation({
                    variables: {
                        input,
                    },
                });
            })
            .then(response => Promise.all(
                response.data.updateReservation.syncApartmentCalendarUpTasks
                    .map(task => taskPromise(task.id))
            ))
            .then(() => navigate(`/reservations/${reservationId}`))
            .finally(() => setUpdateLoading(false));
    }

    return (
        <PageHeader
            title="Reservation"
            onBack={() => navigate(`/reservations/${reservationId}`)}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <ReservationForm
                            form={form}
                            reservation={data?.reservation}
                            {...FORM_COLUMNS}
                        />
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => handleSave()}
                                    loading={updateLoading}
                                    icon={<SaveOutlined />}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    )
}
import { gql, useQuery } from "@apollo/client";
import { Tooltip } from "antd";
import { CLEANING_APARTMENT_STATUS_FINISHED, CLEANING_APARTMENT_STATUS_IN_PROGRESS, CLEANING_GROUP_STATUS_FINISHED, CLEANING_GROUP_STATUS_STARTED } from "cleaning-new/common";
import { compareAsc, differenceInMinutes, format } from "date-fns";
import { useEffect, useState } from "react";
import "./style.css";
import classNames from "classnames";

const QUERY = gql`
    query GetCleaningGroupForTimeline($cleaningGroupId: ID!) {
        cleaningGroup(cleaningGroupId: $cleaningGroupId) {
            id
            apartments {
                id
                apartment {
                    id
                    name
                }
                status
                statusChanges {
                    id
                    status
                    updatedAt
                    updater {
                        id
                        firstName
                        lastName
                    }
                }
            }
            cleaners {
                id
                firstName
                lastName
            }
            status
            statusChanges {
                id
                status
                updatedAt
                updater {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

export default function CleaningGroupTimeline(props) {
    const {
        cleaningGroupId,
        timelineStart,
        timelineEnd,
    } = props;

    const [timelineRef, setTimelineRef] = useState();
    const [currentTimestamp, setCurrentTimestamp] = useState(new Date());
    const { data, loading, error } = useQuery(QUERY, { variables: { cleaningGroupId } });

    useEffect(() => {
        const intervalId = window.setInterval(() => setCurrentTimestamp(new Date()), 60 * 1000);
        return () => window.clearInterval(intervalId);
    }, []);

    if (loading) {
        return null;
    }

    if (error) {
        return null;
    }

    const timelineRange = differenceInMinutes(timelineEnd, timelineStart);

    function timestampOffset(timestamp) {
        if (!timelineRef) {
            return 0;
        }

        const offset = differenceInMinutes(timestamp, timelineStart);
        const timelineWidth = timelineRef.offsetWidth;

        return offset * (timelineWidth / timelineRange);
    }

    const dots = [];
    const blocks = [];

    const firstCleaningGroupStart = [...data.cleaningGroup.statusChanges ?? []]
        .sort((a, b) => compareAsc(a.updatedAt, b.updatedAt))
        .find(statusChange => statusChange.status === CLEANING_GROUP_STATUS_STARTED);

    if (firstCleaningGroupStart) {
        dots.push({
            key: 'cleaningGroupStart',
            timestamp: firstCleaningGroupStart.updatedAt,
            description: `${firstCleaningGroupStart.updater.firstName} ${firstCleaningGroupStart.updater.lastName} started work at ${format(firstCleaningGroupStart.updatedAt, 'HH:mm')}`,
        });
    }

    const lastCleaningGroupFinish = [...data.cleaningGroup.statusChanges ?? []]
        .sort((a, b) => compareAsc(a.updatedAt, b.updatedAt))
        .findLast(statusChange => statusChange.status === CLEANING_GROUP_STATUS_FINISHED);

    if (lastCleaningGroupFinish) {
        dots.push({
            key: 'cleaningGroupFinish',
            timestamp: lastCleaningGroupFinish.updatedAt,
            description: `${lastCleaningGroupFinish.updater.firstName} ${lastCleaningGroupFinish.updater.lastName} finished work at ${format(lastCleaningGroupFinish.updatedAt, 'HH:mm')}`,
        });
    }

    [...data.cleaningGroup.apartments ?? []]
        .forEach(item => {
            if (item.status === CLEANING_APARTMENT_STATUS_FINISHED) {
                const lastApartmentStart = [...item.statusChanges ?? []]
                    .sort((a, b) => compareAsc(a.updatedAt, b.updatedAt))
                    .findLast(statusChange => statusChange.status === CLEANING_APARTMENT_STATUS_IN_PROGRESS);
                const lastApartmentFinish = [...item.statusChanges ?? []]
                    .sort((a, b) => compareAsc(a.updatedAt, b.updatedAt))
                    .findLast(statusChange => statusChange.status === CLEANING_APARTMENT_STATUS_FINISHED);

                blocks.push({
                    key: `apartmentCleaning:${item.id}`,
                    timestampFrom: lastApartmentStart.updatedAt,
                    timestampTo: lastApartmentFinish.updatedAt,
                    description: item.apartment.name,
                    finished: true,
                });
            }

            if (item.status === CLEANING_APARTMENT_STATUS_IN_PROGRESS) {
                const lastApartmentStart = [...item.statusChanges ?? []]
                    .sort((a, b) => compareAsc(a.updatedAt, b.updatedAt))
                    .findLast(statusChange => statusChange.status === CLEANING_APARTMENT_STATUS_IN_PROGRESS);

                blocks.push({
                    key: `apartmentCleaning:${item.id}`,
                    timestampFrom: lastApartmentStart.updatedAt,
                    timestampTo: currentTimestamp,
                    description: item.apartment.name,
                    finished: false,
                });
            }
        });

    return (
        <div className="cleaning-group-timeline-container">
            <div className="cleaning-group-cleaner-name">
                {data.cleaningGroup.cleaners.map(cleaner => `${cleaner.firstName} ${cleaner.lastName}`).join(', ')}
            </div>
            <div
                className="cleaning-group-timeline-timeline"
                ref={ref => setTimelineRef(ref)}
            >
                {dots.map(dot => (
                    <Tooltip
                        title={dot.description}
                        key={dot.key}
                    >
                        <div
                            className="cleaning-group-timeline-timeline-dot"
                            style={{
                                left: timestampOffset(dot.timestamp),
                            }}
                        />
                    </Tooltip>
                ))}
                {blocks.map(block => (
                    <Tooltip
                        title={block.description}
                        key={block.key}
                    >
                        <div
                            className={classNames({
                                'cleaning-group-timeline-timeline-block': true,
                                'cleaning-group-timeline-timeline-block-not-finished': !block.finished,
                            })}
                            style={{
                                left: timestampOffset(block.timestampFrom),
                                width: timestampOffset(block.timestampTo) - timestampOffset(block.timestampFrom),
                            }}
                        />
                    </Tooltip>
                ))}
            </div>
            <div className="cleaning-group-timeline-status">
                {data.cleaningGroup.status}
            </div>
        </div>
    );
}
import { FileOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import PreviewModal from "components/attachments/PreviewModal";
import { useState } from "react";
import { saveAs } from 'file-saver';
import "components/attachments/styles.scss";

export default function Thumbnail(props) {
    const {
        file
    } = props;

    const [previewModalOpen, setPreviewModalOpen] = useState(false);

    function handleDownload() {
        saveAs(file.getUrl, file.originalFileName);
    }

    if (file.mimeType.startsWith('image/')) {
        return (
            <div className="thumbnail-box">
                <img
                    alt={file.name}
                    src={file.getUrl}
                    onClick={() => setPreviewModalOpen(true)}
                />
                <PreviewModal
                    open={previewModalOpen}
                    file={file}
                    onClose={() => setPreviewModalOpen(false)}
                />
            </div>
        );
    }
    if (file.mimeType.startsWith('video/')) {
        return (
            <div className="thumbnail-box">
                <video
                    src={file.getUrl}
                    onClick={() => setPreviewModalOpen(true)}
                />
                <PlayCircleOutlined
                    className="thumbnail-icon"
                />
                <PreviewModal
                    open={previewModalOpen}
                    file={file}
                    onClose={() => setPreviewModalOpen(false)}
                />
            </div>
        );
    }

    return (
        <div className="thumbnail-box">
            <Tooltip title={file.name}>
                <FileOutlined
                    style={{
                        fontSize: '48px',
                        color: '#A0A0A0',
                    }}
                    onClick={() => handleDownload()}
                />
            </Tooltip>
        </div>
    );
}
import { gql, useQuery } from "@apollo/client";
import { Alert, Col, Divider, Form, Input, InputNumber, Row, Skeleton } from "antd";
import ApartmentSelect from "apartments/components/apartment-select/ApartmentSelect";
import { useAuth } from "auth";
import { formatCurrency } from "common/common";
import DatePicker from "components/DatePicker";
import PhoneInput from "components/PhoneInput";
import { isEqual, isWithinInterval, startOfDay, startOfToday, subDays } from "date-fns";
import { useEffect, useState } from "react";
import { calculatePrice } from "reservations/common";
import ReservationCheckinTimeSelect from "reservations/components/ReservationCheckinTimeSelect";
import ReservationCheckoutTimeSelect from "reservations/components/ReservationCheckoutTimeSelect";

const GET_APARTMENT_QUERY = gql`
    query GetApartmentForReservationForm($apartmentId: ID!, $today: Date!) {
        apartment(apartmentId: $apartmentId) {
            id
            maxGuestCount
            increasePriceAbove
            increasePriceBy
            cleaningPrice
            calendar(filter: {dateFrom: $today}) {
                id
                date
                price
                available
                minStay
            }
        }
    }
`;

export default function ReservationForm(props) {
    const {
        form,
        reservation,
        ...otherProps
    } = props;

    const { user } = useAuth();

    const apartmentId = Form.useWatch('apartmentId', form);
    const dateRange = Form.useWatch('dateRange', form);
    const numberOfGuests = Form.useWatch('numberOfGuests', form);
    const priceAccommodation = Form.useWatch('priceAccommodation', form);
    const priceCleaning = Form.useWatch('priceCleaning', form);
    const totalPrice = parseFloat(priceAccommodation) + parseFloat(priceCleaning);
    const [datePickerSelectedField, setDatePickerSelectedField] = useState('startDate');
    const externalReservationDisabledField = reservation ? !reservation.isInternal : false;

    const { data, loading, error } = useQuery(GET_APARTMENT_QUERY, {
        variables: {
            apartmentId,
            today: startOfToday(),
        },
        skip: !apartmentId,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
            const price = calculatePrice({
                datePrices: [...data?.apartment?.calendar ?? []]
                    .filter(item => isWithinInterval(item.date, { start: startOfDay(dateRange[0]), end: subDays(startOfDay(dateRange[1]), 1) }))
                    .map(item => item.price),
                numberOfGuests,
                increasePriceAbove: data?.apartment?.increasePriceAbove,
                increasePriceBy: data?.apartment?.increasePriceBy,
                cleaningPrice: data?.apartment?.cleaningPrice,
            });

            if (form && form.isFieldsTouched(['apartmentId', 'dateRange', 'numberOfGuests'])) {
                form.setFieldValue('priceAccommodation', price);
            }
        }
    }, [form, dateRange, data, numberOfGuests]);

    useEffect(() => {
        if (form && form.isFieldsTouched(['apartmentId'])) {
            form.setFieldValue('priceCleaning', data?.apartment?.cleaningPrice);
        }
    }, [form, data]);

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartment data"
            />
        );
    }

    function dateAvailable(date) {
        const isThisReservation = reservation
            ? isWithinInterval(startOfDay(date), { start: reservation.startDate, end: subDays(reservation.endDate, 1) })
            : false;
        const thisDateAvailavle = [...data?.apartment?.calendar]
            .find(item => isEqual(startOfDay(date), item.date))
            ?.available;
        const prevDateAvailable = [...data?.apartment?.calendar]
            .find(item => isEqual(subDays(startOfDay(date), 1), item.date))
            ?.available;

        const startingReservation = !thisDateAvailavle && prevDateAvailable;
        const endingReservation = thisDateAvailavle && !prevDateAvailable;
        const noReservation = thisDateAvailavle && prevDateAvailable;

        if (datePickerSelectedField === 'startDate') {
            return isThisReservation || endingReservation || noReservation;
        }
        if (datePickerSelectedField === 'endDate') {
            return isThisReservation || startingReservation || noReservation;
        }
    }

    function handleCalendarChange(values) {
        if (values[0] !== null && values[1] === null) {
            setDatePickerSelectedField('endDate');
        }
        else if (values[0] !== null && values[1] !== null) {
            setDatePickerSelectedField('endDate');
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form
                    form={form}
                    initialValues={{
                        apartmentId: reservation?.apartment?.id,
                        dateRange: [reservation?.startDate, reservation?.endDate],
                        numberOfGuests: reservation?.numberOfGuests,
                        priceAccommodation: reservation?.priceAccommodation,
                        priceCleaning: reservation?.priceCleaning,
                        guestFirstName: reservation?.guestFirstName,
                        guestLastName: reservation?.guestLastName,
                        guestPhone: reservation?.guestPhone,
                        checkinTime: reservation?.checkinTime,
                        checkoutTime: reservation?.checkoutTime,
                    }}
                    {...otherProps}
                >
                    <Form.Item
                        name="apartmentId"
                        label="Apartment"
                        rules={[{ required: true, message: 'Apartment is required' }]}
                    >
                        <ApartmentSelect
                            disabled={externalReservationDisabledField}
                        />
                    </Form.Item>
                    {apartmentId && loading && (
                        <Row>
                            <Col
                                offset={otherProps.labelCol.span}
                                span={otherProps.wrapperCol.span}
                            >
                                <Skeleton />
                            </Col>
                        </Row>
                    )}
                    {apartmentId && !loading && (
                        <>
                            <Form.Item
                                name="dateRange"
                                label="Date range"
                                rules={[{ required: true, message: 'Date range is required' }]}
                            >
                                <DatePicker.RangePicker
                                    disabledDate={date => !dateAvailable(date)}
                                    onCalendarChange={value => handleCalendarChange(value)}
                                    disabled={externalReservationDisabledField}
                                />
                            </Form.Item>
                            <Form.Item
                                name="numberOfGuests"
                                label="Number of guests"
                                rules={[{ required: true, message: 'Number of guests is required' }]}
                            >
                                <InputNumber
                                    min={1}
                                    max={data?.apartment?.maxGuestCount}
                                    addonAfter="guests"
                                    disabled={externalReservationDisabledField}
                                />
                            </Form.Item>
                            <Form.Item
                                name="priceAccommodation"
                                label="Accommodation price"
                                rules={[{ required: true, message: 'Accommodation price is required' }]}
                            >
                                <InputNumber
                                    min={0}
                                    addonAfter={user?.organization?.currency}
                                    disabled={externalReservationDisabledField}
                                />
                            </Form.Item>
                            <Form.Item
                                name="priceCleaning"
                                label="Cleaning price"
                                rules={[{ required: true, message: 'Cleaning price is required' }]}
                            >
                                <InputNumber
                                    min={0}
                                    addonAfter={user?.organization?.currency}
                                    disabled={externalReservationDisabledField}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Total price"
                            >
                                {formatCurrency(totalPrice)} {user?.organization?.currency}
                            </Form.Item>
                            <Divider orientation="left">
                                Guest
                            </Divider>
                            <Form.Item
                                name="guestFirstName"
                                label="First name"
                                rules={[{ required: true, message: 'First name is required' }]}
                            >
                                <Input
                                    disabled={externalReservationDisabledField}
                                />
                            </Form.Item>
                            <Form.Item
                                name="guestLastName"
                                label="Last name"
                                rules={[{ required: true, message: 'Last name is required' }]}
                            >
                                <Input
                                    disabled={externalReservationDisabledField}
                                />
                            </Form.Item>
                            <Form.Item
                                name="guestPhone"
                                label="Phone"
                                rules={[
                                    { required: true, message: 'Phone number is required' },
                                    {
                                        validator: (_, { valid }) => {
                                            if (valid()) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject("Invalid phone number");
                                        }
                                    },
                                ]}
                            >
                                <PhoneInput
                                    disabled={externalReservationDisabledField}
                                />
                            </Form.Item>
                            <Form.Item
                                name="checkinTime"
                                label="Check-in time"
                            >
                                <ReservationCheckinTimeSelect
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                name="checkoutTime"
                                label="Check-out time"
                            >
                                <ReservationCheckoutTimeSelect
                                    allowClear
                                />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Col>
        </Row>
    )
}
import { gql, useQuery } from "@apollo/client";
import { Alert, Checkbox, Col, Row, Skeleton } from "antd";
import { WEB_MENU_ITEMS } from "common/common";

const QUERY = gql`
    query GetUserGroupWebMenuForWebMenuSelect {
        userGroups {
            id
            webMenu
        }
    }
`;

export default function UserWebMenuSelect(props) {
    const {
        value,
        onChange,
        inheritMenuItemsFromGroupIds,
    } = props;

    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load user groups"
            />
        );
    }

    const userGroupMenuItems = [...data.userGroups ?? []]
        .filter(userGroup => [...inheritMenuItemsFromGroupIds ?? []].includes(userGroup.id))
        .map(userGroup => userGroup.webMenu)
        .flat()
        .filter((item, index, webMenu) => webMenu.indexOf(item) === index);

    const menuItems = WEB_MENU_ITEMS
        .map(item => {
            if (item.children) {
                return item.children.map(child => ({
                    label: `${item.label} / ${child.label}`,
                    link: child.link,
                }));
            }
            else {
                return [{
                    label: item.label,
                    link: item.link,
                }]
            }
        })
        .flat()

    return (
        <Checkbox.Group
            value={value}
            onChange={value => onChange(value)}
        >
            <Row gutter={[8, 8]}>
                {menuItems.map(item => (
                    <Col
                        key={item.link}
                        span={24}
                    >
                        {inheritMenuItemsFromGroupIds && userGroupMenuItems.includes(item.link) && (
                            <Checkbox
                                value={item.link}
                                disabled
                                defaultChecked={true}
                            >
                                {item.label}
                            </Checkbox>
                        )}
                        {(!inheritMenuItemsFromGroupIds || !userGroupMenuItems.includes(item.link)) && (
                            <Checkbox
                                value={item.link}
                            >
                                {item.label}
                            </Checkbox>
                        )}
                    </Col>
                ))}
            </Row>
        </Checkbox.Group>
    );
}
import { Col, PageHeader, Row } from "antd";
import { addDays, startOfToday, subDays } from "date-fns";

import { GET_STATISTICS_BY_ACCOMMODATION_DATE_QUERY } from "statistics/graphql";
import StatisticsApartmentsTable from "statistics/StatisticsApartmentsTable";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useState } from 'react';
import ApartmentSelectWithTags from "apartments/components/apartment-select/ApartmentSelectWithTags";
import { gql } from "graphql.macro";

const QUERY = gql`
    query GetActiveApartments {
        apartments(filter: {active: true}) {
            id
            name
            active
            tags
            storage {
                id
                name
            }
            maxGuestCount
        }
    }
`;

export default function Statistics100DaysComparison() {
    const navigate = useNavigate();

    const [selectedApartments, setSelectedApartments] = useState([]);

    const dateFrom = startOfToday();
    const dateTo = addDays(dateFrom, 149);

    const { data: apartmentsData, loading: apartmentsLoading } = useQuery(QUERY);

    const { data: statisticsData, loading: statisticsLoading } = useQuery(GET_STATISTICS_BY_ACCOMMODATION_DATE_QUERY, {
        variables: {
            filter: {
                apartmentGroups: [...apartmentsData?.apartments ?? []]
                    .filter(apartment => selectedApartments.length === 0 || selectedApartments.includes(apartment.id))
                    .map(apartment => ({
                        name: apartment.name,
                        apartmentIds: [apartment.id],
                    })),
                dateFrom,
                dateTo,
                intervalSize: 30,
                intervalType: "day",
            }
        },
        skip: apartmentsLoading,
    });

    const { data: statisticsComparisonData, loading: statisticsComparisonLoading } = useQuery(GET_STATISTICS_BY_ACCOMMODATION_DATE_QUERY, {
        variables: {
            filter: {
                apartmentGroups: [...apartmentsData?.apartments ?? []]
                    .map(apartment => ({
                        name: apartment.name,
                        apartmentIds: [apartment.id],
                    })),
                dateFrom: subDays(dateFrom, 10),
                dateTo: subDays(dateTo, 10),
                bookedAtTo: subDays(dateFrom, 10),
                intervalSize: 30,
                intervalType: "day",
            }
        },
        skip: apartmentsLoading,
    });

    return (
        <PageHeader
            title="Compare next 150 days by 30 days"
            onBack={() => navigate(-1)}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <ApartmentSelectWithTags
                                value={selectedApartments}
                                onChange={value => setSelectedApartments(value)}
                                placeholder="Apartments"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <StatisticsApartmentsTable
                        referenceDate="accommodationDate"
                        fieldNames={[
                            "bookedDays",
                            "dailyRevenueNetMean",
                        ]}
                        statistics={statisticsData}
                        apartments={apartmentsData}
                        statisticsComparison={statisticsComparisonData}
                        comparisonEnabled={true}
                        pagination={false}
                        loading={statisticsLoading || statisticsComparisonLoading}
                    />
                </Col>
            </Row>
        </PageHeader>
    );
}
import { Dropdown } from "antd";

export default function ContextMenu(props) {
    const {
        trigger,
        editDisabled,
        fixDisabled,
        moveToGroupDisabled,
        moveToNewGroupDisabled,
        jobsDisabled,
        addJobDisabled,
        addJobForCleanerDisabled,
        goToConversationDisabled,
        unassignDisabled,
        children,
        onClick,
    } = props;

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        label: 'Edit',
                        key: 'edit',
                        disabled: editDisabled,
                    },
                    {
                        label: 'Move to group',
                        key: 'moveToGroup',
                        disabled: moveToGroupDisabled,
                    },
                    {
                        label: 'Move to new group',
                        key: 'moveToNewGroup',
                        disabled: moveToNewGroupDisabled,
                    },
                    {
                        label: 'Fix',
                        key: 'fix',
                        disabled: fixDisabled,
                    },
                    {
                        label: 'Jobs',
                        key: 'jobs',
                        disabled: jobsDisabled,
                    },
                    {
                        label: 'Add job',
                        key: 'addJob',
                        disabled: addJobDisabled,
                    },
                    {
                        label: 'Add job for cleaner',
                        key: 'addJobForCleaner',
                        disabled: addJobForCleanerDisabled,
                    },
                    {
                        label: 'Go to conversation',
                        key: 'goToConversation',
                        disabled: goToConversationDisabled,
                    },
                    {
                        label: 'Unassign',
                        key: 'unassign',
                        danger: true,
                        disabled: unassignDisabled,
                    },
                ],
                onClick: ({ key }) => onClick(key),
            }}
            trigger={trigger}
        >
            {children}
        </Dropdown>
    );
}
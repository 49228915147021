import { ClockCircleOutlined } from "@ant-design/icons";
import { Select } from "antd";

const CHECKOUT_TIMES = [
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
]

export default function ReservationCheckoutTimeSelect(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    const options = CHECKOUT_TIMES.map(checkinTime => ({
        value: checkinTime,
        label: checkinTime,
    }));

    return (
        <Select
            options={options}
            value={value}
            onChange={value => onChange(value)}
            suffixIcon={<ClockCircleOutlined />}
            {...otherProps}
        />
    )
}
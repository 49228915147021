import { useLazyQuery, useMutation } from "@apollo/client";
import { Form, message, Modal } from "antd";
import errorHandler from "common/errorHandler";
import { formatISO } from "date-fns";
import { GET_APARTMENT_PRICE_CONFIGS_FOR_MODIFICATION_QUERY, UPDATE_APARTMENT_PRICE_CONFIGS_MUTATION } from "prices/graphql";
import PriceConfigModificationModalContent from "prices/PriceConfigModificationModalContent";

export default function PriceConfigModificationModal(props) {
    const { visible, onClose, selectedApartments } = props;

    const [form] = Form.useForm();

    const [fetchApartmentPriceConfigs] = useLazyQuery(GET_APARTMENT_PRICE_CONFIGS_FOR_MODIFICATION_QUERY);

    const [updateApartmentPriceConfigs, { loading: updateApartmentPriceConfigsLoading }] = useMutation(UPDATE_APARTMENT_PRICE_CONFIGS_MUTATION, {
        onCompleted(response) {
            if (response.updateApartmentPriceConfigs.error) {
                errorHandler(response.updateApartmentPriceConfigs.error);
            }
            else {
                message.success('Update succeeded')
            }
            onClose();
        }
    });

    function handlePriceConfigModify(priceModification) {
        selectedApartments.map(apartmentId => {
            fetchApartmentPriceConfigs({
                variables: {
                    apartmentIds: [apartmentId],
                    dateFrom: priceModification.dates[0],
                    dateTo: priceModification.dates[1],
                },
            })
                .then(response => {
                    const operations = response.data.apartments
                        .map(apartment => apartment.priceConfigs)
                        .flat()
                        .map(priceConfig => {
                            const parameterValue = priceConfig[priceModification.parameter];
                            let newParameterValue;
                            if (priceModification.parameter === 'minPrice' || priceModification.parameter === 'delta') {
                                if (priceModification.mode === 'value') {
                                    newParameterValue = parameterValue + priceModification.changeBy;
                                }
                                if (priceModification.mode === 'percent') {
                                    newParameterValue = Math.round(parameterValue + (parameterValue * (priceModification.changeBy / 100)));
                                }
                            }
                            if (priceModification.parameter === 'slope') {
                                newParameterValue = priceModification.setTo;
                            }

                            return {
                                date: priceConfig.date,
                                [priceModification.parameter]: newParameterValue,
                            }
                        });

                    updateApartmentPriceConfigs({
                        variables: {
                            input: {
                                apartmentId,
                                changes: operations,
                            },
                        },
                    });
                });

        });
    }

    return (
        <Modal
            visible={visible}
            title="Modify prices"
            okText="Modify"
            onOk={() => form.submit()}
            okButtonProps={{
                loading: updateApartmentPriceConfigsLoading,
            }}
            onCancel={() => onClose()}
            destroyOnClose={true}
            cancelButtonProps={{
                disabled: updateApartmentPriceConfigsLoading,
            }}
        >
            <PriceConfigModificationModalContent
                form={form}
                onSubmit={values => handlePriceConfigModify(values)}
            />
        </Modal>
    );
}
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Alert, Skeleton, Space, Table } from "antd";
import { gql } from "graphql.macro";
import { Link } from "react-router-dom";

const QUERY = gql`
    query GetStorages {
        storages {
            id
            name
            order
        }
    }
`;

const SWAP_MUTATION = gql`
    mutation SwapStorages($input: SwapStoragesInput!) {
        swapStorages(input: $input) {
            error {
                type
                message
            }
            storageA {
                id
                order
            }
            storageB {
                id
                order
            }
        }
    }
`;

export default function StoragesTable(props) {
    const {
        displayReorder,
    } = props;

    const { data, loading, error } = useQuery(QUERY);

    const [swapStorages] = useMutation(SWAP_MUTATION);

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load storages"
            />
        );
    }

    function handleMoveUp(storageId) {
        const storages = [...data?.storages ?? []]
            .sort((a, b) => a.order - b.order);
        const storageIndex = storages
            .findIndex(storage => storage.id === storageId);

        if (storageIndex === -1 || storageIndex === 0) {
            return;
        }

        const thisStorage = storages[storageIndex];
        const prevStorage = storages[storageIndex - 1];

        swapStorages({
            variables: {
                input: {
                    storageAId: thisStorage.id,
                    storageBId: prevStorage.id,
                },
            },
        });
    }

    function handleMoveDown(storageId) {
        const storages = [...data?.storages ?? []]
            .sort((a, b) => a.order - b.order);
        const storageIndex = storages
            .findIndex(storage => storage.id === storageId);

        if (storageIndex === -1 || storageIndex === (storages.length - 1)) {
            return;
        }

        const thisStorage = storages[storageIndex];
        const nextStorage = storages[storageIndex + 1];

        swapStorages({
            variables: {
                input: {
                    storageAId: thisStorage.id,
                    storageBId: nextStorage.id,
                },
            },
        });
    }

    const columns = [];
    if (displayReorder) {
        columns.push({
            title: 'Reorder',
            key: 'reorder',
            width: '100px',
            align: 'center',
            render: storage => (
                <Space>
                    <ArrowUpOutlined
                        onClick={() => handleMoveUp(storage.id)}
                    />
                    <ArrowDownOutlined
                        onClick={() => handleMoveDown(storage.id)}
                    />
                </Space>
            ),
        });
    }

    return (
        <Table
            columns={[
                {
                    title: 'Name',
                    key: 'name',
                    render: storage => (
                        <Link to={`/storages/${storage.id}`}>
                            {storage.name}
                        </Link>
                    ),
                },
                ...columns,
            ]}
            dataSource={
                [...data.storages ?? []]
                    .sort((a, b) => a.order - b.order)
            }
            rowKey="id"
            size="small"
            pagination={false}
        />
    );
}
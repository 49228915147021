import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, PageHeader, Row } from "antd";
import { Link } from "react-router-dom";
import UserGroupsList from "user-groups/components/user-groups-list/UserGroupsList";

export default function ListUserGroupsView() {
    return (
        <PageHeader
            title="User groups"
            extra={
                <Link
                    to="/user-groups/create"
                    key="createUserGroup"
                >
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                    >
                        Create user group
                    </Button>
                </Link>
            }
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <UserGroupsList />
                </Col>
            </Row>
        </PageHeader>
    )
}
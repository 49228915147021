import { gql, useQuery } from "@apollo/client";
import { Alert, Skeleton } from "antd";
import { compareAsc, endOfDay, startOfDay } from "date-fns";
import Job from "./Job";
import "./styles.css";

const QUERY = gql`
    query GetRecentJobs($dateFrom: DateTime!, $dateTo: DateTime!) {
        jobsNew(filter: {deleted: true, createdAtFrom: $dateFrom, createdAtTo: $dateTo}) {
            id
            title
            tags
            apartment {
                id
                name
                storage {
                    id
                    name
                }
            }
            storage {
                id
                name
            }
            status
            assigners {
                id
                firstName
                lastName
            }
            watchers {
                id
                firstName
                lastName
            }
            watcherGroups {
                id
                name
            }
            priority
            author {
                id
                firstName
                lastName
                phone
            }
            createdAt
            calculatedDate
            endingReservation {
                id
                endDate
                checkoutTime
            }
            currentReservation {
                id
                startDate
                endDate
            }
            startingReservation {
                id
                startDate
                checkinTime
            }
            deleted
        }
    }
`;

export default function RecentJobsList(props) {
    const {
        date,
        selectedJobs,
        onSelect,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            dateFrom: startOfDay(date),
            dateTo: endOfDay(date),
        }
    });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load recent jobs"
            />
        );
    }

    if (data.jobsNew.length === 0) {
        return (
            <Alert
                type="info"
                showIcon
                message="No jobs on given day"
            />
        );
    }

    const jobs = [...data?.jobsNew ?? []]
        .sort((a, b) => compareAsc(b.createdAt, a.createdAt));

    return (
        <div className="recent-jobs-list-container">
            {jobs.map(job => (
                <Job
                    key={job.id}
                    job={job}
                    selectedJobs={selectedJobs}
                    onSelect={value => onSelect(value)}
                />
            ))}
        </div>
    )
}
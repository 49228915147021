import { gql, useQuery } from "@apollo/client";
import { Select } from "antd";
import { useAuth } from "auth";
import { fuzzySearch } from "common/common";

const QUERY = gql`
    query GetApartmentExpenseCategories($organizationId: ID!) {
        organization(organizationId: $organizationId) {
            apartmentExpenseCategories {
                id
                name
            }
        }
    }
`

export default function ApartmentExpenseCategorySelect(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    const { user } = useAuth();
    const { data, loading, error } = useQuery(QUERY, { variables: { organizationId: user.organization.id } });

    if (loading) {
        return (
            <Select
                loading
                style={{
                    width: '250px',
                }}
            />
        );
    }

    if (error) {
        return (
            <Select
                status="error"
                style={{
                    width: '250px',
                }}
            />
        );
    }

    const options = [...data.organization.apartmentExpenseCategories ?? []]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(apartment => ({
            label: apartment.name,
            value: apartment.id,
        }));

    return (
        <Select
            options={options}
            value={value}
            onChange={value => onChange(value)}
            showSearch
            filterOption={(input, option) => fuzzySearch(option.label, input)}
            style={{
                width: '250px',
            }}
            {...otherProps}
        />
    );
}
export const USER_STATUS_ONBOARDING = 'onboarding';
export const USER_STATUS_ACTIVE = 'active';
export const USER_STATUS_DELETED = 'deleted';

export const USER_LANGUAGES = [
    { language: 'pl', label: 'Polish' },
    { language: 'ua', label: 'Ukrainian' },
    { language: 'en', label: 'English' },
];

export function userName(user) {
    return `${user.firstName} ${user.lastName}`;
}

export function generatePassword() {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXYZ0123456789';
    const length = 10;

    return Array.from(crypto.getRandomValues(new Uint32Array(length)))
        .map(x => characters[x % characters.length])
        .join('');
}
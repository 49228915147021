// import { STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING, STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING } from "statistics/common";

// import { Line } from "@ant-design/charts";

export default function StatisticsByAccommodationDateChart(props) {
    // const { referenceDate, fieldName, statistics, loading } = props;

    // const fieldNameMapping = referenceDate === 'accommodationDate'
    //     ? STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING
    //     : STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING;

    // const data = referenceDate === 'accommodationDate'
    //     ? statistics?.statisticsByAccommodationDate
    //     : statistics?.statisticsByBookedDate;

    return null;

    // return (
    //     <Line
    //         data={[...data || []]}
    //         loading={loading}
    //         xField="interval"
    //         yField={fieldName}
    //         yAxis={{
    //             label: {
    //                 formatter: v => parseFloat(v).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    //             }
    //         }}
    //         tooltip={{
    //             formatter: v => ({
    //                 name: v.apartmentGroupName || fieldNameMapping[fieldName],
    //                 value: v[fieldName].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    //             })
    //         }}
    //         seriesField="apartmentGroupName"
    //         legend={false}
    //     />
    // )
}
import { Col, Row } from "antd";

export default function MessagesSummary(props) {
    const { reservationId } = props;

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <div
                    style={{
                        fontSize: '14px',
                        color: '#808080'
                    }}
                >
                    Arrival
                </div>
                <div
                    style={{
                        fontSize: '18px',
                    }}
                >
                    Guest says that their flight lands at <strong>11am</strong> and they expect to arrive at the apartment around <strong>3pm</strong>.
                </div>
            </Col>
            <Col span={24}>
                <div
                    style={{
                        fontSize: '14px',
                        color: '#808080'
                    }}
                >
                    Departure
                </div>
                <div
                    style={{
                        fontSize: '18px',
                    }}
                >
                    Guest says that they have an early flight at <strong>8am</strong> and will have to leave the aprtment at <strong>6am</strong>.
                </div>
            </Col>
            <Col span={24}>
                <div
                    style={{
                        fontSize: '14px',
                        color: '#808080'
                    }}
                >
                    Separate beds
                </div>
                <div
                    style={{
                        fontSize: '18px',
                    }}
                >
                    Guest says that they arrive with two couples and one single person, so they will require <strong>all beds</strong>.
                </div>
            </Col>
        </Row>
    )
}
import { useEffect, useState } from "react";
import { Card, PageHeader, Result } from "antd";
import { formatISO, parseISO } from "date-fns";
import JobCreateButton from "jobs-new/components/JobCreateButton";
import JobsListActions from "jobs-new/components/JobsListActions";
import JobsListControls from "jobs-new/components/jobs-list-controls/JobsListControls";
import JobsList from "jobs-new/components/jobs-list/JobsList";
import { useAuth } from "auth";
import { contains, intersect } from "common/common";

export default function ListJobsView() {
    const { permissions } = useAuth();

    const [display, setDisplay] = useState();
    const [filter, setFilter] = useState();
    const [initialFilter, setInitialFilter] = useState();
    const [expandedItems, setExpandedItems] = useState([]);
    const [selectedJobs, setSelectedJobs] = useState([]);

    useEffect(() => {
        const storedFilterStr = localStorage.getItem('jobsFilter');
        if (storedFilterStr) {
            const storedFilter = JSON.parse(storedFilterStr);

            if (storedFilter.key === 'other') {
                setInitialFilter({
                    key: 'other',
                    filter: {
                        ...storedFilter.filter,
                        dateFrom: parseISO(storedFilter.filter.dateFrom),
                        dateTo: parseISO(storedFilter.filter.dateTo),
                    },
                });
            }
            else {
                setInitialFilter({
                    key: storedFilter.key,
                });
            }
        }

        const storedDisplayStr = localStorage.getItem('jobsDisplay');
        if (storedDisplayStr) {
            const storedDisplay = JSON.parse(storedDisplayStr);
            setDisplay(storedDisplay);
        }
        else {
            setDisplay({
                grouping: "locations",
                displayedColumns: ['storage', 'status', 'assigners', 'priority'],
            });
        }

        const storedExpandedItemsStr = localStorage.getItem('jobsExpandedItems');
        if (storedExpandedItemsStr) {
            const storedExpandedItems = JSON.parse(storedExpandedItemsStr);
            setExpandedItems(storedExpandedItems);
        }
    }, []);

    useEffect(() => {
        if (!filter) {
            return;
        }

        if (filter.key === 'other') {
            localStorage.setItem(
                'jobsFilter',
                JSON.stringify({
                    key: 'other',
                    filter: {
                        ...filter.filter,
                        dateFrom: formatISO(filter.filter.dateFrom, { representation: 'date' }),
                        dateTo: formatISO(filter.filter.dateTo, { representation: 'date' }),
                    },
                }),
            );
        }
        else {
            localStorage.setItem(
                'jobsFilter',
                JSON.stringify({
                    key: filter.key,
                }),
            );
        }
    }, [filter]);

    useEffect(() => {
        if (!display) {
            return;
        }

        localStorage.setItem(
            'jobsDisplay',
            JSON.stringify(display),
        );
    }, [display]);

    useEffect(() => {
        if (!expandedItems) {
            return;
        }

        localStorage.setItem(
            'jobsExpandedItems',
            JSON.stringify(expandedItems),
        );
    }, [expandedItems]);

    if (!contains(permissions, ['user:get:self', 'user:get:other', 'user_group:get', 'apartment:get', 'storage:get', 'reservation:get', 'job:get', 'user_jobs_filter_preset:get:self'])) {
        return (
            <Result
                status="error"
                title="No permissions"
                subTitle="You don't have permissions to view jobs"
            />
        );
    }

    const canCreate = permissions.includes('job:create');
    const canUpdate = intersect(permissions, ['job:update:self', 'job:update:other']);

    return (
        <PageHeader
            title="Jobs"
            extra={
                canCreate
                    ? [
                        <JobCreateButton
                            key="createButton"
                        />
                    ]
                    : []
            }
        >
            <Card
                title={
                    canUpdate && (
                        <JobsListActions
                            selectedJobs={selectedJobs}
                            clearSelectedJobs={() => setSelectedJobs([])}
                        />
                    )
                }
                extra={
                    <JobsListControls
                        display={display}
                        onDisplayChange={value => setDisplay(value)}
                        filter={filter}
                        initialFilter={initialFilter}
                        onFilterChange={value => setFilter(value)}
                    />
                }
                size="small"
            >
                <JobsList
                    filter={filter?.filter}
                    display={display}
                    expandedItems={expandedItems}
                    onExpandChange={value => setExpandedItems(value)}
                    selectedJobs={selectedJobs}
                    onSelect={value => setSelectedJobs(value)}
                />
            </Card>
        </PageHeader>
    );
}
import { Alert, Button, Card, Col, PageHeader, Row, Skeleton, } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import ReservationExternalLinks from 'reservations/components/ReservationExternalLinks';
import { gql, useQuery } from '@apollo/client';
import ReservationInformation from '../components/ReservationInformation';
import { EditOutlined } from '@ant-design/icons';

const QUERY = gql`
    query GetReservationForReservationView($reservationId: ID!) {
        reservation(reservationId: $reservationId) {
            id
            isInternal
            jobs {
                id
                attachTo
                apartment {
                    id
                }
                date
                reservationDate
                calculatedDate
                category
                description
            }
        }
    }
`;

export default function DisplayReservationView() {
    const { reservationId } = useParams();
    const navigate = useNavigate();

    const { data, loading, error } = useQuery(QUERY, { variables: { reservationId } });

    if (loading) {
        return (
            <Skeleton
                style={{
                    margin: '24px',
                }}
            />
        );
    }

    if (error) {
        return (
            <Alert
                type='error'
                showIcon
                message="Failed to load reservation"
            />
        );
    }

    return (
        <PageHeader
            title="Reservation"
            onBack={() => navigate(-1)}
        >
            <Row gutter={[16, 16]}>
                <Col span={14}>
                    <Card
                        title="Information"
                        extra={
                            data.reservation.isInternal && (
                                <Button
                                    onClick={() => navigate(`/reservations/${reservationId}/update`)}
                                    icon={<EditOutlined />}
                                >
                                    Change
                                </Button>
                            )
                        }
                    >
                        <ReservationInformation
                            reservationId={reservationId}
                        />
                    </Card>
                </Col>
                <Col span={10}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card
                                title="External links"
                            >
                                <ReservationExternalLinks
                                    reservationId={reservationId}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </PageHeader>
    );
}
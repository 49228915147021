import { useEffect } from "react";
import { Form, Modal } from "antd";
import UserSelect from "users/components/user-select/UserSelect";

export default function JobAssignersModal(props) {
    const {
        open,
        form,
        job,
        onSubmit,
        onCancel,
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            assigners: [...job?.assigners ?? []].map(assigner => assigner.id),
        });
    }, [open, form, job?.assigners]);

    return (
        <Modal
            open={open}
            title={job?.title ?? 'Assigners'}
            onOk={() => onSubmit()}
            onCancel={() => onCancel()}
            destroyOnClose
        >
            <Form
                form={form}
                preserve={false}
            >
                <Form.Item
                    name="assigners"
                    label="Assigners"
                >
                    <UserSelect
                        active
                        mode="multiple"
                        placeholder="Assigners"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
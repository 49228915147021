import { Tag } from "antd";

export default function Groups(props) {
    const {
        groups,
    } = props;

    return (
        <>
            {[...groups ?? []].map(group => (
                <Tag key={group.id}>
                    {group.name}
                </Tag>
            ))}
        </>
    );
}
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Form, List, Modal, message } from "antd";
import { useAuth } from "auth";
import { taskPromise } from "common/task";
import { formatISO } from "date-fns";
import OrganizationOrganizationCalendarRestrictionForm from "organizations/components/calendar-restrictions-list/ModalForm";
import { useState } from "react";

const UPDATE_MUTATION = gql`
    mutation UpdateOrganizationCalendarRestriction($input: UpdateOrganizationCalendarRestrictionInput!) {
        updateOrganizationCalendarRestriction(input: $input) {
            error {
                type
                message
            }
            organizationCalendarRestriction {
                id
                date
                minStay
            }
            recalculateApartmentCalendarTasks {
                id
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation DeleteOrganizationCalendarRestriction($input: DeleteOrganizationCalendarRestrictionInput!) {
        deleteOrganizationCalendarRestriction(input: $input) {
            error {
                type
                message
            }
            recalculateApartmentCalendarTasks {
                id
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        organizationCalendarRestriction,
    } = props;

    const { user } = useAuth();

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateForm] = Form.useForm();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [updateOrganizationCalendarRestriction] = useMutation(UPDATE_MUTATION);
    const [deleteOrganizationCalendarRestriction] = useMutation(DELETE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Organization',
                    id: user.organization.id,
                }),
                fieldName: 'calendarRestrictions',
            });
        }
    });

    function handleUpdate() {
        setUpdateLoading(true);
        updateForm
            .validateFields()
            .then(values => {
                updateOrganizationCalendarRestriction({
                    variables: {
                        input: {
                            organizationCalendarRestrictionId: organizationCalendarRestriction.id,
                            date: values.date,
                            minStay: values.minStay,
                        },
                    },
                })
                    .then(response => {
                        const error = response.data.updateOrganizationCalendarRestriction.error;
                        if (error) {
                            message.error('Failed to update calendar restriction');
                            setUpdateLoading(false);
                        }
                        else {
                            const tasks = response.data.updateOrganizationCalendarRestriction.recalculateApartmentCalendarTasks;
                            Promise.all(tasks.map(task => taskPromise(task.id)))
                                .then(() => {
                                    message.success('Calendar restriction updated');
                                    setUpdateLoading(false);
                                    setUpdateModalOpen(false);
                                })
                        }
                    })
            });
    }

    function handleDelete() {
        Modal.confirm({
            title: 'Do you want to delete this calendar restriction?',
            okText: 'Delete',
            okButtonProps: {
                danger: true,
                icon: <DeleteOutlined />,
                loading: deleteLoading,
            },
            onOk: () => {
                setDeleteLoading(true);
                return deleteOrganizationCalendarRestriction({
                    variables: {
                        input: {
                            organizationCalendarRestrictionId: organizationCalendarRestriction.id,
                        },
                    },
                })
                    .then(response => {
                        const error = response.data.deleteOrganizationCalendarRestriction.error;
                        if (error) {
                            message.error('Failed to delete calendar restriction');
                            setDeleteLoading(false);
                        }
                        else {
                            const tasks = response.data.deleteOrganizationCalendarRestriction.recalculateApartmentCalendarTasks;
                            Promise.all(tasks.map(task => taskPromise(task.id)))
                                .then(() => {
                                    message.success('Calendar restriction deleted');
                                    setDeleteLoading(false);
                                    return Promise.resolve();
                                })
                        }
                    })
            },
        })
    }

    return (
        <List.Item
            actions={[
                <EditOutlined
                    onClick={() => setUpdateModalOpen(true)}
                />,
                <DeleteOutlined
                    onClick={() => handleDelete()}
                />
            ]}
        >
            <List.Item.Meta
                title={formatISO(organizationCalendarRestriction.date, { representation: 'date' })}
                description={`Min stay: ${organizationCalendarRestriction.minStay} days`}
            />
            <Modal
                title="Update calendar restriction"
                open={updateModalOpen}
                destroyOnClose
                onCancel={() => setUpdateModalOpen(false)}
                onOk={() => handleUpdate()}
                okText="Update"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateLoading,
                }}
            >
                <OrganizationOrganizationCalendarRestrictionForm
                    form={updateForm}
                    organizationCalendarRestriction={organizationCalendarRestriction}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 8,
                    }}
                />
            </Modal>
        </List.Item>
    );
}
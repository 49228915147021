import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import "./style.css";
import { Tooltip } from "antd";

const UNKNOWN_AVAILABILITY_COLOR = "#d0d0d0";

const QUERY = gql`
    query GetAvailabilityTypesForAvailabilityStatus {
        organization(organizationId: "self") {
            id
            userAvailabilityTypes {
                id
                availability
                label
                color
            }
        }
        userGroups {
            id
            userAvailabilityTypes {
                id
                availability
                label
                color
            }
        }
    }
`;

export default function UserAvailabilityStatus(props) {
    const {
        availability,
        comment,
        shape,
        size,
        showLabel,
    } = props;

    const { data } = useQuery(QUERY);

    if (!data) {
        return null;
    }

    const organizationAvailabilityTypes = [...data.organization.userAvailabilityTypes]
        .map(availabilityType => ({
            availability: availabilityType.availability,
            label: availabilityType.label,
            color: availabilityType.color,
        }));

    const userGroupAvailabilityTypes = [...data.userGroups]
        .map(userGroup => userGroup.userAvailabilityTypes
            .map(availabilityType => ({
                availability: availabilityType.availability,
                label: availabilityType.label,
                color: availabilityType.color,
            }))
        )
        .flat();

    const availabilityTypes = Object.fromEntries(
        [...organizationAvailabilityTypes, ...userGroupAvailabilityTypes]
            .map(item => ([
                item.availability,
                {
                    availability: item.availability,
                    label: item.label,
                    color: item.color,
                }
            ]))
    );

    const content = (
        <div className="user-availability-status-container">
            <div
                className={classNames({
                    'user-availability-status-circle': shape === 'circle',
                    'user-availability-status-square': shape === 'square',
                })}
                style={{
                    backgroundColor: availabilityTypes[availability]?.color ?? UNKNOWN_AVAILABILITY_COLOR,
                    width: size,
                    height: size,
                }}
            />
            {comment && (
                <div className="user-availability-status-comment-badge" />
            )}
            {showLabel && (
                <div className="user-availability-status-label">
                    {availabilityTypes[availability]?.availability}
                </div>
            )}
        </div>
    );

    if (comment) {
        return (
            <Tooltip title={comment}>
                {content}
            </Tooltip>
        );
    }

    return content;
}
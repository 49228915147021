import { gql, useQuery } from "@apollo/client";
import { Select } from "antd";
import { compareAsc, formatISO, startOfToday } from "date-fns";

const QUERY = gql`
    query GetReservations($filter: ReservationsFilter = {}) {
        reservations(filter: $filter) {
            id
            startDate
            endDate
        }
    }
`;

export default function JobReservationSelect(props) {
    const {
        apartmentId,
        limitReservations,
        value,
        onChange,
    } = props;

    const { data: reservationsData, loading: reservationsLoading } = useQuery(QUERY, {
        variables: {
            filter: {
                apartmentId,
                status: 'confirmed',
                endDateGte: startOfToday(),
            },
        },
        skip: !apartmentId,
    });

    function reservationLabel(reservation) {
        const startDate = formatISO(reservation.startDate, { representation: 'date' });
        const endDate = formatISO(reservation.endDate, { representation: 'date' });

        return `${startDate} - ${endDate}`;
    }

    return (
        <Select
            loading={reservationsLoading}
            options={
                [...reservationsData?.reservations ?? []]
                    .filter(reservation => !limitReservations || limitReservations.includes(reservation.id))
                    .sort((a, b) => compareAsc(a.startDate, b.startDate))
                    .map(reservation => ({
                        value: reservation.id,
                        label: reservationLabel(reservation),
                    }))
            }
            value={value}
            onChange={value => onChange(value)}
            style={{
                width: '100%',
            }}
            placeholder="Reservation"
        />
    )
}
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, PageHeader } from "antd";
import CleaningTimelineList from "cleaning-new/components/cleaning-timeline-list/CleaningTimelineList";
import DatePicker from "components/DatePicker";
import { addDays, endOfToday, formatISO, isAfter, isEqual, parseISO, startOfToday, subDays } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";

export default function DisplayCleaningTimelineView() {
    const { date: dateStr } = useParams();
    const date = parseISO(dateStr);

    const navigate = useNavigate();

    function handleDateChange(date) {
        navigate(`/cleaning/timeline/${formatISO(date, { representation: 'date' })}`);
    }

    return (
        <PageHeader
            title="Cleaning timeline"
            extra={[
                <Button
                    icon={<LeftOutlined />}
                    onClick={() => handleDateChange(subDays(date, 1))}
                    key="prevDate"
                />,
                <DatePicker
                    value={date}
                    onChange={value => handleDateChange(value)}
                    disabledDate={date => isAfter(date, endOfToday())}
                    allowClear={false}
                    key="datePicker"
                />,
                <Button
                    icon={<RightOutlined />}
                    onClick={() => handleDateChange(addDays(date, 1))}
                    disabled={isEqual(date, startOfToday())}
                    key="nextDate"
                />
            ]}
        >
            <CleaningTimelineList
                date={date}
            />
        </PageHeader>
    );
}
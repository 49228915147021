import { gql, useMutation } from '@apollo/client';
import { Button, Card, Col, Form, Row } from 'antd';
import { useAuth } from 'auth';
import UserOnboardingForm from './UserOnboardingForm';
import { SaveOutlined } from '@ant-design/icons';
import { USER_STATUS_ACTIVE } from 'users/common';

const MUTATION = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            error {
                type
                message
            }
            user {
                id
                firstName
                lastName
            }
        }
    }
`;

export default function OnboardingView() {
    const { user } = useAuth();

    const [updateUser, { loading }] = useMutation(MUTATION);

    const [form] = Form.useForm();

    function handleSubmit() {
        form
            .validateFields()
            .then(values => {
                return updateUser({
                    variables: {
                        input: {
                            userId: user.id,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            password: values.password,
                            status: USER_STATUS_ACTIVE,
                        },
                    },
                });
            })
            .then(() => window.location.reload());
    }

    return (
        <Row
            align="middle"
            justify="center"
        >
            <Col span={12}>
                <Card title="Update your account">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <UserOnboardingForm
                                form={form}
                                user={user}
                            />
                        </Col>
                        <Col span={24}>
                            <Row justify="end">
                                <Col>
                                    <Button
                                        type='primary'
                                        onClick={() => handleSubmit()}
                                        icon={<SaveOutlined />}
                                        loading={loading}
                                    >
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}
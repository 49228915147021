import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";

export default function FieldsForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.List
                name="fields"
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                            <Form.Item
                                label="Field"
                                {...field}
                                rules={[{ required: true, message: 'Field can not be empty' }]}
                            >
                                <Input
                                    addonAfter={
                                        <DeleteOutlined
                                            onClick={() => remove(field.name)}
                                        />
                                    }
                                />
                            </Form.Item>
                        ))}
                        <Form.Item noStyle>
                            <Button
                                icon={<PlusOutlined />}
                                onClick={() => add()}
                                block
                                type="link"
                            >
                                Add field
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
}
import { EditOutlined, SnippetsOutlined, SyncOutlined } from "@ant-design/icons";
import { Menu } from "antd";

export default function PriceConfigActionsMenu(props) {
    const { onAction } = props;

    return (
        <Menu
            items={[
                {
                    label: 'Modify prices',
                    key: 'modifyPrices',
                    icon: <EditOutlined />,
                },
                {
                    label: 'Recalculate',
                    key: 'recalculate',
                    icon: <SyncOutlined />,
                },
                {
                    label: 'Paste',
                    key: 'paste',
                    icon: <SnippetsOutlined />,
                },
            ]}
            onClick={({ key: action }) => onAction(action)}
        />
    );
}
import { useEffect } from "react";
import { Form, Modal } from "antd";
import JobStatusSelect from "jobs-new/components/JobStatusSelect";

export default function JobStatusModal(props) {
    const {
        open,
        form,
        job,
        onSubmit,
        onCancel,
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            status: job.status,
        });
    }, [open, form, job.status]);

    return (
        <Modal
            open={open}
            title={job.title}
            onOk={() => onSubmit()}
            onCancel={() => onCancel()}
            destroyOnClose
        >
            <Form
                form={form}
                preserve={false}
            >
                <Form.Item
                    name="status"
                    label="Status"
                >
                    <JobStatusSelect
                        // disabledOptions={['unassigned']}
                        // disabled={!job.status || job.status === JOB_STATUS_UNASSIGNED}
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
import { useState } from "react";
import { Typography } from "antd";
import classNames from "classnames";
import { format } from "date-fns";
import { getTemplateInfo } from "./common";
import "./style.css";

export default function ReservationMessage(props) {
    const {
        message,
        translate,
        mark,
    } = props;

    const [show, setShow] = useState(false);
    const template = getTemplateInfo(message.content);

    return (
        <div
            className={classNames({
                "message-container": true,
                "message-host": message.author === 'property',
                "message-guest": message.author === 'guest',
            })}
        >
            {message.content && (!template || show) && (
                <div
                    className={classNames({
                        'message-content': true,
                        'mark': mark,
                    })}
                >
                    {translate && (message.translation?.content || message.content)}
                    {!translate && message.content}
                </div>
            )}
            {message.content && (template && !show) && (
                <div className="message-template">
                    <div className="message-template-label">
                        {template.label}
                    </div>
                    <div className="message-template-show">
                        <Typography.Link onClick={() => setShow(true)}>
                            Show
                        </Typography.Link>
                    </div>
                </div>
            )}
            {message.attachments.map(attachment => (
                <div
                    className="attachment"
                    key={attachment.id}
                >
                    <img src={attachment.getUrl} alt={attachment.fileName} />
                </div>
            ))}
            <div className="message-timestamp">
                {format(message.timestamp, 'yyyy-MM-dd HH:mm')}
            </div>
        </div>
    )
}
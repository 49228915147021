import { Col, List, Row } from "antd";
import { compareDesc, format } from "date-fns";

export default function PriceConfigChangesList(props) {
    const { changes } = props;

    return (
        <List
            dataSource={
                [...changes]
                    .sort((a, b) => compareDesc(a.changedAt, b.changedAt))
            }
            renderItem={change => (
                <List.Item>
                    <Row
                        gutter={[16, 16]}
                        style={{
                            width: '100%',
                        }}
                    >
                        <Col span={9}>
                            {format(change.changedAt, 'yyyy-MM-dd HH:mm')}
                        </Col>
                        <Col span={5}>
                            <Row
                                gutter={[16, 16]}
                                align="end"
                            >
                                <Col>
                                    MP
                                </Col>
                                <Col>
                                    {change.minPrice}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={5}>
                            <Row
                                gutter={[16, 16]}
                                align="end"
                            >
                                <Col>
                                    D
                                </Col>
                                <Col>
                                    {change.delta}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={5}>
                            <Row
                                gutter={[16, 16]}
                                align="end"
                            >
                                <Col>
                                    S
                                </Col>
                                <Col>
                                    {change.slope}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </List.Item>
            )}
        />
    )
}
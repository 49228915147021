import { useState } from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Alert, Button, Skeleton } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { compareDesc, format, formatISO } from "date-fns";
import "./style.css";
import UserAvailabilityStatus from "../user-availability-status/UserAvailabilityStatus";

const QUERY = gql`
    query GetUserAvailabilityHistory($userId: ID!, $date: Date!) {
        user(userId: $userId) {
            id
            firstName
            lastName
            availability(filter: {dateFrom: $date, dateTo: $date}) {
                id
                changes {
                    id
                    availability
                    comment
                    date
                    changedAt
                    updater {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

const TRUNCATE_ELEMENTS = 5;

export default function UserAvailabilityDayDetails(props) {
    const {
        userId,
        date,
        onClose,
    } = props;

    const [availabilityChangesShowAll, setAvailabilityChangesShowAll] = useState(false);
    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            userId,
            date: formatISO(date, { representation: 'date' }),
        }
    });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load user availability history"
            />
        );
    }

    const availabilityChangesSorted = [...data.user.availability?.[0]?.changes ?? []]
        .sort((a, b) => compareDesc(a.changedAt, b.changedAt));

    const availabilityChanges = availabilityChangesShowAll
        ? availabilityChangesSorted
        : availabilityChangesSorted.slice(0, TRUNCATE_ELEMENTS);

    const displayShowAll = !availabilityChangesShowAll && availabilityChangesSorted.length > TRUNCATE_ELEMENTS;

    return (
        <div className="user-availability-day-details-container">
            <div className="user-availability-day-details-header">
                <div className="user-availability-day-details-header-label">
                    <div className="user-availability-day-details-header-date">
                        {formatISO(date, { representation: 'date' })}
                    </div>
                    <div className="user-availability-day-details-header-user-name">
                        {data.user.firstName} {data.user.lastName}
                    </div>
                </div>
                <div className="user-availability-day-details-header-close">
                    <CloseOutlined
                        onClick={() => onClose()}
                    />
                </div>
            </div>
            <div className="user-availability-day-details-section">
                <div className="user-availability-day-details-changes-history">
                    {availabilityChanges.map(change => (
                        <div
                            className="user-availability-day-details-change"
                            key={change.id}
                        >
                            <UserAvailabilityStatus
                                availability={change.availability}
                                shape="square"
                                size={20}
                                showLabel
                            />
                            <div className="user-availability-day-details-change-changed-at">
                                {format(change.changedAt, 'yyyy-MM-dd HH:mm')}
                            </div>
                            <div className="user-availability-day-details-change-values">
                                {change.comment && (
                                    <div className="user-availability-day-details-change-comment">
                                        {change.comment}
                                    </div>
                                )}
                                {change.updater && (
                                    <div className="user-availability-day-details-change-updater">
                                        <Link to={`/users/${change.updater.id}`}>
                                            {change.updater.firstName} {change.updater.lastName}
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    {displayShowAll && (
                        <Button
                            onClick={() => setAvailabilityChangesShowAll(true)}
                            type="link"
                            block
                        >
                            Show all
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
import { Alert, Select, Skeleton } from "antd";
import { gql, useMutation, useQuery } from "@apollo/client";
import { format, formatISO, isAfter, isBefore, isEqual, startOfToday, startOfTomorrow } from "date-fns";
import { useAuth } from "auth";
import ReservationTimeSelect from "../reservation-time-select/ReservationTimeSelect";
import "./style.css";
import { dateRange } from "common/common";
import { Link } from "react-router-dom";
import JobCreateButton from "jobs-new/components/JobCreateButton";
import ReservationTagsSelect from "../reservation-tags-select/ReservationTagsSelect";

const RESERVATION_QUERY = gql`
    query GetReservationForToolbelt($reservationId: ID!) {
        reservation(reservationId: $reservationId) {
            id
            guestFirstName
            guestLastName
            numberOfGuests
            startDate
            endDate
            apartment {
                id
                name
            }
            checkinTime
            checkoutTime
            tags
        }
    }
`;

const JOBS_QUERY = gql`
    query GetJobsForToolbelt($filter: JobsNewFilter!) {
        jobsNew(filter: $filter) {
            id
            title
        }
    }
`;

const MUTATION = gql`
    mutation UpdateReservation($input: UpdateReservationInput!) {
        updateReservation(input: $input) {
            error {
                type
                message
            }
            reservation {
                id
                checkinTime
                checkoutTime
                tags
            }
        }
    }
`

export default function ReservationToolbelt(props) {
    const { reservationId } = props;

    const { data: reservationData, loading: reservationLoading, error: reservationError } = useQuery(RESERVATION_QUERY, {
        variables: {
            reservationId,
        },
    });
    const { data: jobsData, loading: jobsLoading, error: jobsError } = useQuery(JOBS_QUERY, {
        variables: {
            filter: {
                apartmentIds: [reservationData?.reservation?.apartment?.id],
                dates: dateRange(reservationData?.reservation?.startDate, reservationData?.reservation?.endDate),
            },
        },
        skip: !reservationData,
    })
    const [updateReservation] = useMutation(MUTATION);

    function handleCheckinTimeChange(value) {
        updateReservation({
            variables: {
                input: {
                    reservationId,
                    checkinTime: value ?? null,
                },
            },
        });
    }

    function handleCheckoutTimeChange(value) {
        updateReservation({
            variables: {
                input: {
                    reservationId,
                    checkoutTime: value ?? null,
                },
            },
        });
    }

    function handleTagsChange(value) {
        updateReservation({
            variables: {
                input: {
                    reservationId,
                    tags: value,
                },
            },
        });
    }

    function reservationInfo() {
        if (isEqual(reservationData.reservation.startDate, startOfTomorrow())) {
            return 'Starting tomorrow';
        }
        if (isEqual(reservationData.reservation.startDate, startOfToday())) {
            return 'Starting today';
        }
        if (isEqual(reservationData.reservation.endDate, startOfTomorrow())) {
            return 'Ending tomorrow';
        }
        if (isEqual(reservationData.reservation.endDate, startOfToday())) {
            return 'Ending today';
        }
        if (isAfter(reservationData.reservation.startDate, startOfToday())) {
            return 'Future reservation';
        }
        if (isBefore(reservationData.reservation.endDate, startOfToday())) {
            return 'Past reservation';
        }
    }

    return (
        <div className="toolbelt-container">
            {reservationLoading && (
                <Skeleton />
            )}
            {reservationError && (
                <Alert
                    type="error"
                    showIcon
                    message="Failed to load reservation"
                />
            )}
            {reservationData && (
                <>
                    <div className="apartment-name">
                        {reservationData.reservation.apartment.name}
                    </div>
                    <div className="guest-name">
                        {reservationData.reservation.guestFirstName} {reservationData.reservation.guestLastName}
                    </div>
                    <div className="number-of-guests">
                        {reservationData.reservation.numberOfGuests} {reservationData.reservation.numberOfGuests === 1 ? 'guest' : 'guests'}
                    </div>
                    <hr />
                    <div className="reservation-info">
                        {reservationInfo()}
                    </div>
                    <div className="reservation-date">
                        <div className="label">
                            Start date
                        </div>
                        <div className="value">
                            <span className="date">
                                {formatISO(reservationData.reservation.startDate, { representation: 'date' })}
                            </span>
                            <span className="weekday">
                                {format(reservationData.reservation.startDate, 'iii')}
                            </span>
                        </div>
                    </div>
                    <div className="reservation-date">
                        <div className="label">
                            End date
                        </div>
                        <div className="value">
                            <span className="date">
                                {formatISO(reservationData.reservation.endDate, { representation: 'date' })}
                            </span>
                            <span className="weekday">
                                {format(reservationData.reservation.endDate, 'iii')}
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div className="section">
                        <div className="label">
                            Check-in time
                        </div>
                        <div className="input">
                            <ReservationTimeSelect
                                timeFrom="13:00"
                                timeTo="23:30"
                                interval={30}
                                value={reservationData.reservation.checkinTime}
                                onChange={value => handleCheckinTimeChange(value)}
                            />
                        </div>
                    </div>
                    <div className="section">
                        <div className="label">
                            Check-out time
                        </div>
                        <div className="input">
                            <ReservationTimeSelect
                                timeFrom="06:00"
                                timeTo="13:00"
                                interval={30}
                                value={reservationData.reservation.checkoutTime}
                                onChange={value => handleCheckoutTimeChange(value)}
                            />
                        </div>
                    </div>
                    <div className="section">
                        <div className="label">
                            Tags
                        </div>
                        <div className="input">
                            <ReservationTagsSelect
                                value={reservationData.reservation.tags ?? []}
                                onChange={value => handleTagsChange(value)}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
            <hr />
            {jobsLoading && (
                <Skeleton />
            )}
            {jobsError && (
                <Alert
                    type="error"
                    showIcon
                    message="Failed to load jobs"
                />
            )}
            {jobsData && (
                <div className="jobs-container">
                    <div className="jobs-container-header">
                        <div className="jobs-container-header-title">
                            Jobs
                        </div>
                        <div className="jobs-container-header-button">
                            <JobCreateButton
                                preset={{
                                    location: { apartmentId: reservationData.reservation.apartment.id },
                                    availableReservations: reservationId,
                                }}
                            />
                        </div>
                    </div>
                    <div className="jobs-list">
                        {jobsData.jobsNew.length === 0 && (
                            <div className="no-jobs">
                                No jobs
                            </div>
                        )}
                        {jobsData.jobsNew.map(job => (
                            <Link
                                to={`/jobs-new/${job.id}`}
                                key={job.id}
                            >
                                {job.title}
                            </Link>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
import { Form } from "antd";
import { useEffect } from "react";
import UserWebMenuSelect from "users/components/user-web-menu-select/UserWebMenuSelect";

export default function UserGroupWebMenuForm(props) {
    const {
        userGroup,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            webMenu: userGroup?.webMenu,
        });
    }, [userGroup, form]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="webMenu"
                label="Menu"
            >
                <UserWebMenuSelect />
            </Form.Item>
        </Form>
    )
}
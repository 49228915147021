import { gql, useQuery } from "@apollo/client";
import { Alert, Select, Tooltip } from "antd";
import { useAuth } from "auth";

const QUERY = gql`
    query GetUserGroupsForSelect {
        userGroups {
            id
            name
        }
    }
`;

export default function UserGroupSelect(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    const { permissions } = useAuth();
    const { data, loading, error } = useQuery(QUERY);

    if (!permissions.includes('user_group:get')) {
        return (
            <Tooltip title="You don't have permissions to user groups">
                <Select
                    disabled
                    placeholder="User groups"
                    style={{
                        width: '100%',
                    }}
                    {...otherProps}
                />
            </Tooltip>
        );
    }

    if (loading) {
        return (
            <Select loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load user groups"
            />
        );
    }

    return (
        <Select
            options={
                [...data.userGroups ?? []]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(userGroup => ({
                        label: userGroup.name,
                        value: userGroup.id,
                    }))
            }
            value={value}
            onChange={value => onChange(value)}
            style={{
                width: '100%',
            }}
            {...otherProps}
        />
    );
}
export const RESERVATION_STATUS_REQUEST = 'request';
export const RESERVATION_STATUS_CONFIRMED = 'confirmed';
export const RESERVATION_STATUS_CANCELED = 'canceled';

export const RESERVATION_STATUSES = [
    { label: 'Request', value: RESERVATION_STATUS_REQUEST },
    { label: 'Confirmed', value: RESERVATION_STATUS_CONFIRMED },
    { label: 'Canceled', value: RESERVATION_STATUS_CANCELED },
]

export function calculatePrice({ datePrices, numberOfGuests, increasePriceAbove, increasePriceBy, cleaningPrice }) {
    const dailyGuestFee = Math.max(numberOfGuests - increasePriceAbove, 0) * increasePriceBy;
    return datePrices
        .reduce((sum, price) => sum + price + dailyGuestFee, cleaningPrice);
}
import { CalendarOutlined, CheckOutlined, ClockCircleOutlined, LinkOutlined, PlusOutlined, SendOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Input, List, Modal, Row, Select, Switch, Table, Timeline, Typography } from "antd";
import { Link } from "react-router-dom";
import { useState } from "react";
import { blue, green } from "@ant-design/colors";

export default function MessagesView() {
    const [isPlannedActionsVisible, setPlannedActionsVisible] = useState(false);

    function plannedActionsAvatar(status) {
        if (status === 'done') {
            return (
                <Avatar
                    icon={<CheckOutlined />}
                    style={{
                        backgroundColor: green.primary,
                    }}
                />
            );
        }

        if (status === 'pending') {
            return (
                <Avatar
                    icon={<ClockCircleOutlined />}
                    style={{
                        backgroundColor: blue.primary,
                    }}
                />
            );
        }
    }

    function plannedActionsItemActions(item) {
        if (item.cancellable) {
            return [
                <Link to="/messages">
                    Edit
                </Link>,
                <Switch key="switch" defaultChecked={true} />
            ];
        }

        return []
    }

    return (
        <Row
            gutter={[16, 16]}
            style={{
                padding: '16px',
            }}
        >
            <Col span={6}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Select
                            options={[
                                { label: 'Check-in today', value: 'checkinToday' },
                                { label: 'Check-in tomorrow', value: 'checkinTomorrow' },
                                { label: 'Check-out today', value: 'checkoutToday' },
                                { label: 'Check-out tomorrow', value: 'checkintTomorrow' },
                                { label: 'New reservations', value: 'newReservations' },
                                { label: 'Others', value: 'others' },
                            ]}
                            placeholder="Thread type"
                            style={{
                                width: '100%',
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'Apartment',
                                    render: item => (
                                        <Link
                                            to="/messages"
                                        >
                                            {item.apartment.name}
                                        </Link>
                                    )
                                }
                            ]}
                            dataSource={[
                                {
                                    apartment: {
                                        name: 'Rajska 3/106',
                                    },
                                },
                                {
                                    apartment: {
                                        name: 'Dietla 51/18',
                                    },
                                },
                                {
                                    apartment: {
                                        name: 'Dietla 51/18',
                                    },
                                },
                                {
                                    apartment: {
                                        name: 'Dietla 51/18',
                                    },
                                },
                                {
                                    apartment: {
                                        name: 'Dietla 51/18',
                                    },
                                },
                                {
                                    apartment: {
                                        name: 'Dietla 51/18',
                                    },
                                },
                                {
                                    apartment: {
                                        name: 'Dietla 51/18',
                                    },
                                },
                                {
                                    apartment: {
                                        name: 'Dietla 51/18',
                                    },
                                },
                                {
                                    apartment: {
                                        name: 'Dietla 51/18',
                                    },
                                },
                                {
                                    apartment: {
                                        name: 'Dietla 51/18',
                                    },
                                },
                            ]}
                            showHeader={false}
                            pagination={false}
                            rowSelection={true}
                            size='small'
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={12}>
                <Row gutter={[16, 16]}>
                    <Col
                        span={24}
                        style={{
                            padding: '0 8px',
                        }}
                    >
                        <Row
                            gutter={[16, 16]}
                            justify="space-between"
                            align="middle"
                        >
                            <Col>
                                <Typography.Title
                                    level={4}
                                    style={{
                                        margin: 0,
                                    }}
                                >
                                    Dietla 51/18 &middot; John Smith
                                </Typography.Title>
                            </Col>
                            <Col>
                                <Switch defaultChecked="true" />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Card>
                            <Row
                                gutter={[16, 16]}
                                style={{
                                    maxHeight: '450px',
                                    overflow: 'scroll',
                                }}
                            >
                                <Col span={18}>
                                    <Typography.Paragraph>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt bibendum ex, sed auctor magna bibendum a. Vestibulum accumsan justo urna, at tristique dui pretium lobortis.
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={18} offset={6}>
                                    <Typography.Paragraph>
                                        Nullam finibus enim eget ligula venenatis tincidunt. Integer cursus leo magna, vel volutpat diam euismod porttitor.
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={18}>
                                    <Typography.Paragraph>
                                        Praesent cursus pulvinar ullamcorper. Cras in neque justo. Nunc non leo nisl. Nulla malesuada dolor id orci porttitor condimentum. Morbi tempus felis leo, efficitur malesuada tellus malesuada at. Morbi sit amet nisl in nisl facilisis gravida. Phasellus vehicula arcu suscipit semper maximus. Ut quis dictum neque.
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={18} offset={6}>
                                    <Typography.Paragraph>
                                        Fusce imperdiet quam a odio consequat ultrices.
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={18}>
                                    <Typography.Paragraph>
                                        Suspendisse in diam elit. Aenean quis nibh a mi gravida maximus et faucibus dolor. Morbi lobortis feugiat ipsum in semper.
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={18} offset={6}>
                                    <Typography.Paragraph>
                                        Sed vitae libero non erat eleifend tempor non non odio. Etiam molestie tincidunt dolor, vel ullamcorper risus lobortis id. Duis commodo iaculis orci vel placerat.
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={18}>
                                    <Typography.Paragraph>
                                        Integer fermentum mi eget vulputate hendrerit. Ut ut metus ut velit viverra dapibus
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={18} offset={6}>
                                    <Typography.Paragraph>
                                        Curabitur et quam sit amet turpis commodo iaculis
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card
                            bodyStyle={{
                                padding: '16px',
                            }}
                        >
                            <Row gutter={[8, 8]}>
                                <Col flex="auto">
                                    <Input />
                                </Col>
                                <Col flex="none">
                                    <Button
                                        icon={<UnorderedListOutlined />}
                                    />
                                </Col>
                                <Col flex="none">
                                    <Button
                                        icon={<SendOutlined />}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col span={6}>
                <Card>
                    <Row gutter={[8, 16]}>
                        <Col span={24}>
                            <Typography.Title
                                level={5}
                            >
                                Today - 16 Aug
                            </Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Typography.Text>
                                3 adults &middot; 2 days &middot; 780 zł
                            </Typography.Text>
                        </Col>
                        <Col span={12}>
                            <Select
                                options={[
                                    { value: '9:00' },
                                    { value: '9:30' },
                                    { value: '10:00' },
                                    { value: '10:30' },
                                    { value: '11:00' },
                                    { value: '11:30' },
                                    { value: '12:00' },
                                    { value: '12:30' },
                                    { value: '13:00' },
                                    { value: '13:30' },
                                ]}
                                placeholder="Check-in"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <Select
                                options={[
                                    { value: '9:00' },
                                    { value: '9:30' },
                                    { value: '10:00' },
                                    { value: '10:30' },
                                    { value: '11:00' },
                                    { value: '11:30' },
                                    { value: '12:00' },
                                    { value: '12:30' },
                                    { value: '13:00' },
                                    { value: '13:30' },
                                ]}
                                placeholder="Check-out"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            <Typography.Title
                                level={5}
                            >
                                Tatiana Lutska
                            </Typography.Title>
                            <Timeline
                                style={{
                                    marginTop: '32px',
                                    marginBottom: '-50px',
                                }}
                            >
                                <Timeline.Item
                                    color='green'
                                >
                                    <Row justify="space-between">
                                        <Col>
                                            Dietla 68/7
                                        </Col>
                                        <Col>
                                            3 h
                                        </Col>
                                    </Row>
                                </Timeline.Item>
                                <Timeline.Item
                                    color='green'
                                >
                                    <Row justify="space-between">
                                        <Col>
                                            Dietla 51/14A
                                        </Col>
                                        <Col>
                                            2 h
                                        </Col>
                                    </Row>
                                </Timeline.Item>
                                <Timeline.Item
                                    color='blue'
                                >
                                    <Row justify="space-between">
                                        <Col>
                                            Dietla 51/15A
                                        </Col>
                                        <Col>
                                            34 m
                                        </Col>
                                    </Row>
                                </Timeline.Item>
                                <Timeline.Item
                                    color='gray'
                                >
                                    Dietla 51/18
                                </Timeline.Item>
                                <Timeline.Item
                                    color='gray'
                                >
                                    Sarego 16/16
                                </Timeline.Item>
                            </Timeline>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <Row justify="space-between">
                                        <Col>
                                            <Typography.Title level={5}>
                                                Jobs
                                            </Typography.Title>
                                        </Col>
                                        <Col>
                                            <PlusOutlined />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <List>
                                        <List.Item>
                                            Łózeczko dla dziecka
                                        </List.Item>
                                        <List.Item>
                                            Pościelić dwa osobne łózka
                                        </List.Item>
                                    </List>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Button
                                icon={<CalendarOutlined />}
                                block
                                onClick={() => setPlannedActionsVisible(true)}
                            >
                                Planned actions
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Button
                                icon={<LinkOutlined />}
                                block
                            >
                                Airbnb
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Modal
                visible={isPlannedActionsVisible}
                title="Planned actions"
                onCancel={() => setPlannedActionsVisible(false)}
                onOk={() => setPlannedActionsVisible(false)}
            >
                <List
                    dataSource={[
                        {
                            title: 'Send welcome message',
                            timestamp: '3 days ago',
                            status: 'done',
                            cancellable: false,
                        },
                        {
                            title: 'Send check-in instructions',
                            timestamp: '4 hours ago',
                            status: 'done',
                            cancellable: false,
                        },
                        {
                            title: 'Send check-out reminder',
                            timestamp: 'in 3 days',
                            status: 'pending',
                            cancellable: true,
                        },
                        {
                            title: 'Add guest review',
                            timestamp: 'in 5 days',
                            status: 'pending',
                            cancellable: true,
                        },
                    ]}
                    renderItem={item => (
                        <List.Item
                            actions={plannedActionsItemActions(item)}
                        >
                            <List.Item.Meta
                                title={item.title}
                                description={item.timestamp}
                                avatar={plannedActionsAvatar(item.status)}
                            />
                        </List.Item>
                    )}
                />
            </Modal>
        </Row>
    );
}
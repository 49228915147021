import { Button, Card, Col, List, PageHeader, Row } from "antd";
import { CREATE_STATISTICS_VIEW_MUTATION, GET_STATISTICS_VIEWS_QUERY } from "./graphql";
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@apollo/client";

import StatisticsViewsList from "./StatisticsViewsList";

export default function StatisticsViewsListView() {
    const navigate = useNavigate();

    const { data: statisticsViewsData, loading: statisticsViewsLoading } = useQuery(GET_STATISTICS_VIEWS_QUERY);
    const [createStatisticsView, { loading: createStatisticsViewLoading }] = useMutation(CREATE_STATISTICS_VIEW_MUTATION, {
        onCompleted(response) {
            const statisticsViewId = response.createStatisticsView.statisticsView.id;
            navigate(`/statistics/${statisticsViewId}`);
        },
        refetchQueries: [
            {
                query: GET_STATISTICS_VIEWS_QUERY,
            }
        ]
    });

    function handleAddStatisticsView() {
        createStatisticsView({
            variables: {
                input: {
                    name: 'New statistics',
                },
            },
        });
    }

    return (
        <PageHeader title="Statistics views">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card
                        title="Custom statistics"
                    >
                        <List>
                            <List.Item>
                                <Link to="/statistics/100-days-comparison">
                                    150 days comparison
                                </Link>
                            </List.Item>
                            <List.Item>
                                <Link to="/statistics/100-days-comparison-30-days">
                                    150 days comparison by 30 days
                                </Link>
                            </List.Item>
                        </List>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card>
                        <StatisticsViewsList
                            statisticsViews={[...statisticsViewsData?.statisticsViews || []]}
                            loading={statisticsViewsLoading}
                        />
                    </Card>
                </Col>
                <Col span={24}>
                    <Button
                        type="dashed"
                        block
                        onClick={() => handleAddStatisticsView()}
                        loading={createStatisticsViewLoading}
                    >
                        Add view
                    </Button>
                </Col>
            </Row>
        </PageHeader>
    )
}
import { gql, useLazyQuery } from "@apollo/client";
import { useState, useEffect, useRef } from "react";
import { apolloClient } from 'apollo';

const GET_TASK_QUERY = gql`
    query GetTask($taskId: ID!) {
        task(taskId: $taskId) {
            id
            status
            result
        }
    }
`;

const GET_TASKS_QUERY = gql`
    query GetTasks($taskIds: [ID]!) {
        tasks(filter: {taskIds: $taskIds}) {
            id
            status
            result
        }
    }
`;

export function taskPromise(taskId) {
    if (!taskId) {
        return Promise.resolve();
    }

    var promiseResolve;
    var promiseReject;
    var intervalId;

    function callback() {
        apolloClient.query({
            query: GET_TASK_QUERY,
            variables: {
                taskId,
            },
            fetchPolicy: 'network-only',
        })
            .then(response => {
                const status = response.data.task.status;
                if (status === 'error') {
                    window.clearInterval(intervalId);
                    promiseReject();
                }
                if (status === 'finished') {
                    window.clearInterval(intervalId);
                    promiseResolve();
                }
            });
    }

    intervalId = window.setInterval(callback, 1000);

    return new Promise((resolve, reject) => {
        promiseResolve = resolve;
        promiseReject = reject;
    });
}


export function useWaitForTask(taskId, options = {}) {
    const optionsRef = useRef(options);

    const [called, setCalled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [finished, setFinished] = useState(false);
    const [getTask, { data: taskData }] = useLazyQuery(GET_TASK_QUERY);

    useEffect(() => {
        if (taskId) {
            setLoading(true);
            setError(false);
            const intervalId = window.setInterval(() => {
                setCalled(true);
                getTask({
                    variables: {
                        taskId,
                    },
                    fetchPolicy: 'network-only',
                    onCompleted(response) {
                        const status = response.task.status;
                        if (status === 'error') {
                            window.clearInterval(intervalId);
                            setError(true);
                            setLoading(false);
                            setFinished(true);
                            if (optionsRef.current.onCompleted) {
                                optionsRef.current.onCompleted(response.task);
                            }
                        }
                        if (status === 'finished') {
                            window.clearInterval(intervalId);
                            setLoading(false);
                            setFinished(true);
                            if (optionsRef.current.onCompleted) {
                                optionsRef.current.onCompleted(response.task);
                            }
                        }
                    },
                })
            }, 1000);

            return () => {
                window.clearInterval(intervalId);
                setLoading(false);
                setError(false);
            }
        }
    }, [taskId, getTask]);

    return {
        called,
        loading,
        finished,
        error,
        result: taskData?.task?.result,
    }
}


export function useWaitForTasks(taskIds, options = {}) {
    const taskIdsJson = JSON.stringify(taskIds);
    const optionsRef = useRef(options);

    const [called, setCalled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState(false);
    const [getTasks, { data: taskData }] = useLazyQuery(GET_TASKS_QUERY);

    useEffect(() => {
        const taskIds_ = JSON.parse(taskIdsJson);

        if (taskIds_ && taskIds_.length > 0) {
            setLoading(true);
            setError(false);
            const intervalId = window.setInterval(() => {
                setCalled(true);
                getTasks({
                    variables: {
                        taskIds: taskIds_,
                    },
                    fetchPolicy: 'network-only',
                    onCompleted(response) {
                        if (response.tasks.some(task => task.status === 'error')) {
                            window.clearInterval(intervalId);
                            setError(true);
                            setLoading(false);
                            setFinished(true);
                            if (optionsRef.current.onCompleted) {
                                optionsRef.current.onCompleted(response.tasks);
                            }
                        }
                        if (response.tasks.every(task => task.status === 'finished')) {
                            window.clearInterval(intervalId);
                            setLoading(false);
                            setFinished(true);
                            if (optionsRef.current.onCompleted) {
                                optionsRef.current.onCompleted(response.tasks);
                            }
                        }
                    },
                })
            }, 1000);

            return () => {
                window.clearInterval(intervalId);
                setLoading(false);
                setError(false);
            }
        }
    }, [taskIdsJson, getTasks]);

    return {
        called,
        loading,
        finished,
        error,
        result: taskData?.task?.result,
    }
}
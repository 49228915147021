import { Button } from "antd";
import JobStatus from "jobs-new/components/JobStatus";

export default function JobStatusButton(props) {
    const {
        status,
        loading,
        ...otherProps
    } = props;

    return (
        <Button
            loading={loading}
            {...otherProps}
        >
            <JobStatus
                status={status}
            />
        </Button>
    );
}
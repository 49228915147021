import { Form, Input, Switch } from "antd";
import { useEffect } from "react";

export default function UserAvailabilityTypeForm(props) {
    const {
        form,
        userAvailabilityType,
        disabled,
        disableAvailability,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            availability: userAvailabilityType?.availability,
            isAvailable: userAvailabilityType?.isAvailable ?? false,
            label: userAvailabilityType?.label,
            color: userAvailabilityType?.color,
        })
    }, [form, userAvailabilityType]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="availability"
                label="Availability"
                rules={[{ required: true, message: 'Availability is required' }]}
            >
                <Input
                    disabled={disableAvailability || disabled}
                />
            </Form.Item>
            <Form.Item
                name="isAvailable"
                label="Is available"
                valuePropName="checked"
                rules={[{ required: true, message: 'Is available is required' }]}
            >
                <Switch
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="label"
                label="Label"
                rules={[{ required: true, message: 'Label is required' }]}
            >
                <Input
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="color"
                label="Color"
                rules={[{ required: true, message: 'Color is required' }]}
            >
                <Input
                    disabled={disabled}
                />
            </Form.Item>
        </Form>
    );
}
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Card, Tag } from "antd";
import StatusButton from "./StatusButton";
import CardTitle from "./CardTitle";
import { formatISO } from "date-fns";
import JobStatus from "jobs-new/components/JobStatus";
import { Link } from "react-router-dom";
import "./style.css";
import { useAuth } from "auth";

const QUERY = gql`
    query GetCleaningInformation($cleaningGroupApartmentId: ID!, $date: Date!) {
        cleaningGroupApartment(cleaningGroupApartmentId: $cleaningGroupApartmentId) {
            id
            checkinTime
            checkoutTime
            note
            numberOfGuests
            status
            tags
            apartment {
                id
                name
                jobsNew(filter: {dates: [$date]}) {
                    id
                    title
                    status
                }
            }
            lastStatusChange {
                id
                updatedAt
            }
            storage {
                id
                name
            }
        }
    }
`;

const UPDATE_STATUS_MUTATION = gql`
    mutation UpdateCleaningGroupApartmentStatus($input: UpdateCleaningGroupApartmentStatusInput!) {
        updateCleaningGroupApartmentStatus(input: $input) {
            error {
                type
                message
            }
            cleaningGroupApartment {
                id
                status
                lastStatusChange {
                    id
                    updatedAt
                }
            }
        }
    }
`;

export default function CleaningInformationApartmentCard(props) {
    const {
        cleaningGroupApartmentId,
        date,
    } = props;

    const { permissions } = useAuth();
    const canEdit = permissions.includes('cleaning:set:other');

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            cleaningGroupApartmentId,
            date: formatISO(date, { representation: 'date' }),
        },
    });
    const [updateStatus] = useMutation(UPDATE_STATUS_MUTATION);

    if (loading) {
        return (
            <Card loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load cleaning information"
            />
        );
    }

    function handleUpdateStatus(status) {
        updateStatus({
            variables: {
                input: {
                    cleaningGroupApartmentId,
                    status,
                },
            },
        });
    }

    return (
        <Card
            title={
                <CardTitle
                    cleaningGroupApartment={data.cleaningGroupApartment}
                />
            }
            extra={
                <StatusButton
                    status={data.cleaningGroupApartment.status}
                    disabled={!canEdit}
                    onStatusChange={status => handleUpdateStatus(status)}
                />
            }
        >
            <div className="cleaning-information-apartment-card-container">
                <div className="cleaning-information-apartment-card-item">
                    <div className="cleaning-information-apartment-card-label">
                        Check-out
                    </div>
                    {data.cleaningGroupApartment.checkoutTime && (
                        <div className="cleaning-information-apartment-card-value">
                            {data.cleaningGroupApartment.checkoutTime}
                        </div>
                    )}
                    {!data.cleaningGroupApartment.checkoutTime && (
                        <div className="cleaning-information-apartment-card-unknown">
                            unknown
                        </div>
                    )}
                </div>
                <div className="cleaning-information-apartment-card-item">
                    <div className="cleaning-information-apartment-card-label">
                        Check-in
                    </div>
                    {data.cleaningGroupApartment.checkinTime && (
                        <div className="cleaning-information-apartment-card-value">
                            {data.cleaningGroupApartment.checkinTime}
                        </div>
                    )}
                    {!data.cleaningGroupApartment.checkinTime && (
                        <div className="cleaning-information-apartment-card-unknown">
                            unknown
                        </div>
                    )}
                </div>
                <div className="cleaning-information-apartment-card-item">
                    <div className="cleaning-information-apartment-card-label">
                        Arriving
                    </div>
                    <div className="cleaning-information-apartment-card-value">
                        {data.cleaningGroupApartment.numberOfGuests} guests
                    </div>
                </div>
                <div className="cleaning-information-apartment-card-item">
                    <div className="cleaning-information-apartment-card-label">
                        Storage
                    </div>
                    <div className="cleaning-information-apartment-card-value">
                        {data.cleaningGroupApartment.storage.name}
                    </div>
                </div>
                {data.cleaningGroupApartment.note && (
                    <div className="cleaning-information-apartment-card-item">
                        <div className="cleaning-information-apartment-card-label">
                            Note
                        </div>
                        <div className="cleaning-information-apartment-card-value">
                            {data.cleaningGroupApartment.note}
                        </div>
                    </div>
                )}
                {data.cleaningGroupApartment.tags?.length > 0 && (
                    <div className="cleaning-information-apartment-card-item">
                        <div className="cleaning-information-apartment-card-label">
                            Tags
                        </div>
                        <div className="cleaning-information-apartment-card-value">
                            {[...data.cleaningGroupApartment.tags].map(tag => (
                                <Tag key={tag}>
                                    {tag}
                                </Tag>
                            ))}
                        </div>
                    </div>
                )}
                {data.cleaningGroupApartment.apartment.jobsNew.length > 0 && (
                    <div className="cleaning-information-apartment-jobs-container">
                        <div className="cleaning-information-apartment-jobs-title">
                            Jobs
                        </div>
                        {data.cleaningGroupApartment.apartment.jobsNew.map(job => (
                            <div
                                className="cleaning-information-apartment-job"
                                key={job.id}
                            >
                                <div className="cleaning-information-apartment-job-status">
                                    <JobStatus status={job.status} />
                                </div>
                                <div className="cleaning-information-apartment-job-title">
                                    <Link to={`/jobs-new/${job.id}`}>
                                        {job.title}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Card>
    );
}
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Input, InputNumber, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';

export default function EditableInput(props) {
    const { value, onChange, editable, number } = props;

    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => setCurrentValue(value), [value]);

    function onCancel() {
        setIsEditing(false);
        setCurrentValue(value);
    }

    function onSave() {
        setIsEditing(false);
        onChange(currentValue);
    }

    if (isEditing) {
        if (number) {
            return (
                <InputNumber
                    value={currentValue}
                    onChange={e => setCurrentValue(e.target.value)}
                    onPressEnter={() => onSave()}
                    addonAfter={
                        <Space>
                            <CloseOutlined onClick={() => onCancel()} />
                            <CheckOutlined onClick={() => onSave()} />
                        </Space>
                    }
                />
            );
        } else {
            return (
                <Input
                    value={currentValue}
                    onChange={e => setCurrentValue(e.target.value)}
                    onPressEnter={() => onSave()}
                    addonAfter={
                        <Space>
                            <CloseOutlined onClick={() => onCancel()} />
                            <CheckOutlined onClick={() => onSave()} />
                        </Space>
                    }
                />
            );
        }
    } else {
        return (
            <Space>
                {!value && (
                    <Typography.Text disabled>
                        None
                    </Typography.Text>
                )}
                {value && (
                    <Typography.Text>
                        {value}
                    </Typography.Text>
                )}
                {editable && (
                    <EditOutlined
                        onClick={() => setIsEditing(true)}
                    />
                )}
            </Space>
        )
    }
}

export function generateThumbnail(file) {
    return new Promise(resolve => {
        if (!file.type || file.type.indexOf('image/') !== 0) {
            resolve('');
            return;
        }

        const canvas = document.createElement('canvas');
        canvas.width = 200;
        canvas.height = 200;
        const ctx = canvas.getContext('2d');

        const img = new Image();
        img.onload = () => {
            const { width, height } = img;

            let sx = 0;
            let sy = 0;
            let sWidth = width;
            let sHeight = height;
            const dx = 0;
            const dy = 0;
            const dWidth = 200;
            const dHeight = 200;

            if (width < height) {
                sy = (height - width) / 2;
                sHeight = width;
            } else {
                sx = (width - height) / 2;
                sWidth = height;
            }

            ctx.drawImage(img, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
            const dataURL = canvas.toDataURL();

            resolve(dataURL);
        };

        img.src = window.URL.createObjectURL(file);
    });
}
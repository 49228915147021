import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Col, Input, Row, Space, Typography } from "antd";
import { useState } from "react";

export default function WeekdayDelta(props) {
    const { weekdayDelta, isCreating, onSave, onCancel, onDelete } = props;

    const [isEditing, setIsEditing] = useState(isCreating);
    const [name, setName] = useState(weekdayDelta?.name);
    const [weekdayDelta0, setWeekdayDelta0] = useState(weekdayDelta?.weekdayDeltas[0]);
    const [weekdayDelta1, setWeekdayDelta1] = useState(weekdayDelta?.weekdayDeltas[1]);
    const [weekdayDelta2, setWeekdayDelta2] = useState(weekdayDelta?.weekdayDeltas[2]);
    const [weekdayDelta3, setWeekdayDelta3] = useState(weekdayDelta?.weekdayDeltas[3]);
    const [weekdayDelta4, setWeekdayDelta4] = useState(weekdayDelta?.weekdayDeltas[4]);
    const [weekdayDelta5, setWeekdayDelta5] = useState(weekdayDelta?.weekdayDeltas[5]);
    const [weekdayDelta6, setWeekdayDelta6] = useState(weekdayDelta?.weekdayDeltas[6]);

    function toggleEdit() {
        if (isEditing) {
            if (!isCreating) {
                setName(weekdayDelta.name);
                setWeekdayDelta0(weekdayDelta.weekdayDeltas[0]);
                setWeekdayDelta1(weekdayDelta.weekdayDeltas[1]);
                setWeekdayDelta2(weekdayDelta.weekdayDeltas[2]);
                setWeekdayDelta3(weekdayDelta.weekdayDeltas[3]);
                setWeekdayDelta4(weekdayDelta.weekdayDeltas[4]);
                setWeekdayDelta5(weekdayDelta.weekdayDeltas[5]);
                setWeekdayDelta6(weekdayDelta.weekdayDeltas[6]);
                setIsEditing(false);
            }

            if (onCancel) {
                onCancel();
            }
        }
        else {
            setIsEditing(true);
        }
    }

    function handleSave() {
        if (onSave) {
            onSave({
                name,
                weekdayDeltas: [
                    parseInt(weekdayDelta0),
                    parseInt(weekdayDelta1),
                    parseInt(weekdayDelta2),
                    parseInt(weekdayDelta3),
                    parseInt(weekdayDelta4),
                    parseInt(weekdayDelta5),
                    parseInt(weekdayDelta6),
                ],
            });
        }

        setIsEditing(false);
    }

    function handleDelete() {
        if (onDelete) {
            onDelete();
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={7}>
                {!isEditing && (
                    <Typography.Text>
                        {weekdayDelta.name}
                    </Typography.Text>
                )}
                {isEditing && (
                    <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                )}
            </Col>
            <Col span={2}>
                {!isEditing && (
                    <Typography.Text>
                        {weekdayDelta.weekdayDeltas[0]}
                    </Typography.Text>
                )}
                {isEditing && (
                    <Input
                        value={weekdayDelta0}
                        onChange={e => setWeekdayDelta0(e.target.value)}
                    />
                )}
            </Col>
            <Col span={2}>
                {!isEditing && (
                    <Typography.Text>
                        {weekdayDelta.weekdayDeltas[1]}
                    </Typography.Text>
                )}
                {isEditing && (
                    <Input
                        value={weekdayDelta1}
                        onChange={e => setWeekdayDelta1(e.target.value)}
                    />
                )}
            </Col>
            <Col span={2}>
                {!isEditing && (
                    <Typography.Text>
                        {weekdayDelta.weekdayDeltas[2]}
                    </Typography.Text>
                )}
                {isEditing && (
                    <Input
                        value={weekdayDelta2}
                        onChange={e => setWeekdayDelta2(e.target.value)}
                    />
                )}
            </Col>
            <Col span={2}>
                {!isEditing && (
                    <Typography.Text>
                        {weekdayDelta.weekdayDeltas[3]}
                    </Typography.Text>
                )}
                {isEditing && (
                    <Input
                        value={weekdayDelta3}
                        onChange={e => setWeekdayDelta3(e.target.value)}
                    />
                )}
            </Col>
            <Col span={2}>
                {!isEditing && (
                    <Typography.Text>
                        {weekdayDelta.weekdayDeltas[4]}
                    </Typography.Text>
                )}
                {isEditing && (
                    <Input
                        value={weekdayDelta4}
                        onChange={e => setWeekdayDelta4(e.target.value)}
                    />
                )}
            </Col>
            <Col span={2}>
                {!isEditing && (
                    <Typography.Text>
                        {weekdayDelta.weekdayDeltas[5]}
                    </Typography.Text>
                )}
                {isEditing && (
                    <Input
                        value={weekdayDelta5}
                        onChange={e => setWeekdayDelta5(e.target.value)}
                    />
                )}
            </Col>
            <Col span={2}>
                {!isEditing && (
                    <Typography.Text>
                        {weekdayDelta.weekdayDeltas[6]}
                    </Typography.Text>
                )}
                {isEditing && (
                    <Input
                        value={weekdayDelta6}
                        onChange={e => setWeekdayDelta6(e.target.value)}
                    />
                )}
            </Col>
            <Col span={3}>
                <Row align="end">
                    <Col>
                        {isEditing && (
                            <Space>
                                <CheckOutlined
                                    onClick={() => handleSave()}
                                />
                                <CloseOutlined
                                    onClick={() => toggleEdit()}
                                />
                            </Space>
                        )}
                        {!isEditing && (
                            <Space>
                                <EditOutlined
                                    onClick={() => toggleEdit()}
                                />
                                <DeleteOutlined
                                    onClick={() => handleDelete()}
                                />
                            </Space>
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
import { Form, Select } from "antd";
import { useEffect } from "react";

export default function SettingsForm(props) {
    const {
        form,
        settings,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            dayValue: settings?.dayValue,
            hideDayValueOnReservation: settings?.hideDayValueOnReservation,
            hideDayValueOnUnavailable: settings?.hideDayValueOnUnavailable,
            reservationValue: settings?.reservationValue,
        });
    }, [form, settings]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="dayValue"
                label="Day value"
            >
                <Select
                    options={[
                        {
                            label: 'None',
                            value: 'none',
                        },
                        {
                            label: 'Price',
                            value: 'price',
                        },
                        {
                            label: 'Min stay',
                            value: 'minStay',
                        },
                    ]}
                    style={{
                        width: '150px',
                    }}
                />
            </Form.Item>
            <Form.Item
                name="reservationValue"
                label="Reservation value"
            >
                <Select
                    options={[
                        {
                            label: 'None',
                            value: 'none',
                        },
                        {
                            label: 'Guest name',
                            value: 'guestName',
                        },
                        {
                            label: 'Number of guests',
                            value: 'numberOfGuests',
                        },
                        {
                            label: 'Price',
                            value: 'price',
                        },
                        {
                            label: 'Booking window',
                            value: 'bookingWindow',
                        },
                    ]}
                    style={{
                        width: '150px',
                    }}
                />
            </Form.Item>
        </Form>
    );
}
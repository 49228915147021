import { useMemo } from "react";
import { useNavigate } from "react-router-dom"
import { gql, useQuery } from "@apollo/client";
import { Dropdown } from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";

const QUERY = gql`
    query GetJobsPresets {
        organization(organizationId: "self") {
            id
            jobsPresets {
                id
                name
                preset
            }
        }
        user(userId: "self") {
            id
            jobsPresets {
                id
                name
                preset
            }
            groups {
                id
                jobsPresets {
                    id
                    name
                    preset
                }
            }
        }
    }
`;

export default function JobCreateButton(props) {
    const {
        preset,
    } = props;

    const navigate = useNavigate();

    const { data, loading } = useQuery(QUERY);

    const organizationPresets = useMemo(() =>
        [...data?.organization?.jobsPresets ?? []]
            .map(preset => ({
                label: preset.name,
                key: `organization:${preset.id}`,
                preset: preset.preset,
            })),
        [data]);

    const userPresets = useMemo(() =>
        [...data?.user?.jobsPresets ?? []]
            .map(preset => ({
                label: preset.name,
                key: `user:${preset.id}`,
                preset: preset.preset,
            })),
        [data]);

    const userGroupsPresets = useMemo(() =>
        [...data?.user?.groups ?? []]
            .map(group => group.jobsPresets)
            .flat()
            .map(preset => ({
                label: preset.name,
                key: `user-group:${preset.id}`,
                preset: preset.preset,
            })),
        [data]);

    const presets = useMemo(() =>
        [
            ...[
                ...organizationPresets,
                ...userPresets,
                ...userGroupsPresets,
            ]
                .sort((a, b) => a.label.localeCompare(b.label)),
        ],
        [organizationPresets, userPresets, userGroupsPresets]);

    function handlePresetClick(key) {
        const item = presets.find(item => item.key === key);
        navigate('/jobs-new/create/preset', {
            state: {
                label: item.label,
                preset: { ...(preset ?? {}), ...item.preset },
            },
        });
    }

    function handleButtonClick() {
        if (preset) {
            navigate('/jobs-new/create/preset', {
                state: {
                    preset,
                },
            });
        }
        else {
            navigate('/jobs-new/create');
        }
    }

    return (
        <Dropdown.Button
            icon={loading ? <LoadingOutlined /> : <DownOutlined />}
            type="primary"
            onClick={() => handleButtonClick()}
            menu={{
                items: presets,
                onClick: ({ key }) => handlePresetClick(key),
                style: {
                    minWidth: '150px',
                }
            }}
        >
            Create job
        </Dropdown.Button>
    );
}
import { STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING, STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING } from "statistics/common";
import { Table, Tooltip } from "antd";
import { formatISO } from "date-fns";

export default function StatisticsTable(props) {
    const { referenceDate, fieldNames, series, statistics, statisticsComparison, comparisonEnabled, loading } = props;

    const baseColumns = [
        {
            title: "Interval",
            dataIndex: "interval",
            render: interval => formatISO(interval, { representation: 'date' }),
            sorter: (a, b) => a.interval.localeCompare(b.interval),
            // defaultSortOrder: referenceDate === 'accommodationDate' ? 'ascend' : 'descend',
        },
    ];

    if (series.length > 0) {
        baseColumns.push({
            title: "Series",
            dataIndex: "apartmentGroupName",
            sorter: {
                compare: (a, b) => a.apartmentGroupName.localeCompare(b.apartmentGroupName),
                multiple: 1,
            },
        })
    }

    function renderPercent(value) {
        if (comparisonEnabled) {
            const change = Math.round((value[0] - value[1]) / value[1] * 100);
            const changeStr = change > 0 ? '+' + change : change;
            return (
                <Tooltip
                    title={`${value[1]} → ${value[0]}`}
                >
                    {Math.round(value[0] * 100) + '% (' + changeStr + '%)'}
                </Tooltip>
            )

        }
        else {
            return Math.round(value * 100) + '%';
        }
    }

    function renderDecimal(value) {
        if (comparisonEnabled) {
            const change = Math.round((value[0] - value[1]) / value[1] * 100);
            const changeStr = change > 0 ? '+' + change : change;
            return (
                <Tooltip
                    title={`${value[1]} → ${value[0]}`}
                >
                    {value[0].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + changeStr + '%)'}
                </Tooltip>
            );
        }
        else {
            return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    }

    function renderInteger(value) {
        if (comparisonEnabled) {
            const change = Math.round((value[0] - value[1]) / value[1] * 100);
            const changeStr = change > 0 ? '+' + change : change;
            return (
                <Tooltip
                    title={`${value[1]} → ${value[0]}`}
                >
                    {value[0] + ' (' + changeStr + '%)'}
                </Tooltip>
            );
        }
        else {
            return value;
        }
    }

    const fieldNameMapping = referenceDate === 'accommodationDate'
        ? STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING
        : STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING;

    const TABLE_COLUMNS_CONFIG = {
        bookedDays: {
            render: value => renderInteger(value),
        },
        availableDays: {
            render: value => renderInteger(value),
        },
        bookingRate: {
            render: value => renderPercent(value),
        },
        bookingWindowMean: {
            render: value => renderDecimal(value),
        },
        numberOfGuestsMean: {
            render: value => renderDecimal(value),
        },
        numberOfGuestsArriving: {
            render: value => renderInteger(value),
        },
        numberOfGuestsDeparting: {
            render: value => renderInteger(value),
        },
        accommodationPriceTotal: {
            render: value => renderDecimal(value),
        },
        dailyAccommodationPriceMean: {
            render: value => renderDecimal(value),
        },
        basePriceTotal: {
            render: value => renderDecimal(value),
        },
        dailyBasePriceMean: {
            render: value => renderDecimal(value),
        },
        cleaningPriceTotal: {
            render: value => renderDecimal(value),
        },
        dailyCleaningPriceMean: {
            render: value => renderDecimal(value),
        },
        commissionTotal: {
            render: value => renderDecimal(value),
        },
        dailyCommissionMean: {
            render: value => renderDecimal(value),
        },
        revenueNetTotal: {
            render: value => renderDecimal(value),
        },
        revenueGrossTotal: {
            render: value => renderDecimal(value),
        },
        vatAmountTotal: {
            render: value => renderDecimal(value),
        },
        dailyRevenueNetMean: {
            render: value => renderDecimal(value),
        },
        dailyRevenueGrossMean: {
            render: value => renderDecimal(value),
        },
        revenueGrossAfterCommissionTotal: {
            render: value => renderDecimal(value),
        },
        dailyRevenueGrossAfterCommissionMean: {
            render: value => renderDecimal(value),
        },
        expensesNetTotal: {
            render: value => renderDecimal(value),
        },
        expensesGrossTotal: {
            render: value => renderDecimal(value),
        },
        incomeNetTotal: {
            render: value => renderDecimal(value),
        },
        reservationsCount: {
            render: value => renderInteger(value),
        },
        reservationLengthTotal: {
            render: value => renderInteger(value),
        },
        incomeGrossTotal: {
            render: value => renderDecimal(value),
        },
        reservationLengthMean: {
            render: value => renderDecimal(value),
        },
        numberOfGuestsTotal: {
            render: value => renderInteger(value),
        },
        accommodationPriceMean: {
            render: value => renderDecimal(value),
        },
        cleaningPriceMean: {
            render: value => renderDecimal(value),
        },
        commissionMean: {
            render: value => renderDecimal(value),
        },
        revenueNetMean: {
            render: value => renderDecimal(value),
        },
        revenueGrossMean: {
            render: value => renderDecimal(value),
        },
    };

    function sorter(a, b, fieldName) {
        if (comparisonEnabled) {
            return a[fieldName][0] - b[fieldName][0];
        }
        else {
            return a[fieldName] - b[fieldName];
        }
    }

    const columns = fieldNames.map(fieldName => ({
        title: fieldNameMapping[fieldName],
        dataIndex: fieldName,
        align: 'right',
        sorter: (a, b) => sorter(a, b, fieldName),
        ...TABLE_COLUMNS_CONFIG[fieldName]
    }));

    function buildComparisonData(data, dataComparison) {
        const excludedKeys = [
            'interval',
            'apartmentGroupName',
        ];

        return data.map((item, i) => {
            return Object.fromEntries(
                Object.entries(item).map(([key, value]) => ([
                    key,
                    excludedKeys.includes(key) ? value : [value, dataComparison?.[i]?.[key] ?? 0],
                ]))
            );
        });
    }

    const data = referenceDate === 'accommodationDate'
        ? comparisonEnabled
            ? buildComparisonData(
                statistics?.statisticsByAccommodationDate ?? [],
                statisticsComparison?.statisticsByAccommodationDate ?? [],
            )
            : statistics?.statisticsByAccommodationDate
        : statistics?.statisticsByBookedDate;

    return (
        <Table
            dataSource={[...data || []]}
            loading={loading}
            columns={[...baseColumns, ...columns]}
            pagination={{
                hideOnSinglePage: true,
                defaultPageSize: 20,
            }}
            tableLayout="fixed"
            scroll={{
                x: '100%',
            }}
            rowKey={record => {
                if (series.length > 0) {
                    return `${record.interval}-${record.apartmentGroupName}`;
                } else {
                    return record.interval;
                }
            }}
        />
    )
}
import { Col, Row, Tooltip } from "antd";

import { ExportOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { blue } from "@ant-design/colors";
import { useAuth } from "auth";

export default function UserBadge() {
    const { user, logout } = useAuth();

    return (
        <Row
            gutter={[16, 16]}
            style={{
                padding: '16px 12px 16px 24px',
                marginRight: 0,
                color: '#FFFFFF',
                backgroundColor: blue.primary,
            }}
        >
            <Col flex="auto">
                <Link
                    to={`/users/self`}
                    style={{
                        color: '#FFFFFF',
                    }}
                >
                    {user.firstName} {user.lastName}
                </Link>
            </Col>
            <Col flex="30px">
                <Tooltip title="Logout">
                    <ExportOutlined
                        onClick={() => logout()}
                    />
                </Tooltip>
            </Col>
        </Row>
    );
}
import { gql } from '@apollo/client';

export const GET_RESERVATIONS_QUERY = gql`
    query GetReservations($filter: ReservationsFilter = {}) {
        reservations(filter: $filter) {
            id
            apartment {
                id
                name
                tags
            }
            startDate
            endDate
            status
            bookedAt
            canceledAt
            guestFirstName
            guestLastName
            guestPhone
            guestLocation
            priceAccommodation
            priceCleaning
            priceCommission
            airbnbConfirmationCode
            airbnbThreadId
            numberOfGuests
            checkinTime
            checkoutTime
        }
    }
`;

export const GET_RESERVATION_QUERY = gql`
    query GetReservation($reservationId: ID!) {
        reservation(reservationId: $reservationId) {
            id
            apartment {
                id
                name
            }
            startDate
            endDate
            status
            bookedAt
            canceledAt
            guestFirstName
            guestLastName
            guestPhone
            guestLocation
            priceAccommodation
            priceCleaning
            priceCommission
            airbnbConfirmationCode
            airbnbThreadId
            numberOfGuests
            checkinTime
            checkoutTime
            jobs {
                id
                attachTo
                apartment {
                    id
                }
                date
                reservationDate
                calculatedDate
                category
                description
            }
        }
    }
`;


export const GET_APARTMENT_FOR_RESERVATION_FORM_QUERY = gql`
    query GetApartmentForReservationForm($apartmentId: ID!, $today: Date!) {
        apartment(apartmentId: $apartmentId) {
            id
            maxGuestCount
            increasePriceAbove
            increasePriceBy
            cleaningPrice
            calendar(filter: {dateFrom: $today}) {
                id
                date
                price
                available
                minStay
            }
        }
    }
`;

export const CREATE_RESERVATION_MUTATION = gql`
    mutation CreateReservation($input: CreateReservationInput!) {
        createReservation(input: $input) {
            error {
                type
                message
            }
            reservation {
                id
                apartment {
                    id
                    name
                }
                startDate
                endDate
                status
                bookedAt
                canceledAt
                guestFirstName
                guestLastName
                guestPhone
                guestLocation
                priceAccommodation
                priceCleaning
                priceCommission
                airbnbConfirmationCode
                airbnbThreadId
                numberOfGuests
                checkinTime
                checkoutTime
            }
            syncApartmentCalendarUpTask {
                id
            }
        }
    }
`;

export const UPDATE_RESERVATION_MUTATION = gql`
    mutation UpdateReservation($input: UpdateReservationInput!) {
        updateReservation(input: $input) {
            error {
                type
                message
            }
            reservation {
                id
                apartment {
                    id
                    name
                }
                startDate
                endDate
                status
                bookedAt
                canceledAt
                guestFirstName
                guestLastName
                guestPhone
                guestLocation
                priceAccommodation
                priceCleaning
                priceCommission
                airbnbConfirmationCode
                airbnbThreadId
                numberOfGuests
                checkinTime
                checkoutTime
            }
        }
    }
`;

export const DELETE_RESERVATION_MUTATION = gql`
    mutation DeleteReservation($input: DeleteReservationInput!) {
        deleteReservation(input: $input) {
            error {
                type
                message
            }
        }
    }
`;
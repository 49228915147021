import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Col, Form, List, Modal, Row, Skeleton } from "antd";
import ListItem from "./ListItem";
import ApartmentExpenseCategoryForm from "expenses/forms/ApartmentExpenseCategoryForm";

const QUERY = gql`
    query GetOrganizationUserApartmentExpenseCategories($organizationId: ID!) {
        organization(organizationId: $organizationId) {
            id
            apartmentExpenseCategories {
                id
                name
            }
        }
    }
`;

const CREATE_MUTATION = gql`
    mutation CreateApartmentExpenseCategory($input: CreateApartmentExpenseCategoryInput!) {
        createApartmentExpenseCategory(input: $input) {
            apartmentExpenseCategory {
                id
                name
            }
            error {
                type
                message
            }
        }
    }
`;

export default function ApartmentExpenseCategories(props) {
    const {
        organizationId,
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { organizationId } });

    const [createApartmentExpenseCategory, { loading: createLoading }] = useMutation(CREATE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Organization',
                    id: organizationId,
                }),
                fieldName: 'apartmentExpenseCategories',
            });
        },
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartment expense categories"
            />
        );
    }

    function handleCreate() {
        form
            .validateFields()
            .then(values => {
                createApartmentExpenseCategory({
                    variables: {
                        input: {
                            name: values.name,
                        },
                    },
                })
                    .then(() => {
                        setModalOpen(false);
                    })
            });
    }

    return (
        <List
            dataSource={
                [...data?.organization?.apartmentExpenseCategories ?? []]
                    .sort((a, b) => a.name.localeCompare(b.name))
            }
            renderItem={apartmentExpenseCategory => (
                <ListItem
                    apartmentExpenseCategory={apartmentExpenseCategory}
                    organizationId={organizationId}
                />
            )}
            footer={
                <Row justify="end">
                    <Col>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setModalOpen(true)}
                        >
                            Create apartment expense category
                        </Button>
                        <Modal
                            title="Create apartment expense category"
                            width={800}
                            open={modalOpen}
                            destroyOnClose
                            onCancel={() => setModalOpen(false)}
                            onOk={() => handleCreate()}
                            okText="Create"
                            okButtonProps={{
                                icon: <PlusOutlined />,
                                loading: createLoading,
                            }}
                        >
                            <ApartmentExpenseCategoryForm
                                form={form}
                                labelCol={{
                                    span: 6,
                                }}
                                wrapperCol={{
                                    span: 18,
                                }}
                                preserve={false}
                            />
                        </Modal>
                    </Col>
                </Row>
            }
        />
    );
}
import { compareAsc } from "date-fns";
import JobCreation from "./JobCreation";
import JobComment from "./JobComment";
import JobStatusChange from "./JobStatusChange";
import "./styles.css";

export default function JobLog(props) {
    const {
        job,
    } = props;

    const comments = [...job?.comments ?? []].map(comment => ({
        type: 'comment',
        key: `comment:${comment.id}`,
        timestamp: comment.createdAt,
        comment,
    }));

    const statusChanges = [...job?.statusChanges ?? []].map(statusChange => ({
        type: 'statusChange',
        key: `statusChange:${statusChange.id}`,
        timestamp: statusChange.updatedAt,
        statusChange,
    }));

    const items = [...comments, ...statusChanges]
        .sort((a, b) => compareAsc(a.timestamp, b.timestamp));

    return (
        <div className="log-list-container">
            <JobCreation job={job} />
            {items.map(item => {
                if (item.type === 'comment') {
                    return (
                        <JobComment
                            comment={item.comment}
                            key={item.key}
                        />
                    );
                }
                if (item.type === 'statusChange') {
                    return (
                        <JobStatusChange
                            statusChange={item.statusChange}
                            key={item.key}
                        />
                    );
                }
                return null;
            })}
        </div>
    );
}
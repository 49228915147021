import { formatISO } from "date-fns";

export function variableToString(type, value) {
    if (value === undefined) {
        return null;
    }

    if (type === 'integer') {
        return value.toString();
    }
    if (type === 'float') {
        return value.toString();
    }
    if (type === 'boolean') {
        return value ? 'true' : 'false';
    }
    if (type === 'string') {
        return value;
    }
    if (type === 'date') {
        return formatISO(value, { representation: 'date' });
    }
    if (type === 'datetime') {
        return formatISO(value);
    }
    if (type === 'interval') {
        return value;
    }
    if (type === 'array') {
        return value;
    }
}
import { gql, useQuery } from "@apollo/client";
import { Alert, Skeleton, Tooltip } from "antd";
import { formatISO } from "date-fns";
import classNames from "classnames";
import { CLEANING_APARTMENT_STATUS_FINISHED, CLEANING_APARTMENT_STATUS_IN_PROGRESS } from "cleaning-new/common";
import { TagOutlined } from "@ant-design/icons";
import "./style.css";

const QUERY = gql`
    query GetCleaning($date: Date!) {
        cleaningGroups(filter: {dates: [$date]}) {
            id
            apartments {
                id
                status
                apartment {
                    id
                    name
                }
                order
                tags
            }
            cleaners {
                id
                firstName
                lastName
            }
        }
    }
`;

export default function CleaningList(props) {
    const {
        date,
        selectedCleaningGroupApartmentId,
        sortBy,
        onClick,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            date: formatISO(date, { representation: 'date' }),
        },
    });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load cleaning plan"
            />
        );
    }

    function sorter(a, b) {
        if (sortBy === 'apartment') {
            return a.apartmentName.localeCompare(b.apartmentName);
        }
        if (sortBy === 'cleaner') {
            const aCleaners = a.cleaners.map(cleaner => `${cleaner.firstName} ${cleaner.lastName}`);
            const bCleaners = b.cleaners.map(cleaner => `${cleaner.firstName} ${cleaner.lastName}`);
            return aCleaners.join(' ').localeCompare(bCleaners.join(' '));
        }
    }

    const apartments = [...data.cleaningGroups ?? []]
        .map(cleaningGroup => {
            return cleaningGroup.apartments.map(item => ({
                id: item.id,
                status: item.status,
                apartmentName: item.apartment.name,
                tags: item.tags,
                cleaningOrder: item.order,
                totalCleanings: cleaningGroup.apartments.length,
                cleaners: cleaningGroup.cleaners,
            }));
        })
        .flat()
        .sort((a, b) => a.cleaningOrder - b.cleaningOrder)
        .sort(sorter);

    return (
        <div className="cleaning-list-container">
            {apartments.map(item => (
                <div
                    className={classNames({
                        "cleaning-list-item": true,
                        "cleaning-list-item-selected": selectedCleaningGroupApartmentId === item.id,
                    })}
                    onClick={() => onClick(item.id)}
                    key={item.id}
                >
                    <div className="cleaning-list-status-container">
                        <div
                            className={classNames({
                                'cleaning-list-status': true,
                                'cleaning-list-status-in-progress': item.status === CLEANING_APARTMENT_STATUS_IN_PROGRESS,
                                'cleaning-list-status-finished': item.status === CLEANING_APARTMENT_STATUS_FINISHED,
                            })}
                        />
                    </div>
                    <div className="cleaning-list-name">
                        <div>
                            {item.apartmentName}
                        </div>
                        <div>
                            {item?.tags?.length > 0 && (
                                <Tooltip
                                    title={item.tags.join(', ')}
                                >
                                    <TagOutlined />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    <div className="cleaning-list-order">
                        {item.cleaningOrder}/{item.totalCleanings}
                    </div>
                    <div className="cleaning-list-cleaners">
                        {item.cleaners.map(cleaner => `${cleaner.firstName} ${cleaner.lastName}`).join(', ')}
                    </div>
                </div>
            ))}
        </div>
    );
}
import { gql, useQuery } from "@apollo/client";
import { Alert, Button, Col, Row, Skeleton, Typography } from "antd";
import { Link } from "react-router-dom";

const QUERY = gql`
    query GetReservationsSummary($startDateFrom: Date, $startDateTo: Date, $endDateFrom: Date, $endDateTo: Date) {
        reservations(filter: {
            startDateGte: $startDateFrom,
            startDateLte: $startDateTo,
            endDateGte: $endDateFrom,
            endDateLte: $endDateTo,
            status: "confirmed",
            hasNewGuestMessagesSinceLastReview: true,
        }) {
            id
        }
    }
`

export default function ReservationsSummary(props) {
    const {
        title,
        startDateFrom,
        startDateTo,
        endDateFrom,
        endDateTo,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            startDateFrom,
            startDateTo,
            endDateFrom,
            endDateTo,
        },
    });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Faield to load reservations summary"
            />
        );
    }

    const reservationIds = data.reservations.map(reservation => reservation.id);

    return (
        <Row gutter={[16, 0]}>
            <Col flex={1}>
                <Typography.Text>
                    {title}
                </Typography.Text>
            </Col>
            <Col flex={0}>
                {reservationIds.length}
            </Col>
            <Col flex={0}>
                {reservationIds.length === 0 && (
                    <Typography.Text disabled>
                        Review
                    </Typography.Text>
                )}
                {reservationIds.length > 0 && (
                    <Link
                        to="/reservations/review"
                        state={{
                            reservationIds,
                        }}
                    >
                        Review
                    </Link>
                )}
            </Col>
        </Row>
    );
}
import { Form } from "antd";
import DatePicker from "components/DatePicker";
import { differenceInDays } from "date-fns";
import JobLocationSelect from "jobs-new/components/JobLocationSelect";
import JobStatusSelect from "jobs-new/components/JobStatusSelect";
import JobTagsSelect from "jobs-new/components/JobTagsSelect";
import { useEffect } from "react";
import UserSelect from "users/components/user-select/UserSelect";
import UserUserGroupSelect from "users/components/user-user-group-select/UserUserGroupSelect";

export default function JobsFilterForm(props) {
    const {
        filter,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            dateRange: filter ? [filter.dateFrom, filter.dateTo] : [],
            statuses: filter?.statuses,
            tags: filter?.tags,
            authors: [
                ...[...filter?.authorIds ?? []].map(userId => ({ userId })),
                ...[...filter?.authorGroupIds ?? []].map(userGroupId => ({ userGroupId })),
            ],
            watchers: [
                ...[...filter?.watcherIds ?? []].map(userId => ({ userId })),
                ...[...filter?.watcherGroupIds ?? []].map(userGroupId => ({ userGroupId })),
            ],
            assignerIds: filter?.assignerIds,
            locations: [
                ...[...filter?.apartmentIds ?? []].map(apartmentId => ({ apartmentId })),
                ...[...filter?.storageIds ?? []].map(storageId => ({ storageId })),
            ],
        })
    }, [form, filter])

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="dateRange"
                label="Date range"
                rules={[
                    ({ getFieldValue }) => ({
                        validator: (_, value) => {
                            if (!value || value.length === 0) {
                                return Promise.reject(new Error('Date range is required'));
                            }

                            const watchers = getFieldValue('watchers');
                            const assigners = getFieldValue('assignerIds');
                            const dateRangeLength = differenceInDays(value[1], value[0]);

                            if ((watchers?.length > 0 || assigners?.length > 0) && dateRangeLength > 30) {
                                return Promise.reject(new Error('Maximum range length is 30 days'));
                            }

                            if (!(watchers?.length > 0 || assigners?.length > 0) && dateRangeLength > 10) {
                                return Promise.reject(new Error('Maximum range length without watchers or assigners is 10 days'));
                            }

                            return Promise.resolve();
                        },
                    }),
                ]}
            >
                <DatePicker.RangePicker />
            </Form.Item>
            <Form.Item
                name="statuses"
                label="Statuses"
            >
                <JobStatusSelect
                    mode="multiple"
                    placeholder="Statuses"
                />
            </Form.Item>
            <Form.Item
                name="tags"
                label="Tags"
            >
                <JobTagsSelect />
            </Form.Item>
            <Form.Item
                name="authors"
                label="Authors"
            >
                <UserUserGroupSelect
                    active
                    mode="multiple"
                    placeholder="Authors"
                />
            </Form.Item>
            <Form.Item
                name="watchers"
                label="Watchers"
            >
                <UserUserGroupSelect
                    active
                    mode="multiple"
                    placeholder="Watchers"
                />
            </Form.Item>
            <Form.Item
                name="assignerIds"
                label="Assigners"
            >
                <UserSelect
                    active
                    mode="multiple"
                    placeholder="Assigners"
                />
            </Form.Item>
            <Form.Item
                name="locations"
                label="Locations"
            >
                <JobLocationSelect
                    mode="multiple"
                    placeholder="Locations"
                />
            </Form.Item>
        </Form>
    )
}
import { Card, Col, PageHeader, Row } from "antd";
import ReservationsSummary from "cleaning-new/components/reservations-summary/ReservationsSummary";
import { addDays, startOfTomorrow } from "date-fns";

export default function ViewReservationsSummary() {
    return (
        <PageHeader
            title="Reservations summary"
        >
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Card title="Review">
                        <Row gutter={[16, 8]}>
                            <Col span={24}>
                                <ReservationsSummary
                                    title="Reservations starting tomorrow"
                                    startDateFrom={startOfTomorrow()}
                                    startDateTo={startOfTomorrow()}
                                />
                            </Col>
                            <Col span={24}>
                                <ReservationsSummary
                                    title="Reservations starting in next 7 days"
                                    startDateFrom={startOfTomorrow()}
                                    startDateTo={addDays(startOfTomorrow(), 7)}
                                />
                            </Col>
                            <Col span={24}>
                                <ReservationsSummary
                                    title="Reservations ending tomorrow"
                                    endDateFrom={startOfTomorrow()}
                                    endDateTo={startOfTomorrow()}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </PageHeader>
    );
}
import { addDays, addMonths, addWeeks, addYears, endOfMonth, endOfYear, nextFriday, nextMonday, nextSaturday, nextSunday, nextThursday, nextTuesday, nextWednesday, parseISO, previousFriday, previousMonday, previousSaturday, previousSunday, previousThursday, previousTuesday, previousWednesday, startOfMonth, startOfToday, startOfYear } from "date-fns";

export function calculateDate(value) {
    if (value?.preset === 'fixedDate') {
        if (value?.date) {
            return parseISO(value?.date);
        }
        return null;
    }
    if (value?.preset === 'today') {
        return startOfToday();
    }
    if (value?.preset === 'next') {
        switch (value?.weekday) {
            case 'sunday':
                return nextSunday(startOfToday());
            case 'monday':
                return nextMonday(startOfToday());
            case 'tuesday':
                return nextTuesday(startOfToday());
            case 'wednesday':
                return nextWednesday(startOfToday());
            case 'thursday':
                return nextThursday(startOfToday());
            case 'friday':
                return nextFriday(startOfToday());
            case 'saturday':
                return nextSaturday(startOfToday());
            default:
                return null;
        }
    }
    if (value?.preset === 'previous') {
        switch (value?.weekday) {
            case 'sunday':
                return previousSunday(startOfToday());
            case 'monday':
                return previousMonday(startOfToday());
            case 'tuesday':
                return previousTuesday(startOfToday());
            case 'wednesday':
                return previousWednesday(startOfToday());
            case 'thursday':
                return previousThursday(startOfToday());
            case 'friday':
                return previousFriday(startOfToday());
            case 'saturday':
                return previousSaturday(startOfToday());
            default:
                return null;
        }
    }
    if (value?.preset === 'startOf') {
        switch (value?.period) {
            case 'month':
                return startOfMonth(startOfToday());
            case 'year':
                return startOfYear(startOfToday());
            default:
                return null;
        }
    }
    if (value?.preset === 'endOf') {
        switch (value?.period) {
            case 'month':
                return endOfMonth(startOfToday());
            case 'year':
                return endOfYear(startOfToday());
            default:
                return null;
        }
    }
}

export function calculateOffset(date, offset) {
    if (offset?.period === 'day') {
        return addDays(date, offset?.offset);
    }
    if (offset?.period === 'week') {
        return addWeeks(date, offset?.offset);
    }
    if (offset?.period === 'month') {
        return addMonths(date, offset?.offset);
    }
    if (offset?.period === 'year') {
        return addYears(date, offset?.offset);
    }
    return date;
}
import { LoadingOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Alert } from "antd";
import { formatISO } from "date-fns";

const QUERY = gql`
    query GetCleanerInformation($cleanerId: ID!, $date: Date!) {
        cleaningGroups(filter: {cleanerIds: [$cleanerId], dates: [$date]}) {
            id
            apartments {
                id
                apartment {
                    id
                    name
                }
                order
            }
        }
    }
`;

export default function CleanerInformation(props) {
    const {
        cleanerId,
        date,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            cleanerId,
            date: formatISO(date, { representation: 'date' }),
        },
    });

    if (loading) {
        return (
            <LoadingOutlined />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load information"
            />
        );
    }

    const apartments = [...data.cleaningGroups ?? []]
        .map(cleaningGroup => cleaningGroup.apartments)
        .flat()
        .sort((a, b) => a.order - b.order)

    return (
        <div className="cleaner-information-container">
            {apartments.map(item => (
                <div
                    className="cleaner-information-item"
                    key={item.id}
                >
                    {item.apartment.name}
                </div>
            ))}
        </div>
    );
}
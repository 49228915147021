import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, InputNumber, Row, Space, Switch, Tooltip, Typography } from "antd";
import TimeSelect from "components/TimeSelect";
import { useEffect } from "react";

export default function ApartmentAvailabilityForm(props) {
    const {
        apartment,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            calendarLength: apartment?.calendarLength,
            cutOffTime: apartment?.cutOffTime,
            weekdayMinStay: apartment?.weekdayMinStay ?? [1, 1, 1, 1, 1, 1, 1],
            defaultAvailable: apartment?.defaultAvailable ?? true,
            defaultAvailableForRent: apartment?.defaultAvailableForRent ?? true,
        });
    }, [apartment, form]);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form
                    form={form}
                    {...otherProps}
                >
                    <Form.Item
                        name="calendarLength"
                        label="Calendar length"
                        tooltip="Your calendar will be open for this many days into the future"
                        rules={[{ required: true, message: 'Calendar length is required' }]}
                    >
                        <InputNumber
                            min={1}
                            max={750}
                            addonAfter="days"
                        />
                    </Form.Item>
                    <Form.Item
                        name="cutOffTime"
                        label="Cut off time"
                        tooltip="After this time the apartment will be unavailable for same-day reservation"
                        rules={[{ required: true, message: 'Cut off time is required' }]}
                    >
                        <TimeSelect
                            startTime="15:00"
                            endTime="00:00"
                            minutesInterval={30}
                        />
                    </Form.Item>
                    <Form.Item
                        name="defaultAvailable"
                        label="Default available"
                        tooltip="If enabled, any new calendar day will be available by default"
                        rules={[{ required: true, message: 'This field is required' }]}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name="defaultAvailableForRent"
                        label="Default available for rent"
                        tooltip="If enabled, any new calendar day will be available for rent by default"
                        rules={[{ required: true, message: 'This field is required' }]}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Divider
                        orientation="left"
                    >
                        <Space>
                            <Typography.Text>
                                Minimum stay
                            </Typography.Text>
                            <Tooltip
                                title="Define what is the minimum length of a reservation which starts on a given weekday"
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </Space>
                    </Divider>
                    <Form.Item
                        name={['weekdayMinStay', 0]}
                        label="Monday"
                        rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <InputNumber
                            min={1}
                            addonAfter="days"
                        />
                    </Form.Item>
                    <Form.Item
                        name={['weekdayMinStay', 1]}
                        label="Tuesday"
                        rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <InputNumber
                            min={1}
                            addonAfter="days"
                        />
                    </Form.Item>
                    <Form.Item
                        name={['weekdayMinStay', 2]}
                        label="Wednesday"
                        rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <InputNumber
                            min={1}
                            addonAfter="days"
                        />
                    </Form.Item>
                    <Form.Item
                        name={['weekdayMinStay', 3]}
                        label="Thursday"
                        rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <InputNumber
                            min={1}
                            addonAfter="days"
                        />
                    </Form.Item>
                    <Form.Item
                        name={['weekdayMinStay', 4]}
                        label="Friday"
                        rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <InputNumber
                            min={1}
                            addonAfter="days"
                        />
                    </Form.Item>
                    <Form.Item
                        name={['weekdayMinStay', 5]}
                        label="Saturday"
                        rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <InputNumber
                            min={1}
                            addonAfter="days"
                        />
                    </Form.Item>
                    <Form.Item
                        name={['weekdayMinStay', 6]}
                        label="Sunday"
                        rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <InputNumber
                            min={1}
                            addonAfter="days"
                        />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
import { Form } from "antd";
import { useEffect } from "react";
import UserGroupSelect from "user-groups/components/user-group-select/UserGroupSelect";

export default function UserUserGroupsForm(props) {
    const {
        user,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            groupIds: [...user?.groups ?? []].map(group => group.id),
        })
    }, [user, form]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="groupIds"
                label="Groups"
            >
                <UserGroupSelect
                    mode="multiple"
                />
            </Form.Item>
        </Form>
    )
}
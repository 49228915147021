import { Select } from "antd";

const SLOPES = [
    30,
    35,
    40,
    45,
    50,
    60,
    70,
    80,
];

export default function PriceConfigSlopeSelect(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    return (
        <Select
            value={value}
            onChange={value => onChange(value)}
            options={
                SLOPES.map(slope => ({
                    label: slope,
                    value: slope,
                }))
            }
            style={{
                width: '100px',
            }}
            {...otherProps}
        />
    );
}
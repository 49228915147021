import { formatISO, isEqual, startOfToday, startOfTomorrow } from "date-fns";

export default function JobDate(props) {
    const {
        date,
    } = props;

    if (isEqual(date, startOfToday())) {
        return 'Today';
    }
    if (isEqual(date, startOfTomorrow())) {
        return 'Tomorrow';
    }

    return formatISO(date, { representation: 'date' });
}
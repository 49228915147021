import { Form } from "antd";
import UserSelect from "users/components/user-select/UserSelect";

export default function JobsListActionAssignerForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="assignerId"
                label="Assigner"
            >
                <UserSelect
                    active
                    placeholder="Assigner"
                />
            </Form.Item>
        </Form>
    );
}
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";

export default function OrganizationJobTagsForm(props) {
    const {
        form,
        organization,
        ...otherProps
    } = props;

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                jobTags: organization?.jobTags ?? [],
            });
        }
    }, [form, organization]);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form
                    form={form}
                    {...otherProps}
                >
                    <Form.List
                        name="jobTags"
                        rules={[{
                            validator: (_, values) => {
                                const duplicates = values.filter((value, index) => values.indexOf(value) !== index);
                                if (duplicates.length > 0) {
                                    return Promise.reject(new Error(`There are duplicated tag names: ${duplicates.join(', ')}`))
                                }
                                return Promise.resolve();
                            }
                        }]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map(field => (
                                    <Form.Item
                                        label="Tag"
                                        {...field}
                                        rules={[{ required: true, message: 'Tag can not be empty' }]}
                                    >
                                        <Input
                                            addonAfter={
                                                <DeleteOutlined
                                                    onClick={() => remove(field.name)}
                                                />
                                            }
                                            disabled={field.key < (organization?.jobTags?.length ?? 0)}
                                        />
                                    </Form.Item>
                                )
                                )}
                                <Form.Item
                                    wrapperCol={{
                                        offset: otherProps?.labelCol?.span,
                                        span: otherProps?.wrapperCol?.span,
                                    }}
                                >
                                    <Form.ErrorList errors={errors} />
                                    <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => add()}
                                        block
                                        type="dashed"
                                    >
                                        Add tag
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Col>
        </Row>
    )
}
import { Col, Row } from "antd";
import Thumbnail from "components/attachments/Thumbnail";

export default function JobAttachmentsList(props) {
    const {
        attachments,
        columnSize,
    } = props;

    return (
        <Row gutter={[16, 16]}>
            {attachments.map(attachment => (
                <Col
                    key={attachment.id}
                    span={columnSize || 4}
                >
                    <Thumbnail
                        file={attachment}
                    />
                </Col>
            ))}
        </Row>
    )
}
import { Alert, Button, Card, Col, Form, PageHeader, Row, Skeleton, Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import ApartmentGeneralForm from 'apartments/forms/ApartmentGeneralForm';
import LocationForm from 'components/LocationForm';
import ApartmentCleaningForm from 'apartments/forms/ApartmentCleaningForm';
import ApartmentGuestsForm from 'apartments/forms/ApartmentGuestsForm';
import ApartmentAvailabilityForm from 'apartments/forms/ApartmentAvailabilityForm';
import { startOfToday } from 'date-fns';
import { useState } from 'react';
import { taskPromise } from 'common/task';
import ApartmentPriceConfigForm from 'apartments/forms/ApartmentPriceConfigForm';
import ApartmentChannels from 'apartments/components/apartment-channels/ApartmentChannels';
import { SaveOutlined } from '@ant-design/icons';
import CalendarRestrictionsList from 'apartments/components/calendar-restrictions-list/CalendarRestrictionsList';
import ApartmentAvailabilityCalendar from 'apartments/components/apartment-availability-calendar/ApartmentAvailabilityCalendar';

const FORM_COLUMNS = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const QUERY = gql`
    query GetApartment($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            storage {
                id
            }
            active
            calendarLength
            cleaningPrice
            cutOffTime
            defaultAvailable
            defaultAvailableForRent
            defaultPriceConfigDelta
            defaultPriceConfigMinPrice
            defaultPriceConfigSlope
            increasePriceAbove
            increasePriceBy
            latitude
            longitude
            maxGuestCount
            name
            tags
            weekdayMinStay
        }
    }
`;

const UPDATE_APARTMENT_MUTATION = gql`
    mutation UpdateApartment($input: UpdateApartmentInput!) {
        updateApartment(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                storage {
                    id
                }
                active
                calendarLength
                cleaningPrice
                cutOffTime
                defaultAvailable
                defaultAvailableForRent
                defaultPriceConfigDelta
                defaultPriceConfigMinPrice
                defaultPriceConfigSlope
                increasePriceAbove
                increasePriceBy
                latitude
                longitude
                maxGuestCount
                name
                tags
                weekdayMinStay
            }
            syncApartmentUpTask {
                id
            }
            recalculateApartmentCalendarTask {
                id
            }
            syncApartmentCalendarUpTask {
                id
            }
        }
    }
`;

const COPY_APARTMENT_PRICE_CONFIGS_MUTATION = gql`
    mutation CopyApartmentPriceConfigs($input: CopyApartmentPriceConfigsInput!) {
        copyApartmentPriceConfigs(input: $input) {
            error {
                type
                message
            }
            apartmentPriceConfigs {
                id
                date
                minPrice
                delta
                slope
            }
            recalculateApartmentCalendarTask {
                id
            }
        }
    }
`;

export default function UpdateApartmentView() {
    const { apartmentId } = useParams();
    const navigate = useNavigate();

    const [generalForm] = Form.useForm();
    const [locationForm] = Form.useForm();
    const [cleaningForm] = Form.useForm();
    const [guestsForm] = Form.useForm();
    const [availabilityForm] = Form.useForm();
    const [priceConfigForm] = Form.useForm();

    const [updateApartmentLoading, setUpdateApartmentLoading] = useState(false);
    const { data, loading, error } = useQuery(QUERY, { variables: { apartmentId } });
    const [updateApartment] = useMutation(UPDATE_APARTMENT_MUTATION);
    const [copyApartmentPriceConfigs] = useMutation(COPY_APARTMENT_PRICE_CONFIGS_MUTATION);

    const [currentTab, setCurrentTab] = useState('general');

    if (loading) {
        return (
            <PageHeader
                title={<Skeleton.Input active />}
            >
                <Card loading />
            </PageHeader>
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartment"
            />
        );
    }

    const forms = {
        general: generalForm,
        location: locationForm,
        cleaning: cleaningForm,
        guests: guestsForm,
        availability: availabilityForm,
        priceConfig: priceConfigForm,
    };

    const items = [
        {
            label: 'General',
            key: 'general',
            children: (
                <ApartmentGeneralForm
                    form={forms.general}
                    apartment={data.apartment}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Location',
            key: 'location',
            children: (
                <LocationForm
                    form={forms.location}
                    coordinates={data.apartment}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Cleaning',
            key: 'cleaning',
            children: (
                <ApartmentCleaningForm
                    form={forms.cleaning}
                    apartment={data.apartment}
                    apartmentLocation={data.apartment}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Guests',
            key: 'guests',
            children: (
                <ApartmentGuestsForm
                    form={forms.guests}
                    apartment={data.apartment}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Availability',
            key: 'availability',
            children: (
                <ApartmentAvailabilityForm
                    form={forms.availability}
                    apartment={data.apartment}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Availability calendar',
            key: 'availabilityCalendar',
            children: (
                <ApartmentAvailabilityCalendar
                    apartmentId={apartmentId}
                />
            ),
        },
        {
            label: 'Price config',
            key: 'priceConfig',
            children: (
                <ApartmentPriceConfigForm
                    form={forms.priceConfig}
                    apartment={data.apartment}
                    {...FORM_COLUMNS}
                />
            )
        },
        {
            label: 'Channels',
            key: 'channels',
            children: (
                <ApartmentChannels
                    apartmentId={apartmentId}
                />
            )
        },
        {
            label: 'Calendar restrictions',
            key: 'calendarRestrictions',
            children: (
                <CalendarRestrictionsList
                    apartmentId={apartmentId}
                />
            ),
        },
    ];

    const updateFunctions = {
        general: values => {
            return updateApartment({
                variables: {
                    input: {
                        apartmentId,
                        name: values.name,
                        tags: values.tags ?? [],
                        active: values.active,
                    },
                },
            })
                .then(response => taskPromise(response.data.updateApartment.syncApartmentUpTask?.id));
        },
        location: values => {
            return updateApartment({
                variables: {
                    input: {
                        apartmentId,
                        latitude: values.latitude,
                        longitude: values.longitude,
                    },
                },
            });
        },
        cleaning: values => {
            return updateApartment({
                variables: {
                    input: {
                        apartmentId,
                        storageId: values.storageId,
                        cleaningPrice: values.cleaningPrice,
                    },
                },
            });
        },
        guests: values => {
            return updateApartment({
                variables: {
                    input: {
                        apartmentId,
                        maxGuestCount: values.maxGuestCount,
                        increasePriceAbove: values.enableExtraGuestFee ? values.increasePriceAbove : null,
                        increasePriceBy: values.enableExtraGuestFee ? values.increasePriceBy : null,
                    },
                },
            })
                .then(response => taskPromise(response.data.updateApartment.syncApartmentUpTask?.id))
        },
        availability: values => {
            return updateApartment({
                variables: {
                    input: {
                        apartmentId,
                        calendarLength: values.calendarLength,
                        cutOffTime: values.cutOffTime,
                        defaultAvailable: values.defaultAvailable,
                        defaultAvailableForRent: values.defaultAvailableForRent,
                        weekdayMinStay: values.weekdayMinStay,
                    },
                },
            })
                .then(response =>
                    Promise.all([
                        taskPromise(response.data.updateApartment.syncApartmentUpTask?.id),
                        taskPromise(response.data.updateApartment.recalculateApartmentCalendarTask?.id),
                    ])
                );
        },
        priceConfig: values => {
            return updateApartment({
                variables: {
                    input: {
                        apartmentId,
                        defaultPriceConfigMinPrice: values.defaultPriceConfigMinPrice,
                        defaultPriceConfigDelta: values.defaultPriceConfigDelta,
                        defaultPriceConfigSlope: values.defaultPriceConfigSlope,
                    },
                },
            })
                .then(response => taskPromise(response.data.updateApartment.syncApartmentUpTask?.id))
                .then(() => {
                    if (!values.copyPriceConfig) {
                        return Promise.resolve();
                    }

                    return copyApartmentPriceConfigs({
                        variables: {
                            input: {
                                sourceApartmentId: values.copyPriceConfigSourceApartmentId,
                                sourceDateFrom: startOfToday(),
                                destinationApartmentId: apartmentId,
                            },
                        },
                    })
                        .then(response => taskPromise(response.data.copyApartmentPriceConfigs.recalculateApartmentCalendarTask?.id));
                });
        }
    }

    function handleSave() {
        setUpdateApartmentLoading(true);
        forms[currentTab]
            .validateFields()
            .then(values => updateFunctions[currentTab](values))
            .finally(() => setUpdateApartmentLoading(false));
    }

    function canSave() {
        return forms[currentTab] !== undefined;
    }

    return (
        <PageHeader
            title={data.apartment.name}
            onBack={() => navigate('/apartments')}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Tabs
                            items={items}
                            activeKey={currentTab}
                            onChange={value => setCurrentTab(value)}
                            tabPosition="left"
                        />
                    </Col>
                    {canSave() && (
                        <Col span={24}>
                            <Row justify="end">
                                <Col>
                                    <Button
                                        type='primary'
                                        onClick={() => handleSave()}
                                        loading={updateApartmentLoading}
                                        icon={<SaveOutlined />}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
            </Card>
        </PageHeader >
    );
}
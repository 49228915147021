import { Card, Col, Row, Tooltip } from 'antd';
import { DeleteOutlined, DownloadOutlined, SettingOutlined } from '@ant-design/icons';
import { GET_STATISTICS_BY_ACCOMMODATION_DATE_QUERY, GET_STATISTICS_BY_BOOKED_DATE_QUERY } from 'statistics/graphql';
import { STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING, STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING } from 'statistics/common';
import { formatISO, startOfToday, subDays } from 'date-fns';

import StatisticsApartmentsTable from 'statistics/StatisticsApartmentsTable';
import StatisticsApartmentsTableSettingsModal from 'statistics/StatisticsApartmentsTableSettingsModal';
import StatisticsChart from 'statistics/StatisticsChart';
import StatisticsChartSettingsModal from 'statistics/StatisticsChartSettingsModal';
import StatisticsTable from 'statistics/StatisticsTable';
import StatisticsTableSettingsModal from 'statistics/StatisticsTableSettingsModal';
import { downloadData } from 'common/excel';
import { useQuery } from '@apollo/client';
import { useState } from 'react';

export default function StatisticsCard(props) {
    const { type, settings, onChange, onDelete } = props;

    const [isModalVisible, setModalVisible] = useState(false);

    const query = settings.referenceDate === 'accommodationDate'
        ? GET_STATISTICS_BY_ACCOMMODATION_DATE_QUERY
        : GET_STATISTICS_BY_BOOKED_DATE_QUERY;

    const { data: statisticsData, loading: statisticsLoading } = useQuery(query, {
        variables: {
            filter: {
                dateFrom: settings.dateRange[0],
                dateTo: settings.dateRange[1],
                intervalType: settings.intervalType,
                intervalSize: settings.intervalSize,
                includeWeekdays: settings.includeWeekdays,
                apartmentGroups: settings.series.length > 0
                    ? settings.series.map(item => ({ name: item.name, apartmentIds: item.apartments }))
                    : null,
            },
        },
    });

    const { data: statisticsComparisonData } = useQuery(query, {
        variables: {
            filter: {
                dateFrom: subDays(settings.dateRange[0], settings?.comparison?.offset ?? 0),
                dateTo: subDays(settings.dateRange[1], settings?.comparison?.offset ?? 0),
                intervalType: settings.intervalType,
                intervalSize: settings.intervalSize,
                ...(settings?.comparison?.enabled && { bookedAtTo: subDays(startOfToday(), settings.comparison.range) }),
                includeWeekdays: settings.includeWeekdays,
                apartmentGroups: settings.series.length > 0
                    ? settings.series.map(item => ({ name: item.name, apartmentIds: item.apartments }))
                    : null,
            },
        },
        enabled: settings?.comparison?.enabled ?? false,
    });

    function handleModalSubmit(values) {
        onChange(values);
        setModalVisible(false);
    }

    function handleModalCancel() {
        setModalVisible(false);
    }

    function handleDeleteCard() {
        onDelete();
    }

    function handleDownload() {
        const columns = [
            { label: 'Interval', key: 'interval' },
        ];

        if (settings.series.length > 0) {
            columns.push({
                label: 'Apartment group',
                key: 'apartmentGroupName',
            });
        }

        if (type === 'chart') {
            columns.push({
                label: STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING[settings.fieldName],
                key: settings.fieldName,
                number: true,
            });
        }
        if (type === 'table') {
            settings.fieldNames.forEach(fieldName => {
                columns.push({
                    label: STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING[fieldName],
                    key: fieldName,
                    number: true,
                });
            });
        }
        if (type === 'apartmentsTable') {
            settings.fieldNames.forEach(fieldName => {
                columns.push({
                    label: STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING[fieldName],
                    key: fieldName,
                    number: true,
                });
            });
        }

        downloadData({
            columns,
            data: settings.referenceDate === 'accommodationDate'
                ? statisticsData?.statisticsByAccommodationDate
                : statisticsData?.statisticsByBookedDate,
            filename: 'statistics.xlsx',
        });
    }

    return (
        <Card
            title={settings.name}
            extra={[
                <Row
                    gutter={[16, 16]}
                    key="extra"
                >
                    <Col>
                        <Tooltip
                            title="Delete"
                        >
                            <DeleteOutlined onClick={() => handleDeleteCard()} />
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip
                            title="Download"
                        >
                            <DownloadOutlined onClick={() => handleDownload()} />
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip
                            title="Settings"
                        >
                            <SettingOutlined onClick={() => setModalVisible(true)} />
                        </Tooltip>
                    </Col>
                </Row>
            ]}
        >
            {type === 'chart' && (
                <>
                    <StatisticsChart
                        referenceDate={settings.referenceDate}
                        fieldName={settings.fieldName}
                        statistics={statisticsData}
                        loading={statisticsLoading}
                    />
                    <StatisticsChartSettingsModal
                        visible={isModalVisible}
                        settings={settings}
                        onSubmit={handleModalSubmit}
                        onCancel={handleModalCancel}
                    />
                </>
            )}
            {type === 'table' && (
                <>
                    <StatisticsTable
                        referenceDate={settings.referenceDate}
                        series={settings.series}
                        fieldNames={settings.fieldNames}
                        statistics={statisticsData}
                        statisticsComparison={statisticsComparisonData}
                        comparisonEnabled={settings?.comparison?.enabled}
                        loading={statisticsLoading}
                    />
                    <StatisticsTableSettingsModal
                        visible={isModalVisible}
                        settings={settings}
                        onSubmit={handleModalSubmit}
                        onCancel={handleModalCancel}
                    />
                </>
            )}
            {type === 'apartmentsTable' && (
                <>
                    <StatisticsApartmentsTable
                        referenceDate={settings.referenceDate}
                        fieldNames={settings.fieldNames}
                        statistics={statisticsData}
                        statisticsComparison={statisticsComparisonData}
                        comparisonEnabled={settings?.comparison?.enabled}
                        loading={statisticsLoading}
                    />
                    <StatisticsApartmentsTableSettingsModal
                        visible={isModalVisible}
                        settings={settings}
                        onSubmit={handleModalSubmit}
                        onCancel={handleModalCancel}
                    />
                </>
            )}
        </Card >
    )
}
import { useEffect, useState } from "react";
import { addDays, endOfDay, isEqual, isWithinInterval, parse, startOfDay, startOfToday, subDays } from "date-fns";
import classNames from "classnames";
import "./styles.css";

const DEFAULT_CHECKOUT = '11:00';
const DEFAULT_CHECKIN = '15:00';

function parseReservationTime(date, time, defaultTime) {
    if (!time || time === 'unknown') {
        return parse(defaultTime, 'HH:mm', date);
    }

    if (time === '24:00') {
        return endOfDay(date);
    }

    return parse(time, 'HH:mm', date);
}

export default function JobReservationSituationInline(props) {
    const {
        endingReservation,
        currentReservation,
        startingReservation,
        date,
    } = props;

    function isTimeWithinReservation(timestamp) {
        const checkoutTimestamp = endingReservation
            ? parseReservationTime(endingReservation?.endDate, endingReservation?.checkoutTime, DEFAULT_CHECKOUT)
            : subDays(endOfDay(date), 1);
        const checkinTimestamp = startingReservation
            ? parseReservationTime(startingReservation?.startDate, startingReservation?.checkinTime, DEFAULT_CHECKIN)
            : addDays(startOfDay(date), 1);

        return isWithinInterval(timestamp, { start: checkoutTimestamp, end: checkinTimestamp });
    }

    const [canVisitApartment, setCanVisitApartment] = useState(isTimeWithinReservation(new Date()));

    useEffect(() => {
        const intervalId = window.setInterval(() => {
            setCanVisitApartment(isTimeWithinReservation(new Date()));
        }, 60000);

        return () => window.clearInterval(intervalId);
    });

    const statusColor = canVisitApartment
        ? 'green'
        : 'red';

    const isSameDay = isEqual(date, startOfToday());

    if (endingReservation && startingReservation) {
        return (
            <div className="job-reservation-situation-inline-container">
                <div className={classNames({
                    'job-reservation-situation-inline-badge': true,
                    [`job-reservation-situation-inline-badge-${statusColor}`]: isSameDay,
                })} />
                <div>
                    {endingReservation.checkoutTime || '11:00'}
                </div>
                <div>
                    -
                </div>
                <div>
                    {startingReservation.checkinTime || '15:00'}
                </div>
            </div>
        );
    }
    if (!endingReservation && startingReservation) {
        return (
            <div className="job-reservation-situation-inline-container">
                <div className={classNames({
                    'job-reservation-situation-inline-badge': true,
                    [`job-reservation-situation-inline-badge-${statusColor}`]: isSameDay,
                })} />
                <div>
                    wolne
                </div>
                <div>
                    -
                </div>
                <div>
                    {startingReservation.checkinTime || '15:00'}
                </div>
            </div>
        );
    }
    if (endingReservation && !startingReservation) {
        return (
            <div className="job-reservation-situation-inline-container">
                <div className={classNames({
                    'job-reservation-situation-inline-badge': true,
                    [`job-reservation-situation-inline-badge-${statusColor}`]: isSameDay,
                })} />
                <div>
                    {endingReservation.checkoutTime || '11:00'}
                </div>
                <div>
                    -
                </div>
                <div>
                    wolne
                </div>
            </div>
        );
    }
    if (currentReservation) {
        return (
            <div className="job-reservation-situation-inline-container">
                <div className={classNames({
                    'job-reservation-situation-inline-badge': true,
                    'job-reservation-situation-inline-badge-red': isSameDay,
                })} />
                <div>
                    Trwająca rezerwacja
                </div>
            </div>
        );
    }
    if (!currentReservation) {
        return (
            <div className="job-reservation-situation-inline-container">
                <div className={classNames({
                    'job-reservation-situation-inline-badge': true,
                    'job-reservation-situation-inline-badge-green': isSameDay,
                })} />
                <div>
                    Brak rezerwacji
                </div>
            </div>
        );
    }
}
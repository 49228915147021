import { gql, useQuery } from "@apollo/client";
import { formatISO } from "date-fns";

const QUERY = gql`
    query GetCleanerForCleaningGroup($cleanerId: ID!, $date: Date!) {
        user(userId: $cleanerId) {
            id
            firstName
            lastName
            availability(filter: {dateFrom: $date, dateTo: $date}) {
                id
                availability
            }
            groups {
                id
                userAvailabilityTypes {
                    id
                    availability
                    color
                }
            }
            organization {
                id
                userAvailabilityTypes {
                    id
                    availability
                    color
                }
            }
        }
    }
`;

export default function Cleaner(props) {
    const {
        cleanerId,
        date,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            cleanerId,
            date: formatISO(date, { representation: 'date' }),
        },
    });

    if (loading) {
        return (
            <div className="cleaning-group-cleaner-container-loading" />
        );
    }

    if (error) {
        return (
            <div className="cleaning-group-cleaner-container-error" />
        );
    }

    const organizationAvailabilityTypes = data.user.organization.userAvailabilityTypes;
    const userGroupAvailabilityTypes = data.user.groups
        .map(userGroup => userGroup.userAvailabilityTypes)
        .flat();

    const availabilityTypes = [
        ...organizationAvailabilityTypes,
        ...userGroupAvailabilityTypes,
    ];

    const availabilityColor = Object.fromEntries(
        availabilityTypes.map(availabilityType => ([
            availabilityType.availability,
            availabilityType.color,
        ]))
    );

    return (
        <div className="cleaning-group-cleaner-container">
            <div
                className="cleaning-group-cleaner-availability"
                style={{
                    backgroundColor: availabilityColor?.[data.user.availability?.[0]?.availability] ?? '#a0a0a0',
                }}
            />
            <div className="cleaning-group-cleaner-name">
                {data.user.firstName} {data.user.lastName}
            </div>
        </div>
    );
}
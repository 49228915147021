import { message, Select } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { fuzzySearch } from "common/common";
import { useCallback, useEffect, useState } from "react";

const QUERY = gql`
    query GetUsersAndUserGroupsForSelect($usersFilter: UsersFilter!) {
        users(filter: $usersFilter) {
            id
            firstName
            lastName
        }
        userGroups {
            id
            name
        }
    }
`;

export default function UserUserGroupSelect(props) {
    const {
        active,
        value,
        onChange,
        ...otherProps
    } = props;

    const usersFilter = active ? { statuses: ['active', 'onboarding'] } : {};
    const { data, loading, error } = useQuery(QUERY, { variables: { usersFilter } });
    const [inputStatus, setInputStatus] = useState();

    const multiple = otherProps?.mode === 'multiple';

    const getValue = useCallback(value => {
        if (multiple) {
            return [...value ?? []].map(item => {
                if (item.userId) {
                    return `user:${item.userId}`;
                }
                if (item.userGroupId) {
                    return `userGroup:${item.userGroupId}`;
                }
                return null;
            });
        }
        else {
            if (value?.userId) {
                return `user:${value.userId}`;
            }
            if (value?.userGroupId) {
                return `userGroup:${value.userGroupId}`;
            }
            return null;
        }
    }, [multiple]);

    const handleOnChange = useCallback(value => {
        if (multiple) {
            onChange(
                [...value ?? []].map(item => {
                    if (item.startsWith('user:')) {
                        const userId = item.replace('user:', '');
                        return { userId };
                    }
                    if (item.startsWith('userGroup:')) {
                        const userGroupId = item.replace('userGroup:', '');
                        return { userGroupId };
                    }
                    return null;
                })
            );
        }
        else {
            if (value?.startsWith('user:')) {
                const userId = value.replace('user:', '');
                onChange({ userId });
            }
            if (value?.startsWith('userGroup:')) {
                const userGroupId = value.replace('userGroup:', '');
                onChange({ userGroupId });
            }
            return null
        }
        setInputStatus();
    }, [multiple, onChange]);

    useEffect(() => {
        if (data) {
            const possibleKeys = [
                ...data.users.map(user => `user:${user.id}`),
                ...data.userGroups.map(userGroup => `userGroup:${userGroup.id}`),
            ];
            if (multiple) {
                const valueKeys = getValue(value);
                const inexistentKeys = valueKeys.filter(key => !possibleKeys.includes(key));
                if (inexistentKeys.length > 0) {
                    message.error("Users of user groups do not exist");
                    setInputStatus("error");
                    handleOnChange(valueKeys.filter(key => !inexistentKeys.includes(key)));
                }
            }
            else {
                const valueKey = getValue(value);
                if (!possibleKeys.includes(valueKey)) {
                    message.error("User of user group does not exist");
                    setInputStatus("error");
                    handleOnChange();
                }
            }
        }
    }, [data, value, multiple, getValue, handleOnChange]);

    if (loading) {
        return (
            <Select loading />
        );
    }

    if (error) {
        return (
            <Select status="error" />
        );
    }

    function userName(user) {
        return `${user.firstName} ${user.lastName}`;
    }

    const groupsOptions = [...data.userGroups]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(userGroup => ({
            label: userGroup.name,
            value: `userGroup:${userGroup.id}`,
        }));

    const usersOptions = [...data.users]
        .sort((a, b) => userName(a).localeCompare(userName(b)))
        .map(user => ({
            label: userName(user),
            value: `user:${user.id}`,
        }));

    const options = [
        {
            label: 'Groups',
            key: 'groups',
            options: groupsOptions,
        },
        {
            label: 'Users',
            key: 'users',
            options: usersOptions,
        },
    ];

    return (
        <Select
            options={options}
            value={getValue(value)}
            onChange={value => handleOnChange(value)}
            status={inputStatus}
            showSearch
            filterOption={(inputValue, option) => fuzzySearch(option.label, inputValue)}
            suffixIcon={<UserOutlined />}
            style={{
                width: '100%',
            }}
            {...otherProps}
        />
    )
}
import { Link } from "react-router-dom";
import "./style.css";

export default function ApartmentCell(props) {
    const { apartment } = props;

    return (
        <div className="calendar-apartment-cell-container">
            <Link
                to={`/apartments/${apartment?.id}`}
            >
                {apartment?.name}
            </Link>
        </div>
    );
}
import { Form } from "antd";
import { useEffect } from "react";
import UserPermissionsSelect from "users/components/user-permissions-select/UserPermissionsSelect";

export default function UserGroupPermissionsForm(props) {
    const {
        userGroup,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            permissions: userGroup?.permissions,
        });
    }, [userGroup, form]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="permissions"
                label="Permissions"
            >
                <UserPermissionsSelect />
            </Form.Item>
        </Form>
    )
}
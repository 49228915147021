import { useEffect } from "react";
import { Form, Input, InputNumber } from "antd";
import ApartmentSelect from "apartments/components/apartment-select/ApartmentSelect";
import TimeSelect from "cleaning-new/components/time-select/TimeSelect";
import ReservationTagsSelect from "reservations/components/reservation-tags-select/ReservationTagsSelect";
import StorageSelect from "storages/components/storage-select/StorageSelect";
import { gql, useQuery } from "@apollo/client";

const QUERY = gql`
    query GetApartmentDataForApartmentCleaningForm($apartmentId: ID!, $date: Date!) {
        apartment(apartmentId: $apartmentId) {
            id
            storage {
                id
            }
            departingReservation(date: $date) {
                id
                checkoutTime
            }
            arrivingReservation(date: $date) {
                id
                numberOfGuests
                checkinTime
            }
            ongoingReservation(date: $date) {
                id
                numberOfGuests
            }
            maxGuestCount
        }
    }
`;

export default function ApartmentCleaningForm(props) {
    const {
        date,
        form,
        apartmentCleaning,
        disableApartment,
        disableStorage,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            apartmentId: apartmentCleaning?.apartment?.id,
            storageId: apartmentCleaning?.storage?.id,
            numberOfGuests: apartmentCleaning?.numberOfGuests,
            checkoutTime: apartmentCleaning?.checkoutTime,
            checkinTime: apartmentCleaning?.checkinTime,
            tags: apartmentCleaning?.tags,
            note: apartmentCleaning?.note,
        });
    }, [form, apartmentCleaning]);

    const apartmentId = Form.useWatch('apartmentId', form);

    const { data, loading, refetch } = useQuery(QUERY, {
        variables: {
            apartmentId,
            date,
        },
        fetchPolicy: 'network-only',
        skip: !apartmentId,
    });

    useEffect(() => {
        if (!apartmentCleaning) {
            refetch({
                apartmentId,
                date,
            });
        }
    }, [apartmentId, date, apartmentCleaning, refetch]);

    useEffect(() => {
        if (!apartmentCleaning) {
            form?.setFieldsValue({
                storageId: data?.apartment?.storage?.id,
                numberOfGuests: data?.apartment?.arrivingReservation?.numberOfGuests ?? data?.apartment?.ongoingReservation?.numberOfGuests,
                checkoutTime: data?.apartment?.departingReservation?.checkoutTime,
                checkinTime: data?.apartment?.arrivingReservation?.checkinTime,
            });
        }
    }, [data, form, apartmentCleaning]);

    return (
        <Form
            form={form}
            disabled={loading}
            {...otherProps}
        >
            <Form.Item
                name="apartmentId"
                label="Apartment"
                rules={[{ required: true, message: 'Apartment is required' }]}

            >
                <ApartmentSelect
                    disabled={disableApartment}
                />
            </Form.Item>
            <Form.Item
                name="storageId"
                label="Storage"
                rules={[{ required: true, message: 'Storage is required' }]}
            >
                <StorageSelect
                    disabled={disableStorage}
                />
            </Form.Item>
            <Form.Item
                name="numberOfGuests"
                label="Number of guests"
            >
                <InputNumber
                    min={1}
                    max={data?.apartment?.maxGuestCount}
                />
            </Form.Item>
            <Form.Item
                name="checkoutTime"
                label="Check-out time"
            >
                <TimeSelect />
            </Form.Item>
            <Form.Item
                name="checkinTime"
                label="Check-in time"
            >
                <TimeSelect />
            </Form.Item>
            <Form.Item
                name="tags"
                label="Tags"
            >
                <ReservationTagsSelect />
            </Form.Item>
            <Form.Item
                name="note"
                label="Note"
            >
                <Input.TextArea />
            </Form.Item>
        </Form>
    )
}
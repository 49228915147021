import { Col, Input, Row } from "antd";
import TypeSelect from "../type-select/TypeSelect";

export default function VariableInput(props) {
    const {
        value,
        onChange,
        extra,
    } = props;

    function handleNameChange(name) {
        onChange({ ...value, name });
    }

    function handleTypeChange(type) {
        onChange({ ...value, type });
    }

    function handleValueChange(value_) {
        onChange({ ...value, value: value_ });
    }

    return (
        <Row
            gutter={[8, 8]}
            align="middle"
            wrap={false}
        >
            <Col flex={1}>
                <Row gutter={[8, 8]}>
                    <Col span={8}>
                        <Input
                            value={value?.name}
                            onChange={e => handleNameChange(e.target.value)}
                            placeholder="Name"
                        />
                    </Col>
                    <Col span={8}>
                        <TypeSelect
                            value={value?.type}
                            onChange={value => handleTypeChange(value)}
                            placeholder="Type"
                        />
                    </Col>
                    <Col span={8}>
                        <Input
                            value={value?.value}
                            onChange={e => handleValueChange(e.target.value)}
                            placeholder="Value"
                        />
                    </Col>
                </Row>
            </Col>
            {extra && (
                <Col flex={0}>
                    {extra}
                </Col>
            )}
        </Row>
    );
}
import { gql, useQuery } from "@apollo/client";
import { Button, Card, Col, PageHeader, Row, Space, Statistic, Table, Tag, Tooltip, Typography } from "antd";
import { addDays, compareAsc, differenceInDays, endOfDay, endOfToday, format, isAfter, isEqual, startOfDay, startOfToday, subDays } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import DatePicker from "components/DatePicker";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import ApartmentTagsSelect from "apartments/components/apartment-select/ApartmentTagsSelect";
import { apartmentTagsFilterAnd, apartmentTagsFilterOr } from "apartments/common";

const QUERY = gql`
    query GetReservations($filter: ReservationsFilter = {}) {
        reservations(filter: $filter) {
            id
            apartment {
                id
                name
                tags
            }
            startDate
            endDate
            status
            bookedAt
            canceledAt
            guestFirstName
            guestLastName
            guestPhone
            guestLocation
            priceAccommodation
            priceCleaning
            priceCommission
            airbnbConfirmationCode
            airbnbThreadId
            numberOfGuests
            checkinTime
            checkoutTime
            otaName
        }
    }
`;

export default function NewReservationsView() {
    const navigate = useNavigate();

    const [date, setDate] = useState(startOfToday());
    const [apartmentTagsSearch, setApartmentTagsSearch] = useState([]);
    const [apartmentTagsLogicalOperator, setApartmentTagsLogicalOperator] = useState('or');

    const { data: bookedReservationsData, loading: bookedReservationsLoading } = useQuery(QUERY, {
        variables: {
            filter: {
                bookedAtGte: startOfDay(date),
                bookedAtLte: endOfDay(date),
            },
        }
    });

    const { data: canceledReservationsData, loading: canceledReservationsLoading } = useQuery(QUERY, {
        variables: {
            filter: {
                canceledAtGte: startOfDay(date),
                canceledAtLte: endOfDay(date),
            },
        }
    });

    function filterReservation(reservation) {
        if (apartmentTagsLogicalOperator === 'or') {
            return apartmentTagsFilterOr(reservation.apartment.tags, apartmentTagsSearch);
        }
        else {
            return apartmentTagsFilterAnd(reservation.apartment.tags, apartmentTagsSearch);
        }
    }

    function totalDaysBooked() {
        return [...bookedReservationsData?.reservations ?? []]
            .filter(reservation => filterReservation(reservation))
            .map(reservation => differenceInDays(reservation.endDate, reservation.startDate))
            .reduce((sum, count) => sum + count, 0);
    }

    function totalDaysCanceled() {
        return [...canceledReservationsData?.reservations ?? []]
            .filter(reservation => filterReservation(reservation))
            .map(reservation => differenceInDays(reservation.endDate, reservation.startDate))
            .reduce((sum, count) => sum + count, 0);
    }

    function totalDaysStatistics() {
        const booked = totalDaysBooked();
        const canceled = totalDaysCanceled();

        if (canceled === 0) {
            return `${booked} / 0`;
        }
        else {
            return `${booked} / -${canceled}`;
        }
    }

    function totalPriceBooked() {
        return [...bookedReservationsData?.reservations ?? []]
            .filter(reservation => filterReservation(reservation))
            .map(reservation => parseFloat(reservation.priceAccommodation ?? 0) + parseFloat(reservation.priceCleaning ?? 0))
            .reduce((sum, count) => sum + count, 0);
    }

    function totalPriceCanceled() {
        return [...canceledReservationsData?.reservations ?? []]
            .filter(reservation => filterReservation(reservation))
            .map(reservation => parseFloat(reservation.priceAccommodation ?? 0) + parseFloat(reservation.priceCleaning ?? 0))
            .reduce((sum, count) => sum + count, 0);
    }

    function totalPriceStatistics() {
        const booked = totalPriceBooked().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        const canceled = totalPriceCanceled();

        if (canceled === 0) {
            return `${booked} / 0.00`;
        }
        else {
            const canceledStr = canceled.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            return `${booked} / -${canceledStr}`;
        }
    }

    function averageReservationLengthBooked() {
        const apartmentsCount = [...bookedReservationsData?.reservations ?? []]
            .filter(reservation => filterReservation(reservation)).length;

        if (apartmentsCount === 0) {
            return 0;
        }

        return [...bookedReservationsData?.reservations ?? []]
            .filter(reservation => filterReservation(reservation))
            .map(reservation => differenceInDays(reservation.endDate, reservation.startDate))
            .reduce((sum, count) => sum + count, 0) / apartmentsCount;
    }

    function averageBookingWindowBooked() {
        const apartmentsCount = [...bookedReservationsData?.reservations ?? []]
            .filter(reservation => filterReservation(reservation)).length;

        if (apartmentsCount === 0) {
            return 0;
        }

        return [...bookedReservationsData?.reservations ?? []]
            .filter(reservation => filterReservation(reservation))
            .map(reservation => differenceInDays(reservation.startDate, reservation.bookedAt))
            .reduce((sum, count) => sum + count, 0) / apartmentsCount;
    }

    function averageDailyPriceBooked() {
        const apartmentsCount = [...bookedReservationsData?.reservations ?? []]
            .filter(reservation => filterReservation(reservation)).length;

        if (apartmentsCount === 0) {
            return 0;
        }

        return [...bookedReservationsData?.reservations ?? []]
            .filter(reservation => filterReservation(reservation))
            .map(reservation => (parseFloat(reservation.priceAccommodation ?? 0) + parseFloat(reservation.priceCleaning ?? 0)) / differenceInDays(reservation.endDate, reservation.startDate))
            .reduce((sum, count) => sum + count, 0) / apartmentsCount;
    }

    return (
        <PageHeader
            title="New reservations"
            onBack={() => navigate(-1)}
            extra={[
                <ApartmentTagsSelect
                    value={apartmentTagsSearch}
                    onChange={value => setApartmentTagsSearch(value)}
                    mode="multiple"
                    placeholder="Tags"
                    showLogicalOperator
                    logicalOperator={apartmentTagsLogicalOperator}
                    onLogicalOperatorChange={value => setApartmentTagsLogicalOperator(value)}
                    key="tagsSelect"
                />,
                <Button
                    icon={<LeftOutlined />}
                    onClick={() => setDate(subDays(date, 1))}
                    key="prevDate"
                />,
                <DatePicker
                    value={date}
                    onChange={value => setDate(value)}
                    disabledDate={date => isAfter(date, endOfToday())}
                    allowClear={false}
                    key="datePicker"
                />,
                <Button
                    icon={<RightOutlined />}
                    onClick={() => setDate(addDays(date, 1))}
                    disabled={isEqual(date, startOfToday())}
                    key="nextDate"
                />
            ]}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card loading={bookedReservationsLoading}>
                        <Row gutter={[16, 16]}>
                            <Col flex="1">
                                <Statistic
                                    title="Days booked"
                                    value={totalDaysStatistics()}
                                />
                            </Col>
                            <Col flex="2">
                                <Statistic
                                    title="Total price"
                                    value={totalPriceStatistics()}
                                />
                            </Col>
                            <Col flex="1">
                                <Statistic
                                    title="Avg length"
                                    value={averageReservationLengthBooked().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                />
                            </Col>
                            <Col flex="1">
                                <Statistic
                                    title="Avg booking window"
                                    value={averageBookingWindowBooked().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                />
                            </Col>
                            <Col flex="1">
                                <Statistic
                                    title="Avg daily price"
                                    value={averageDailyPriceBooked().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Table
                        columns={[
                            {
                                title: 'Booked time',
                                render: reservation => format(reservation.bookedAt, 'HH:mm'),
                                sorter: (a, b) => compareAsc(a.bookedAt, b.bookedAt),
                                defaultSortOrder: 'ascend',
                            },
                            {
                                title: 'Apartment',
                                dataIndex: ['apartment'],
                                sorter: (a, b) => a.apartment.name.localeCompare(b.apartment.name),
                                render: apartment => (
                                    <Space>
                                        <Typography.Text>
                                            {apartment.name}
                                        </Typography.Text>
                                        <Tooltip
                                            title={apartment.tags.join(',')}
                                        >
                                            <Tag>
                                                tags
                                            </Tag>
                                        </Tooltip>
                                    </Space>
                                )
                            },
                            {
                                title: 'Start date',
                                render: reservation => format(reservation.startDate, 'yyyy-MM-dd, eee'),
                                sorter: (a, b) => compareAsc(a.startDate, b.startDate),
                            },
                            {
                                title: 'End date',
                                render: reservation => format(reservation.endDate, 'yyyy-MM-dd, eee'),
                                sorter: (a, b) => compareAsc(a.endDate, b.endDate),
                            },
                            {
                                title: 'Guests',
                                dataIndex: 'numberOfGuests',
                                sorter: (a, b) => a.numberOfGuests - b.numberOfGuests,
                            },
                            {
                                title: 'Price',
                                render: reservation => (parseFloat(reservation.priceAccommodation ?? 0) + parseFloat(reservation.priceCleaning ?? 0)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                                align: 'right',
                                sorter: (a, b) => (parseFloat(a.priceAccommodation ?? 0) + parseFloat(a.priceCleaning ?? 0)) - (parseFloat(b.priceAccommodation ?? 0) + parseFloat(b.priceCleaning ?? 0))
                            },
                            {
                                title: 'Daily price',
                                render: reservation => ((parseFloat(reservation.priceAccommodation ?? 0) + parseFloat(reservation.priceCleaning ?? 0)) / differenceInDays(reservation.endDate, reservation.startDate)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                                align: 'right',
                                sorter: (a, b) => ((parseFloat(a.priceAccommodation ?? 0) + parseFloat(a.priceCleaning ?? 0)) / differenceInDays(a.endDate, a.startDate)) - ((parseFloat(b.priceAccommodation ?? 0) + parseFloat(b.priceCleaning ?? 0)) / differenceInDays(b.endDate, b.startDate))
                            },
                            {
                                title: 'Source',
                                dataIndex: 'otaName',
                                sorter: (a, b) => a.otaName.localeCompare(b.otaName),
                            },
                            {
                                title: '',
                                render: reservation => (
                                    <Link to={`/reservations/${reservation.id}`}>
                                        View
                                    </Link>
                                )
                            },
                        ]}
                        dataSource={
                            [...bookedReservationsData?.reservations ?? []]
                                .filter(reservation => filterReservation(reservation))
                        }
                        loading={bookedReservationsLoading}
                        rowKey="id"
                        size="small"
                        pagination={false}
                    />
                </Col>
                <Col span={24}>
                    <Table
                        columns={[
                            {
                                title: 'Canceled time',
                                render: reservation => format(reservation.canceledAt, 'HH:mm'),
                                sorter: (a, b) => compareAsc(a.canceledAt, b.canceledAt),
                                defaultSortOrder: 'ascend',
                            },
                            {
                                title: 'Apartment',
                                dataIndex: ['apartment'],
                                sorter: (a, b) => a.apartment.name.localeCompare(b.apartment.name),
                                render: apartment => (
                                    <Space>
                                        <Typography.Text>
                                            {apartment.name}
                                        </Typography.Text>
                                        <Tooltip
                                            title={apartment.tags.join(',')}
                                        >
                                            <Tag>
                                                tags
                                            </Tag>
                                        </Tooltip>
                                    </Space>
                                )
                            },
                            {
                                title: 'Start date',
                                render: reservation => format(reservation.startDate, 'yyyy-MM-dd, eee'),
                                sorter: (a, b) => compareAsc(a.startDate, b.startDate),
                            },
                            {
                                title: 'End date',
                                render: reservation => format(reservation.endDate, 'yyyy-MM-dd, eee'),
                                sorter: (a, b) => compareAsc(a.endDate, b.endDate),
                            },
                            {
                                title: 'Guests',
                                dataIndex: 'numberOfGuests',
                                sorter: (a, b) => a.numberOfGuests - b.numberOfGuests,
                            },
                            {
                                title: 'Price',
                                render: reservation => (parseFloat(reservation.priceAccommodation ?? 0) + parseFloat(reservation.priceCleaning ?? 0)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                                align: 'right',
                                sorter: (a, b) => (parseFloat(a.priceAccommodation ?? 0) + parseFloat(a.priceCleaning ?? 0)) - (parseFloat(b.priceAccommodation ?? 0) + parseFloat(b.priceCleaning ?? 0))
                            },
                            {
                                title: 'Daily price',
                                render: reservation => ((parseFloat(reservation.priceAccommodation ?? 0) + parseFloat(reservation.priceCleaning ?? 0)) / differenceInDays(reservation.endDate, reservation.startDate)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                                align: 'right',
                                sorter: (a, b) => ((parseFloat(a.priceAccommodation ?? 0) + parseFloat(a.priceCleaning ?? 0)) / differenceInDays(a.endDate, a.startDate)) - ((parseFloat(b.priceAccommodation ?? 0) + parseFloat(b.priceCleaning ?? 0)) / differenceInDays(b.endDate, b.startDate))
                            },
                            {
                                title: 'Source',
                                dataIndex: 'otaName',
                            },
                            {
                                title: '',
                                render: reservation => (
                                    <Link to={`/reservations/${reservation.id}`}>
                                        View
                                    </Link>
                                )
                            },
                        ]}
                        dataSource={
                            [...canceledReservationsData?.reservations ?? []]
                                .filter(reservation => filterReservation(reservation))
                        }
                        loading={canceledReservationsLoading}
                        rowKey="id"
                        size="small"
                        pagination={false}
                    />
                </Col>
            </Row>
        </PageHeader>
    )
}
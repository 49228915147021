import { useParams } from "react-router-dom";
import UserGroupAvailabilityCalendar from "users/components/user-group-availability-calendar/UserGroupAvailabilityCalendar";

export default function ViewUserAvailabilityView() {
    const { userGroupId } = useParams();

    return (
        <UserGroupAvailabilityCalendar
            userGroupId={userGroupId}
        />
    );
}
import { useState } from "react";
import { blue, green, orange, red } from "@ant-design/colors";
import { Form } from "antd";
import { JOB_STATUS_ASSIGNED, JOB_STATUS_BLOCKED, JOB_STATUS_DONE, JOB_STATUS_IN_PROGRESS, JOB_STATUS_UNASSIGNED } from "jobs-new/common";
import JobStatusModal from "jobs-new/components/JobStatusModal";
import "./styles.css";
import classNames from "classnames";

export default function JobStatus(props) {
    const {
        job,
        editable,
        onUpdate,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();

    function handleSubmit() {
        form
            .validateFields()
            .then(values => {
                onUpdate(values.status);
                setModalOpen(false);
            })
    }

    function statusColor() {
        switch (job.status) {
            case JOB_STATUS_UNASSIGNED: return orange.primary;
            case JOB_STATUS_ASSIGNED: return blue.primary;
            case JOB_STATUS_IN_PROGRESS: return blue.primary;
            case JOB_STATUS_BLOCKED: return red.primary;
            case JOB_STATUS_DONE: return green.primary;
            default: return 'Unknown';
        }
    }

    function statusText() {
        switch (job.status) {
            case JOB_STATUS_UNASSIGNED: return 'Unassigned';
            case JOB_STATUS_ASSIGNED: return 'Assigned';
            case JOB_STATUS_IN_PROGRESS: return 'In progress';
            case JOB_STATUS_BLOCKED: return 'Blocked';
            case JOB_STATUS_DONE: return 'Done';
            default: return 'Unknown';
        }
    }

    return (
        <>
            <div className="job-status-container">
                <div
                    className="job-status-badge"
                    style={{
                        backgroundColor: statusColor(),
                    }}
                />
                <span
                    className={classNames({
                        'job-status-link': editable,
                    })}
                    onClick={() => editable && setModalOpen(true)}
                >
                    {statusText()}
                </span>
            </div>
            <JobStatusModal
                open={modalOpen}
                form={form}
                job={job}
                onSubmit={() => handleSubmit()}
                onCancel={() => setModalOpen(false)}
            />
        </>
    )
}
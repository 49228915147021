import { Card, PageHeader } from "antd";
import OrganizationsList from "organizations/components/organizations-list/OrganizationsList";

export default function ListOrganizationsView() {
    return (
        <PageHeader
            title="Organizations"
        >
            <Card>
                <OrganizationsList />
            </Card>
        </PageHeader>
    )
}
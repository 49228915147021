import { Form, Select } from "antd";
import { MOBILE_MENU_ITEMS } from "common/common";
import { useEffect } from "react";
import UserMobileMenuSelect from "users/components/user-mobile-menu-select/UserMobileMenuSelect";

export default function UserGroupMobileMenuForm(props) {
    const {
        userGroup,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            initialRoute: userGroup?.mobileMenu?.initialRoute,
            items: userGroup?.mobileMenu?.items,
        });
    }, [userGroup, form]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="initialRoute"
                label="Initial route"
            >
                <Select
                    options={MOBILE_MENU_ITEMS.map(item => ({
                        title: item,
                        value: item,
                    }))}
                    allowClear
                    style={{
                        width: '150px',
                    }}
                />
            </Form.Item>
            <Form.Item
                name="items"
                label="Items"
            >
                <UserMobileMenuSelect />
            </Form.Item>
        </Form>
    )
}
import { gql, useQuery } from "@apollo/client";
import { Alert, Button, Skeleton } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { compareAsc, formatISO, isEqual, parse } from "date-fns";
import { toggleSelect } from "common/common";
import UnassignedApartmentCleaning from "../apartment-cleaning/UnassignedApartmentCleaning";
import "./style.css";

const QUERY = gql`
    query GetApartmentsForCleaning($date: Date!) {
        apartmentsForCleaning(date: $date) {
            id
            name
            storage {
                id
                name
                order
            }
            previousReservation(date: $date) {
                id
                endDate
                checkoutTime
                numberOfGuests
            }
            arrivingReservation(date: $date) {
                id
                airbnbThreadId
            }
            nextReservation(date: $date) {
                id
                startDate
                checkinTime
                numberOfGuests
            }
            calendar(filter: {dateFrom: $date, dateTo: $date}) {
                id
                available
                minStayAvailable
            }
            jobsNew(filter: {dates: [$date]}) {
                id
            }
        }
    }
`;


export default function ApartmentsForCleaning(props) {
    const {
        date,
        selectedApartmentIds,
        createGroupLoading,
        size,
        onSelectedApartmentIdsChange,
        onCreateCleaningGroup,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            date: formatISO(date, { representation: 'date' }),
        },
        notifyOnNetworkStatusChange: true,
    });

    const isInitialLoading = !data && loading;
    const isRefetching = data && loading;

    if (isInitialLoading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartments"
            />
        );
    }

    function nameSorter(a, b) {
        return a.name.localeCompare(b.name);
    }

    function storageSorter(a, b) {
        return a.storage.order - b.storage.order;
    }

    function arrivalSorter(a, b) {
        const aTodayAvailable = a.calendar[0].available && a.calendar[0].minStayAvailable;
        const bTodayAvailable = b.calendar[0].available && b.calendar[0].minStayAvailable;
        const aHasNextReservation = !!a.nextReservation;
        const bHasNextReservation = !!b.nextReservation;
        const aSameDayArrival = aHasNextReservation && isEqual(a.nextReservation.startDate, date);
        const bSameDayArrival = bHasNextReservation && isEqual(b.nextReservation.startDate, date);

        // Compare apartments on their reservations check-in times
        // If check-in time is unknown - assume default check-in time
        // If today is available and we can receive last-minute reservation - assume default check-in time
        if ((aSameDayArrival || aTodayAvailable) && (bSameDayArrival || bTodayAvailable)) {
            const aCheckinTimeStr = aSameDayArrival
                ? a.nextReservation?.checkinTime ?? '15:00'
                : '15:00';
            const bCheckinTimeStr = bSameDayArrival
                ? b.nextReservation?.checkinTime ?? '15:00'
                : '15:00';
            const aCheckinTime = parse(aCheckinTimeStr, 'HH:mm', new Date());
            const bCheckinTime = parse(bCheckinTimeStr, 'HH:mm', new Date());

            return compareAsc(aCheckinTime, bCheckinTime);
        }
        // Here at least one of the apartments is in a situation where there is no reservation
        // arriving today, and today is not available (blocked or blocked due to min-stay)
        // We put such apartment on the bottom of the list
        else {
            if (!(aSameDayArrival || aTodayAvailable)) {
                return 1;
            }
            if (!(bSameDayArrival || bTodayAvailable)) {
                return -1;
            }

            return 0;
        }
    }

    const apartments = [...data.apartmentsForCleaning]
        .sort(nameSorter)
        .sort(arrivalSorter)
        .sort(storageSorter);

    if (apartments.length === 0) {
        return (
            <Alert
                type="info"
                message="There are no apartments for cleaninig left"
                closable
            />
        );
    }

    return (
        <div className="apartments-for-cleaning-container">
            <div className="apartments-for-cleaning-header">
                <div className="apartments-for-cleaning-title">
                    Apartments for cleaning
                </div>
                <div className="apartments-for-cleaning-info">
                    {data.apartmentsForCleaning.length} apartments
                </div>
            </div>
            <div className="apartments-for-cleaning-content">
                <div className="apartments-for-cleaning-quick-actions-container">
                    <Button
                        onClick={() => onCreateCleaningGroup()}
                        icon={<PlusOutlined />}
                        loading={createGroupLoading}
                    >
                        Create group
                    </Button>
                </div>
                <div className="apartments-for-cleaning-list-container">
                    {apartments.map(apartment => (
                        <UnassignedApartmentCleaning
                            apartmentId={apartment.id}
                            date={date}
                            selected={selectedApartmentIds.find(apartmentId => apartmentId === apartment.id)}
                            size={size}
                            onSelect={apartmentId => onSelectedApartmentIdsChange(toggleSelect(selectedApartmentIds, apartmentId, (a, b) => a === b))}
                            disabled={isRefetching}
                            key={apartment.id}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
import { Button } from "antd";
import "./style.css"

const NODE_CATEGORIES = [
    {
        category: 'Triggers',
        nodes: [
            {
                label: 'User created',
                key: 'user_created'
            },
            {
                label: 'User updated',
                key: 'user_updated'
            },
            {
                label: 'User deleted',
                key: 'user_deleted'
            },
            {
                label: 'User logged in',
                key: 'user_logged_in'
            },
            {
                label: "User logged out",
                key: "user_logged_out"
            },
            {
                label: "User availability updated",
                key: "user_availability_updated"
            },
            {
                label: "User relation created",
                key: "user_relation_created"
            },
            {
                label: "User relation deleted",
                key: "user_relation_deleted"
            },
            {
                label: "User group created",
                key: "user_group_created"
            },
            {
                label: "User group updated",
                key: "user_group_updated"
            },
            {
                label: "User group deleted",
                key: "user_group_deleted"
            },
            {
                label: "User group added user",
                key: "user_group_added_user"
            },
            {
                label: "User group removed user",
                key: "user_group_removed_user"
            },
            {
                label: "File created",
                key: "file_created"
            },
            {
                label: "Storage created",
                key: "storage_created"
            },
            {
                label: "Storage updated",
                key: "storage_updated"
            },
            {
                label: "Storage deleted",
                key: "storage_deleted"
            },
            {
                label: "Apartment created",
                key: "apartment_created"
            },
            {
                label: "Apartment updated",
                key: "apartment_updated"
            },
            {
                label: "Apartment deleted",
                key: "apartment_deleted"
            },
            {
                label: "Apartment calendar updated",
                key: "apartment_calendar_updated"
            },
            {
                label: "Apartment channel created",
                key: "apartment_channel_created"
            },
            {
                label: "Apartment channel deleted",
                key: "apartment_channel_deleted"
            },
            {
                label: "Apartment expense created",
                key: "apartment_expense_created"
            },
            {
                label: "Apartment expense updated",
                key: "apartment_expense_updated"
            },
            {
                label: "Apartment expense deleted",
                key: "apartment_expense_deleted"
            },
            {
                label: "Apartment price config updated",
                key: "apartment_price_config_updated"
            },
            {
                label: "Reservation confirmed",
                key: "reservation_confirmed"
            },
            {
                label: "Reservation updated",
                key: "reservation_updated"
            },
            {
                label: "Reservation canceled",
                key: "reservation_canceled"
            },
            {
                label: "Reservation message received",
                key: "reservation_message_received"
            },
            {
                label: "Reservation message sent",
                key: "reservation_message_sent"
            },
            {
                label: "Reservation review received",
                key: "reservation_review_received"
            },
            {
                label: "Reservation guest review sent",
                key: "reservation_guest_review_sent"
            },
            {
                label: "Cleaning group created",
                key: "cleaning_group_created"
            },
            {
                label: "Cleaning group submitted",
                key: "cleaning_group_submitted"
            },
            {
                label: "Cleaning group confirmed",
                key: "cleaning_group_confirmed"
            },
            {
                label: "Cleaning group started",
                key: "cleaning_group_started"
            },
            {
                label: "Cleaning group finished",
                key: "cleaning_group_finished"
            },
            {
                label: "Cleaning group apartment created",
                key: "cleaning_group_apartment_created"
            },
            {
                label: "Cleaning group apartment updated",
                key: "cleaning_group_apartment_updated"
            },
            {
                label: "Cleaning group apartment deleted",
                key: "cleaning_group_apartment_deleted"
            },
            {
                label: "Cleaning group apartment started",
                key: "cleaning_group_apartment_started"
            },
            {
                label: "Cleaning group apartment finished",
                key: "cleaning_group_apartment_finished"
            },
            {
                label: "Cleaning group cleaner added",
                key: "cleaning_group_cleaner_added"
            },
            {
                label: "Cleaning group cleaner removed",
                key: "cleaning_group_cleaner_removed"
            },
            {
                label: "Job created",
                key: "job_created"
            },
            {
                label: "Job updated",
                key: "job_updated"
            },
            {
                label: "Job deleted",
                key: "job_deleted"
            },
            {
                label: "Job assigner created",
                key: "job_assigner_created"
            },
            {
                label: "Job assigner removed",
                key: "job_assigner_removed"
            },
            {
                label: "Job comment created",
                key: "job_comment_created"
            },
            {
                label: "Job comment updated",
                key: "job_comment_updated"
            },
            {
                label: "Job comment deleted",
                key: "job_comment_deleted"
            },
        ],
    },
    {
        category: 'Flow control',
        nodes: [
            {
                label: "For each",
                key: "for_each"
            },
            {
                label: 'IF',
                key: "if"
            },
        ],
    },
    {
        category: 'Logical',
        nodes: [
            {
                label: "AND",
                key: "and"
            },
            {
                label: "OR",
                key: "or"
            },
            {
                label: '==',
                key: 'eq',
            },
            {
                label: ">",
                key: "gt"
            },
            {
                label: ">=",
                key: "gte"
            },
            {
                label: "<",
                key: "lt"
            },
            {
                label: "<=",
                key: "lte"
            },
            {
                label: "NOT",
                key: "not"
            },
            {
                label: "Contains",
                key: "contains"
            },
        ],
    },
    {
        category: 'Arithmetic',
        nodes: [
            {
                label: "+",
                key: "add"
            },
            {
                label: "-",
                key: "subtract"
            },
            {
                label: "*",
                key: "multiply"
            },
            {
                label: "/",
                key: "divide"
            },
        ]
    },
    {
        category: 'Manipulation',
        nodes: [
            {
                label: 'Extract',
                key: 'extract'
            },
            {
                label: 'Combine',
                key: 'combine',
            },
        ],
    },
    {
        category: 'Value',
        nodes: [
            {
                label: 'String',
                key: 'string',
            },
            {
                label: 'Number',
                key: 'number',
            },
            {
                label: 'Date',
                key: 'date',
            },
            {
                label: 'Date time',
                key: 'datetime',
            },
            {
                label: "Today",
                key: "today"
            },
            {
                label: "Now",
                key: "now"
            },
        ],
    },
    {
        category: 'Action',
        nodes: [
            {
                label: "Create apartment",
                key: "create_apartment"
            },
            {
                label: "Update apartment",
                key: "update_apartment"
            },
            {
                label: "Delete apartment",
                key: "delete_apartment"
            },
            {
                label: "Update apartment calendar",
                key: "update_apartment_calendar"
            },
            {
                label: "Sync apartment calendar down",
                key: "sync_apartment_calendar_down"
            },
            {
                label: "Sync apartment calendar up",
                key: "sync_apartment_calendar_up"
            },
            {
                label: "Create apartment expense",
                key: "create_apartment_expense"
            },
            {
                label: "Update apartment expense",
                key: "update_apartment_expense"
            },
            {
                label: "Delete apartment expense",
                key: "delete_apartment_expense"
            },
            {
                label: "Update apartment price config",
                key: "update_apartment_price_config"
            },
            {
                label: "Sync apartment reservations down",
                key: "sync_apartment_reservations_down"
            },
            {
                label: "Update cleaning group",
                key: "update_cleaning_group"
            },
            {
                label: "Delete cleaning group",
                key: "delete_cleaning_group"
            },
            {
                label: "Update cleaning group status",
                key: "update_cleaning_group_status"
            },
            {
                label: "Update cleaning group apartment",
                key: "update_cleaning_group_apartment"
            },
            {
                label: "Delete cleaning group apartment",
                key: "delete_cleaning_group_apartment"
            },
            {
                label: "Update cleaning group apartment status",
                key: "update_cleaning_group_apartment_status"
            },
            {
                label: "Upload file",
                key: "upload_file"
            },
            {
                label: "Create job",
                key: "create_job"
            },
            {
                label: "Update job",
                key: "update_job"
            },
            {
                label: "Create job",
                key: "delete_job"
            },
            {
                label: "Update job status",
                key: "update_job_status"
            },
            {
                label: "Add job assigner",
                key: "add_job_assigner"
            },
            {
                label: "Remove job assigner",
                key: "remove_job_assigner"
            },
            {
                label: "Create job comment",
                key: "create_job_comment"
            },
            {
                label: "Create reservation",
                key: "create_reservation"
            },
            {
                label: "Update reservation",
                key: "update_reservation"
            },
            {
                label: "Cancel reservation",
                key: "cancel_reservation"
            },
            {
                label: "Send reservation message",
                key: "send_reservation_message"
            },
            {
                label: "Send reservation guest review",
                key: "send_reservation_guest_review"
            },
            {
                label: "Sync reservation review",
                key: "sync_reservation_review"
            },
            {
                label: "Create storage",
                key: "create_storage"
            },
            {
                label: "Update storage",
                key: "update_storage"
            },
            {
                label: "Delete storage",
                key: "delete_storage"
            },
            {
                label: "Create user",
                key: "create_user"
            },
            {
                label: "Update user",
                key: "update_user"
            },
            {
                label: "Delete user",
                key: "delete_user"
            },
            {
                label: "Create user group",
                key: "create_user_group"
            },
            {
                label: "Update user group",
                key: "update_user_group"
            },
            {
                label: "Delete user group",
                key: "delete_user_group"
            },
            {
                label: "Add user to user group",
                key: "add_user_to_user_group"
            },
            {
                label: "Remove user from user group",
                key: "remove_user_from_user_group"
            },
            {
                label: "Create user relation",
                key: "create_user_relation"
            },
            {
                label: "Delete user relation",
                key: "delete_user_relation"
            },
            {
                label: "Send email",
                key: "send_email"
            },
            {
                label: "Send SMS",
                key: "send_sms"
            },
            {
                label: "Send notification",
                key: "send_notification"
            },
            {
                label: "Generate PDF",
                key: "generate_pdf"
            },
        ],
    },
];

export default function NodesPanel(props) {
    const {
        onClick,
    } = props;

    return (
        <div className="nodes-panel-container">
            {NODE_CATEGORIES.map(categoryItem => (
                <div
                    className="nodes-panel-category-container"
                    key={categoryItem.category}
                >
                    <div className="nodes-panel-category-header">
                        {categoryItem.category}
                    </div>
                    {categoryItem.nodes.map(nodeItem => (
                        <div key={nodeItem.key}>
                            <Button
                                onClick={() => onClick(nodeItem.key)}
                                block
                            >
                                {nodeItem.label}
                            </Button>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
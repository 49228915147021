import { contains } from "common/common";
import { gql, useQuery } from "@apollo/client";
import { useAuth } from "auth";
import "./style.css";

const QUERY = gql`
    query GetDataForCleaningAvailability($date: Date!) {
        organization(organizationId: "self") {
            id
            cleaningUserGroups {
                id
                users {
                    id
                    availability (filter: {dateFrom: $date, dateTo: $date}) {
                        id
                        availability
                    }
                }
            }
            userAvailabilityTypes {
                id
                availability
                isAvailable
            }
            userGroups {
                id
                userAvailabilityTypes {
                    id
                    availability
                    isAvailable
                }
            }
        }
        cleaningStatistics (filter: {dateFrom: $date, dateTo: $date}) {
            date
            apartmentsCount
        }
    }
`;

export default function CleaningDayStatisticsBadge(props) {
    const {
        date,
    } = props;

    const { permissions } = useAuth();
    const hasPermissions = contains(permissions, ['user:get:self', 'user_group:get', 'user_availability:get:self']);

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            date,
        },
        skip: !hasPermissions,
    });

    const availableAvailabilityTypes = [
        ...[...data?.organization?.userAvailabilityTypes ?? []],
        ...[...data?.organization?.userGroups ?? []].map(userGroup => userGroup.userAvailabilityTypes).flat(),
    ]
        .filter(availabilityType => availabilityType.isAvailable)
        .map(availabilityType => availabilityType.availability);

    function getAvailableCleanersCountOnDate() {
        const availableCleaners = [...data?.organization?.cleaningUserGroups ?? []]
            .map(userGroup => userGroup.users)
            .flat()
            .filter(user => {
                const availability = user.availability?.[0]?.availability;
                return availability && availableAvailabilityTypes.includes(availability);
            })

        return availableCleaners.length;
    }

    function getCleaningApartmentsCountOnDate() {
        return data?.cleaningStatistics?.[0]?.apartmentsCount ?? 0;
    }

    function getAverageApartmentsPerCleaner() {
        const cleanersCount = getAvailableCleanersCountOnDate();
        const cleaningApartmentsCount = getCleaningApartmentsCountOnDate();

        if (cleanersCount === 0) {
            return 0;
        }

        return cleaningApartmentsCount / cleanersCount;
    }

    return (
        <div className="cleaning-day-statistics-badge-container">
            {loading && "…"}
            {error && "!"}
            {data && getAverageApartmentsPerCleaner().toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
        </div>
    );
}
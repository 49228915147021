import { Dropdown } from "antd";

export default function ContextMenu(props) {
    const {
        trigger,
        optionsDisabled,
        addApartmentDisabled,
        submitDisabled,
        withdrawDisabled,
        confirmDisabled,
        copyAsTexrtDisabled,
        deleteDisabled,
        onClick,
        children,
    } = props;

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        label: 'Options',
                        key: 'edit',
                        disabled: optionsDisabled,
                    },
                    {
                        label: 'Add apartment',
                        key: 'addApartment',
                        disabled: addApartmentDisabled,
                    },
                    {
                        label: 'Submit',
                        key: 'submit',
                        disabled: submitDisabled,
                    },
                    {
                        label: 'Withdraw',
                        key: 'withdraw',
                        disabled: withdrawDisabled,
                    },
                    {
                        label: 'Confirm',
                        key: 'confirm',
                        disabled: confirmDisabled,
                    },
                    {
                        label: 'Copy as text',
                        key: 'copyAsText',
                        disabled: copyAsTexrtDisabled,
                    },
                    {
                        label: 'Delete',
                        key: 'delete',
                        danger: true,
                        disabled: deleteDisabled,
                    },
                ],
                onClick: ({ key }) => onClick(key),
            }}
            trigger={trigger}
        >
            {children}
        </Dropdown>
    )
}
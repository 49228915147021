import { Select } from "antd";


export default function TimeSelect(props) {
    const {
        value,
        onChange,
        hours: _hours,
        minutes: _minutes,
        ...otherProps
    } = props;

    const hours = _hours ?? [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    const minutes = _minutes ?? [0, 30];

    const options = hours.map(hour => minutes.map(minute => `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`)).flat();

    return (
        <Select
            options={
                options.map(option => ({
                    label: option,
                    value: option,
                }))
            }
            value={value}
            onChange={value => onChange(value)}
            style={{
                width: '150px',
            }}
            {...otherProps}
        />
    );
}
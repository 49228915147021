import { PlusOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Col, Form, List, Modal, Row, Skeleton, message } from "antd";
import { compareAsc, isBefore, startOfToday } from "date-fns";
import { useState } from "react";
import ListItem from "./ListItem";
import ModalForm from "./ModalForm";
import { taskPromise } from "common/task";

const QUERY = gql`
    query GetApartmentCalendarRestrictions($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            calendarRestrictions {
                id
                date
                minStay
            }
        }
    }
`;

const CREATE_MUTATION = gql`
    mutation CreateApartmentCalendarRestriction($input: CreateApartmentCalendarRestrictionInput!) {
        createApartmentCalendarRestriction(input: $input) {
            error {
                type
                message
            }
            apartmentCalendarRestriction {
                id
                date
                minStay
            }
            recalculateApartmentCalendarTask {
                id
            }
        }
    }
`;

export default function CalendarRestrictionsList(props) {
    const {
        apartmentId,
    } = props;

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);

    const { data, loading, error } = useQuery(QUERY, { variables: { apartmentId } });
    const [createApartmentCalendarRestriction] = useMutation(CREATE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Apartment',
                    id: apartmentId,
                }),
                fieldName: 'calendarRestrictions',
            });
        },
    });

    const [createForm] = Form.useForm();

    function handleCreate() {
        setCreateLoading(true);
        createForm
            .validateFields()
            .then(values => {
                return createApartmentCalendarRestriction({
                    variables: {
                        input: {
                            apartmentId,
                            date: values.date,
                            minStay: values.minStay,
                        },
                    },
                })
                    .then(response => {
                        const error = response.data.createApartmentCalendarRestriction.error;
                        if (error) {
                            message.error('Failed to create calendar restriction');
                            setCreateLoading(false);
                        }
                        else {
                            taskPromise(response.data.createApartmentCalendarRestriction.recalculateApartmentCalendarTask.id)
                                .then(() => {
                                    message.success('Calendar restriction created');
                                    setCreateLoading(false);
                                    setCreateModalOpen(false);
                                });
                        }
                    })
            });
    }

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Faield to load calendar restrictions"
            />
        );
    }

    return (
        <List
            dataSource={
                [...data.apartment.calendarRestrictions ?? []]
                    .filter(apartmentCalendarRestriction => !isBefore(apartmentCalendarRestriction.date, startOfToday()))
                    .sort((a, b) => compareAsc(a.date, b.date))
            }
            renderItem={apartmentCalendarRestriction => (
                <ListItem
                    apartmentCalendarRestriction={apartmentCalendarRestriction}
                    apartmentId={apartmentId}
                />
            )}
            footer={
                <Row justify="end">
                    <Col>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setCreateModalOpen(true)}
                        >
                            Create calendar restriction
                        </Button>
                        <Modal
                            title="Create calendar restriction"
                            open={createModalOpen}
                            destroyOnClose
                            onCancel={() => setCreateModalOpen(false)}
                            onOk={() => handleCreate()}
                            okText="Create"
                            okButtonProps={{
                                icon: <PlusOutlined />,
                                loading: createLoading,
                            }}
                        >
                            <ModalForm
                                form={createForm}
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 8,
                                }}
                            />
                        </Modal>
                    </Col>
                </Row>
            }
            rowKey="id"
        />
    );
}
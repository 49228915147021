import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING, STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING, STATISTICS_REFERENCE_DATE_MAPPING } from 'statistics/common';
import { addMonths, endOfMonth, isAfter, startOfMonth, startOfToday, subMonths } from 'date-fns';

import { CloseCircleOutlined } from '@ant-design/icons';
import DatePicker from 'components/DatePicker';
import endOfToday from 'date-fns/endOfToday';
import { useState } from 'react';
import ApartmentSelect from 'apartments/components/apartment-select/ApartmentSelect';

const DEFAULT_CHART_SETTINGS = {
    name: "",
    fieldName: "revenueGrossTotal",
    referenceDate: "accommodationDate",
    dateRange: [
        subMonths(startOfMonth(startOfToday()), 3),
        addMonths(endOfMonth(startOfToday()), 1),
    ],
    interval: "week",
    series: [],
};

export default function StatisticsChartSettingsModal(props) {
    const { settings: incomingSettings, visible, onSubmit, onCancel } = props;

    const [settings, setSettings] = useState(incomingSettings || DEFAULT_CHART_SETTINGS);
    const [referenceDate, setReferenceDate] = useState(settings.referenceDate);
    const [form] = Form.useForm();

    const fieldsMapping = referenceDate === 'accommodationDate'
        ? STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING
        : STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING;

    function handleModalOk() {
        form.submit();
    }

    function handleFormSubmit(values) {
        setSettings(values);
        onSubmit(values);
    }

    function handleModalCancel() {
        form.resetFields();
        if (onCancel) {
            onCancel();
        }
    }

    return (
        <Modal
            title="Chart configuration"
            visible={visible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            width={600}
        >
            <Form
                layout="vertical"
                initialValues={settings}
                onFinish={handleFormSubmit}
                form={form}
            >
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: "Name is required" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Reference date"
                            name="referenceDate"
                            rules={[{ required: true, message: "Rerefence date is required" }]}
                        >
                            <Select
                                options={
                                    Object.entries(STATISTICS_REFERENCE_DATE_MAPPING)
                                        .map(([key, value]) => ({ label: value, value: key }))
                                }
                                onChange={setReferenceDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Date range"
                            name="dateRange"
                            rules={[{ required: true, message: "Date range is required" }]}
                        >
                            <DatePicker.RangePicker
                                allowClear={false}
                                disabledDate={date => referenceDate === 'bookedDate' && isAfter(date, endOfToday())}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Interval"
                            name="interval"
                            rules={[{ required: true, message: "Interval is required" }]}
                        >
                            <Select
                                placeholder="Interval"
                                options={[
                                    { label: 'Month', value: 'month' },
                                    { label: 'Week', value: 'week' },
                                    { label: 'Day', value: 'day' },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="fieldName"
                            label="Field"
                            rules={[{ required: true, message: "Field is required" }]}
                        >
                            <Select
                                placeholder="Field"
                                options={
                                    Object.entries(fieldsMapping)
                                        .map(([key, value]) => ({ label: value, value: key }))
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List
                    label="Series"
                    name="series"
                >
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                <Row
                                    key={key}
                                    gutter={[16, 16]}
                                    align="middle"
                                >
                                    <Col flex={1}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            rules={[{ required: true, message: "Name is required" }]}
                                        >
                                            <Input placeholder="Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col flex={1}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'apartments']}
                                            rules={[{ required: true, message: "Apartments list can't be empty" }]}
                                        >
                                            <ApartmentSelect mode="multiple" />
                                        </Form.Item>
                                    </Col>
                                    <Col flex="30px">
                                        <Form.Item>
                                            <CloseCircleOutlined
                                                onClick={() => remove(name)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    block
                                    onClick={() => add()}
                                >
                                    Add series
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
}
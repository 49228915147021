import { SyncOutlined } from "@ant-design/icons";
import { Button, Form, Input, Switch, message } from "antd";
import { useState } from "react";
import { generatePassword } from "users/common";

export default function UserPasswordForm(props) {
    const {
        user,
        form,
        forceChangePassword,
        ...otherProps
    } = props;

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
    const confirmChangePassword = Form.useWatch('confirmChangePassword', form);
    const changePassword = forceChangePassword || confirmChangePassword;

    function doGeneratePassword() {
        const password = generatePassword();
        setPasswordVisible(true);
        setPasswordConfirmVisible(true);
        form.setFieldValue('password', password);
        form.setFieldValue('passwordConfirm', password);
        navigator.clipboard.writeText(password);
        message.info("Password copied to clipboard");
    }

    return (
        <Form
            form={form}
            {...otherProps}
        >
            {!forceChangePassword && (
                <Form.Item
                    name="confirmChangePassword"
                    label="Change password"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            )}
            {changePassword && (
                <>
                    <Form.Item
                        label="Generate password"
                    >
                        <Button
                            type="primary"
                            onClick={() => doGeneratePassword()}
                            icon={<SyncOutlined />}
                        >
                            Generate
                        </Button>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: 'Password is required' }]}
                    >
                        <Input.Password
                            visibilityToggle={{
                                visible: passwordVisible,
                                onVisibleChange: () => setPasswordVisible(!passwordVisible),
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="passwordConfirm"
                        label="Confirm password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            { required: true, message: 'Confirm password is required' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Password does not match'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            visibilityToggle={{
                                visible: passwordConfirmVisible,
                                onVisibleChange: () => setPasswordConfirmVisible(!passwordConfirmVisible),
                            }}
                        />
                    </Form.Item>
                </>
            )}
        </Form>
    )
}
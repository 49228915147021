import { Col, Form, InputNumber, Row } from "antd";
import StorageSelect from "storages/components/storage-select/StorageSelect";
import { useAuth } from "auth";
import { useEffect } from "react";

export default function ApartmentCleaningForm(props) {
    const {
        apartment,
        form,
        apartmentLocation,
        ...otherProps
    } = props;

    const { user } = useAuth();
    const currency = user?.organization?.currency;

    useEffect(() => {
        form?.setFieldsValue({
            storageId: apartment?.storage?.id,
            cleaningPrice: apartment?.cleaningPrice,
        });
    }, [apartment, form]);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form
                    form={form}
                    {...otherProps}
                >
                    <Form.Item
                        name="storageId"
                        label="Storage"
                        tooltip="This storage will be used to supply your apartment"
                        rules={[{ required: true, message: 'Storage is required' }]}
                    >
                        <StorageSelect
                            sortBy="distance"
                            showDistance
                            distancePoint={apartmentLocation}
                        />
                    </Form.Item>
                    <Form.Item
                        name="cleaningPrice"
                        label="Cleaning price"
                        tooltip="Every reservation will be charged with this amount for cleaning"
                        rules={[{ required: true, message: 'Cleaning price is required' }]}
                    >
                        <InputNumber
                            min={0}
                            addonAfter={currency}
                        />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
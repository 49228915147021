import { Button, Dropdown } from "antd";
import { SORT_BY_APARTMENT, SORT_BY_CLEANER } from "./common";

export default function CleaningListSortBy(props) {
    const {
        sortBy,
        onChange,
    } = props;

    const labels = {
        [SORT_BY_APARTMENT]: 'Sort by apartment',
        [SORT_BY_CLEANER]: 'Sort by cleaner',
    };

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        label: 'Apartment',
                        key: SORT_BY_APARTMENT,
                    },
                    {
                        label: 'Cleaner',
                        key: SORT_BY_CLEANER,
                    },
                ],
                selectedKeys: [sortBy],
                onClick: ({ key }) => onChange(key),
            }}
        >
            <Button>
                {labels[sortBy]}
            </Button>
        </Dropdown>
    )
}
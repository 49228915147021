import { Col, Form, InputNumber, Row, Switch } from "antd";
import { useAuth } from "auth";
import { useEffect } from "react";

export default function ApartmentGuestsForm(props) {
    const {
        apartment,
        form,
        ...otherProps
    } = props;

    const { user } = useAuth();

    useEffect(() => {
        form?.setFieldsValue({
            maxGuestCount: apartment?.maxGuestCount,
            enableExtraGuestFee: apartment?.increasePriceAbove && apartment?.increasePriceBy,
            increasePriceAbove: apartment?.increasePriceAbove,
            increasePriceBy: apartment?.increasePriceBy,
        });
    }, [apartment, form]);

    const maxGuestCount = Form.useWatch('maxGuestCount', form);
    const enableExtraGuestFee = Form.useWatch('enableExtraGuestFee', form);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form
                    form={form}
                    {...otherProps}
                >
                    <Form.Item
                        name="maxGuestCount"
                        label="Maximum guest count"
                        tooltip="Maximum number of guests this apartment can accommodate"
                        rules={[{ required: true, message: 'Maximum accommodation is required' }]}
                    >
                        <InputNumber
                            min={1}
                            addonAfter="guests"
                        />
                    </Form.Item>
                    <Form.Item
                        name="enableExtraGuestFee"
                        label="Enable extra guest fee"
                        tooltip="If enabled, additional daily fee will be charged per extra guests"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    {enableExtraGuestFee && (
                        <>
                            <Form.Item
                                name="increasePriceAbove"
                                label="Base guest count"
                                tooltip="Base number of guests above which the extra guest fee will be charged"
                                rules={[{ required: true, message: 'Base guest count is required' }]}
                            >
                                <InputNumber
                                    min={1}
                                    max={maxGuestCount - 1}
                                    addonAfter="guests"
                                />
                            </Form.Item>
                            <Form.Item
                                name="increasePriceBy"
                                label="Extra guest fee"
                                tooltip="Amount of extra guest fee which will be charged per each guest above base guest count"
                                rules={[{ required: true, message: 'Extra guest fee is required' }]}
                            >
                                <InputNumber
                                    min={0}
                                    addonAfter={user?.organization?.currency}
                                />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Col>
        </Row>
    );
}
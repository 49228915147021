import { gql, useQuery } from "@apollo/client";
import { Alert } from "antd";
import { formatISO } from "date-fns";
import { LoadingOutlined } from "@ant-design/icons";

const QUERY = gql`
    query GetReservation($reservationId: ID!) {
        reservation(reservationId: $reservationId) {
            id
            startDate
            endDate
            guestFirstName
            guestLastName
            guestPhone
        }
    }
`;

export default function ReservationInformation(props) {
    const {
        reservationId
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { reservationId } });

    if (loading) {
        // The popover container jumps when content size changes
        // so we create the loading container with the same dimensions
        // as the loaded one
        return (
            <div className="reservation-information-container">
                <LoadingOutlined />
            </div>
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load reservation"
            />
        );
    }

    return (
        <div className="reservation-information-container">
            <div className="reservation-information-row">
                <div className="reservation-information-label">
                    Dates
                </div>
                <div className="reservation-information-value">
                    {formatISO(data.reservation.startDate, { representation: 'date' })} - {formatISO(data.reservation.endDate, { representation: 'date' })}
                </div>
            </div>
            <div className="reservation-information-row">
                <div className="reservation-information-label">
                    Guest
                </div>
                <div className="reservation-information-value">
                    {data.reservation.guestFirstName} {data.reservation.guestLastName}
                </div>
            </div>
            <div className="reservation-information-row">
                <div className="reservation-information-label">
                    Phone
                </div>
                <div className="reservation-information-value">
                    <a href={`tel:${data.reservation.guestPhone}`}>
                        {data.reservation.guestPhone}
                    </a>
                </div>
            </div>
        </div>
    );
}
import { Form, Modal, Select } from "antd";
import { addMonths, format, formatISO, parseISO, startOfMonth, startOfToday, subDays } from "date-fns";
import { useEffect } from "react";

export default function ReservationsReportModal(props) {
    const {
        open,
        loading,
        onClose,
        onSubmit,
    } = props;

    const months = [
        addMonths(startOfMonth(startOfToday()), 3),
        addMonths(startOfMonth(startOfToday()), 2),
        addMonths(startOfMonth(startOfToday()), 1),
        addMonths(startOfMonth(startOfToday()), 0),
        addMonths(startOfMonth(startOfToday()), -1),
        addMonths(startOfMonth(startOfToday()), -2),
        addMonths(startOfMonth(startOfToday()), -3),
    ];

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldValue(
            'month',
            formatISO(addMonths(startOfMonth(startOfToday()), -1), { representation: 'date' }),
        );
    })

    function handleSubmit() {
        form
            .validateFields()
            .then(values => {
                const dateFrom = parseISO(values.month);
                const dateTo = subDays(addMonths(dateFrom, 1), 1);

                onSubmit({ dateFrom, dateTo });
            })
    }

    return (
        <Modal
            open={open}
            title="Select period"
            destroyOnClose
            forceRender
            okText="Generate report"
            okButtonProps={{
                loading,
            }}
            onCancel={() => onClose()}
            onOk={() => handleSubmit()}
        >
            <Form
                form={form}
                preserve={false}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 12,
                }}
            >
                <Form.Item
                    name="month"
                    label="Month"
                >
                    <Select
                        options={
                            months
                                .map(month => ({
                                    label: format(month, 'MMMM yyyy'),
                                    value: formatISO(month, { representation: 'date' }),
                                }))
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
import { gql, useQuery } from "@apollo/client";
import { Alert, Card } from "antd";
import CleaningGroupTimeline from "./CleaningGroupTimeline";
import { addHours, startOfToday } from "date-fns";
import "./style.css";

const QUERY = gql`
    query GetCleaningGroupsForCleaningTimeline($date: Date!) {
        cleaningGroups(filter: {dates: [$date]}) {
            id
            apartments {
                id
                apartment {
                    id
                    name
                }
                status
                statusChanges {
                    id
                    status
                    updatedAt
                    updater {
                        id
                        firstName
                        lastName
                    }
                }
            }
            cleaners {
                id
                firstName
                lastName
            }
            status
            statusChanges {
                id
                status
                updatedAt
                updater {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

export default function CleaningTimelineList(props) {
    const {
        date,
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { date } });

    if (loading) {
        return (
            <Card loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load cleaning timeline"
            />
        );
    }

    return (
        <Card>
            <div className="cleaning-timeline-list-container">
                {data.cleaningGroups.map(cleaningGroup => (
                    <CleaningGroupTimeline
                        cleaningGroupId={cleaningGroup.id}
                        timelineStart={addHours(startOfToday(), 8)}
                        timelineEnd={addHours(startOfToday(), 17)}
                        key={cleaningGroup.id}
                    />
                ))}
            </div>
        </Card>
    );
}
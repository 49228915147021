import { useState } from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Alert, Button, Skeleton } from "antd";
import { CheckCircleFilled, CloseCircleFilled, CloseOutlined } from "@ant-design/icons";
import { compareDesc, format, formatISO } from "date-fns";
import "./style.css";

const QUERY = gql`
    query GetDataForDayDetails($apartmentId: ID!, $date: Date!) {
        apartment(apartmentId: $apartmentId) {
            id
            name
            calendar(filter: {dateFrom: $date, dateTo: $date}) {
                id
                available
                comment
                minStay
                minStayAvailable
                price
                priceBeforeUnavailable
                changes {
                    id
                    available
                    changedAt
                    comment
                    minStay
                    price
                    updater {
                        id
                        firstName
                        lastName
                    }
                }
            }
            priceConfigs(filter: {dateFrom: $date, dateTo: $date}) {
                id
                minPrice
                delta
                slope
                changes {
                    id
                    minPrice
                    delta
                    slope
                    changedAt
                }
            }
            reservations(filter: {startDateLte: $date, endDateGt: $date}) {
                id
                status
                startDate
                endDate
                bookedAt
                canceledAt
            }
        }
    }
`;

export default function DayDetails(props) {
    const {
        apartmentId,
        date,
        onClose,
    } = props;

    const [calendarChangesShowAll, setCalendarChangesShowAll] = useState(false);
    const [priceConfigChangesShowAll, setPriceConfigChangesShowAll] = useState(false);
    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            apartmentId,
            date: formatISO(date, { representation: 'date' }),
        },
    });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load day details"
            />
        );
    }

    const reservations = [...data.apartment.reservations]
        .sort((a, b) => compareDesc(a.bookedAt, b.bookedAt));

    const calendarChangesSorted = [...data.apartment.calendar[0]?.changes]
        .sort((a, b) => compareDesc(a.changedAt, b.changedAt));

    const calendarChanges = calendarChangesShowAll
        ? calendarChangesSorted
        : calendarChangesSorted.slice(0, 5);

    const priceConfigChangesSorted = [...data.apartment.priceConfigs[0].changes]
        .sort((a, b) => compareDesc(a.changedAt, b.changedAt));

    const priceConfigChanges = priceConfigChangesShowAll
        ? priceConfigChangesSorted
        : priceConfigChangesSorted.slice(0, 5);

    return (
        <div className="calendar-day-details-container">
            <div className="calendar-day-details-header">
                <div className="calendar-day-details-header-label">
                    <div className="calendar-day-details-header-date">
                        {formatISO(date, { representation: 'date' })}
                    </div>
                    <div className="calendar-day-details-header-apartment-name">
                        {data.apartment.name}
                    </div>
                </div>
                <div className="calendar-day-details-header-close">
                    <CloseOutlined
                        onClick={() => onClose()}
                    />
                </div>
            </div>
            <div className="calendar-day-details-section">
                <div className="calendar-day-details-section-name">
                    Reservations
                </div>
                {reservations.length === 0 && (
                    <Alert
                        type="info"
                        showIcon
                        message="No reservations on selected day"
                    />
                )}
                <div className="calendar-day-details-reservations-history">
                    {reservations.map(reservation => (
                        <div
                            className="calendar-day-details-reservation"
                            key={reservation.id}
                        >
                            <div className="calendar-day-details-reservation-status">
                                {reservation.status === 'confirmed' && (
                                    <CheckCircleFilled
                                        style={{
                                            color: '#52c41a',
                                        }}
                                    />
                                )}
                                {reservation.status === 'canceled' && (
                                    <CloseCircleFilled
                                        style={{
                                            color: '#f5222d',
                                        }}
                                    />
                                )}
                            </div>
                            <div className="calendar-day-details-reservation-right">
                                <div className="calendar-day-details-reservation-dates">
                                    <Link to={`/reservations/${reservation.id}`}>
                                        {format(reservation.startDate, 'yyyy-MM-dd')} &mdash; {format(reservation.endDate, 'yyyy-MM-dd')}
                                    </Link>
                                </div>
                                <div className="calendar-day-details-reservation-booked-at">
                                    <span className="calendar-day-details-reservation-label">Booked at:</span> {format(reservation.bookedAt, 'yyyy-MM-dd HH:mm')}
                                </div>
                                {reservation.canceledAt && (
                                    <div className="calendar-day-details-reservation-canceled-at">
                                        <span className="calendar-day-details-reservation-label">Canceled at:</span> {format(reservation.canceledAt, 'yyyy-MM-dd HH:mm')}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="calendar-day-details-section">
                <div className="calendar-day-details-section-name">
                    Calendar changes
                </div>
                <div className="calendar-day-details-calendar-history">
                    {calendarChanges.map(change => (
                        <div
                            className="calendar-day-details-calendar-change"
                            key={change.id}
                        >
                            <div className="calendar-day-details-calendar-change-availability">
                                {change.available && (
                                    <div className="calendar-day-details-calendar-change-available" />
                                )}
                                {!change.available && (
                                    <div className="calendar-day-details-calendar-change-unavailable" />
                                )}
                            </div>
                            <div className="calendar-day-details-calendar-change-right">
                                <div className="calendar-day-details-calendar-change-changed-at">
                                    {format(change.changedAt, 'yyyy-MM-dd HH:mm')}
                                </div>
                                <div className="calendar-day-details-calendar-change-values">
                                    <div className="calendar-day-details-calendar-change-price">
                                        <span className="calendar-day-details-calendar-change-label">Price:</span> {change.price}
                                    </div>
                                    <div className="calendar-day-details-calendar-change-min-stay">
                                        <span className="calendar-day-details-calendar-change-label">Min stay:</span> {change.minStay} days
                                    </div>
                                    {change.comment && (
                                        <div className="calendar-day-details-calendar-change-comment">
                                            {change.comment}
                                        </div>
                                    )}
                                    {change.updater && (
                                        <div className="calendar-day-details-calendar-change-updater">
                                            <Link to={`/users/${change.updater.id}`}>
                                                {change.updater.firstName} {change.updater.lastName}
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    {!calendarChangesShowAll && (
                        <Button
                            onClick={() => setCalendarChangesShowAll(true)}
                            type="link"
                            block
                        >
                            Show all
                        </Button>
                    )}
                </div>
            </div>
            <div className="calendar-day-details-section">
                <div className="calendar-day-details-section-name">
                    Price config changes
                </div>
                <div className="calendar-day-details-price-config-history">
                    {priceConfigChanges.map(change => (
                        <div
                            className="calendar-day-details-price-config-change"
                            key={change.id}
                        >
                            <div className="calendar-day-details-price-config-change-changed-at">
                                {format(change.changedAt, 'yyyy-MM-dd HH:mm')}
                            </div>
                            <div className="calendar-day-details-price-config-change-values">
                                <div className="calendar-day-details-price-config-change-min-price">
                                    <span className="calendar-day-details-price-config-change-label">MP:</span> {change.minPrice}
                                </div>
                                <div className="calendar-day-details-price-config-change-delta">
                                    <span className="calendar-day-details-price-config-change-label">D:</span> {change.delta}
                                </div>
                                <div className="calendar-day-details-price-config-change-slope">
                                    <span className="calendar-day-details-price-config-change-label">S:</span> {change.slope}
                                </div>
                            </div>
                        </div>
                    ))}
                    {!priceConfigChangesShowAll && (
                        <Button
                            onClick={() => setPriceConfigChangesShowAll(true)}
                            type="link"
                            block
                        >
                            Show all
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
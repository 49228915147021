import { Col, Form, Input, Row } from "antd";
import CurrencySelect from "components/CurrencySelect";
import TimezoneSelect from "components/TimezoneSelect";
import { useEffect } from "react";

export default function OrganizationGeneralForm(props) {
    const {
        form,
        organization,
        ...otherProps
    } = props;

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                name: organization?.name,
                timezone: organization?.timezone,
                currency: organization?.currency,
            });
        }
    }, [form, organization]);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form
                    form={form}
                    {...otherProps}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Name is required' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="timezone"
                        label="Timezone"
                        rules={[{ required: true, message: 'Timezone is required' }]}
                    >
                        <TimezoneSelect
                            searchable
                        />
                    </Form.Item>
                    <Form.Item
                        name="currency"
                        label="Currency"
                        rules={[{ required: true, message: 'Currency is required' }]}
                    >
                        <CurrencySelect
                            searchable
                        />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}

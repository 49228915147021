import { gql, useQuery } from "@apollo/client";
import { Alert } from "antd";
import Calendar from "components/Calendar";
import ApartmentCell from "../apartment-cell/ApartmentCell";
import DateCell from "../date-cell/DateCell";
import DayCell from "../day-cell/DayCell";
import { useCallback, useEffect, useState } from "react";
import { addDays, formatISO, isAfter, isBefore, isEqual, startOfToday, subDays } from "date-fns";

const QUERY = gql`
    query GetApartmentsForCalendar($apartmentsFilter: ApartmentsFilter!, $dateFrom: Date!, $dateTo: Date!) {
        apartments(filter: $apartmentsFilter) {
            id
            name
            tags
            reservations(filter: {endDateGte: $dateFrom, startDateLte: $dateTo, status: "confirmed"}) {
                id
                startDate
                endDate
                bookedAt
                priceAccommodation
                guestFirstName
                guestLastName
                numberOfGuests
                otaName
            }
            calendar(filter: {dateFrom: $dateFrom, dateTo: $dateTo}) {
                id
                date
                price
                priceBeforeUnavailable
                available
                minStay
                minStayAvailable
                comment
                updater {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

export default function ApartmentsCalendar(props) {
    const {
        dateFrom,
        dateTo,
        apartmentIds,
        selectedDays,
        dayValue,
        reservationValue,
        onSelect,
        onColumnHeaderClick,
    } = props;

    const initialFetchDateFrom = subDays(startOfToday(), 10);
    const initialFetchDateTo = addDays(startOfToday(), 30);
    const [scrollToDate, setScrollToDate] = useState(startOfToday());
    const [currentDateFrom, setCurrentDateFrom] = useState(initialFetchDateFrom);
    const [currentDateTo, setCurrentDateTo] = useState(initialFetchDateTo);
    const { data, loading, error, fetchMore } = useQuery(QUERY, {
        variables: {
            apartmentsFilter: {
                ...apartmentIds?.length > 0 ? { apartmentIds } : {},
                active: true,
            },
            dateFrom: formatISO(initialFetchDateFrom, { representation: 'date' }),
            dateTo: formatISO(initialFetchDateTo, { representation: 'date' }),
        },
    });

    useEffect(() => {
        setScrollToDate(startOfToday());
    }, [apartmentIds]);

    const renderCell = useCallback((apartment, date, selected) => (
        <DayCell
            apartment={apartment}
            date={date}
            selected={selected}
            value={dayValue}
            reservationValue={reservationValue}
        />
    ), [dayValue, reservationValue]);

    const handleColumnHeaderClick = useCallback(date => {
        if (onColumnHeaderClick) {
            onColumnHeaderClick(
                [...data?.apartments ?? []]
                    .map(apartment => ([apartment.id, date]))
            );
        }
    }, [data, onColumnHeaderClick]);

    const handleOnSelect = useCallback(([apartmentId, date]) => {
        const apartment = [...data?.apartments ?? []]
            .find(apartment => apartment.id === apartmentId);
        const item = [...apartment?.calendar ?? []]
            .find(item => isEqual(item.date, date));

        // Prevent from clicking on days which have no entry in apartment calendar
        if (item) {
            onSelect([apartmentId, date]);
        }
    }, [onSelect, data]);

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load calendar"
            />
        );
    }

    function handleScrollDate([dateLeft, dateRight]) {
        if (isBefore(dateLeft, addDays(currentDateFrom, 3))) {
            const fetchDateFrom = subDays(currentDateFrom, 30);
            const fetchDateTo = subDays(currentDateFrom, 1);

            const newDateFrom = subDays(currentDateFrom, 30);

            setCurrentDateFrom(newDateFrom);

            fetchMore({
                variables: {
                    dateFrom: formatISO(fetchDateFrom, { representation: 'date' }),
                    dateTo: formatISO(fetchDateTo, { representation: 'date' }),
                },
                updateQuery(previousResult, { fetchMoreResult }) {
                    return {
                        apartments: [...previousResult.apartments ?? []]
                            .map(apartment => {
                                const otherApartment = [...fetchMoreResult.apartments ?? []]
                                    .find(a => a.id === apartment.id);

                                if (!otherApartment) {
                                    return apartment;
                                }

                                return {
                                    ...apartment,
                                    reservations: [...apartment.reservations, ...otherApartment.reservations],
                                    calendar: [...apartment.calendar, ...otherApartment.calendar],
                                };
                            })
                    };
                },
            });
        }
        if (isAfter(dateRight, subDays(currentDateTo, 3))) {
            const fetchDateFrom = addDays(currentDateTo, 1);
            const fetchDateTo = addDays(currentDateTo, 30);

            const newDateTo = addDays(currentDateTo, 30);

            setCurrentDateTo(newDateTo);

            fetchMore({
                variables: {
                    dateFrom: formatISO(fetchDateFrom, { representation: 'date' }),
                    dateTo: formatISO(fetchDateTo, { representation: 'date' }),
                },
                updateQuery(previousResult, { fetchMoreResult }) {
                    return {
                        apartments: [...previousResult.apartments ?? []]
                            .map(apartment => {
                                const otherApartment = [...fetchMoreResult.apartments ?? []]
                                    .find(a => a.id === apartment.id);

                                if (!otherApartment) {
                                    return apartment;
                                }

                                return {
                                    ...apartment,
                                    reservations: [...apartment.reservations, ...otherApartment.reservations],
                                    calendar: [...apartment.calendar, ...otherApartment.calendar],
                                };
                            })
                    };
                },
            });
        }
    }

    const apartments = [...data?.apartments ?? []]
        .sort((a, b) => a.name.localeCompare(b.name));

    if (!loading && apartments.length === 0) {
        return (
            <Alert
                type="info"
                showIcon
                message="No apartments to display. Please change filter"
            />
        );
    }

    return (
        <Calendar
            dataSource={apartments}
            loading={loading}
            dateFrom={dateFrom}
            dateTo={dateTo}
            rowKey="id"
            renderRowHeaderCell={apartment => (
                <ApartmentCell
                    apartment={apartment}
                />
            )}
            renderColumnHeaderCell={date => (
                <DateCell
                    date={date}
                />
            )}
            renderCell={renderCell}
            scrollToDate={scrollToDate}
            columnHeaderHeight={40}
            rowHeaderWidth={180}
            cellHeight={30}
            cellWidth={50}
            selectedCells={selectedDays}
            autofocus
            onSelect={handleOnSelect}
            onColumnHeaderClick={handleColumnHeaderClick}
            onScrollDate={date => handleScrollDate(date)}
        />
    );
}
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, InputNumber, Row, Space, Switch, Tooltip, Typography } from "antd";
import ApartmentSelect from "apartments/components/apartment-select/ApartmentSelect";
import PriceConfigSlopeSelect from "prices/PriceConfigSlopeSelect";
import { useEffect } from "react";

export default function ApartmentPriceConfig(props) {
    const {
        apartment,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            defaultPriceConfigMinPrice: apartment?.defaultPriceConfigMinPrice,
            defaultPriceConfigDelta: apartment?.defaultPriceConfigDelta,
            defaultPriceConfigSlope: apartment?.defaultPriceConfigSlope,
        });
    }, [apartment, form]);

    const copyPriceConfig = Form.useWatch('copyPriceConfig', form);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form
                    form={form}
                    {...otherProps}
                >
                    <Divider orientation="left">
                        <Space>
                            <Typography.Text>
                                Default price config
                            </Typography.Text>
                            <Tooltip
                                title="Default values for price config that will be used if price config is not specified"
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </Space>
                    </Divider>
                    <Form.Item
                        name="defaultPriceConfigMinPrice"
                        label="Minimum price"
                        rules={[{ required: true, message: 'Minimum price is required' }]}
                    >
                        <InputNumber
                            min={0}
                        />
                    </Form.Item>
                    <Form.Item
                        name="defaultPriceConfigDelta"
                        label="Delta"
                        rules={[{ required: true, message: 'Delta is required' }]}
                    >
                        <InputNumber
                            min={0}
                        />
                    </Form.Item>
                    <Form.Item
                        name="defaultPriceConfigSlope"
                        label="Slope"
                        rules={[{ required: true, message: 'Slope is required' }]}
                    >
                        <PriceConfigSlopeSelect />
                    </Form.Item>
                    <Divider orientation="left">
                        <Space>
                            <Typography.Text>
                                Copy price config
                            </Typography.Text>
                            <Tooltip
                                title="You can use this option to copy an existing price config to this apartment"
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </Space>
                    </Divider>
                    <Form.Item
                        name="copyPriceConfig"
                        label="Copy from another apartment"
                        tooltip="If enabled price config will be copied from another apartment"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    {copyPriceConfig && (
                        <Form.Item
                            name="copyPriceConfigSourceApartmentId"
                            label="Apartment"
                            rules={[{ required: true, message: 'Apartment is required' }]}
                        >
                            <ApartmentSelect />
                        </Form.Item>
                    )}
                </Form>
            </Col>
        </Row>
    );
}
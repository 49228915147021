import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Switch } from 'antd';
import { STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING, STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING, STATISTICS_REFERENCE_DATE_MAPPING } from 'statistics/common';
import { addMonths, endOfMonth, startOfMonth, startOfToday, subMonths } from 'date-fns';

import { CloseCircleOutlined } from '@ant-design/icons';
import DatePicker from 'components/DatePicker';
import { useState } from 'react';
import ApartmentSelectWithTags from 'apartments/components/apartment-select/ApartmentSelectWithTags';

const DEFAULT_TABLE_SETTINGS = {
    name: "",
    fieldNames: [
        "revenueGrossTotal",
    ],
    referenceDate: "accommodationDate",
    dateRange: [
        subMonths(startOfMonth(startOfToday()), 3),
        addMonths(endOfMonth(startOfToday()), 1),
    ],
    comparison: {
        enabled: false,
        range: 0,
        offset: 0,
    },
    interval: "week",
    intervalSize: 1,
    includeWeekdays: [1, 2, 3, 4, 5, 6, 7],
    series: [],
};

export default function StatisticsTableSettingsModal(props) {
    const { settings: incomingSettings, visible, onSubmit, onCancel } = props;

    const [settings, setSettings] = useState(incomingSettings || DEFAULT_TABLE_SETTINGS);
    const [referenceDate, setReferenceDate] = useState(settings.referenceDate);
    const [interval, setInterval] = useState(settings.interval);
    const [intervalType, setIntervalType] = useState(settings.intervalType);
    const [intervalSize, setIntervalSize] = useState(settings.intervalSize);
    const [comparisonEnabled, setComparisonEnabled] = useState(settings?.comparison?.enabled ?? false);
    const [form] = Form.useForm();

    const fieldsMapping = referenceDate === 'accommodationDate'
        ? STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING
        : STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING;

    function handleModalOk() {
        form.submit();
    }

    function handleFormSubmit(values) {
        values.intervalType = intervalType;
        values.intervalSize = intervalSize;
        setSettings(values);
        onSubmit(values);
    }

    function handleModalCancel() {
        form.resetFields();
        if (onCancel) {
            onCancel();
        }
    }

    function handleIntervalChange(interval) {
        if (interval === 'year') {
            setIntervalType('year');
            setIntervalSize(1);
        }
        if (interval === 'month') {
            setIntervalType('month');
            setIntervalSize(1);
        }
        if (interval === 'week') {
            setIntervalType('day');
            setIntervalSize(7);
        }
        if (interval === 'day') {
            setIntervalType('day');
            setIntervalSize(1);
        }
        if (interval === 'custom') {
            setIntervalType('day');
        }

        setInterval(interval);
    }

    return (
        <Modal
            title="Table configuration"
            open={visible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            width={600}
        >
            <Form
                layout="vertical"
                initialValues={settings}
                onFinish={handleFormSubmit}
                form={form}
            >
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: "Name is required" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Reference date"
                            name="referenceDate"
                            rules={[{ required: true, message: "Reference date is required" }]}
                        >
                            <Select
                                options={
                                    Object.entries(STATISTICS_REFERENCE_DATE_MAPPING)
                                        .map(([key, value]) => ({ label: value, value: key }))
                                }
                                onChange={setReferenceDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Date range"
                            name="dateRange"
                            rules={[{ required: true, message: "Date range is required" }]}
                        >
                            <DatePicker.RangePicker
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={interval === 'custom' ? 6 : 12}>
                        <Form.Item
                            label="Interval"
                            name="interval"
                            rules={[{ required: true, message: "Interval is required" }]}
                        >
                            <Select
                                placeholder="Interval"
                                options={[
                                    { label: 'Year', value: 'year' },
                                    { label: 'Month', value: 'month' },
                                    { label: 'Week', value: 'week' },
                                    { label: 'Day', value: 'day' },
                                    { label: 'Custom', value: 'custom' },
                                ]}
                                onChange={value => handleIntervalChange(value)}
                            />
                        </Form.Item>
                    </Col>
                    {interval === 'custom' && (
                        <Col span={6}>
                            <Form.Item
                                label="Interval days"
                                name="intervalSize"
                            >
                                <InputNumber
                                    onChange={value => setIntervalSize(value)}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={12}>
                        <Form.Item
                            name={['comparison', 'enabled']}
                            label="Enable comparison"
                            valuePropName="checked"
                        >
                            <Switch
                                onChange={value => setComparisonEnabled(value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name={['comparison', 'range']}
                            label="Range in days"
                        >
                            <InputNumber
                                disabled={!comparisonEnabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name={['comparison', 'offset']}
                            label="Offset in days"
                        >
                            <InputNumber
                                disabled={!comparisonEnabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="includeWeekdays"
                            label="Include days"
                        >
                            <Checkbox.Group
                                options={[
                                    { label: 'Mon', value: 1 },
                                    { label: 'Tue', value: 2 },
                                    { label: 'Wed', value: 3 },
                                    { label: 'Thu', value: 4 },
                                    { label: 'Fri', value: 5 },
                                    { label: 'Sat', value: 6 },
                                    { label: 'Sun', value: 7 },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="fieldNames"
                            label="Fields"
                            rules={[{ required: true, message: "Fields are required" }]}
                        >
                            <Select
                                placeholder="Fields"
                                mode="multiple"
                                options={
                                    Object.entries(fieldsMapping)
                                        .map(([key, value]) => ({ label: value, value: key }))
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List
                    label="Series"
                    name="series"
                >
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                <Row
                                    key={key}
                                    gutter={[16, 16]}
                                    align="middle"
                                >
                                    <Col span={11}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            rules={[{ required: true, message: "Name is required" }]}
                                        >
                                            <Input placeholder="Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'apartments']}
                                            rules={[{ required: true, message: "Apartments list can't be empty" }]}
                                        >
                                            <ApartmentSelectWithTags />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        <Form.Item>
                                            <CloseCircleOutlined
                                                onClick={() => remove(name)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    block
                                    onClick={() => add()}
                                >
                                    Add series
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
}
import { Select } from "antd";
import { addMinutes, differenceInMinutes, format, parse, startOfToday } from "date-fns";

export default function ReservationTimeSelect(props) {
    const {
        timeFrom,
        timeTo,
        interval,
        value,
        onChange,
        ...otherProps
    } = props;

    const timeFromDate = parse(timeFrom, 'HH:mm', startOfToday());
    const timeToDate = parse(timeTo, 'HH:mm', startOfToday());
    const intervals = differenceInMinutes(timeToDate, timeFromDate) / interval;

    const options = [];
    for (let i = 0; i <= intervals; i++) {
        const time = format(addMinutes(timeFromDate, i * interval), 'HH:mm');
        options.push({
            label: time,
            value: time,
        });
    }

    return (
        <Select
            options={options}
            value={value}
            onChange={value => onChange(value)}
            allowClear
            style={{
                width: '150px',
            }}
            {...otherProps}
        />
    );
}
import { Form, Input, Select, Switch } from "antd";
import RelativeDatePicker from "components/relative-date-picker/RelativeDatePicker";
import { JOB_PRESET_DATE_TYPE_CLOSEST_AVAILABLE, JOB_PRESET_DATE_TYPE_FIXED_DATE, JOB_PRESET_DATE_TYPE_RESERVATION_ENDING_ON_FIXED_DATE, JOB_PRESET_DATE_TYPE_RESERVATION_STARTING_ON_FIXED_DATE } from "jobs-new/common";
import JobLocationSelect from "jobs-new/components/JobLocationSelect";
import JobPrioritySelect from "jobs-new/components/JobPrioritySelect";
import JobTagsSelect from "jobs-new/components/JobTagsSelect";
import { useEffect } from "react";
import UserSelect from "users/components/user-select/UserSelect";
import UserUserGroupSelect from "users/components/user-user-group-select/UserUserGroupSelect";

export default function JobsPresetForm(props) {
    const {
        jobsPreset,
        form,
        disabled,
        ...otherProps
    } = props;

    const dateType = Form.useWatch('dateType', form);

    useEffect(() => {
        form?.setFieldsValue({
            name: jobsPreset?.name,
            title: jobsPreset?.preset?.title,
            hideTitle: jobsPreset?.preset?.hideTitle,
            description: jobsPreset?.preset?.description,
            hideDescription: jobsPreset?.preset?.hideDescription,
            tags: jobsPreset?.preset?.tags,
            hideTags: jobsPreset?.preset?.hideTags,
            priority: jobsPreset?.preset?.priority,
            hidePriority: jobsPreset?.preset?.hidePriority,
            watchers: jobsPreset?.preset?.watchers,
            hideWatchers: jobsPreset?.preset?.hideWatchers,
            assignerIds: jobsPreset?.preset?.assignerIds,
            hideAssigners: jobsPreset?.preset?.hideAssigners,
            dateType: jobsPreset?.preset?.dateType,
            date: jobsPreset?.preset?.date,
            hideDate: jobsPreset?.preset?.hideDate,
            location: jobsPreset?.preset?.location,
            hideLocation: jobsPreset?.preset?.hideLocation,
            hideAttachments: jobsPreset?.preset?.hideAttachments,
        })
    }, [form, jobsPreset])

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Title is required' }]}
            >
                <Input
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="title"
                label="Title"
            >
                <Input
                    placeholder="Title"
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="hideTitle"
                label="Hide title"
                valuePropName="checked"
            >
                <Switch
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="description"
                wrapperCol={{
                    offset: otherProps?.labelCol?.span,
                    span: otherProps?.wrapperCol?.span,
                }}
            >
                <Input.TextArea
                    placeholder="Description"
                    autoSize
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="hideDescription"
                label="Hide description"
                valuePropName="checked"
            >
                <Switch
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="tags"
                label="Tags"
            >
                <JobTagsSelect
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="hideTags"
                label="Hide tags"
                valuePropName="checked"
            >
                <Switch
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="priority"
                label="Priority"
            >
                <JobPrioritySelect
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="hidePriority"
                label="Hide priority"
                valuePropName="checked"
            >
                <Switch
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="watchers"
                label="Watchers"
            >
                <UserUserGroupSelect
                    active
                    mode="multiple"
                    placeholder="Watchers"
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="hideWatchers"
                label="Hide watchers"
                valuePropName="checked"
            >
                <Switch
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="assignerIds"
                label="Assigners"
            >
                <UserSelect
                    active
                    mode="multiple"
                    placeholder="Assigners"
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="hideAssigners"
                label="Hide assigners"
                valuePropName="checked"
            >
                <Switch
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="dateType"
                label="Date type"
            >
                <Select
                    options={[
                        {
                            label: 'Next available date',
                            value: JOB_PRESET_DATE_TYPE_CLOSEST_AVAILABLE,
                        },
                        {
                            label: 'Fixed date',
                            value: JOB_PRESET_DATE_TYPE_FIXED_DATE,
                        },
                        {
                            label: 'Reservation starting on fixed date',
                            value: JOB_PRESET_DATE_TYPE_RESERVATION_STARTING_ON_FIXED_DATE,
                        },
                        {
                            label: 'Reservation ending on fixed date',
                            value: JOB_PRESET_DATE_TYPE_RESERVATION_ENDING_ON_FIXED_DATE,
                        },
                    ]}
                    style={{
                        width: '250px',
                    }}
                    disabled={disabled}
                />
            </Form.Item>
            {[JOB_PRESET_DATE_TYPE_FIXED_DATE, JOB_PRESET_DATE_TYPE_RESERVATION_STARTING_ON_FIXED_DATE, JOB_PRESET_DATE_TYPE_RESERVATION_ENDING_ON_FIXED_DATE].includes(dateType) && (
                <Form.Item
                    name="date"
                    label="Date"
                    rules={[{ required: true, message: 'Date is required' }]}
                >
                    <RelativeDatePicker
                        disabled={disabled}
                    />
                </Form.Item>
            )}
            <Form.Item
                name="hideDate"
                label="Hide date"
                valuePropName="checked"
            >
                <Switch
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="location"
                label="Where"
            >
                <JobLocationSelect
                    allowClear
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="hideLocation"
                label="Hide location"
                valuePropName="checked"
            >
                <Switch
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item
                name="hideAttachments"
                label="Hide attachments"
                valuePropName="checked"
            >
                <Switch
                    disabled={disabled}
                />
            </Form.Item>
        </Form>
    )
}
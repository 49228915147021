import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import VariableInput from "stayql/components/variable-input/VariableInput";
import useDeepCompareEffect from "use-deep-compare-effect";

export default function QueryVariablesForm(props) {
    const {
        form,
        variables,
        ...otherProps
    } = props;

    useDeepCompareEffect(() => {
        form?.setFieldValue('variables', variables ?? []);
    }, [form, variables]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.List
                name="variables"
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                            <Form.Item
                                {...field}
                            >
                                <VariableInput
                                    extra={
                                        <DeleteOutlined
                                            onClick={() => remove(field.name)}
                                        />
                                    }
                                />
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <Button
                                block
                                type="dashed"
                                icon={<PlusOutlined />}
                                onClick={() => add()}
                            >
                                Add variable
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
}
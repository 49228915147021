import { Alert, Popover, Skeleton } from "antd";
import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import { toggleSelect } from "common/common";
import { userName } from "users/common";
import "./style.css";
import { QuestionCircleOutlined } from "@ant-design/icons";
import CleanerInformation from "./CleanerInformation";
import { formatISO } from "date-fns";

const USED_RELATIONS = [
    'cleaner_main',
    'cleaner',
];
const NO_RELATION = 'no_relation';
const RELATIONS = [
    {
        label: 'Main cleaners',
        key: 'cleaner_main',
    },
    {
        label: 'Cleaners',
        key: 'cleaner',
    },
    {
        label: 'Other cleaners',
        key: NO_RELATION,
    }
];

function cleanerRelationKeyWithStorages(cleaner, storageIds) {
    const cleanerRelations = cleaner.storageRelations
        .filter(storageRelation => storageIds.includes(storageRelation.storage.id));

    const hasUsedRelation = cleanerRelations.some(cleanerRelation => USED_RELATIONS.includes(cleanerRelation.relation));

    if (!hasUsedRelation) {
        return NO_RELATION;
    }

    return RELATIONS.find(relation => cleanerRelations.some(cleanerRelation => cleanerRelation.relation === relation.key)).key;
}

const QUERY = gql`
    query GetCleaners($date: Date) {
        organization(organizationId: "self") {
            id
            cleaningUserGroups {
                id
                users(filter: {statuses: ["onboarding", "active"]}) {
                    id
                    firstName
                    lastName
                    availabilityNote
                    availability(filter: {dateFrom: $date, dateTo: $date}) {
                        id
                        date
                        availability
                        comment
                    }
                    storageRelations {
                        id
                        storage {
                            id
                        }
                        relation
                    }
                }
            }
            userGroups {
                userAvailabilityTypes {
                    id
                    availability
                    isAvailable
                    color
                }
            }
            userAvailabilityTypes {
                id
                availability
                isAvailable
                color
            }
        }
    }
`;

export default function CleanersSelect(props) {
    const {
        date,
        value,
        onChange,
        multiple,
        relationsForStorageIds,
        disableCleanerIds,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            date: formatISO(date, { representation: 'date' }),
        },
    });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load cleaners"
            />
        );
    }

    function isSelected(cleanerId) {
        if (multiple) {
            return value?.includes(cleanerId);
        }
        else {
            return value === cleanerId;
        }
    }

    function isDisabled(cleanerId) {
        return disableCleanerIds && disableCleanerIds.includes(cleanerId);
    }

    function handleOnClick(cleanerId) {
        if (isDisabled(cleanerId)) {
            return;
        }
        if (multiple) {
            onChange(toggleSelect(value, cleanerId, (a, b) => a === b));
        }
        else {
            onChange(cleanerId);
        }
    }

    const organizationAvailabilityTypes = data.organization.userAvailabilityTypes;
    const userGroupAvailabilityTypes = data.organization.userGroups
        .map(userGroup => userGroup.userAvailabilityTypes)
        .flat();

    const availabilityTypes = [
        ...organizationAvailabilityTypes,
        ...userGroupAvailabilityTypes,
    ];

    const availableAvailabilities = availabilityTypes
        .filter(availabilityType => availabilityType.isAvailable)
        .map(availabilityType => availabilityType.availability);

    const availabilityColor = Object.fromEntries(
        availabilityTypes.map(availabilityType => ([
            availabilityType.availability,
            availabilityType.color,
        ]))
    );

    const cleaners = [...data.organization.cleaningUserGroups]
        .map(userGroup => userGroup.users)
        .flat()
        .filter((user, index, users) => users.findIndex(u => u.id === user.id) === index)
        .filter(user => user.availability.length > 0)
        .filter(user => availableAvailabilities.includes(user.availability[0].availability))
        .sort((a, b) => userName(a).localeCompare(userName(b)));

    return (
        <div className="cleaners-select-container">
            {RELATIONS.map(relation => (
                <div
                    className="cleaners-select-relation-container"
                    key={relation.key}
                >
                    <div className="cleaners-select-relation-label">
                        {relation.label}
                    </div>
                    {cleaners
                        .filter(cleaner => cleanerRelationKeyWithStorages(cleaner, relationsForStorageIds) === relation.key)
                        .map(cleaner => (
                            <div
                                className={classNames({
                                    "cleaners-select-item-container": true,
                                    "cleaners-select-item-selected": isSelected(cleaner.id),
                                    "cleaners-select-item-disabled": isDisabled(cleaner.id),
                                })}
                                onClick={() => handleOnClick(cleaner.id)}
                                key={cleaner.id}
                            >
                                <div
                                    className="cleaners-select-item-availability"
                                    style={{
                                        backgroundColor: availabilityColor?.[cleaner.availability?.[0]?.availability] ?? '#a0a0a0',
                                    }}
                                />
                                <div className="cleaners-select-item-name">
                                    {`${cleaner.firstName} ${cleaner.lastName}`}
                                    {isDisabled(cleaner.id) && (
                                        <Popover
                                            content={
                                                <CleanerInformation
                                                    cleanerId={cleaner.id}
                                                    date={date}
                                                />
                                            }
                                            trigger="click"
                                            placement="right"
                                        >
                                            <QuestionCircleOutlined />
                                        </Popover>
                                    )}
                                </div>
                                <div className="cleaners-select-item-comment">
                                    {cleaner.availability?.[0]?.comment}
                                </div>
                                <div className="cleaners-select-item-comment">
                                    {cleaner.availabilityNote}
                                </div>
                            </div>
                        ))}
                </div>
            ))}
        </div>
    );
}
export const STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING = {
    totalDays: "Total days",
    bookedDays: "Booked days",
    availableDays: "Available days",
    bookingRate: "Booking rate",
    bookingWindowMean: "Average booking window",
    numberOfGuestsMean: "Average number of guests",
    numberOfGuestsArriving: "Number of guests arriving",
    numberOfGuestsDeparting: "Number of guests departing",
    accommodationPriceTotal: "Total accommodation price",
    dailyAccommodationPriceMean: "Average daily accommodation price",
    basePriceTotal: "Total base price",
    dailyBasePriceMean: "Average daily base price",
    cleaningPriceTotal: "Total cleaning price",
    dailyCleaningPriceMean: "Average daily cleaning price",
    commissionTotal: "Total commission",
    dailyCommissionMean: "Average daily commission",
    revenueNetTotal: "Total revenue net",
    revenueGrossTotal: "Total revenue gross",
    vatAmountTotal: "Total VAT amount",
    dailyRevenueNetMean: "Average daily revenue net",
    dailyRevenueGrossMean: "Average daily revenue gross",
    revenueGrossAfterCommissionTotal: "Total revenue gross after commission",
    dailyRevenueGrossAfterCommissionMean: "Average daily revenue grossa after commission",
    expensesNetTotal: "Total expenses net",
    expensesGrossTotal: "Total expenses gross",
    incomeNetTotal: "Total income net",
    incomeGrossTotal: "Total income gross",
};

export const STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING = {
    reservationsCount: "Reservations count",
    reservationLengthTotal: "Total reservations length",
    reservationLengthMean: "Average reservations length",
    bookingWindowMean: "Average booking window",
    numberOfGuestsTotal: "Total number of guests",
    numberOfGuestsMean: "Average number of guests",
    accommodationPriceTotal: "Total accommodation price",
    accommodationPriceMean: "Average accommodation price",
    dailyAccommodationPriceMean: "Average daily accommodation price",
    basePriceTotal: "Total base price",
    basePriceMean: "Average base price",
    dailyBasePriceMean: "Average daily base price",
    cleaningPriceTotal: "Total cleaning price",
    cleaningPriceMean: "Average cleaning price",
    dailyCleaningPriceMean: "Average daily cleaning price",
    commissionTotal: "Total commission",
    commissionMean: "Average commission",
    dailyCommissionMean: "Average daily commission",
    revenueNetTotal: "Total revenue net",
    revenueGrossTotal: "Total revenue gross",
    vatAmountTotal: "Total VAT amount",
    revenueNetMean: "Average revenue net",
    revenueGrossMean: "Average revenue gross",
    dailyRevenueNetMean: "Average daily revenue net",
    dailyRevenueGrossMean: "Average daily revenue gross",
};

export const STATISTICS_REFERENCE_DATE_MAPPING = {
    accommodationDate: "Accommodation date",
    bookedDate: "Booked date",
};

export const STATISTICS_INTERVAL_MAPPING = {
    year: { intervalType: 'year', intervalSize: 1 },
    month: { intervalType: 'month', intervalSize: 1 },
    week: { intervalType: 'day', intervalSize: 7 },
    day: { intervalType: 'day', intervalSize: 1 },
}
import { useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, Col, Form, PageHeader, Row, Skeleton, Tabs } from "antd";
import StorageGeneralForm from "storages/forms/StorageGeneralForm";
import LocationForm from "components/LocationForm";
import { gql } from "graphql.macro";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StorageApartmentsList from "storages/components/storage-apartments-list/StorageApartmentsList";

const FORM_COLUMNS = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const QUERY = gql`
    query GetStorage($storageId: ID!) {
        storage(storageId: $storageId) {
            id
            name
            latitude
            longitude
            order
            apartments {
                id
                name
            }
        }
    }
`;

const MUTATION = gql`
    mutation UpdateStorage($input: UpdateStorageInput!) {
        updateStorage(input: $input) {
            error {
                type
                message
            }
            storage {
                id
                name
                latitude
                longitude
                order
            }
        }
    }
`;

export default function UpdateStorageView() {
    const { storageId } = useParams();
    const navigate = useNavigate();

    const [generalForm] = Form.useForm();
    const [locationForm] = Form.useForm();

    const { data, loading, error } = useQuery(QUERY, { variables: { storageId } });
    const [updateStorage, { loading: updateStorageLoading }] = useMutation(MUTATION);

    const [currentTab, setCurrentTab] = useState('general');

    if (loading) {
        return (
            <PageHeader
                title={<Skeleton.Input active />}
            >
                <Card loading />
            </PageHeader>
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load storage"
            />
        );
    }

    const forms = {
        general: generalForm,
        location: locationForm,
    };

    const items = [
        {
            key: 'general',
            label: 'General',
            children: (
                <StorageGeneralForm
                    form={generalForm}
                    storage={data?.storage}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            key: 'location',
            label: 'Location',
            children: (
                <LocationForm
                    form={locationForm}
                    coordinates={data?.storage}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            key: 'apartments',
            label: 'Apartments',
            children: (
                <StorageApartmentsList
                    apartments={data?.storage?.apartments ?? []}
                />
            ),
        },
    ];

    function handleSave() {
        forms[currentTab]
            .validateFields()
            .then(values => {
                return updateStorage({
                    variables: {
                        input: {
                            storageId,
                            ...values,
                        },
                    },
                });
            });
    }

    return (
        <PageHeader
            title={data?.storage?.name}
            onBack={() => navigate('/storages')}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Tabs
                            items={items}
                            activeKey={currentTab}
                            onChange={value => setCurrentTab(value)}
                            tabPosition="left"
                        />
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                <Button
                                    type="primary"
                                    loading={updateStorageLoading}
                                    onClick={() => handleSave()}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    );
}
import { gql, useQuery } from "@apollo/client";
import { Alert, Card, Col, Row, Space, Table, Tag } from "antd";
import { useAuth } from "auth";
import { Link } from "react-router-dom";
import { USER_LANGUAGES, USER_STATUS_ACTIVE, USER_STATUS_DELETED, USER_STATUS_ONBOARDING, userName } from "users/common";
import Groups from "./columns/Groups";

const QUERY = gql`
    query GetUsers {
        users(filter: {statuses: ["active", "onboarding", "deleted"]}) {
            id
            status
            firstName
            lastName
            email
            phone
            language
            groups {
                id
                name
            }
        }
    }
`;

export default function UsersTable(props) {
    const {
        userIds,
        onlyActive,
        displayedColumns,
    } = props;

    const { user } = useAuth();
    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Card loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load users"
            />
        );
    }

    function filterUserIds(user) {
        if (userIds !== undefined) {
            return userIds.includes(user.id);
        }
        return true;
    }

    function filterOnlyActive(user) {
        if (onlyActive !== undefined && onlyActive !== false) {
            return user.status === USER_STATUS_ACTIVE || user.status === USER_STATUS_ONBOARDING;
        }
        return true;
    }

    const columns = [
        {
            title: 'Email',
            key: 'email',
            render: user => user.email,
        },
        {
            title: 'Phone',
            key: 'phone',
            render: user => user.phone,
        },
        {
            title: 'Language',
            key: 'language',
            render: user => user.language,
            sorter: (a, b) => a.language.localeCompare(b.language),
            filters: USER_LANGUAGES.map(item => ({
                text: item.label,
                value: item.language,
            })),
            onFilter: (language, user) => user.language === language,
        },
        {
            title: 'Groups',
            key: 'groups',
            render: user => <Groups groups={user.groups} />,
            filters: user.organization.userGroups.map(group => ({
                text: group.name,
                value: group.id,
            })),
            onFilter: (groupId, user) => user.groups.some(group => group.id === groupId),
        },
    ];



    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Table
                    columns={[
                        {
                            title: 'Name',
                            key: 'name',
                            render: user => (
                                <Space>
                                    <Link to={`/users/${user.id}`}>
                                        {userName(user)}
                                    </Link>
                                    {user.status === USER_STATUS_DELETED && (
                                        <Tag>
                                            deleted
                                        </Tag>
                                    )}
                                </Space>
                            ),
                            sorter: (a, b) => userName(a).localeCompare(userName(b)),
                            defaultSortOrder: 'ascend',
                        },
                        ...columns.filter(column => displayedColumns.includes(column.key)),
                    ]}
                    dataSource={
                        [...data?.users ?? []]
                            .filter(filterUserIds)
                            .filter(filterOnlyActive)
                    }
                    rowKey="id"
                    size="small"
                    pagination={false}
                />
            </Col>
        </Row>
    );
}
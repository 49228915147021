import { Card, Col, InputNumber, PageHeader, Row, Space, Tag, Tooltip, Typography } from "antd";
import { addDays, startOfToday, subDays } from "date-fns";

import { GET_STATISTICS_BY_ACCOMMODATION_DATE_QUERY } from "statistics/graphql";
import StatisticsApartmentsTable from "statistics/StatisticsApartmentsTable";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useState } from 'react';
import ApartmentSelectWithTags from "apartments/components/apartment-select/ApartmentSelectWithTags";
import { gql } from "graphql.macro";

const QUERY = gql`
    query GetActiveApartments {
        apartments(filter: {active: true}) {
            id
            name
            active
            tags
            storage {
                id
                name
            }
            maxGuestCount
        }
    }
`

export default function Statistics100DaysComparison() {
    const navigate = useNavigate();

    const [selectedApartments, setSelectedApartments] = useState([]);
    const [numberOfDays, setNumberOfDays] = useState(150);
    const [offset, setOffset] = useState(10);

    const dateFrom = startOfToday();
    const dateTo = addDays(dateFrom, numberOfDays - 1);

    const { data: apartmentsData, loading: apartmentsLoading } = useQuery(QUERY);

    const { data: statisticsData, loading: statisticsLoading } = useQuery(GET_STATISTICS_BY_ACCOMMODATION_DATE_QUERY, {
        variables: {
            filter: {
                apartmentGroups: [...apartmentsData?.apartments ?? []]
                    .filter(apartment => selectedApartments.length === 0 || selectedApartments.includes(apartment.id))
                    .map(apartment => ({
                        name: apartment.name,
                        apartmentIds: [apartment.id],
                    })),
                dateFrom,
                dateTo,
                intervalSize: numberOfDays,
                intervalType: "day",
            }
        },
        skip: apartmentsLoading,
    });

    const { data: statisticsComparisonData, loading: statisticsComparisonLoading } = useQuery(GET_STATISTICS_BY_ACCOMMODATION_DATE_QUERY, {
        variables: {
            filter: {
                apartmentGroups: [...apartmentsData?.apartments ?? []]
                    .map(apartment => ({
                        name: apartment.name,
                        apartmentIds: [apartment.id],
                    })),
                dateFrom: subDays(dateFrom, offset),
                dateTo: subDays(dateTo, offset),
                bookedAtTo: subDays(dateFrom, offset),
                intervalSize: numberOfDays,
                intervalType: "day",
            }
        },
        skip: apartmentsLoading,
    });

    const totalBookedDays = [...statisticsData?.statisticsByAccommodationDate ?? []]
        .reduce((sum, item) => sum + item.bookedDays, 0);
    const totalBookedDaysComparison = [...statisticsComparisonData?.statisticsByAccommodationDate ?? []]
        .reduce((sum, item) => sum + item.bookedDays, 0);
    const totalDailyRevenueNetMean = [...statisticsData?.statisticsByAccommodationDate ?? []]
        .reduce((sum, item) => sum + item.dailyRevenueNetMean, 0);
    const totalDailyRevenueNetMeanComparison = [...statisticsComparisonData?.statisticsByAccommodationDate ?? []]
        .reduce((sum, item) => sum + item.dailyRevenueNetMean, 0);
    const apartmentsCount = apartmentsData?.apartments?.length ?? 1;

    function renderDecimal(value) {
        const change = Math.round((value[0] - value[1]) / value[1] * 100);
        const changeStr = change > 0 ? '+' + change : change;
        return (
            <Tooltip
                title={`${value[1]} → ${value[0]}`}
            >
                <Space>
                    <Typography.Text>
                        {value[0].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Typography.Text>
                    <Tag
                        color={change > 0 ? "green" : "red"}
                        style={{
                            width: '50px',
                            textOverflow: 'clip',
                            overflow: 'hidden',
                        }}
                    >
                        {changeStr}%
                    </Tag>
                </Space>
            </Tooltip>
        );
    }

    function renderInteger(value) {
        const change = Math.round((value[0] - value[1]) / value[1] * 100);
        const changeStr = change > 0 ? '+' + change : change;
        return (
            <Tooltip
                title={`${value[1]} → ${value[0]}`}
            >
                <Space>
                    <Typography.Text>
                        {value[0]}
                    </Typography.Text>
                    <Tag
                        color={change > 0 ? "green" : "red"}
                        style={{
                            width: '50px',
                            textOverflow: 'clip',
                            overflow: 'hidden',
                        }}
                    >
                        {changeStr}%
                    </Tag>
                </Space>
            </Tooltip>
        );
    }

    return (
        <PageHeader
            title="Compare next days"
            extra={[
                <Typography.Text key="compareNextText">
                    Compare next
                </Typography.Text>,
                <InputNumber
                    value={numberOfDays}
                    onChange={value => setNumberOfDays(value)}
                    min={10}
                    max={1000}
                    key="numberOfDays"
                />,
                <Typography.Text key="daysWithText">
                    days with
                </Typography.Text>,
                <InputNumber
                    value={offset}
                    onChange={value => setOffset(value)}
                    min={1}
                    max={100}
                    key="offset"
                />,
                <Typography.Text key="daysOffsetText">
                    days offset
                </Typography.Text>
            ]}
            onBack={() => navigate(-1)}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <ApartmentSelectWithTags
                                value={selectedApartments}
                                onChange={value => setSelectedApartments(value)}
                                placeholder="Apartments"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Card title="Total booked days">
                        {renderInteger([totalBookedDays, totalBookedDaysComparison])}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Total average daily revenue net">
                        {renderDecimal([totalDailyRevenueNetMean / apartmentsCount, totalDailyRevenueNetMeanComparison / apartmentsCount])}
                    </Card>
                </Col>
                <Col span={24}>
                    <StatisticsApartmentsTable
                        referenceDate="accommodationDate"
                        fieldNames={[
                            "bookedDays",
                            "dailyRevenueNetMean",
                        ]}
                        apartments={apartmentsData}
                        statistics={statisticsData}
                        statisticsComparison={statisticsComparisonData}
                        comparisonEnabled={true}
                        pagination={false}
                        loading={statisticsLoading || statisticsComparisonLoading}
                    />
                </Col>
            </Row>
        </PageHeader>
    );
}
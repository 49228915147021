import { CheckOutlined } from "@ant-design/icons";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Alert, Anchor, Button, Card, Col, PageHeader, Row, Skeleton, Space, Switch, Typography } from "antd";
import { formatDistanceToNow, minTime } from "date-fns";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReservationMessagesList from "reservations/components/reservation-messages-list/ReservationMessagesList";
import ReservationToolbelt from "reservations/components/reservation-toolbelt/ReservationToolbelt";

const GET_RESERVATION_QUERY = gql`
    query GetReservationForCheck($reservationId: ID!) {
        reservation(reservationId: $reservationId) {
            id
            lastCheckAt
            lastChecker {
                id
                firstName
                lastName
            }
        }
    }
`;

const GET_NEXT_RESERVATION_QUERY = gql`
    query GetNextReservationForCheck($filter: ReservationsFilter!) {
        reservations(filter: $filter) {
            id
        }
    }
`

const UPDATE_LAST_CHECK_MUTATION = gql`
    mutation UpdateLastCheck($input: UpdateReservationLastCheckInput!) {
        updateReservationLastCheck(input: $input) {
            error {
                type
                message
            }
            reservation {
                id
                lastCheckAt
                lastChecker {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

export default function CheckReservationView() {
    const { reservationId } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [translate, setTranslate] = useState(false);
    const { data, loading, error } = useQuery(GET_RESERVATION_QUERY, { variables: { reservationId } });
    const [getNextReservation] = useLazyQuery(GET_NEXT_RESERVATION_QUERY);
    const [updateLastCheck, { loading: updateLastCheckLoading }] = useMutation(UPDATE_LAST_CHECK_MUTATION);

    async function handleChecked() {
        await updateLastCheck({
            variables: {
                input: {
                    reservationId,
                    timestamp: new Date(),
                },
            },
        });

        const nextReservation = await getNextReservation({
            variables: {
                filter: {
                    status: "confirmed",
                    hasNewGuestMessagesSinceLastCheck: true,
                    limit: 1,
                    ...(state?.filter ?? {}),
                },
            },
            fetchPolicy: 'network-only',
        });

        if (nextReservation.data.reservations?.length > 0) {
            const nextReservationId = nextReservation.data.reservations[0].id;
            navigate(`/reservations/check/${nextReservationId}`, { state });
        }
        else {
            navigate('/');
        }
    }

    function lastCheckedText() {
        if (!data) {
            return null
        }

        if (data.reservation.lastCheckAt) {
            return `Checked ${formatDistanceToNow(data.reservation.lastCheckAt, { addSuffix: true })} by ${data.reservation.lastChecker.firstName} ${data.reservation.lastChecker.lastName}`;
        }
        else {
            return 'Never checked';
        }
    }

    return (
        <PageHeader
            title="Check reservation"
            subTitle={lastCheckedText()}
            onBack={() => navigate(-1)}
            extra={
                <Button
                    onClick={() => handleChecked()}
                    type="primary"
                    icon={<CheckOutlined />}
                    loading={updateLastCheckLoading}
                    key="markChecked"
                >
                    Mark checked
                </Button>
            }
        >
            {loading && (
                <Skeleton />
            )}
            {error && (
                <Alert
                    type="error"
                    showIcon
                    message="Failed to load reservation"
                />
            )}
            {data && (
                <Row gutter={[16, 16]}>
                    <Col span={16}>
                        <Card
                            title="Messages"
                            extra={
                                <Space key="translate">
                                    <Typography.Text>
                                        Translate
                                    </Typography.Text>
                                    <Switch
                                        checked={translate}
                                        onChange={value => setTranslate(value)}
                                    />
                                </Space>
                            }
                        >
                            <ReservationMessagesList
                                reservationId={reservationId}
                                translate={translate}
                                markMessagesAfterTimestamp={data.reservation.lastCheckAt ?? new Date(minTime)}
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Anchor>
                            <Card>
                                <ReservationToolbelt
                                    reservationId={reservationId}
                                />
                            </Card>
                        </Anchor>
                    </Col>
                </Row>
            )}
        </PageHeader>
    );
}
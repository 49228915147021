export default function JobDescription(props) {
    const { job } = props;

    return (
        <div
            style={{
                width: '400px',
            }}
        >
            <div
                style={{
                    margin: '8px 0 16px 0',
                }}
            >
                {job.title}
            </div>
            {job.description && (
                <div
                    style={{
                        margin: '8px 0 16px 0',
                    }}
                >
                    {job.description}
                </div>
            )}
            {job.author && (
                <div>
                    Author: {job.author.firstName} {job.author.lastName}
                </div>
            )}
            {job.currentReservation && (
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                    }}
                >
                    <div>
                        <div
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            Current reservation
                        </div>
                        <div>
                            Guest name: {job.currentReservation.guestFirstName} {job.currentReservation.guestLastName}
                        </div>
                        <div>
                            Guests count: {job.currentReservation.numberOfGuests}
                        </div>
                        <div>
                            Phone: {job.currentReservation.guestPhone}
                        </div>
                    </div>
                </div>
            )}
            {!job.currentReservation && (
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                    }}
                >
                    <div>
                        <div
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            Previous reservation
                        </div>
                        {job.endingReservation && (
                            <>
                                <div>
                                    Guest name: {job.endingReservation.guestFirstName} {job.endingReservation.guestLastName}
                                </div>
                                <div>
                                    Guests count: {job.endingReservation.numberOfGuests}
                                </div>
                                <div>
                                    Check-out: {job.endingReservation.checkoutTime}
                                </div>
                                <div>
                                    Phone: {job.endingReservation.guestPhone}
                                </div>
                            </>
                        )}
                        {!job.endingReservation && (
                            <div>
                                No reservation
                            </div>
                        )}
                    </div>
                    <div>
                        <div
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            Next reservation
                        </div>
                        {job.startingReservation && (
                            <>
                                <div>
                                    Guest name: {job.startingReservation.guestFirstName} {job.startingReservation.guestLastName}
                                </div>
                                <div>
                                    Guests count: {job.startingReservation.numberOfGuests}
                                </div>
                                <div>
                                    Check-in: {job.startingReservation.checkinTime}
                                </div>
                                <div>
                                    Phone: {job.startingReservation.guestPhone}
                                </div>
                            </>
                        )}
                        {!job.startingReservation && (
                            <div>
                                No reservation
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
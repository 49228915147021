import { EnvironmentTwoTone } from "@ant-design/icons";
import { Button, List } from "antd";
import { useEffect, useState } from "react";

function getAddressName(address) {
    const city = address.city || address.town || address.hamlet || address.village;
    const street = address.road;
    const buildingNumber = address.house_number;

    return `${city}, ${street} ${buildingNumber}`;
}

export default function GeocodingList(props) {
    const {
        query,
        onChoose,
    } = props;

    const [geocodingResults, setGeocodingResults] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!query) {
            setGeocodingResults([]);
        }
        else {
            setLoading(true);
            fetch(`https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=${query.replace(' ', '+')}`)
                .then(response => response.json())
                .then(response => {
                    setGeocodingResults(
                        response.map((result, index) => ({
                            name: getAddressName(result.address),
                            coordinates: {
                                latitude: parseFloat(result.lat),
                                longitude: parseFloat(result.lon),
                            },
                            index,
                        }))
                    );
                    setLoading(false);
                });
        }
    }, [query, setGeocodingResults]);

    return (
        <List
            dataSource={geocodingResults}
            loading={loading}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        title={item.name}
                        avatar={<EnvironmentTwoTone />}
                    />
                    <Button
                        onClick={() => onChoose(item)}
                    >
                        Choose
                    </Button>
                </List.Item>
            )}
            rowKey={item => item.index}
        />
    )
}
import { Form, Input } from "antd";
import TimeSelect from "cleaning-new/components/time-select/TimeSelect";
import { useEffect } from "react";

export default function CleaningGroupForm(props) {
    const {
        form,
        cleaningGroup,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            startTime: cleaningGroup?.startTime,
            endTime: cleaningGroup?.endTime,
            note: cleaningGroup?.note,
        });
    }, [form, cleaningGroup]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="startTime"
                label="Start time"
            >
                <TimeSelect
                    allowClear
                    hours={[7, 8, 9, 10, 11, 12, 13]}
                    minutes={[0, 15, 30, 45]}
                />
            </Form.Item>
            <Form.Item
                name="endTime"
                label="End time"
            >
                <TimeSelect
                    allowClear
                    hours={[7, 8, 9, 10, 11, 12, 13]}
                    minutes={[0, 15, 30, 45]}
                />
            </Form.Item>
            <Form.Item
                name="note"
                label="Note"
            >
                <Input.TextArea />
            </Form.Item>
        </Form>
    );
}
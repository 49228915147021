import { QuestionCircleOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import { message, Modal, Space, Typography } from "antd";
import { useState } from "react";
import { CREATE_ADJUST_PRICES_TASK, GET_APARTMENT_PRICE_CONFIGS_FOR_RECALCULATION_QUERY } from "prices/graphql";

export default function PriceConfigRecalculateModal(props) {
    const { visible, onClose, selectedApartments } = props;

    const [loading, setLoading] = useState(false);

    const [fetchApartmentPriceConfigs] = useLazyQuery(GET_APARTMENT_PRICE_CONFIGS_FOR_RECALCULATION_QUERY);
    const [createAdjustPricesTask] = useMutation(CREATE_ADJUST_PRICES_TASK);

    function handleRecalculate() {
        setLoading(true);

        fetchApartmentPriceConfigs({
            variables: {
                apartmentIds: selectedApartments,
            }
        })
            .then(response => {
                Promise.all(
                    selectedApartments.map(apartmentId => {
                        return createAdjustPricesTask({
                            variables: {
                                input: {
                                    apartmentId,
                                },
                            },
                        });
                    })
                )
                    .then(() => message.success('Recalculation started'))
                    .catch(() => message.error('Error'))
                    .finally(() => {
                        setLoading(false);
                        onClose();
                    });
            });
    }

    return (
        <Modal
            visible={visible}
            title="Confirm recalculation"
            okText="Recalculate"
            onOk={() => handleRecalculate()}
            onCancel={() => onClose()}
            destroyOnClose={true}
            okButtonProps={{
                loading,
            }}
            cancelButtonProps={{
                disabled: loading,
            }}
        >
            <Space>
                <QuestionCircleOutlined />
                <Typography.Text>
                    Do you want to recalculate prices for {selectedApartments.length} price configs?
                </Typography.Text>
            </Space>
        </Modal>
    );
}
import { Form, InputNumber, Modal, Select } from "antd";
import { useAuth } from "auth";
import TimeSelect from "components/TimeSelect";
import PriceConfigSlopeSelect from "prices/PriceConfigSlopeSelect";
import StorageSelect from "storages/components/storage-select/StorageSelect";

export default function UpdateApartmentParameterModal(props) {
    const {
        open,
        loading,
        onClose,
        onSubmit,
    } = props;

    const { user } = useAuth();

    const [form] = Form.useForm();

    function handleOnSubmit() {
        form
            .validateFields()
            .then(values => {
                onSubmit({
                    parameter: values.parameter,
                    value: values.value,
                })
            });
    }

    const parameter = Form.useWatch('parameter', form);

    const parameters = [
        {
            label: 'Storage',
            value: 'storageId',
            component: (
                <StorageSelect />
            ),
        },
        {
            label: 'Cleaning price',
            value: 'cleaningPrice',
            component: (
                <InputNumber
                    min={0}
                    addonAfter={user.organization.currency}
                />
            ),
        },
        {
            label: 'Maximum guest count',
            value: 'maxGuestCount',
            component: (
                <InputNumber
                    min={1}
                    addonAfter="guests"
                />
            ),
        },
        {
            label: 'Base guest count',
            value: 'increasePriceAbove',
            component: (
                <InputNumber
                    min={1}
                    addonAfter="guests"
                />
            ),
        },
        {
            label: 'Extra guest fee',
            value: 'increasePriceBy',
            component: (
                <InputNumber
                    min={0}
                    addonAfter={user.organization.currency}
                />
            ),
        },
        {
            label: 'Calendar length',
            value: 'calendarLength',
            component: (
                <InputNumber
                    min={0}
                    addonAfter="days"
                />
            ),
        },
        {
            label: 'Cut off time',
            value: 'cutOffTime',
            component: (
                <TimeSelect
                    startTime="15:00"
                    endTime="00:00"
                    minutesInterval={30}
                />
            ),
        },
        {
            label: 'Default price config min price',
            value: 'defaultPriceConfigMinPrice',
            component: (
                <InputNumber
                    min={0}
                />
            ),
        },
        {
            label: 'Default price config delta',
            value: 'defaultPriceConfigDelta',
            component: (
                <InputNumber
                    min={0}
                />
            ),
        },
        {
            label: 'Default price config slope',
            value: 'defaultPriceConfigSlope',
            component: (
                <PriceConfigSlopeSelect />
            ),
        },
    ];

    return (
        <Modal
            open={open}
            title="Update parameter"
            destroyOnClose
            okText="Update parameter"
            okButtonProps={{
                loading,
            }}
            onCancel={() => onClose()}
            onOk={() => handleOnSubmit()}
        >
            <Form
                form={form}
                preserve={false}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
            >
                <Form.Item
                    name="parameter"
                    label="Parameter"
                >
                    <Select
                        options={parameters}
                    />
                </Form.Item>
                {parameter && (
                    <Form.Item
                        name="value"
                        label="Value"
                    >
                        {parameters.find(item => item.value === parameter).component}
                    </Form.Item>
                )}
            </Form>
        </Modal>
    )
}
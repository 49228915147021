import { PhoneOutlined } from "@ant-design/icons";
import { Popover, Space } from "antd";

export default function Cleaner(props) {
    const {
        cleaner,
    } = props;

    return (
        <Space>
            <span>
                {cleaner.firstName} {cleaner.lastName}
            </span>
            <Popover
                content={`Phone: ${cleaner.phone}`}
            >
                <PhoneOutlined />
            </Popover>
        </Space>
    );
}
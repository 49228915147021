import { Form, Select, Switch } from "antd";
import { MOBILE_MENU_ITEMS } from "common/common";
import { useEffect } from "react";
import UserMobileMenuSelect from "users/components/user-mobile-menu-select/UserMobileMenuSelect";

export default function UserMobileMenuForm(props) {
    const {
        user,
        form,
        inheritMenuItemsFromGroupIds,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            customize: user?.mobileMenu?.initialRoute || user?.mobileMenu?.items,
            initialRoute: user?.mobileMenu?.initialRoute,
            items: user?.mobileMenu?.items,
        });
    }, [user, form]);

    const customize = Form.useWatch('customize', form);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="customize"
                label="Customize menu"
                valuePropName="checked"
                tooltip="Menu of this user will be inherited from assigned user groups"
            >
                <Switch />
            </Form.Item>
            {customize && (
                <>
                    <Form.Item
                        name="initialRoute"
                        label="Initial route"
                    >
                        <Select
                            options={MOBILE_MENU_ITEMS.map(item => ({
                                title: item,
                                value: item,
                            }))}
                            allowClear
                            style={{
                                width: '150px',
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="items"
                        label="Items"
                    >
                        <UserMobileMenuSelect
                            inheritMenuItemsFromGroupIds={inheritMenuItemsFromGroupIds}
                        />
                    </Form.Item>
                </>
            )}
        </Form>
    )
}
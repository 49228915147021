import { Form, Switch } from "antd";
import { useEffect } from "react";
import UserPermissionsSelect from "users/components/user-permissions-select/UserPermissionsSelect";

export default function UserPermissionsForm(props) {
    const {
        user,
        form,
        inheritPermissionsFromGroupIds,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            permissions: user?.permissions,
        });
    }, [user, form]);

    const customize = Form.useWatch('customize', form);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="customize"
                label="Customize permissions"
                valuePropName="checked"
                tooltip="Permissions of this user will be inherited from assigned user groups"
            >
                <Switch />
            </Form.Item>
            {customize && (
                <Form.Item
                    name="permissions"
                    label="Permissions"
                >
                    <UserPermissionsSelect
                        inheritPermissionsFromGroupIds={inheritPermissionsFromGroupIds}
                    />
                </Form.Item>
            )}
        </Form>
    )
}
import { useState } from "react";
import { Avatar, Form, List, message, Modal } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import UserAvailabilityTypeForm from "users/forms/UserAvailabilityTypeForm";
import { useAuth } from "auth";

const UPDATE_MUTATION = gql`
    mutation UpdateUserGroupUserAvailabilityType($input: UpdateUserGroupUserAvailabilityTypeInput!) {
        updateUserGroupUserAvailabilityType(input: $input) {
            error {
                type
                message
            }
            userGroupUserAvailabilityType {
                id
                availability
                isAvailable
                label
                color
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation DeleteUserGroupUserAvailabilityType($input: DeleteUserGroupUserAvailabilityTypeInput!) {
        deleteUserGroupUserAvailabilityType(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        userGroupUserAvailabilityType,
        userGroupId,
    } = props;

    const { userGroupIds, permissions } = useAuth();

    const canEdit = userGroupIds.includes(userGroupId)
        ? permissions.includes('user_group_availability_type:set:self')
        : permissions.includes('user_group_availability_type:set:other');

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateForm] = Form.useForm();

    const [updateUserGroupUserAvailabilityType, { loading: updateLoading }] = useMutation(UPDATE_MUTATION);
    const [deleteUserGroupUserAvailabilityType, { loading: deleteLoading }] = useMutation(DELETE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'UserGroup',
                    id: userGroupId,
                }),
                fieldName: 'userAvailabilityTypes',
            });
        }
    });

    function handleUpdate() {
        updateForm
            .validateFields()
            .then(values => {
                updateUserGroupUserAvailabilityType({
                    variables: {
                        input: {
                            userGroupUserAvailabilityTypeId: userGroupUserAvailabilityType.id,
                            isAvailable: values.isAvailable,
                            label: values.label,
                            color: values.color,
                        },
                    },
                })
                    .then(response => {
                        if (response.data.updateUserGroupUserAvailabilityType.error) {
                            message.error("Failed updating availability type");
                        }
                        else {
                            message.success("Availability type updated");
                        }
                        setUpdateModalOpen(false);
                    })
                    .catch(() => {
                        message.error("Network error");
                        setUpdateModalOpen(false);
                    })
            });
    }

    function handleDelete() {
        Modal.confirm({
            title: 'Do you want to delete this availability?',
            okText: 'Delete',
            okButtonProps: {
                danger: true,
                icon: <DeleteOutlined />,
                loading: deleteLoading,
            },
            onOk: () => {
                deleteUserGroupUserAvailabilityType({
                    variables: {
                        input: {
                            userGroupUserAvailabilityTypeId: userGroupUserAvailabilityType.id,
                        },
                    },
                })
                    .then(response => {
                        if (response.data.deleteUserGroupUserAvailabilityType.error) {
                            message.error("Failed deleting availability type");
                        }
                        else {
                            message.success("Availability type deleted");
                        }
                    })
                    .catch(() => {
                        message.error("Network error");
                    });
            },
        })
    }

    return (
        <List.Item
            actions={
                canEdit
                    ? [
                        <EditOutlined
                            onClick={() => setUpdateModalOpen(true)}
                        />,
                        <DeleteOutlined
                            onClick={() => handleDelete()}
                        />
                    ]
                    : [
                        <EyeOutlined
                            onClick={() => setUpdateModalOpen(true)}
                        />
                    ]
            }
        >
            <List.Item.Meta
                avatar={
                    <Avatar
                        style={{ backgroundColor: userGroupUserAvailabilityType.color }}
                        size="small"
                    />
                }
                title={userGroupUserAvailabilityType.label}
                description={userGroupUserAvailabilityType.availability}
            />
            <Modal
                title="Update user availability type"
                width={800}
                open={updateModalOpen}
                destroyOnClose
                onCancel={() => setUpdateModalOpen(false)}
                onOk={() => handleUpdate()}
                okText="Update"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateLoading,
                    disabled: !canEdit,
                }}
            >
                <UserAvailabilityTypeForm
                    form={updateForm}
                    userAvailabilityType={userGroupUserAvailabilityType}
                    disabled={!canEdit}
                    disableAvailability
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                />
            </Modal>
        </List.Item>
    );
}
import { grey } from "@ant-design/colors";
import { Typography } from "antd";
import { useAuth } from "auth";

export default function ExtraGuestFee(props) {
    const {
        increasePriceBy,
    } = props;

    const { user } = useAuth();

    if (!increasePriceBy) {
        return (
            <Typography.Text
                style={{
                    color: grey[0],
                }}
            >
                disabled
            </Typography.Text>
        );
    }

    return (
        <Typography.Text>
            {increasePriceBy} {user.organization.currency}
        </Typography.Text>
    );
}
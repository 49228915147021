import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useWaitForTask } from "common/task";
import { LoadingOutlined } from "@ant-design/icons";
import { Alert } from "antd";

const QUERY = gql`
    query GetApartmentChannexParams($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            channexPropertyId
        }
    }
`;

const MUTATION = gql`
    mutation CreateGetChannexOneTimeKeyTaskForIframe($input: CreateGetChannexOneTimeKeyTaskInput!) {
        createGetChannexOneTimeKeyTask(input: $input) {
            error {
                type
                message
            }
            task {
                id
            }
        }
    }
`;

export default function Iframe(props) {
    const {
        apartmentId,
    } = props;

    const { data: apartmentData, error: apartmentError } = useQuery(QUERY, {
        variables: {
            apartmentId,
        },
    });

    const [createGetChannexOneTimeKeyTask, {
        data: createGetChannexOneTimeKeyTaskData,
        error: createGetChannexOneTimeKeyTaskError,
    }] = useMutation(MUTATION);

    const {
        result: getChannexOneTimeKeyTaskResult,
        error: getChannexOneTimeKeyTaskError,
    } = useWaitForTask(createGetChannexOneTimeKeyTaskData?.createGetChannexOneTimeKeyTask?.task?.id);

    useEffect(() => {
        createGetChannexOneTimeKeyTask({
            variables: {
                input: {
                    apartmentId,
                },
            },
        });
    }, [apartmentId, createGetChannexOneTimeKeyTask]);

    if (createGetChannexOneTimeKeyTaskError || getChannexOneTimeKeyTaskError || apartmentError) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to connect to Channex. Please contact your administrator."
            />
        );
    }

    const oneTimeToken = getChannexOneTimeKeyTaskResult?.channex_one_time_token;

    const url = new URL(`${process.env.REACT_APP_CHANNEX_URL}/auth/exchange`)
    url.searchParams.append('oauth_session_key', oneTimeToken);
    url.searchParams.append('app_mode', 'headless');
    url.searchParams.append('redirect_to', '/channels');
    url.searchParams.append('property_id', apartmentData?.apartment?.channexPropertyId);

    if (oneTimeToken && apartmentData) {
        return (
            <iframe
                src={url.toString()}
                title="Channex"
                style={{
                    width: '100%',
                    height: '450px',
                    border: 'none',
                    margin: '0px',
                }}
            />
        );
    }
    else {
        return (
            <div
                style={{
                    width: '100%',
                    height: '456px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <LoadingOutlined
                    style={{
                        fontSize: '64px',
                    }}
                />
            </div>
        );
    }
}
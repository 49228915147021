import { Button, Card, PageHeader, Space } from "antd";
import { LeftOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { addDays, startOfToday, subDays } from "date-fns";

import ICalModal from "./ICalModal";
import JobModal from "./JobModal";
import JobsCalendar from "./JobsCalendar";
import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react';

export default function JobsView() {
    const navigate = useNavigate();

    const [date, setDate] = useState(startOfToday());
    const [isJobModalVisible, setIsJobModalVisible] = useState(false);
    const [isICalModalVisible, setIsICalModalVisible] = useState(false);

    return (
        <PageHeader
            title="Jobs"
            onBack={() => navigate(-1)}
            extra={[
                <Link
                    to={`/jobs-new/create`}
                    key="addJob"
                >
                    <Button icon={<PlusOutlined />}>
                        Add job
                    </Button>
                </Link>
            ]}
        >
            <Card
                extra={(
                    <Space>
                        <Button
                            icon={<LeftOutlined />}
                            onClick={() => setDate(subDays(date, 7))}
                        />
                        <Button
                            onClick={() => setDate(startOfToday())}
                        >
                            Today
                        </Button>
                        <Button
                            icon={<RightOutlined />}
                            onClick={() => setDate(addDays(date, 7))}
                        />
                    </Space>
                )}
            >
                <JobsCalendar
                    date={date}
                />
            </Card>
            <JobModal
                visible={isJobModalVisible}
                title="Add job"
                onClose={() => setIsJobModalVisible(false)}
            />
            <ICalModal
                visible={isICalModalVisible}
                onCancel={() => setIsICalModalVisible(false)}
            />
        </PageHeader>
    )
}
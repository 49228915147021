import { Card, Table, Typography } from "antd";

const COLUMN_TYPE_TEMPLATES = {
    integer: {
        align: 'right',
        render: value => {
            if (value !== null && value !== undefined) {
                return parseInt(value).toString();
            }
            return '';
        },
    },
    float: {
        align: 'right',
        render: value => {
            if (value !== null && value !== undefined) {
                return parseFloat(value).toLocaleString();
            }
            return '';
        },
    },
    boolean: {
        align: 'right',
        render: value => {
            if (value !== null && value !== undefined) {
                return value;
            }
            return '';
        },
    },
    string: {
        align: 'left',
        render: value => {
            if (value !== null && value !== undefined) {
                return value;
            }
            return '';
        },
    },
    date: {
        align: 'right',
        render: value => {
            if (value !== null && value !== undefined) {
                return value;
            }
            return '';
        },
    },
    datetime: {
        align: 'right',
        render: value => {
            if (value !== null && value !== undefined) {
                return value;
            }
            return '';
        },
    },
    interval: {
        align: 'right',
        render: value => {
            if (value !== null && value !== undefined) {
                return value;
            }
            return '';
        },
    },
    array: {
        align: 'right',
        render: value => {
            if (value !== null && value !== undefined) {
                return value;
            }
            return '';
        },
    },
}

export default function StayqlTable(props) {
    const {
        columns,
        rows,
        title,
    } = props;

    const columnDefinitions = [...columns ?? []]
        .map((column, index) => ({
            ...COLUMN_TYPE_TEMPLATES[column.type],
            title: column.name,
            dataIndex: `col${index}`,
        }));
    const dataSource = [...rows ?? []]
        .map((row, rowIndex) => ({
            index: rowIndex,
            ...Object.fromEntries(
                row.values.map((value, valueIndex) => ([`col${valueIndex}`, value]))
            ),
        }));

    if (columnDefinitions && dataSource) {
        return (
            <Card
                title={title}
                bodyStyle={{
                    padding: '0 16px',
                }}
            >
                <Table
                    dataSource={dataSource}
                    columns={columnDefinitions}
                    size="small"
                    pagination={false}
                    rowKey="index"
                />
            </Card>
        );
    }

}
import { addDays, isEqual } from "date-fns";
import JobsCalendarColumn from "./JobsCalendarColumn";
import { gql, useQuery } from "@apollo/client";
import { Skeleton } from "antd";

const QUERY = gql`
    query GetJobs($filter: JobsNewFilter!) {
        jobsNew(filter: $filter) {
            id
            title
            description
            calculatedDate
            author {
                id
                firstName
                lastName
            }
            apartment {
                id
                name
            }
            storage {
                id
                name
            }
            startingReservation {
                id
                guestFirstName
                guestLastName
                guestPhone
                numberOfGuests
                checkinTime
            }
            currentReservation {
                id
                guestFirstName
                guestLastName
                guestPhone
                numberOfGuests
            }
            endingReservation {
                id
                guestFirstName
                guestLastName
                guestPhone
                checkoutTime
            }
            watcherGroups {
                id
                name
            }
        }
    }
`;

export default function JobsCalendar(props) {
    const { date: startDate } = props;

    const dates = [0, 1, 2, 3, 4, 5, 6].map(i => addDays(startDate, i));

    const { data: jobsData, loading: jobsLoading } = useQuery(QUERY, {
        variables: {
            filter: {
                dates,
            },
        },
    });

    if (jobsLoading) {
        return (
            <Skeleton />
        );
    }

    return (
        <div
            style={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                gridGap: '1px',
                backgroundColor: '#eee',
            }}
        >
            {dates.map(date => (
                <JobsCalendarColumn
                    key={date}
                    date={date}
                    jobs={
                        [...jobsData?.jobsNew ?? []]
                            .filter(job => isEqual(job.calculatedDate, date))
                    }
                />
            ))}
        </div>
    );
}
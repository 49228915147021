import classNames from "classnames";
import "./style.css";

export default function EndingReservation(props) {
    const {
        otaName,
    } = props;

    return (
        <div
            className={classNames({
                'calendar-ending-reservation-container': true,
                'calendar-reservation-ota-airbnb': otaName === 'airbnb',
                'calendar-reservation-ota-booking': otaName === 'booking',
            })}
        />
    );
}
import { Select } from "antd";
import { useAuth } from "auth";

export default function ReservationTagsSelect(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    const { user } = useAuth();

    return (
        <Select
            options={
                user.organization.reservationTags.map(tag => ({
                    label: tag,
                    value: tag,
                }))
            }
            mode="tags"
            value={value ?? []}
            onChange={value => onChange(value)}
            {...otherProps}
        />
    );
}
import { CloseCircleTwoTone } from "@ant-design/icons";

export default function ImageThumbnail(props) {
    const {
        file,
        onPreview,
        onRemove,
    } = props;

    return (
        <div className="thumbnail-box">
            <img
                alt={file.name}
                src={file.thumbUrl || file.url}
                style={{
                    width: '100%',
                    height: '100%',
                    userSelect: 'none',
                }}
                onClick={() => onPreview()}
            />
            <CloseCircleTwoTone
                style={{
                    position: 'absolute',
                    top: '4px',
                    right: '4px',
                    fontSize: '24px',
                }}
                onClick={() => onRemove()}
            />
        </div>
    );
}
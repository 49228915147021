import { Card, Col, PageHeader, Row } from "antd";
import ReservationsCard from "./components/reservations-card/ReservationsCard";
import { gql, useQuery } from "@apollo/client";
import { startOfToday } from "date-fns";

const QUERY = gql`
    query GetApartmentsForCleaning($date: Date!) {
        apartmentsForCleaning(date: $date) {
            id
            name
        }
    }
`

export default function HomeView() {
    const { data } = useQuery(QUERY, {
        variables: {
            date: startOfToday(),
        }
    })

    return (
        <PageHeader title="Home">
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <ReservationsCard />
                </Col>
                {/* <Col span={12}>
                    <Card>
                        {[...data?.apartmentsForCleaning ?? []].map(apartment => apartment.name).join(', ')}
                    </Card>
                </Col> */}
            </Row>
        </PageHeader>
    );
}

import { useEffect } from "react";
import { Checkbox, Col, Form, Row } from "antd";

export default function JobssListDisplayedColumnsForm(props) {
    const {
        grouping,
        displayedColumns,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            displayedColumns,
        })
    }, [form, displayedColumns])

    const locationsFields = [
        {
            label: 'Tags',
            key: 'tags',
        },
        {
            label: 'Storage',
            key: 'storage',
        },
        {
            label: 'Status',
            key: 'status',
        },
        {
            label: 'Assigners',
            key: 'assigners',
        },
        {
            label: 'Watchers',
            key: 'watchers',
        },
        {
            label: 'Priority',
            key: 'priority',
        },
    ];

    const userGroupsFields = [
        {
            label: 'Tags',
            key: 'tags',
        },
        {
            label: 'Location',
            key: 'location',
        },
        {
            label: 'Reservation situation',
            key: 'reservationSituation',
        },
        {
            label: 'Status',
            key: 'status',
        },
        {
            label: 'Assigners',
            key: 'assigners',
        },
        {
            label: 'Priority',
            key: 'priority',
        },
    ];

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item name="displayedColumns">
                <Checkbox.Group>
                    <Row gutter={[8, 8]}>
                        {grouping === 'locations' && locationsFields.map(field => (
                            <Col
                                key={field.key}
                                span={24}
                            >
                                <Checkbox
                                    value={field.key}
                                >
                                    {field.label}
                                </Checkbox>
                            </Col>
                        ))}
                        {grouping === 'userGroups' && userGroupsFields.map(field => (
                            <Col
                                key={field.key}
                                span={24}
                            >
                                <Checkbox
                                    value={field.key}
                                >
                                    {field.label}
                                </Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            </Form.Item>
        </Form>
    );
}
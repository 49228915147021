import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Anchor, Button, Card, Col, PageHeader, Row, Space, Typography } from "antd";
import ReservationMessagesList from "reservations/components/reservation-messages-list/ReservationMessagesList";
import MessagesSummary from "cleaning-new/components/messages-summary/MessagesSummary";
import ReservationToolbelt from "reservations/components/reservation-toolbelt/ReservationToolbelt";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function ReviewReservationView() {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [reservationIdIdx, setReservationIdIdx] = useState(0);
    const [messagesSummaryToggle, setMessagesSummaryToggle] = useState('messages');

    function handleBack() {
        if (reservationIdIdx > 0) {
            setReservationIdIdx(reservationIdIdx - 1);
        }
    }

    function backDisabled() {
        return reservationIdIdx === 0;
    }

    function handleNext() {
        if (reservationIdIdx + 1 < state.reservationIds.length) {
            setReservationIdIdx(reservationIdIdx + 1);
        }
    }

    function nextDisabled() {
        return reservationIdIdx + 1 === state.reservationIds.length;
    }

    const reservationId = state.reservationIds[reservationIdIdx];

    return (
        <PageHeader
            title="Review reservation"
            onBack={() => navigate(-1)}
            extra={
                <Space>
                    <Button
                        onClick={() => handleBack()}
                        disabled={backDisabled()}
                    >
                        <Space>
                            <LeftOutlined />
                            <Typography.Text>
                                Back
                            </Typography.Text>
                        </Space>
                    </Button>
                    <Button
                        onClick={() => handleNext()}
                        disabled={nextDisabled()}
                    >
                        <Space>
                            <Typography.Text>
                                Next
                            </Typography.Text>
                            <RightOutlined />
                        </Space>
                    </Button>
                </Space>
            }
        >
            <Row gutter={[16, 16]}>
                <Col span={16}>
                    {messagesSummaryToggle === 'messages' && (
                        <Card
                            title="Messages"
                            extra={
                                <Typography.Link onClick={() => setMessagesSummaryToggle('summary')}>
                                    Show summary
                                </Typography.Link>
                            }
                        >
                            <ReservationMessagesList
                                reservationId={reservationId}
                            />
                        </Card>
                    )}
                    {messagesSummaryToggle === 'summary' && (
                        <Card
                            title="Summary"
                            extra={
                                <Typography.Link onClick={() => setMessagesSummaryToggle('messages')}>
                                    Show messages
                                </Typography.Link>
                            }
                        >
                            <MessagesSummary
                                reservationId={reservationId}
                            />
                        </Card>
                    )}
                </Col>
                <Col span={8}>
                    <Anchor>
                        <Card>
                            <ReservationToolbelt
                                reservationId={reservationId}
                            />
                        </Card>
                    </Anchor>
                </Col>
            </Row>
        </PageHeader>
    );
}
import { Form } from "antd";
import DatePicker from "components/DatePicker";

export default function DateTimeValueForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="value"
                label="Value"
                rules={[{ required: true, message: 'Value is required' }]}
            >
                <DatePicker showTime />
            </Form.Item>
        </Form>
    );
}
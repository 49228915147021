import { gql, useQuery } from "@apollo/client";
import { Alert, Card, Table } from "antd";
import { formatISO } from "date-fns";
import { Link } from "react-router-dom";

const QUERY = gql`
    query GetApartmentExpenses($filter: ApartmentExpensesFilter!) {
        apartmentExpenses(filter: $filter) {
            id
            apartment {
                id
                name
            }
            amountGross
            amountNet
            category {
                id
                name
            }
            dateFrom
            dateTo
            description
        }
    }
`

export default function ApartmentExpensesTable(props) {
    const {
        dateRange,
        apartmentIds,
        apartmentExpenseCategoryIds,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            filter: {
                apartmentExpenseCategoryIds,
                apartmentIds,
                dateFrom: (dateRange?.length > 0 && dateRange[0]) ? formatISO(dateRange[0], { representation: 'date' }) : null,
                dateTo: (dateRange?.length > 1 && dateRange[1]) ? formatISO(dateRange[1], { representation: 'date' }) : null,
            },
        },
    });

    if (loading) {
        return (
            <Card loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartment expenses"
            />
        );
    }

    function renderName(item) {
        if (item.description?.length > 0) {
            return `${item.category.name} - ${item.description}`;
        }
        else {
            return item.category.name;
        }
    }

    return (
        <Table
            columns={[
                {
                    title: 'Apartment',
                    dataIndex: ['apartment', 'name'],
                    sorter: (a, b) => a.apartment.name.localeCompare(b.apartment.name),
                    width: '250px',
                },
                {
                    title: 'Name',
                    render: item => renderName(item),
                    sorter: (a, b) => a.category.name.localeCompare(b.category.name),
                    ellipsis: true,
                },
                {
                    title: 'Amount net',
                    dataIndex: 'amountNet',
                    render: value => value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    align: 'right',
                    sorter: (a, b) => a - b,
                    width: '150px',
                },
                {
                    title: 'Amount gross',
                    dataIndex: 'amountGross',
                    render: value => value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    align: 'right',
                    sorter: (a, b) => a - b,
                    width: '150px',
                },
                {
                    title: '',
                    align: 'right',
                    render: item => (
                        <Link
                            to={`/expenses/${item.id}`}
                        >
                            Edit
                        </Link>
                    ),
                    width: '50px',
                }
            ]}
            dataSource={data.apartmentExpenses}
            rowKey="id"
            size="small"
            pagination={false}
        />
    );
}
import { SettingOutlined } from "@ant-design/icons";
import { Handle, Position } from "@xyflow/react";
import { Form, Modal } from "antd";
import FieldsForm from "automation/forms/FieldsForm";
import { useState } from "react";
import ContextMenu from "./ContextMenu";

export default function ExtractNode(props) {
    const { data } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();

    function handleModalOpen() {
        form.setFieldValue('fields', data?.fields ?? []);
        setModalOpen(true);
    }

    function handleSubmit() {
        form
            .validateFields()
            .then(values => {
                data.onChange({ fields: values.fields });
                setModalOpen(false);
            });
    }

    function handleAction(action) {
        if (action === 'delete') {
            data.onDelete();
        }
    }

    return (
        <>
            <Handle
                type="target"
                id="input"
                position={Position.Left}
                style={{
                    top: 36,
                }}
                className="node-handle"
            />
            <ContextMenu
                onClick={action => handleAction(action)}
            >
                <div className="node-container">
                    <div className="node-title">
                        <span>
                            Extract
                        </span>
                        <SettingOutlined
                            onClick={() => handleModalOpen()}
                        />
                    </div>
                    <div className="node-labels">
                        <div className="node-input-labels">
                            <div className="node-input-label">
                                Input
                            </div>
                        </div>
                        <div className="node-output-labels">
                            {[...data?.fields ?? []].map(item => (
                                <div
                                    className="node-output-label"
                                    key={item}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </ContextMenu>
            {[...data?.fields ?? []].map((item, index) => (
                <Handle
                    type="source"
                    id={`output${index}`}
                    position={Position.Right}
                    style={{
                        top: 36 + (index * 24)
                    }}
                    className="node-handle"
                    key={item}
                />
            ))}
            <Modal
                open={modalOpen}
                title="Edit fields"
                okButtonProps={{
                    type: 'primary',
                }}
                onOk={() => handleSubmit()}
                onCancel={() => setModalOpen(false)}
            >
                <FieldsForm
                    form={form}
                />
            </Modal>
        </>
    );
}
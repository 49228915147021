import { blue, green, orange, red } from "@ant-design/colors";
import { Badge, Space, Typography } from "antd";
import { JOB_STATUS_BLOCKED, JOB_STATUS_DONE, JOB_STATUS_IN_PROGRESS, JOB_STATUS_UNASSIGNED, JOB_STATUS_ASSIGNED } from "jobs-new/common";

export default function JobStatus(props) {
    const {
        status,
    } = props;

    const statusLabels = {
        [JOB_STATUS_UNASSIGNED]: 'Unassigned',
        [JOB_STATUS_ASSIGNED]: 'Assigned',
        [JOB_STATUS_IN_PROGRESS]: 'In progress',
        [JOB_STATUS_BLOCKED]: 'Blocked',
        [JOB_STATUS_DONE]: 'Done',
    };

    const colors = {
        [JOB_STATUS_UNASSIGNED]: orange.primary,
        [JOB_STATUS_ASSIGNED]: blue.primary,
        [JOB_STATUS_IN_PROGRESS]: blue.primary,
        [JOB_STATUS_BLOCKED]: red.primary,
        [JOB_STATUS_DONE]: green.primary,
    }

    return (
        <Space>
            <Badge
                color={colors[status]}
                size="default"
            />
            <Typography.Text>
                {statusLabels[status]}
            </Typography.Text>
        </Space>
    );
}
import { Tooltip } from "antd";
import classNames from "classnames";
import { isAfter, isBefore, isEqual, isPast, isToday, subDays } from "date-fns";
import EndingReservation from "./EndingReservation";
import OngoingReservation from "./OngoingReservation";
import StartingReservation from "./StartingReservation";
import Value from "./Value";
import ReservationValue from "./ReservationValue";

export default function DayCell(props) {
    const {
        apartment,
        date,
        selected,
        value,
        reservationValue,
        onReservationClick,
    } = props;

    const calendarItem = apartment.calendar.find(item => isEqual(item.date, date));
    const startingReservation = apartment.reservations.find(reservation => isEqual(reservation.startDate, date));
    const endingReservation = apartment.reservations.find(reservation => isEqual(reservation.endDate, date));
    const ongoingReservation = apartment.reservations.find(reservation => isBefore(reservation.startDate, date) && isAfter(reservation.endDate, date));
    const endingTomorrowReservation = apartment.reservations.find(reservation => isEqual(date, subDays(reservation.endDate, 1)));

    const isUnavailable = !calendarItem || !calendarItem.available || !calendarItem.minStayAvailable;

    const content = (
        <div
            className={classNames({
                "calendar-day-cell-container": true,
                "calendar-day-cell-selected": selected,
                "calendar-day-cell-unavailable": isUnavailable,
            })}
        >
            {endingReservation && (
                <EndingReservation
                    onClick={() => onReservationClick(endingReservation.id)}
                    otaName={endingReservation.otaName}
                />
            )}
            {ongoingReservation && (
                <OngoingReservation
                    onClick={() => onReservationClick(ongoingReservation.id)}
                    otaName={ongoingReservation.otaName}
                />
            )}
            {startingReservation && (
                <StartingReservation
                    onClick={() => onReservationClick(startingReservation.id)}
                    otaName={startingReservation.otaName}
                />
            )}
            {endingTomorrowReservation && (
                <ReservationValue
                    value={reservationValue}
                    reservation={endingTomorrowReservation}
                />
            )}
            {!(startingReservation || ongoingReservation) && (
                <Value
                    calendarItem={calendarItem}
                    value={value}
                />
            )}
        </div>
    );

    const isMinStayUnavailable = calendarItem && calendarItem.available && !calendarItem.minStayAvailable;

    if (!isPast(date) && isMinStayUnavailable) {
        return (
            <Tooltip title="Unavailable due to min stay">
                {content}
            </Tooltip>
        )
    }

    const isUnavailableWithComment = calendarItem && !calendarItem.available && calendarItem.comment;

    if (isUnavailableWithComment) {
        return (
            <Tooltip title={calendarItem.comment}>
                {content}
            </Tooltip>
        );
    }

    return content;
}
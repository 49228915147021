import { Button, Col, PageHeader, Popconfirm, Row, message } from "antd";
import { DELETE_STATISTICS_VIEW_MUTATION, GET_STATISTICS_VIEWS_QUERY, GET_STATISTICS_VIEW_QUERY, UPDATE_STATISTICS_VIEW_MUTATION } from "./graphql";
import { DeleteOutlined, LineChartOutlined, TableOutlined } from "@ant-design/icons";
import { parseISO } from "date-fns";
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from 'react-router-dom';

import EditableInput from "components/EditableInput";
import StatisticsApartmentsTableSettingsModal from "statistics/StatisticsApartmentsTableSettingsModal";
import StatisticsCard from "statistics/StatisticsCard";
import StatisticsChartSettingsModal from "statistics/StatisticsChartSettingsModal";
import StatisticsTableSettingsModal from "statistics/StatisticsTableSettingsModal";

export default function StatisticsView() {
    const { statisticsViewId } = useParams();
    const navigate = useNavigate();

    const [isChartSettingsModalVisible, setChartSettingsModalVisible] = useState(false);
    const [isTableSettingsModalVisible, setTableSettingsModalVisible] = useState(false);
    const [isApartmentsTableSettingsModalVisible, setApartmentsTableSettingsModalVisible] = useState(false);
    const [cards, setCards] = useState([]);

    const { data: statisticsViewData } = useQuery(GET_STATISTICS_VIEW_QUERY, {
        variables: {
            statisticsViewId,
        },
    });

    const [updateStatisticsView] = useMutation(UPDATE_STATISTICS_VIEW_MUTATION, {
        onCompleted(response) {
            if (response.updateStatisticsView.error) {
                switch (response.updateStatisticsView.error.type) {
                    case 'not_found':
                        message.error('This statistics view is not found');
                        break;
                    default:
                        message.error('Unknown error');
                }
            }
        },
        refetchQueries: [
            {
                query: GET_STATISTICS_VIEW_QUERY,
                variables: {
                    statisticsViewId,
                }
            }
        ]
    });
    const [deleteStatisticsView] = useMutation(DELETE_STATISTICS_VIEW_MUTATION, {
        onCompleted(response) {
            if (response.deleteStatisticsView.error) {
                switch (response.deleteStatisticsView.error.type) {
                    case 'not_found':
                        message.error('This statistics view is not found');
                        break;
                    default:
                        message.error('Unknown error');
                }
            } else {
                navigate("/statistics");
            }
        },
        refetchQueries: [
            {
                query: GET_STATISTICS_VIEWS_QUERY,
            },
        ]
    })

    function serializeSettings(settings) {
        return settings.map(item => ({
            type: item.type,
            settings: {
                name: item.settings.name,
                dateRange: {
                    dateFrom: item.settings.dateRange[0],
                    dateTo: item.settings.dateRange[1],
                },
                comparison: {
                    enabled: item.settings?.comparison?.enabled ?? false,
                    range: item.settings?.comparison?.range ?? 0,
                    offset: item.settings?.comparison?.offset ?? 0,
                },
                intervalType: item.settings.intervalType,
                intervalSize: item.settings.intervalSize,
                referenceDate: item.settings.referenceDate,
                fieldName: item.settings.fieldName,
                fieldNames: item.settings.fieldNames,
                includeWeekdays: item.settings.includeWeekdays,
                series: item.settings.series,
            }
        }));
    }

    function findInterval(settings) {
        if (settings.interval_type === 'year') {
            return 'year';
        }
        if (settings.interval_type === 'month') {
            return 'month';
        }
        if (settings.interval_type === 'day' && settings.interval_size === 7) {
            return 'week';
        }
        if (settings.interval_type === 'day' && settings.interval_size === 1) {
            return 'day';
        }
        return 'custom';
    }

    useEffect(() => {
        function deserializeSettings(settings) {
            return settings.map(item => ({
                type: item.type,
                settings: {
                    name: item.settings.name,
                    dateRange: [
                        parseISO(item.settings.date_range?.date_from ?? item.settings.dateRange?.dateFrom),
                        parseISO(item.settings.date_range?.date_to ?? item.settings.dateRange?.dateTo),
                    ],
                    comparison: {
                        enabled: item.settings?.comparison?.enabled ?? false,
                        range: item.settings?.comparison?.range ?? 0,
                        offset: item.settings?.comparison?.offset ?? 0,
                    },
                    interval: findInterval(item.settings),
                    intervalType: item.settings.interval_type ?? item.settings.intervalType,
                    intervalSize: item.settings.interval_size ?? item.settings.intervalSize,
                    referenceDate: item.settings.reference_date ?? item.settings.referenceDate,
                    fieldName: item.settings.field_name ?? item.settings.fieldName,
                    fieldNames: item.settings.field_names ?? item.settings.fieldNames,
                    includeWeekdays: item.settings.include_weekdays ?? item.settings.includeWeekdays,
                    series: item.settings.series,
                }
            }));
        }

        setCards(deserializeSettings(statisticsViewData?.statisticsView?.settings || []))
    }, [statisticsViewData]);

    function handleAddChart(values) {
        const card = {
            type: 'chart',
            settings: values,
        };
        const newCards = [...cards, card];

        setCards(newCards);
        saveSettings(newCards);
        setChartSettingsModalVisible(false);
    }

    function handleAddTable(values) {
        const card = {
            type: 'table',
            settings: values,
        };
        const newCards = [...cards, card];

        setCards(newCards);
        saveSettings(newCards);
        setTableSettingsModalVisible(false);
    }

    function handleAddApartmentsTable(values) {
        const card = {
            type: 'apartmentsTable',
            settings: values,
        };
        const newCards = [...cards, card];

        setCards(newCards);
        saveSettings(newCards);
        setTableSettingsModalVisible(false);
    }

    function handleCardSettingsChange(index, value) {
        const newCards = [...cards];
        newCards[index].settings = value;
        setCards(newCards);
        saveSettings(newCards);
    }

    function handleCardDelete(index) {
        const newCards = [...cards];
        newCards.splice(index, 1);
        setCards(newCards);
        saveSettings(newCards);
    }

    function saveSettings(settings) {
        updateStatisticsView({
            variables: {
                input: {
                    statisticsViewId,
                    settings: serializeSettings(settings),
                },
            },
        });
    }

    function handleChangeName(name) {
        updateStatisticsView({
            variables: {
                input: {
                    statisticsViewId,
                    name,
                },
            },
        });
    }

    function handleDeleteStatisticsView() {
        deleteStatisticsView({
            variables: {
                input: {
                    statisticsViewId,
                },
            },
        })
    }

    const extra = [
        <Popconfirm
            title="Are you sure to delete this view?"
            onConfirm={() => handleDeleteStatisticsView()}
            okText="Yes"
            cancelText="No"
            placement="leftTop"
            key="deleteButton"
        >
            <Button
                danger
                type="link"
                icon={<DeleteOutlined />}
            >
                Delete
            </Button>
        </Popconfirm>
    ];

    return (
        <PageHeader
            title={
                <EditableInput
                    value={statisticsViewData?.statisticsView?.name}
                    onChange={value => handleChangeName(value)}
                    editable={true}
                />
            }
            extra={extra}
            onBack={() => navigate("/statistics")}
        >
            <Row gutter={[16, 16]}>
                {cards.map((card, index) => (
                    <Col
                        span={24}
                        key={index}
                    >
                        <StatisticsCard
                            type={card.type}
                            settings={card.settings}
                            onChange={value => handleCardSettingsChange(index, value)}
                            onDelete={() => handleCardDelete(index)}
                        />
                    </Col>
                ))}
                <Col span={8}>
                    <Button
                        type="dashed"
                        block
                        icon={<LineChartOutlined />}
                        onClick={() => setChartSettingsModalVisible(true)}
                    >
                        Add chart
                    </Button>
                </Col>
                <Col span={8}>
                    <Button
                        type="dashed"
                        block
                        icon={<TableOutlined />}
                        onClick={() => setTableSettingsModalVisible(true)}
                    >
                        Add table
                    </Button>
                </Col>
                <Col span={8}>
                    <Button
                        type="dashed"
                        block
                        icon={<TableOutlined />}
                        onClick={() => setApartmentsTableSettingsModalVisible(true)}
                    >
                        Add apartments table
                    </Button>
                </Col>
            </Row>
            <StatisticsChartSettingsModal
                visible={isChartSettingsModalVisible}
                onSubmit={values => handleAddChart(values)}
                onCancel={() => setChartSettingsModalVisible(false)}
            />
            <StatisticsTableSettingsModal
                visible={isTableSettingsModalVisible}
                onSubmit={values => handleAddTable(values)}
                onCancel={() => setTableSettingsModalVisible(false)}
            />
            <StatisticsApartmentsTableSettingsModal
                visible={isApartmentsTableSettingsModalVisible}
                onSubmit={values => handleAddApartmentsTable(values)}
                onCancel={() => setApartmentsTableSettingsModalVisible(false)}
            />
        </PageHeader>
    );
}
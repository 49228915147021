import { message } from "antd";

export default function errorHandler(error) {
    switch (error.type) {
        case 'not_found':
            message.error('This element does not exist');
            break;
        case 'already_exists':
            message.error('The same element already exists');
            break;
        case 'unauthorized':
            message.error('You are not authorized to perform this action');
            break;
        default:
            message.error('Unknown error');
            break;
    }
}
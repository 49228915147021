import AntdPhoneInput from "antd-phone-input/legacy";

export default function PhoneInput(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    return (
        <AntdPhoneInput
            value={value}
            onChange={value => onChange(value)}
            enableSearch
            country=""
            {...otherProps}
        />
    );
}
import { useCallback } from "react";
import { Checkbox } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { formatISO } from "date-fns";
import JobReservationSituationInline from "jobs-new/components/job-reservation-situation-inline/JobReservationSituationInline";
import Job from "./Job";

export default function LocationList(props) {
    const {
        location,
        date,
        jobs,
        display,
        expandedItems,
        onExpandChange,
        selectedJobs,
        onSelect,
    } = props;

    const expandKey = `${location.key}-${formatISO(date, { representation: 'date' })}`;
    const expanded = expandedItems.includes(expandKey);

    const handleExpand = useCallback(() => {
        if (!expanded) {
            onExpandChange([...expandedItems, expandKey]);
        }
    }, [onExpandChange, expandedItems, expandKey, expanded]);

    const handleCollapse = useCallback(() => {
        if (expanded) {
            onExpandChange(expandedItems.filter(item => item !== expandKey));
        }
    }, [onExpandChange, expandedItems, expandKey, expanded]);

    const jobIds = jobs.map(job => job.id);
    const allJobsAreSelected = jobIds.every(jobId => selectedJobs.find(selectedJob => selectedJob.id === jobId));
    const someJobsAreSelected = jobIds.some(jobId => selectedJobs.find(selectedJob => selectedJob.id === jobId));

    function handleCheckboxClick() {
        if (allJobsAreSelected) {
            onSelect([...selectedJobs].filter(selectedJob => !jobIds.includes(selectedJob.id)));
        }
        else {
            onSelect([...selectedJobs, ...jobs].filter((job, index, array) => array.findIndex(otherJob => otherJob.id === job.id) === index));
        }
    }

    const jobsSorted = [...jobs ?? []]
        .sort((a, b) => b.priority - a.priority);

    return (
        <div className="jobs-location-list-container">
            <div className="jobs-location-list-header">
                <div className="jobs-location-list-checkbox">
                    <Checkbox
                        checked={allJobsAreSelected}
                        indeterminate={someJobsAreSelected && !allJobsAreSelected}
                        onClick={() => handleCheckboxClick()}
                    />
                </div>
                <div className="jobs-location-list-header-left">
                    <div className="jobs-location-list-label">
                        <div className="jobs-location-list-expand-toggle">
                            {expanded && (
                                <DownOutlined
                                    onClick={() => handleCollapse()}
                                />
                            )}
                            {!expanded && (
                                <UpOutlined
                                    onClick={() => handleExpand()}
                                />
                            )}
                        </div>
                        <div className="jobs-location-list-name">
                            {location.name}
                        </div>
                        {[...display?.displayedColumns ?? []].includes('storage') && (
                            <div className="jobs-location-list-storage">
                                {location.storageName}
                            </div>
                        )}
                    </div>
                </div>
                <div className="jobs-location-list-header-right">
                    <div className="jobs-location-list-reservation-situation">
                        <JobReservationSituationInline
                            endingReservation={location.endingReservation}
                            currentReservation={location.currentReservation}
                            startingReservation={location.startingReservation}
                            date={date}
                        />
                    </div>
                </div>
            </div>
            {expanded && (
                <div className="jobs-location-list-jobs">
                    {jobsSorted.map(job => (
                        <Job
                            key={job.id}
                            job={job}
                            date={date}
                            display={display}
                            selectable
                            selectedJobs={selectedJobs}
                            onSelect={value => onSelect(value)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
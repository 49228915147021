import { useQuery } from "@apollo/client";
import { Alert, Card, Table } from "antd";
import { useAuth } from "auth";
import { formatCurrency } from "common/common";
import { compareAsc, formatISO, minTime } from "date-fns";
import { gql } from "graphql.macro";
import { Link } from "react-router-dom";

const QUERY = gql`
    query GetReservations($reservationIds: [ID]!) {
        reservations(filter: {reservationIds: $reservationIds}) {
            id
            apartment {
                id
                name
            }
            bookedAt
            canceledAt
            checkinTime
            checkoutTime
            endDate
            guestFirstName
            guestLastName
            guestPhone
            numberOfGuests
            priceAccommodation
            priceCleaning
            priceCommission
            startDate
            status
        }
    }
`

export default function ReservationsTable(props) {
    const {
        reservations,
        reservationIds,
        displayedColumns,
    } = props;

    const { user } = useAuth();

    const { data, loading, error } = useQuery(QUERY, { variables: { reservationIds }, skip: reservations });

    if (loading) {
        return (
            <Card loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load reservations"
            />
        );
    }

    const columns = [
        {
            title: 'Apartment',
            key: 'apartment',
            render: reservation => reservation.apartment.name,
            sorter: (a, b) => a.apartment.name.localeCompare(b.apartment.name),
        },
        {
            title: 'Confirmation code',
            key: 'confirmationCode',
            render: reservation => reservation.airbnbConfirmationCode,
            sorter: (a, b) => a.airbnbConfirmationCode.localeCompare(b.airbnbConfirmationCode),
        },
        {
            title: 'Booked at',
            key: 'bookedAt',
            render: reservation => formatISO(reservation.bookedAt),
            sorter: (a, b) => compareAsc(a.bookedAt, b.bookedAt),
        },
        {
            title: 'Canceled at',
            key: 'canceledAt',
            render: reservation => reservation.canceledAt || null,
            sorter: (a, b) => compareAsc(a.canceledAt || minTime, b.canceledAt || minTime),
        },
        {
            title: 'Check-in time',
            key: 'checkinTime',
            render: reservation => reservation.checkinTime,
            sorter: (a, b) => (a.checkinTime || '15:00').localeCompare(b.checkinTime || '15:00'),
        },
        {
            title: 'Check-out time',
            key: 'checkoutTime',
            render: reservation => reservation.checkoutTime,
            sorter: (a, b) => (a.checkoutTime || '11:00').localeCompare(b.checkoutTime || '11:00'),
        },
        {
            title: 'Date',
            key: 'date',
            render: reservation => `${formatISO(reservation.startDate, { representation: 'date' })} - ${formatISO(reservation.endDate, { representation: 'date' })}`,
            sorter: (a, b) => compareAsc(a.startDate, b.startDate),
        },
        {
            title: 'Guest name',
            key: 'guestName',
            render: reservation => `${reservation.guestFirstName} ${reservation.guestLastName}`,
            sorter: (a, b) => `${a.guestFirstName} ${a.guestLastName}`.localeCompare(`${b.guestFirstName} ${b.guestLastName}`),
        },
        {
            title: 'Guest phone',
            key: 'guestPhone',
            render: reservation => reservation.guestPhone,
            sorter: (a, b) => a.guestPhone.localeCompare(b.guestPhone),
        },
        {
            title: 'Number of guests',
            key: 'numberOfGuests',
            align: 'right',
            render: reservation => `${reservation.numberOfGuests} guests`,
            sorter: (a, b) => a.numberOfGuests - b.numberOfGuests,
        },
        {
            title: 'Price accommodation',
            key: 'priceAccommodation',
            align: 'right',
            render: reservation => `${formatCurrency(reservation.priceAccommodation || 0)} ${user.organization.currency}`,
            sorter: (a, b) => (a.priceAccommodation || 0) - (b.priceAccommodation || 0),
        },
        {
            title: 'Price cleaning',
            key: 'priceCleaning',
            align: 'right',
            render: reservation => `${formatCurrency(reservation.priceCleaning || 0)} ${user.organization.currency}`,
            sorter: (a, b) => (a.priceCleaning || 0) - (b.priceCleaning || 0),
        },
        {
            title: 'Price commission',
            key: 'priceCommission',
            align: 'right',
            render: reservation => `${formatCurrency(reservation.priceCommission || 0)} ${user.organization.currency}`,
            sorter: (a, b) => (a.priceCommission || 0) - (b.priceCommission || 0),
        },
        {
            title: 'Status',
            key: 'status',
            render: reservation => reservation.status,
            sorter: (a, b) => a.status.localeCompare(b.status),
        },
    ];

    return (
        <Table
            columns={[
                ...displayedColumns.map(displayedColumn => columns.find(column => column.key === displayedColumn)),
                {
                    title: 'View',
                    key: 'view',
                    width: '100px',
                    align: 'center',
                    render: reservation => (
                        <Link to={`/reservations/${reservation.id}`}>
                            View
                        </Link>
                    ),
                },
            ]}
            dataSource={[...(reservations || data.reservations)]}
            rowKey="id"
            size="small"
            pagination={false}
        />
    )
}
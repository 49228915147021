import { grey } from "@ant-design/colors";
import { Typography } from "antd";

export default function BaseGuestCount(props) {
    const {
        increasePriceAbove,
    } = props;

    if (!increasePriceAbove) {
        return (
            <Typography.Text
                style={{
                    color: grey[0],
                }}
            >
                disabled
            </Typography.Text>
        );
    }

    return (
        <Typography.Text>
            {increasePriceAbove} guests
        </Typography.Text>
    );
}
import { Col, Form, Input, InputNumber, Modal, Row, Select, Switch } from 'antd';
import { STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING, STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING, STATISTICS_REFERENCE_DATE_MAPPING } from 'statistics/common';
import { addMonths, endOfMonth, isAfter, startOfMonth, startOfToday, subMonths } from 'date-fns';

import DatePicker from 'components/DatePicker';
import endOfToday from 'date-fns/endOfToday';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import ApartmentSelectWithTags from 'apartments/components/apartment-select/ApartmentSelectWithTags';
import { gql } from 'graphql.macro';

const DEFAULT_CHART_SETTINGS = {
    name: "",
    fieldNames: ["revenueGrossTotal"],
    referenceDate: "accommodationDate",
    dateRange: [
        subMonths(startOfMonth(startOfToday()), 3),
        addMonths(endOfMonth(startOfToday()), 1),
    ],
    comparison: {
        enabled: false,
        range: 0,
        offset: 0,
    },
    interval: "week",
    series: [],
};

const QUERY = gql`
    query GetActiveApartments {
        apartments(filter: {active: true}) {
            id
            name
            active
            tags
            storage {
                id
                name
            }
            maxGuestCount
        }
    }
`;

export default function StatisticsApartmentsTableSettingsModal(props) {
    const { settings: incomingSettings, visible, onSubmit, onCancel } = props;

    const [settings, setSettings] = useState(incomingSettings || DEFAULT_CHART_SETTINGS);
    const [referenceDate, setReferenceDate] = useState(settings.referenceDate);
    const [interval, setInterval] = useState(settings.interval);
    const [intervalType, setIntervalType] = useState(settings.intervalType);
    const [intervalSize, setIntervalSize] = useState(settings.intervalSize);
    const [comparisonEnabled, setComparisonEnabled] = useState(settings?.comparison?.enabled ?? false);
    const [form] = Form.useForm();

    const { data: apartmentsData } = useQuery(QUERY);

    const fieldsMapping = referenceDate === 'accommodationDate'
        ? STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING
        : STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING;

    function handleModalOk() {
        form.submit();
    }

    function convertToSettings(values) {
        const series = values.apartmentIds
            .filter(apartmentId => apartmentsData?.apartments.find(apartment => apartment.id === apartmentId))
            .map(apartmentId => {
                const apartment = apartmentsData?.apartments.find(apartment => apartment.id === apartmentId);
                return {
                    name: apartment?.name,
                    apartments: [apartmentId],
                };
            });

        return {
            name: values.name,
            referenceDate: values.referenceDate,
            dateRange: values.dateRange,
            intervalType: intervalType,
            intervalSize: intervalSize,
            comparison: values.comparison,
            fieldNames: values.fieldNames,
            series,
        };
    }

    function convertFromSettings(values) {
        const apartmentIds = values.series.map(item => item.apartments[0]);

        return {
            name: values.name,
            referenceDate: values.referenceDate,
            dateRange: values.dateRange,
            intervalType: intervalType,
            intervalSize: intervalSize,
            comparison: values.comparison,
            fieldNames: values.fieldNames,
            apartmentIds,
        };
    }

    function handleFormSubmit(values) {
        const newValues = convertToSettings(values);

        setSettings(newValues);
        onSubmit(newValues);
    }

    function handleModalCancel() {
        form.resetFields();
        if (onCancel) {
            onCancel();
        }
    }

    function handleIntervalChange(interval) {
        if (interval === 'year') {
            setIntervalType('year');
            setIntervalSize(1);
        }
        if (interval === 'month') {
            setIntervalType('month');
            setIntervalSize(1);
        }
        if (interval === 'week') {
            setIntervalType('day');
            setIntervalSize(7);
        }
        if (interval === 'day') {
            setIntervalType('day');
            setIntervalSize(1);
        }
        if (interval === 'custom') {
            setIntervalType('day');
        }

        setInterval(interval);
    }

    return (
        <Modal
            title="Apartments table configuration"
            visible={visible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            width={600}
        >
            <Form
                layout="vertical"
                initialValues={convertFromSettings(settings)}
                onFinish={handleFormSubmit}
                form={form}
            >
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: "Name is required" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Reference date"
                            name="referenceDate"
                            rules={[{ required: true, message: "Rerefence date is required" }]}
                        >
                            <Select
                                options={
                                    Object.entries(STATISTICS_REFERENCE_DATE_MAPPING)
                                        .map(([key, value]) => ({ label: value, value: key }))
                                }
                                onChange={setReferenceDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Date range"
                            name="dateRange"
                            rules={[{ required: true, message: "Date range is required" }]}
                        >
                            <DatePicker.RangePicker
                                allowClear={false}
                                disabledDate={date => referenceDate === 'bookedDate' && isAfter(date, endOfToday())}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={interval === 'custom' ? 6 : 12}>
                        <Form.Item
                            label="Interval"
                            name="interval"
                            rules={[{ required: true, message: "Interval is required" }]}
                        >
                            <Select
                                placeholder="Interval"
                                options={[
                                    { label: 'Month', value: 'month' },
                                    { label: 'Week', value: 'week' },
                                    { label: 'Day', value: 'day' },
                                    { label: 'Custom', value: 'custom' },
                                ]}
                                onChange={value => handleIntervalChange(value)}
                            />
                        </Form.Item>
                    </Col>
                    {interval === 'custom' && (
                        <Col span={6}>
                            <Form.Item
                                label="Interval days"
                                name="intervalSize"
                            >
                                <InputNumber
                                    onChange={value => setIntervalSize(value)}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={12}>
                        <Form.Item
                            name={['comparison', 'enabled']}
                            label="Enable comparison"
                            valuePropName="checked"
                        >
                            <Switch
                                onChange={value => setComparisonEnabled(value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name={['comparison', 'range']}
                            label="Range in days"
                        >
                            <InputNumber
                                disabled={!comparisonEnabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name={['comparison', 'offset']}
                            label="Offset in days"
                        >
                            <InputNumber
                                disabled={!comparisonEnabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="apartmentIds"
                            label="Apartments"
                            rules={[{ required: true, message: "Apartments are required" }]}
                        >
                            <ApartmentSelectWithTags />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name="fieldNames"
                            label="Fields"
                            rules={[{ required: true, message: "Fields are required" }]}
                        >
                            <Select
                                placeholder="Fields"
                                mode="multiple"
                                options={
                                    Object.entries(fieldsMapping)
                                        .map(([key, value]) => ({ label: value, value: key }))
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal >
    );
}
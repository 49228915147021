import { useEffect } from "react";
import { Form } from "antd";
import CleanersSelect from "cleaning-new/components/cleaners-select/CleanersSelect";
import "./style.css";

export default function CleanersSelectForm(props) {
    const {
        form,
        date,
        cleanerIds,
        multiple,
        relationsForStorageIds,
        disableCleanerIds,
        onCleanerSelected,
        ...otherProps
    } = props;

    useEffect(() => {
        if (multiple) {
            form?.setFieldsValue({
                cleanerIds,
            });
        }
        else {
            form?.setFieldsValue({
                cleanerIds: cleanerIds?.[0],
            });
        }
    }, [form, cleanerIds, multiple]);

    function handleCleanerChange() {
        if (!multiple) {
            onCleanerSelected();
        }
    }

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="cleanerIds"
                noStyle
            >
                <CleanersSelect
                    date={date}
                    multiple={multiple}
                    relationsForStorageIds={relationsForStorageIds}
                    disableCleanerIds={disableCleanerIds}
                    onChange={() => handleCleanerChange()}
                />
            </Form.Item>
        </Form>
    );
}
import { useQuery } from "@apollo/client";
import { STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING, STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING } from "./common";
import { Space, Table, Tag, Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";
import { GET_APARTMENT_TAGS_QUERY } from "./graphql";
import { formatISO } from "date-fns";

export default function StatisticsApartmentsTable(props) {
    const { referenceDate, fieldNames, apartments, statistics, statisticsComparison, comparisonEnabled, loading, ...otherProps } = props;

    const fieldNameMapping = referenceDate === 'accommodationDate'
        ? STATISTICS_BY_ACCOMMODATION_DATE_FIELD_MAPPING
        : STATISTICS_BY_BOOKED_DATE_FIELD_MAPPING;

    const { data: apartmentsData } = useQuery(GET_APARTMENT_TAGS_QUERY);

    function renderPercent(value) {
        if (value === null || value === undefined) {
            return null;
        }
        if (comparisonEnabled) {
            const change = Math.round((value[0] - value[1]) / value[1] * 100);
            const changeStr = change > 0 ? '+' + change : change;
            return (
                <Tooltip
                    title={`${value[1]} → ${value[0]}`}
                >
                    <Space>
                        <Typography.Text>
                            {Math.round(value[0] * 100)}%
                        </Typography.Text>
                        <Tag
                            color={change > 0 ? "green" : "red"}
                            style={{
                                width: '50px',
                                textOverflow: 'clip',
                                overflow: 'hidden',
                            }}
                        >
                            {changeStr}%
                        </Tag>
                    </Space>
                    {/* {Math.round(value[0] * 100) + '% (' + changeStr + '%)'} */}
                </Tooltip>
            )

        }
        else {
            return Math.round(value * 100) + '%';
        }
    }

    function renderDecimal(value) {
        if (value === null || value === undefined) {
            return null;
        }
        if (comparisonEnabled) {
            const change = Math.round((value[0] - value[1]) / value[1] * 100);
            const changeStr = change > 0 ? '+' + change : change;
            return (
                <Tooltip
                    title={`${value[1]} → ${value[0]}`}
                >
                    <Space>
                        <Typography.Text>
                            {value[0].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </Typography.Text>
                        <Tag
                            color={change > 0 ? "green" : "red"}
                            style={{
                                width: '50px',
                                textOverflow: 'clip',
                                overflow: 'hidden',
                            }}
                        >
                            {changeStr}%
                        </Tag>
                    </Space>
                    {/* {value[0].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' (' + changeStr + '%)'} */}
                </Tooltip>
            );
        }
        else {
            return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    }

    function renderInteger(value) {
        if (value === null || value === undefined) {
            return null;
        }
        if (comparisonEnabled) {
            const change = Math.round((value[0] - value[1]) / value[1] * 100);
            const changeStr = change > 0 ? '+' + change : change;
            return (
                <Tooltip
                    title={`${value[1]} → ${value[0]}`}
                >
                    <Space>
                        <Typography.Text>
                            {value[0]}
                        </Typography.Text>
                        <Tag
                            color={change > 0 ? "green" : "red"}
                            style={{
                                width: '50px',
                                textOverflow: 'clip',
                                overflow: 'hidden',
                            }}
                        >
                            {changeStr}%
                        </Tag>
                    </Space>
                    {/* {value[0] + ' (' + changeStr + '%)'} */}
                </Tooltip>
            );
        }
        else {
            return value;
        }
    }

    const FIELD_RENDERERS = {
        bookedDays: value => renderInteger(value),
        availableDays: value => renderInteger(value),
        bookingRate: value => renderPercent(value),
        bookingWindowMean: value => renderDecimal(value),
        numberOfGuestsMean: value => renderDecimal(value),
        numberOfGuestsArriving: value => renderInteger(value),
        numberOfGuestsDeparting: value => renderInteger(value),
        accommodationPriceTotal: value => renderDecimal(value),
        dailyAccommodationPriceMean: value => renderDecimal(value),
        basePriceTotal: value => renderDecimal(value),
        dailyBasePriceMean: value => renderDecimal(value),
        cleaningPriceTotal: value => renderDecimal(value),
        dailyCleaningPriceMean: value => renderDecimal(value),
        commissionTotal: value => renderDecimal(value),
        dailyCommissionMean: value => renderDecimal(value),
        revenueNetTotal: value => renderDecimal(value),
        revenueGrossTotal: value => renderDecimal(value),
        vatAmountTotal: value => renderDecimal(value),
        dailyRevenueNetMean: value => renderDecimal(value),
        dailyRevenueGrossMean: value => renderDecimal(value),
        revenueGrossAfterCommissionTotal: value => renderDecimal(value),
        dailyRevenueGrossAfterCommissionMean: value => renderDecimal(value),
        expensesNetTotal: value => renderDecimal(value),
        expensesGrossTotal: value => renderDecimal(value),
        incomeNetTotal: value => renderDecimal(value),
        reservationsCount: value => renderInteger(value),
        reservationLengthTotal: value => renderInteger(value),
        incomeGrossTotal: value => renderDecimal(value),
        reservationLengthMean: value => renderDecimal(value),
        numberOfGuestsTotal: value => renderInteger(value),
        accommodationPriceMean: value => renderDecimal(value),
        cleaningPriceMean: value => renderDecimal(value),
        commissionMean: value => renderDecimal(value),
        revenueNetMean: value => renderDecimal(value),
        revenueGrossMean: value => renderDecimal(value),
    };

    function sorterSingle(a, b, date) {
        if (comparisonEnabled) {
            return a[`${date}_${fieldNames[0]}`][0] - b[`${date}_${fieldNames[0]}`][0];
        }
        else {
            return a[`${date}_${fieldNames[0]}`] - b[`${date}_${fieldNames[0]}`];
        }
    }

    function sorterMultiple(a, b, date, fieldName) {
        if (comparisonEnabled) {
            return a[`${date}_${fieldName}`][0] - b[`${date}_${fieldName}`][0];
        }
        else {
            return a[`${date}_${fieldName}`] - b[`${date}_${fieldName}`];
        }
    }

    function getApartmentTags(apartmentName) {
        const apartment = [...apartmentsData?.apartments ?? []]
            .find(apartment => apartment.name === apartmentName);

        if (!apartment) {
            return '';
        }

        return apartment.tags.join(', ');
    }

    function getColumns(data) {
        const dates = data
            .filter(item => item)
            .filter(item => item.interval)
            .map(item => formatISO(item.interval, { representation: 'date' }))
            .filter((item, index, array) => array.indexOf(item) === index);

        let datesColumns;

        if (dates.length === 1) {
            datesColumns = fieldNames.map(
                fieldName => ({
                    title: fieldNameMapping[fieldName],
                    dataIndex: `${dates[0]}_${fieldName}`,
                    align: 'right',
                    ellipsis: true,
                    render: value => FIELD_RENDERERS[fieldName](value),
                    sorter: (a, b) => sorterMultiple(a, b, dates[0], fieldName),
                })
            )
        }
        else {
            datesColumns = dates
                .sort()
                .map(
                    date => {
                        if (fieldNames.length === 1) {
                            return {
                                title: date,
                                dataIndex: `${date}_${fieldNames[0]}`,
                                align: 'right',
                                ellipsis: true,
                                render: value => FIELD_RENDERERS[fieldNames[0]](value),
                                sorter: (a, b) => sorterSingle(a, b, date),
                            };
                        }
                        else {
                            return {
                                title: date,
                                children:
                                    fieldNames.map(
                                        fieldName => ({
                                            title: fieldNameMapping[fieldName],
                                            dataIndex: `${date}_${fieldName}`,
                                            align: 'right',
                                            ellipsis: true,
                                            render: value => FIELD_RENDERERS[fieldName](value),
                                            sorter: (a, b) => sorterMultiple(a, b, date, fieldName),
                                        })
                                    )
                            }
                        }
                    }
                );
        }

        let apartmentColumn = {
            title: 'Apartment',
            dataIndex: 'apartment',
            render: apartmentName => (
                <Space>
                    <Typography.Text>
                        {apartmentName}
                    </Typography.Text>
                    <Tooltip title={getApartmentTags(apartmentName)}>
                        <Tag>tags</Tag>
                    </Tooltip>
                </Space>
            ),
            sorter: (a, b) => a.apartment.localeCompare(b.apartment),
            width: 300,
            fixed: 'left',
            ellipsis: true,
        };

        if (apartments) {
            apartmentColumn = {
                title: 'Apartment',
                render: item => {
                    const apartment = [...apartments?.apartments ?? []]
                        .find(apartment => apartment.name === item.apartment);

                    if (apartment?.priceConfig) {
                        return (
                            <Space>
                                <Link to={`/prices/${apartment.id}`}>
                                    {apartment.name}
                                </Link>
                                <Tooltip title={getApartmentTags(apartment.name)}>
                                    <Tag>tags</Tag>
                                </Tooltip>
                            </Space>
                        )
                    }
                    else {
                        return (
                            <Space>
                                <Typography.Text>
                                    {apartment.name}
                                </Typography.Text>
                                <Tooltip title={getApartmentTags(apartment.name)}>
                                    <Tag>tags</Tag>
                                </Tooltip>
                            </Space>
                        );
                    }
                },
                width: 300,
                fixed: 'left',
                ellipsis: true,
            };
        }

        return [
            apartmentColumn,
            ...datesColumns,
        ];
    }

    function getData(data, fieldNames) {
        const apartments = data
            .filter(item => item)
            .filter(item => item.apartmentGroupName)
            .map(item => item.apartmentGroupName)
            .filter((item, index, array) => array.indexOf(item) === index)
            .sort();

        const dates = data
            .filter(item => item)
            .filter(item => item.interval)
            .map(item => formatISO(item.interval, { representation: 'date' }))
            .filter((item, index, array) => array.indexOf(item) === index)
            .sort();

        return apartments
            .sort((a, b) => a.localeCompare(b))
            .map(apartment => {
                const values = Object.fromEntries(
                    dates.map(date => {
                        const item = data
                            .filter(item => item)
                            .filter(item => item.apartmentGroupName)
                            .filter(item => item.interval)
                            .find(item => item.apartmentGroupName === apartment && formatISO(item.interval, { representation: 'date' }) === date);

                        return fieldNames.map(fieldName => ([
                            `${date}_${fieldName}`,
                            item?.[fieldName],
                        ]));
                    })
                        .flat()
                );

                return {
                    apartment,
                    ...values,
                }
            });
    }

    function buildComparisonData(data, dataComparison) {
        const excludedKeys = [
            'interval',
            'apartmentGroupName',
        ];

        return data.map((item, i) => {
            return Object.fromEntries(
                Object.entries(item).map(([key, value]) => {
                    return [
                        key,
                        excludedKeys.includes(key) ? value : [value, dataComparison?.[i]?.[key] ?? 0],
                    ];
                })
            );
        });
    }

    const data = referenceDate === 'accommodationDate'
        ? comparisonEnabled
            ? buildComparisonData(
                statistics?.statisticsByAccommodationDate ?? [],
                statisticsComparison?.statisticsByAccommodationDate ?? [],
            )
            : statistics?.statisticsByAccommodationDate
        : comparisonEnabled
            ? buildComparisonData(
                statistics?.statisticsByBookedDate ?? [],
                statisticsComparison?.statisticsByBookedDate ?? [],
            )
            : statistics?.statisticsByBookedDate;

    return (
        <Table
            dataSource={getData(data ?? [], fieldNames)}
            loading={loading}
            columns={getColumns(data ?? [])}
            tableLayout="auto"
            pagination={false}
            scroll={{
                x: true,
            }}
            rowKey="apartment"
            {...otherProps}
        />
    );
}
import { Col, PageHeader, Result, Row } from "antd";
import { useAuth } from "auth";
import { contains } from "common/common";
import JobCard from "jobs-new/components/job-card/JobCard";
import JobLogCard from "jobs-new/components/job-log-card/JobLogCard";
import { useNavigate, useParams } from "react-router-dom";

export default function ShowJobView() {
    const { jobId } = useParams();
    const navigate = useNavigate();

    const { permissions } = useAuth();

    if (!contains(permissions, ['user:get:self', 'user:get:other', 'user_group:get', 'apartment:get', 'storage:get', 'reservation:get', 'job:get'])) {
        return (
            <Result
                status="error"
                title="No permissions"
                subTitle="You don't have permissions to view job"
            />
        );
    }

    return (
        <PageHeader
            title="Job"
            onBack={() => navigate(-1)}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <JobCard
                        jobId={jobId}
                    />
                </Col>
                <Col span={24}>
                    <JobLogCard
                        jobId={jobId}
                    />
                </Col>
            </Row>
        </PageHeader>
    );
}
import { Button, Form, Input, Card } from 'antd';
import { useAuth } from 'auth';
import "./style.css";

export default function LoginView() {
    const { login, loading } = useAuth();

    const [form] = Form.useForm();

    function handleLogin() {
        form
            .validateFields()
            .then(values => {
                login({
                    email: values.email,
                    phone: values.phone,
                    password: values.password,
                });
            });
    }

    return (
        <div className="login-screen-container">
            <div className='login-box'>
                <Card>
                    <Form form={form}>
                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: 'Email is required' },
                                { type: 'email' },
                            ]}
                        >
                            <Input
                                placeholder="Email"
                                onPressEnter={() => handleLogin()}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Password is required' }]}
                        >
                            <Input.Password
                                placeholder="Password"
                                onPressEnter={() => handleLogin()}
                            />
                        </Form.Item>
                    </Form>
                    <Button
                        type="primary"
                        onClick={() => handleLogin()}
                        loading={loading}
                        block
                    >
                        Login
                    </Button>
                </Card>
            </div>
            <div className="support-container">
                Support: <a href="mailto:admin@staysight.com">admin@staysight.com</a>
            </div>
        </div>
    );
}

import { useState } from "react";
import { Form } from "antd";
import classNames from "classnames";
import JobAssignersModal from "jobs-new/components/JobAssignersModal";
import "./styles.css";

export default function JobAssignersList(props) {
    const {
        job,
        editable,
        noWrap,
        onUpdate,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();

    function handleSubmit() {
        form
            .validateFields()
            .then(values => {
                onUpdate(values.assigners);
                setModalOpen(false);
            });
    }

    function isEmpty() {
        return job.assigners.length === 0;
    }

    function linkText() {
        if (isEmpty()) {
            return 'No assigners';
        }
        return job.assigners.map(assigner => `${assigner.firstName} ${assigner.lastName}`).join(', ');
    }

    return (
        <>
            <div
                className={classNames({
                    'job-assigners-list-nowrap': noWrap,
                })}
            >
                <span
                    onClick={() => editable && setModalOpen(true)}
                    className={classNames({
                        'job-assigners-list-link': editable,
                        'job-assigners-list-empty': isEmpty(),
                    })}
                >
                    {linkText()}
                </span>
            </div>
            <JobAssignersModal
                open={modalOpen}
                form={form}
                job={job}
                onSubmit={() => handleSubmit()}
                onCancel={() => setModalOpen(false)}
            />
        </>
    );
}
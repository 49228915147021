import { useEffect, useMemo, useRef } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import Leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import MarkerIcon from "leaflet/dist/images/marker-icon.png";
import MarkerIconX2 from "leaflet/dist/images/marker-icon-2x.png";
import MarkerShadow from "leaflet/dist/images/marker-shadow.png";

Leaflet.Icon.Default.prototype.options.iconUrl = MarkerIcon;
Leaflet.Icon.Default.prototype.options.iconRetinaUrl = MarkerIconX2;
Leaflet.Icon.Default.prototype.options.shadowUrl = MarkerShadow;

function DraggableMarker(props) {
    const {
        marker,
        onMarkerChange,
    } = props;

    const markerRef = useRef();

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                if (markerRef.current) {
                    onMarkerChange({
                        ...marker,
                        coordinates: markerRef.current.getLatLng(),
                    });
                }
            },
        }),
        [marker, onMarkerChange],
    )

    return (
        <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={marker.coordinates}
            ref={markerRef}
        />
    );
}

function getMarkersCenter(markers) {
    if (!markers || markers.length === 0) {
        return [0, 0];
    }

    const minLat = Math.min(...markers.map(marker => marker.coordinates[0]));
    const maxLat = Math.max(...markers.map(marker => marker.coordinates[0]));
    const minLon = Math.min(...markers.map(marker => marker.coordinates[1]));
    const maxLon = Math.max(...markers.map(marker => marker.coordinates[1]));

    return [minLat + (maxLat - minLat) / 2, minLon + (maxLon - minLon) / 2];
}

export default function Map(props) {
    const {
        markers,
        defaultZoom,
        width,
        height,
        onMarkersChange,
    } = props;

    const map = useRef();

    useEffect(() => {
        if (map.current) {
            map.current.setView(getMarkersCenter(markers));
        }
    }, [markers]);

    function handleMarkerChange(newMarker, index) {
        onMarkersChange(markers.map((marker, markerIndex) => markerIndex === index ? newMarker : marker));
    }

    return (
        <MapContainer
            center={getMarkersCenter(markers)}
            zoom={defaultZoom || 16}
            ref={map}
            style={{
                width,
                height,
            }}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markers.map((marker, index) => {
                if (marker.draggable) {
                    return (
                        <DraggableMarker
                            marker={marker}
                            onMarkerChange={value => handleMarkerChange(value, index)}
                            key={index}
                        />
                    );
                }
                else {
                    return (
                        <Marker
                            position={marker.coordinates}
                            key={index}
                        />
                    );
                }
            })}
        </MapContainer>
    )
}
import { useEffect, useState } from "react";
import { CLEANING_APARTMENT_STATUS_FINISHED, CLEANING_APARTMENT_STATUS_IN_PROGRESS, CLEANING_APARTMENT_STATUS_PLANNED } from "cleaning-new/common";
import { format, formatDistance, isSameDay, startOfToday } from "date-fns";
import "./style.css";

export default function CardTitle(props) {
    const { cleaningGroupApartment } = props;

    const [currentTimestamp, setCurrentTimestamp] = useState(new Date());
    useEffect(() => {
        const intervalId = window.setInterval(() => setCurrentTimestamp(new Date()));
        return () => window.clearInterval(intervalId);
    }, []);

    if (cleaningGroupApartment.status === CLEANING_APARTMENT_STATUS_PLANNED) {
        return (
            <div className="cleaning-information-apartment-card-title-container">
                <span className="cleaning-information-apartment-card-title">
                    {cleaningGroupApartment.apartment.name}
                </span>
            </div>
        );
    }

    if (cleaningGroupApartment.status === CLEANING_APARTMENT_STATUS_IN_PROGRESS) {
        const startedAt = cleaningGroupApartment.lastStatusChange.updatedAt;
        const timestampStr = isSameDay(startedAt, startOfToday())
            ? formatDistance(startedAt, currentTimestamp, { addSuffix: true })
            : format(startedAt, 'yyyy-MM-dd HH:mm');

        return (
            <div className="cleaning-information-apartment-card-title-container">
                <span className="cleaning-information-apartment-card-title">
                    {cleaningGroupApartment.apartment.name}
                </span>
                <span className="cleaning-information-apartment-card-subtitle">
                    started {timestampStr}
                </span>
            </div>
        );
    }

    if (cleaningGroupApartment.status === CLEANING_APARTMENT_STATUS_FINISHED) {
        const finishedAt = cleaningGroupApartment.lastStatusChange.updatedAt;
        const timestampStr = isSameDay(finishedAt, startOfToday())
            ? formatDistance(finishedAt, currentTimestamp, { addSuffix: true })
            : format(finishedAt, 'yyyy-MM-dd HH:mm');

        return (
            <div className="cleaning-information-apartment-card-title-container">
                <span className="cleaning-information-apartment-card-title">
                    {cleaningGroupApartment.apartment.name}
                </span>
                <span className="cleaning-information-apartment-card-subtitle">
                    finished {timestampStr}
                </span>
            </div>
        );
    }
}
import JobBox from "./JobBox";
import JobDescription from "./JobDescription";
import JobModal from "./JobModal";
import JobPopoverTitle from "./JobPopoverTitle";
import { Popover } from "antd";
import errorHandler from "common/errorHandler";
import { gql, useMutation } from "@apollo/client";
import { useState } from 'react';

const DELETE_MUTATION = gql`
    mutation DeleteJob($input: DeleteJobNewInput!) {
        deleteJobNew(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function JobPopover(props) {
    const { job } = props;

    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [deleteJob] = useMutation(DELETE_MUTATION, {
        onCompleted(response) {
            if (response.deleteJobNew.error) {
                errorHandler(response.deleteJobNew.error);
            }
        },
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'jobsNew',
            });
        }
    });

    function handleJobDelete() {
        deleteJob({
            variables: {
                input: {
                    jobNewId: job.id,
                },
            },
        });
    }

    return (
        <>
            <Popover
                visible={isPopoverVisible}
                onVisibleChange={value => setIsPopoverVisible(value)}
                content={
                    <JobDescription
                        job={job}
                    />
                }
                title={
                    <JobPopoverTitle
                        job={job}
                        onDelete={() => handleJobDelete()}
                    />
                }
                trigger="click"
                placement="right"
                destroyTooltipOnHide={true}
            >
                <JobBox
                    job={job}
                    onClick={() => setIsPopoverVisible(true)}
                />
            </Popover>
            <JobModal
                visible={isModalVisible}
                job={job}
                title="Edit job"
                onClose={() => setIsModalVisible(false)}
            />
        </>
    );
}
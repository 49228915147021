import { gql, useQuery } from "@apollo/client";
import { Button, Col, Form, Modal, Row, Space } from "antd";
import ApartmentsFilterForm from "calendar/forms/ApartmentsFilterForm";
import { intersection } from "common/common";
import { useEffect, useState } from "react";
import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import SettingsForm from "calendar/forms/SettingsForm";

const QUERY = gql`
    query GetApartmentsForCalendarControls {
        apartments(filter: {active: true}) {
            id
            tags
        }
    }
`;

function storeCalendarApartmentsFilter(value) {
    if (!value) {
        window.localStorage.removeItem('calendarApartmentsFilter');
    }
    else {
        window.localStorage.setItem('calendarApartmentsFilter', JSON.stringify(value));
    }
}

function storeCalendarSettings(value) {
    if (!value) {
        window.localStorage.removeItem('calendarSettings');
    }
    else {
        window.localStorage.setItem('calendarSettings', JSON.stringify(value));
    }
}

function loadCalendarApartmentsFilter() {
    const result = window.localStorage.getItem('calendarApartmentsFilter');
    if (result) {
        return JSON.parse(result);
    }
    return undefined;
}

function loadCalendarSettings() {
    const result = window.localStorage.getItem('calendarSettings');
    if (result) {
        return JSON.parse(result);
    }
    return undefined;
}

export default function CalendarControls(props) {
    const {
        settings,
        onApartmentIdsChange,
        onSettingsChange,
    } = props;

    const [apartmentsFilterModalOpen, setApartmentsFilterModalOpen] = useState(false);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [apartmentsFilter, setApartmentsFilter] = useState(loadCalendarApartmentsFilter());

    const [apartmentsFilterForm] = Form.useForm();
    const [settingsForm] = Form.useForm();

    const { data: apartmentsData } = useQuery(QUERY);

    useEffect(() => {
        const storedSettings = loadCalendarSettings();
        if (storedSettings) {
            onSettingsChange(storedSettings);
        }
        else {
            onSettingsChange({
                dayValue: 'price',
                reservationValue: 'none',
            });
        }
    }, [onSettingsChange]);

    useEffect(() => {
        if (apartmentsFilter?.filter === 'tags') {
            if (apartmentsFilter.tagsMode === 'and') {
                const apartmentIds = [...apartmentsData?.apartments ?? []]
                    .filter(apartment => intersection(apartment.tags, apartmentsFilter.tags).length === apartmentsFilter.tags.length)
                    .map(apartment => apartment.id);
                onApartmentIdsChange(apartmentIds);
            }
            if (apartmentsFilter.tagsMode === 'or') {
                const apartmentIds = [...apartmentsData?.apartments ?? []]
                    .filter(apartment => intersection(apartment.tags, apartmentsFilter.tags).length > 0)
                    .map(apartment => apartment.id);
                onApartmentIdsChange(apartmentIds);
            }
        }
        if (apartmentsFilter?.filter === 'apartments') {
            onApartmentIdsChange(apartmentsFilter.apartmentIds);
        }
        if (!apartmentsFilter?.filter) {
            // const apartmentIds = [...apartmentsData?.apartments ?? []].map(apartment => apartment.id);
            // onApartmentIdsChange(apartmentIds);
            onApartmentIdsChange();
        }
    }, [apartmentsFilter, apartmentsData, onApartmentIdsChange]);

    useEffect(() => {
        storeCalendarApartmentsFilter(apartmentsFilter);
    }, [apartmentsFilter]);

    function handleApartmentsFilterFormSubmit() {
        apartmentsFilterForm
            .validateFields()
            .then(values => {
                setApartmentsFilter(values);
            });
        setApartmentsFilterModalOpen(false);
    }

    function handleSettingsFormSubmit() {
        settingsForm
            .validateFields()
            .then(values => {
                onSettingsChange(values);
                storeCalendarSettings(values);
            });
        setSettingsModalOpen(false);
    }

    function resetFilter() {
        setApartmentsFilter({});
    }

    function hasFilter() {
        return apartmentsFilter?.tags?.length > 0 || apartmentsFilter?.apartmentIds?.length > 0;
    }

    function filterButtonLabel() {
        if (apartmentsFilter?.tags?.length > 0) {
            return `Filter: ${apartmentsFilter.tags.join(` ${apartmentsFilter.tagsMode} `)}`;
        }
        if (apartmentsFilter?.apartmentIds?.length > 0) {
            return `Filter: ${apartmentsFilter.apartmentIds.length} apartments`;
        }

        return 'Filter apartments';
    }

    return (
        <Row
            gutter={[16, 16]}
            justify="space-between"
        >
            <Col>
                {hasFilter() && (
                    <Space>
                        <Button
                            onClick={() => setApartmentsFilterModalOpen(true)}
                        >
                            {filterButtonLabel()}
                        </Button>
                        <CloseOutlined
                            onClick={() => resetFilter()}
                        />
                    </Space>
                )}
                {!hasFilter() && (
                    <Button
                        onClick={() => setApartmentsFilterModalOpen(true)}
                    >
                        Filter apartments
                    </Button>
                )}
                <Modal
                    open={apartmentsFilterModalOpen}
                    title="Filter apartments"
                    onOk={() => handleApartmentsFilterFormSubmit()}
                    onCancel={() => setApartmentsFilterModalOpen(false)}
                    destroyOnClose
                >
                    <ApartmentsFilterForm
                        form={apartmentsFilterForm}
                        apartmentsFilter={apartmentsFilter}
                        preserve={false}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                    />
                </Modal>
            </Col>
            <Col>
                <Button
                    icon={<SettingOutlined />}
                    onClick={() => setSettingsModalOpen(true)}
                >
                    Settings
                </Button>
                <Modal
                    open={settingsModalOpen}
                    title="Settings"
                    onOk={() => handleSettingsFormSubmit()}
                    onCancel={() => setSettingsModalOpen(false)}
                    destroyOnClose
                >
                    <SettingsForm
                        form={settingsForm}
                        settings={settings}
                        preserve={false}
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 12,
                        }}
                    />
                </Modal>
            </Col>
        </Row>
    );
}
import { CloseCircleTwoTone, FileOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export default function DocumentThumbnail(props) {
    const {
        file,
        onDownload,
        onRemove,
    } = props;

    return (
        <div className="thumbnail-box">
            <Tooltip title={file.name}>
                <FileOutlined
                    style={{
                        fontSize: '48px',
                        color: '#A0A0A0',
                    }}
                    onClick={() => onDownload()}
                />
            </Tooltip>
            <CloseCircleTwoTone
                style={{
                    position: 'absolute',
                    top: '4px',
                    right: '4px',
                    fontSize: '24px',
                }}
                onClick={() => onRemove()}
            />
        </div>
    );
}
import { gql } from '@apollo/client';

export const GET_LAUNDRY_QUERY = gql`
    query GetLaundry($dateFrom: Date, $dateTo: Date) {
        reservations(filter: {endDateGte: $dateFrom, endDateLte: $dateTo, status: "confirmed"}) {
            id
            endDate
            numberOfGuests
            nextConfirmedReservation {
                id
                numberOfGuests
                airbnbConfirmationCode
            }
            airbnbConfirmationCode
            apartment {
                id
                name
                storage {
                    id
                    name
                    order
                }
            }
        }
    }
`;
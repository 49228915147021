import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, PageHeader, Row, Tabs, message } from "antd";
import { useMutation } from "@apollo/client";
import { taskPromise } from "common/task";
import ApartmentGuestsForm from "apartments/forms/ApartmentGuestsForm";
import ApartmentAvailabilityForm from "apartments/forms/ApartmentAvailabilityForm";
import ApartmentCleaningForm from "apartments/forms/ApartmentCleaningForm";
import ApartmentGeneralForm from "apartments/forms/ApartmentGeneralForm";
import LocationForm from "components/LocationForm";
import { startOfToday } from "date-fns";
import { apartmentAddressParts } from "apartments/common";
import ApartmentPriceConfigForm from "apartments/forms/ApartmentPriceConfigForm";
import { COPY_APARTMENT_PRICE_CONFIGS_MUTATION } from "prices/graphql";
import { gql } from "graphql.macro";

const FORM_COLUMNS = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const MUTATION = gql`
    mutation CreateApartment($input: CreateApartmentInput!) {
        createApartment(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                storage {
                    id
                    name
                }
                active
                calendarLength
                cleaningPrice
                cutOffTime
                defaultAvailable
                defaultAvailableForRent
                defaultPriceConfigDelta
                defaultPriceConfigMinPrice
                defaultPriceConfigSlope
                increasePriceAbove
                increasePriceBy
                latitude
                longitude
                maxGuestCount
                name
                tags
                weekdayMinStay
            }
            syncApartmentUpTask {
                id
            }
            recalculateApartmentCalendarTask {
                id
            }
        }
    }
`;

export default function CreateApartmentView() {
    const navigate = useNavigate();

    const [generalForm] = Form.useForm();
    const [locationForm] = Form.useForm();
    const [cleaningForm] = Form.useForm();
    const [guestsForm] = Form.useForm();
    const [availabilityForm] = Form.useForm();
    const [priceConfigForm] = Form.useForm();

    const apartmentName = Form.useWatch('name', generalForm);
    const addressParts = apartmentAddressParts(apartmentName);
    const buildingAddress = addressParts ? `${addressParts[0]} ${addressParts[1]}` : '';

    const [createApartment] = useMutation(MUTATION, {
        update: cache => {
            cache.evict({
                fieldName: 'apartments',
                id: 'ROOT_QUERY',
            });
        },
    });
    const [copyApartmentPriceConfigs] = useMutation(COPY_APARTMENT_PRICE_CONFIGS_MUTATION);

    const forms = {
        general: generalForm,
        location: locationForm,
        cleaning: cleaningForm,
        guests: guestsForm,
        availability: availabilityForm,
        priceConfig: priceConfigForm,
    };

    const [currentTab, setCurrentTab] = useState('general');
    const [apartment, setApartment] = useState();
    const [loading, setLoading] = useState(false);

    const items = [
        {
            label: 'General',
            key: 'general',
            children: (
                <ApartmentGeneralForm
                    form={forms.general}
                    defaultActive={true}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Location',
            key: 'location',
            children: (
                <LocationForm
                    form={forms.location}
                    initialQuery={buildingAddress}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Cleaning',
            key: 'cleaning',
            children: (
                <ApartmentCleaningForm
                    form={forms.cleaning}
                    apartmentLocation={apartment}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Guests',
            key: 'guests',
            children: (
                <ApartmentGuestsForm
                    form={forms.guests}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Availability',
            key: 'availability',
            children: (
                <ApartmentAvailabilityForm
                    form={forms.availability}
                    {...FORM_COLUMNS}
                />
            ),
        },
        {
            label: 'Price config',
            key: 'priceConfig',
            children: (
                <ApartmentPriceConfigForm
                    form={forms.priceConfig}
                    {...FORM_COLUMNS}
                />
            ),
        },
    ];

    const isLastTab = currentTab === items[items.length - 1].key;

    function handleNext() {
        const nextTab = items[items.findIndex(item => item.key === currentTab) + 1];
        forms[currentTab]
            .validateFields()
            .then(values => {
                setApartment({
                    ...apartment,
                    ...values,
                });
                setCurrentTab(nextTab.key);
            });
    }

    function handleCreate() {
        setLoading(true);
        items
            .reduce((promise, item) => {
                return promise
                    .then(apartment => {
                        return forms[item.key]
                            .validateFields()
                            .then(values => {
                                return {
                                    ...apartment,
                                    ...values,
                                };
                            })
                            .catch(() => {
                                setCurrentTab(item.key);
                                return Promise.reject();
                            });
                    })
            }, Promise.resolve({}))
            .then(apartment => {
                return createApartment({
                    variables: {
                        input: {
                            name: apartment.name,
                            tags: apartment.tags ?? [],
                            active: apartment.active,
                            latitude: apartment.latitude,
                            longitude: apartment.longitude,
                            storageId: apartment.storageId,
                            cleaningPrice: apartment.cleaningPrice,
                            maxGuestCount: apartment.maxGuestCount,
                            increasePriceAbove: apartment.increasePriceAbove,
                            increasePriceBy: apartment.increasePriceBy,
                            calendarLength: apartment.calendarLength,
                            cutOffTime: apartment.cutOffTime,
                            defaultAvailable: apartment.defaultAvailable,
                            defaultAvailableForRent: apartment.defaultAvailableForRent,
                            weekdayMinStay: apartment.weekdayMinStay,
                            defaultPriceConfigMinPrice: apartment.defaultPriceConfigMinPrice,
                            defaultPriceConfigDelta: apartment.defaultPriceConfigDelta,
                            defaultPriceConfigSlope: apartment.defaultPriceConfigSlope,
                        },
                    },
                })
                    .then(response => {
                        const apartmentId = response.data.createApartment.apartment.id;

                        return Promise.all([
                            taskPromise(response.data.createApartment.syncApartmentUpTask?.id),
                            taskPromise(response.data.createApartment.recalculateApartmentCalendarTask?.id),
                        ])
                            .then(() => {
                                if (!apartment.copyPriceConfig) {
                                    return Promise.resolve();
                                }

                                return copyApartmentPriceConfigs({
                                    variables: {
                                        input: {
                                            sourceApartmentId: apartment.copyPriceConfigSourceApartmentId,
                                            sourceDateFrom: startOfToday(),
                                            destinationApartmentId: apartmentId,
                                        },
                                    },
                                })
                                    .then(response => taskPromise(response.data.copyApartmentPriceConfigs.recalculateApartmentCalendarTask?.id));
                            })
                            .then(() => {
                                message.success('Apartment created');
                                navigate(`/apartments`);
                            });
                    })
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <PageHeader
            title="Create apartment"
            onBack={() => navigate("/apartments")}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Tabs
                            items={items}
                            activeKey={currentTab}
                            onChange={value => setCurrentTab(value)}
                            tabPosition="left"
                        />
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                {!isLastTab && (
                                    <Button
                                        type="primary"
                                        onClick={() => handleNext()}
                                        loading={loading}
                                    >
                                        Next
                                    </Button>
                                )}
                                {isLastTab && (
                                    <Button
                                        type="primary"
                                        onClick={() => handleCreate()}
                                        loading={loading}
                                    >
                                        Create
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    );
}
import { Form, Input, Typography } from "antd";
import Upload from "components/upload/Upload";
import { useState } from "react";

export default function JobCommentForm(props) {
    const {
        form,
        comment,
        ...otherProps
    } = props;

    const [showAttachments, setShowAttachments] = useState(false);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="description"
            >
                <Input.TextArea
                    autoSize
                    placeholder="Comment"
                    style={{
                        width: '100%',
                    }}
                />
            </Form.Item>
            {!showAttachments && (
                <Typography.Link onClick={() => setShowAttachments(true)}>
                    Attachments
                </Typography.Link>
            )}
            {showAttachments && (
                <Form.Item
                    name="attachmentIds"
                    noStyle
                >
                    <Upload />
                </Form.Item>
            )}
        </Form>
    )
}
import { Form, Input } from "antd";
import PhoneInput from "components/PhoneInput";
import { useEffect } from "react";
import UserLanguageSelect from "users/components/user-language-select/UserLanguageSelect";

export default function UserGeneralForm(props) {
    const {
        user,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            phone: user?.phone,
            language: user?.language,
        });
    }, [user, form]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="firstName"
                label="First name"
                rules={[{ required: true, message: 'First name is required' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="lastName"
                label="Last name"
                rules={[{ required: true, message: 'Last name is required' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="email"
                label="Email"
                required
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_, email) {
                            const phone = getFieldValue('phone');
                            if (!phone && !email) {
                                return Promise.reject("At least one of email and phone is required");
                            }
                            return Promise.resolve();
                        },
                    }),
                    { type: 'email', message: 'This is not valid email address' },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="phone"
                label="Phone"
                required
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_, phone) {
                            const email = getFieldValue('email');
                            if (!phone && !email) {
                                return Promise.reject("At least one of email and phone is required")
                            }
                            return Promise.resolve();
                        },
                    }),
                    () => ({
                        validator(_, phone) {
                            if (phone && phone.valid && !phone.valid()) {
                                return Promise.reject("Invalid phone number");
                            }
                            return Promise.resolve();
                        },
                    }),
                ]}
            >
                <PhoneInput />
            </Form.Item>
            <Form.Item
                name="language"
                label="Language"
                rules={[{ required: true, message: 'Language is required' }]}
            >
                <UserLanguageSelect />
            </Form.Item>
        </Form>
    );
}
import { DownOutlined, EyeInvisibleOutlined, EyeOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import DisplayedColumnsModal from "./Modal";
import { useState } from "react";

export default function ApartmentDisplay(props) {
    const {
        showInactiveApartments,
        onShowInactiveApartmentsChange,
        displayedColumns,
        onDisblayedColumnsChange,
    } = props;

    const [displayedColumnsModalOpen, setDisplayedColumnsModalOpen] = useState(false);

    function handleAction(action) {
        if (action === 'columns') {
            setDisplayedColumnsModalOpen(true);
        }
        if (action === 'showInactive') {
            onShowInactiveApartmentsChange(true);
        }
        if (action === 'showOnlyActive') {
            onShowInactiveApartmentsChange(false);
        }
    }

    function handleOnDisplayedColumnsChange(value) {
        setDisplayedColumnsModalOpen(false);
        onDisblayedColumnsChange(value);
    }

    const toggleInactiveItem = showInactiveApartments
        ? {
            label: 'Show only active apartments',
            key: 'showOnlyActive',
            icon: <EyeInvisibleOutlined />,
        }
        : {
            label: 'Show inactive apartments',
            key: 'showInactive',
            icon: <EyeOutlined />,
        };

    return (
        <>
            <Dropdown
                menu={{
                    items: [
                        {
                            label: 'Columns',
                            key: 'columns',
                            icon: <UnorderedListOutlined />,
                        },
                        toggleInactiveItem,
                    ],
                    onClick: ({ key: action }) => handleAction(action),
                }}
            >
                <Button
                    icon={<DownOutlined />}
                >
                    Display
                </Button>
            </Dropdown>
            <DisplayedColumnsModal
                open={displayedColumnsModalOpen}
                displayedColumns={displayedColumns}
                onChange={value => handleOnDisplayedColumnsChange(value)}
                onClose={() => setDisplayedColumnsModalOpen(false)}
            />
        </>
    );
}
import { Input, InputNumber, Switch } from "antd";
import ApartmentSelect from "apartments/components/apartment-select/ApartmentSelect";
import DatePicker from "components/DatePicker";
import StorageSelect from "storages/components/storage-select/StorageSelect";
import UserGroupSelect from "user-groups/components/user-group-select/UserGroupSelect";
import UserSelect from "users/components/user-select/UserSelect";

export default function VariableValueInput(props) {
    const {
        type,
        name,
        value,
        onChange,
    } = props;

    if (name === 'apartment_id') {
        return (
            <ApartmentSelect
                value={value}
                onChange={onChange}
            />
        );
    }

    if (name === 'apartment_ids') {
        return (
            <ApartmentSelect
                value={value}
                onChange={onChange}
                mode="multiple"
            />
        );
    }

    if (name === 'storage_id') {
        return (
            <StorageSelect
                value={value}
                onChange={onChange}
            />
        );
    }

    if (name === 'storage_ids') {
        return (
            <StorageSelect
                value={value}
                onChange={onChange}
                mode="multiple"
            />
        );
    }

    if (name === 'user_id') {
        return (
            <UserSelect
                value={value}
                onChange={onChange}
            />
        );
    }

    if (name === 'user_ids') {
        return (
            <UserSelect
                value={value}
                onChange={onChange}
                mode="multiple"
            />
        );
    }

    if (name === 'user_group_id') {
        return (
            <UserGroupSelect
                value={value}
                onChange={onChange}
            />
        );
    }

    if (name === 'user_group_ids') {
        return (
            <UserGroupSelect
                value={value}
                onChange={onChange}
                mode="multiple"
            />
        );
    }

    if (type === 'integer') {
        return (
            <InputNumber
                value={value}
                onChange={onChange}
                precision={0}
            />
        );
    }

    if (type === 'float') {
        return (
            <InputNumber
                value={value}
                onChange={onChange}
            />
        );
    }

    if (type === 'boolean') {
        return (
            <Switch
                checked={value}
                onChange={onChange}
            />
        );
    }

    if (type === 'string') {
        return (
            <Input
                value={value}
                onChange={e => onChange(e.target.value)}
            />
        );
    }

    if (type === 'date') {
        return (
            <DatePicker
                value={value}
                onChange={onChange}
            />
        );
    }

    if (type === 'datetime') {
        return (
            <DatePicker
                value={value}
                onChange={onChange}
                showTime
                showHour
                showMinute
            />
        );
    }

    if (type === 'interval') {
        return (
            <Input
                value={value}
                onChange={e => onChange(e.target.value)}
            />
        );
    }

    if (type === 'array') {
        return (
            <Input
                value={value}
                onChange={e => onChange(e.target.value)}
            />
        );
    }
}
import { formatISO, startOfToday } from "date-fns";
import { Navigate } from "react-router-dom";

export default function PlanCleaningRedirect() {
    return (
        <Navigate
            to={`/cleaning/plan/${formatISO(startOfToday(), { representation: 'date' })}`}
            replace
        />
    );
}
import { Col, Form, Input, Row, Switch } from "antd";
import ApartmentTagsSelect from "apartments/components/apartment-select/ApartmentTagsSelect";
import { useEffect } from "react";

export default function ApartmentGeneralForm(props) {
    const {
        apartment,
        defaultActive,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            name: apartment?.name,
            tags: apartment?.tags,
            active: apartment?.active || defaultActive,
        });
    }, [apartment, defaultActive, form]);

    return (
        <Row gutter={[32, 32]}>
            <Col span={24}>
                <Form
                    form={form}
                    {...otherProps}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        tooltip="Name allows you to easily distinguish apartments"
                        rules={[{ required: true, message: 'Name is required' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="tags"
                        label="Tags"
                        tooltip="Tags allow for easy grouping your apartments"
                    >
                        <ApartmentTagsSelect
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="active"
                        label="Active"
                        tooltip="Inactive apartments are not going to be displayed in other parts of the system"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
import { Form, InputNumber, Select } from "antd";
import DatePicker from "components/DatePicker";
import { useState } from "react";

export default function PriceConfigModificationModalContent(props) {
    const { form, onSubmit } = props;

    const [parameter, setParameter] = useState();

    return (
        <Form
            form={form}
            onFinish={values => onSubmit(values)}
            preserve={false}
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 18,
            }}
        >
            <Form.Item
                label="Dates"
                name="dates"
                rules={[{ required: true, message: 'Dates are required' }]}
            >
                <DatePicker.RangePicker />
            </Form.Item>
            <Form.Item
                label="Parameter"
                name="parameter"
                rules={[{ required: true, message: 'Parameter is required' }]}
            >
                <Select
                    options={[
                        { value: 'minPrice', label: 'Min price (X)' },
                        { value: 'delta', label: 'Delta (Y)' },
                        { value: 'slope', label: 'Slope (Z)' },
                    ]}
                    onChange={value => setParameter(value)}
                />
            </Form.Item>

            {(parameter === 'minPrice' || parameter === 'delta') && (
                <>
                    <Form.Item
                        label="Change by"
                        name="changeBy"
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        label="Mode"
                        name="mode"
                        initialValue="value"
                    >
                        <Select
                            options={[
                                { label: 'Value', value: 'value' },
                                { label: 'Percent', value: 'percent' },
                            ]}
                        />
                    </Form.Item>
                </>
            )}
            {parameter === 'slope' && (
                <Form.Item
                    label="Set to"
                    name="setTo"
                >
                    <Select
                        options={[
                            { value: 30 },
                            { value: 35 },
                            { value: 40 },
                            { value: 45 },
                            { value: 50 },
                            { value: 60 },
                            { value: 70 },
                            { value: 80 },
                            { value: 90 },
                            { value: 100 },
                        ]}
                    />
                </Form.Item>
            )}
        </Form>
    );
}
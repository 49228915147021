import { Select } from "antd";

export default function TypeSelect(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    return (
        <Select
            value={value}
            onChange={value => onChange(value)}
            options={[
                { label: 'Integer', value: 'integer' },
                { label: 'Float', value: 'float' },
                { label: 'Boolean', value: 'boolean' },
                { label: 'String', value: 'string' },
                { label: 'Date', value: 'date' },
                { label: 'Datetime', value: 'datetime' },
                { label: 'Interval', value: 'interval' },
                { label: 'Array', value: 'array' },
            ]}
            style={{
                width: '100%',
            }}
            {...otherProps}
        />
    )
}
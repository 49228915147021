import { Col, Form, Row, Switch } from "antd";
import { useEffect } from "react";
import UserGroupSelect from "user-groups/components/user-group-select/UserGroupSelect";

export default function OrganizationCleaningForm(props) {
    const {
        form,
        organization,
        ...otherProps
    } = props;

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                cleaningUserGroupIds: organization?.cleaningUserGroups?.map(group => group.id),
                cleaningDirtyDuringUnavailable: organization?.cleaningDirtyDuringUnavailable ?? false,
                cleaningDirtyDuringUnavailableForRent: organization?.cleaningDirtyDuringUnavailableForRent ?? false,
                cleaningDirtyAfterUnavailable: organization?.cleaningDirtyAfterUnavailable ?? false,
            });
        }
    }, [form, organization]);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form
                    form={form}
                    labelWrap
                    {...otherProps}
                >
                    <Form.Item
                        name="cleaningUserGroupIds"
                        label="Cleaning user groups"
                    >
                        <UserGroupSelect
                            mode="multiple"
                        />
                    </Form.Item>
                    <Form.Item
                        name="cleaningDirtyDuringUnavailable"
                        label="Show apartment in cleaning plan during blocked period"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name="cleaningDirtyDuringUnavailableForRent"
                        label="Show apartment in cleaning plan during unavailable for rent period"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name="cleaningDirtyAfterUnavailable"
                        label="Show apartment in cleaning plan after blocked period"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}

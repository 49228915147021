import { gql, useQuery } from "@apollo/client";
import { Alert } from "antd";
import { formatDistance, formatISO, subDays } from "date-fns";
import Microcalendar from "./Microcalendar";
import { LoadingOutlined } from "@ant-design/icons";

const QUERY = gql`
    query GetNoDepartureInformationData($apartmentId: ID!, $dateFrom: Date!, $dateTo: Date!) {
        apartment(apartmentId: $apartmentId) {
            id
            previousReservation(date: $dateTo) {
                id
                endDate
            }
            calendar(filter: {dateFrom: $dateFrom, dateTo: $dateTo}) {
                id
                date
                available
                minStayAvailable
                comment
            }
            reservations(filter: {status: "confirmed", endDateGte: $dateFrom, startDateLte: $dateTo}) {
                id
                startDate
                endDate
            }
        }
    }
`;

export default function NoDepartureInformation(props) {
    const {
        apartmentId,
        date,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            apartmentId,
            dateFrom: formatISO(subDays(date, 5), { representation: 'date' }),
            dateTo: formatISO(date, { representation: 'date' }),
        },
    });

    if (loading) {
        // The popover container jumps when content size changes
        // so we create the loading container with the same dimensions
        // as the loaded one
        return (
            <div className="no-departure-information-container-loading">
                <LoadingOutlined />
            </div>
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load departure information"
            />
        );
    }

    return (
        <div className="no-departure-information-container">
            <div className="no-departure-information-description">
                Departure {formatDistance(data.apartment.previousReservation.endDate, date, { addSuffix: true })}
            </div>
            <div className="no-departure-information-microcalendar">
                <Microcalendar
                    calendar={data.apartment.calendar}
                    reservations={data.apartment.reservations}
                    markDate={date}
                />
            </div>
        </div>
    );
}
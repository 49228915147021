import { useState } from "react";
import { Button, Form, Modal, Radio, Space, Tooltip } from "antd";
import { HomeOutlined, UnorderedListOutlined, UserOutlined } from "@ant-design/icons";
import JobssListDisplayedColumnsForm from "jobs-new/forms/JobsListDisplayedColumnsForm";

export default function Display(props) {
    const {
        display,
        onChange,
    } = props;

    const [form] = Form.useForm();
    const [modalOpen, setModalOpen] = useState(false);

    function handleGroupingChange(value) {
        const displayedColumns = display?.grouping === 'userGroups'
            ? [
                'tags',
                'storage',
                'status',
                'assigners',
                'watchers',
                'priority',
            ]
            : [
                'tags',
                'location',
                'reservationSituation',
                'status',
                'assigners',
                'priority',
            ];

        onChange({
            ...display,
            grouping: value,
            displayedColumns,
        });
    }

    function handleSaveDisplayedColumns() {
        form
            .validateFields()
            .then(values => {
                onChange({
                    ...display,
                    displayedColumns: values.displayedColumns,
                });
                setModalOpen(false);
            });
    }

    return (
        <Space>
            <Radio.Group
                value={display?.grouping}
                onChange={e => handleGroupingChange(e.target.value)}
            >
                <Tooltip title="Group by user groups">
                    <Radio.Button value="userGroups">
                        <UserOutlined />
                    </Radio.Button>
                </Tooltip>
                <Tooltip title="Group by locations">
                    <Radio.Button value="locations">
                        <HomeOutlined />
                    </Radio.Button>
                </Tooltip>
            </Radio.Group>
            <Tooltip title="Displayed columns">
                <Button
                    onClick={() => setModalOpen(true)}
                >
                    <UnorderedListOutlined />
                </Button>
            </Tooltip>
            <Modal
                title="Displayed columns"
                open={modalOpen}
                destroyOnClose
                onCancel={() => setModalOpen(false)}
                onOk={() => handleSaveDisplayedColumns()}
                okText="Save"
            >
                <JobssListDisplayedColumnsForm
                    form={form}
                    grouping={display?.grouping}
                    displayedColumns={display?.displayedColumns}
                />
            </Modal>
        </Space>
    );
}
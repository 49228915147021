import { Checkbox, Col, Form, Modal, Row } from "antd";
import { useEffect } from "react";

export default function DisplayedColumnsModal(props) {
    const {
        open,
        displayedColumns,
        onChange,
        onClose,
    } = props;

    const columns = [
        {
            title: 'Tags',
            key: 'tags',
        },
        {
            title: 'Storage',
            key: 'storage',
        },
        {
            title: 'Cleaning price',
            key: 'cleaningPrice',
        },
        {
            title: 'Maximum guest count',
            key: 'maxGuestCount',
        },
        {
            title: 'Base guest count',
            key: 'increasePriceAbove',
        },
        {
            title: 'Extra guest fee',
            key: 'increasePriceBy',
        },
        {
            title: 'Calendar length',
            key: 'calendarLength',
        },
        {
            title: 'Cut off time',
            key: 'cutOffTime',
        },
        {
            title: 'Default price config min price',
            key: 'defaultPriceConfigMinPrice',
        },
        {
            title: 'Default price config delta',
            key: 'defaultPriceConfigDelta',
        },
        {
            title: 'Default price config slope',
            key: 'defaultPriceConfigSlope',
        },
        {
            title: 'Channels',
            key: 'channels',
        },
    ];

    const [form] = Form.useForm();

    useEffect(() => {
        form?.setFieldValue('displayedColumns', displayedColumns);
    }, [displayedColumns, form]);

    function handleSubmit() {
        form
            .validateFields()
            .then(values => {
                onChange(values.displayedColumns);
            });
    }

    return (
        <Modal
            title="Displayed columns"
            open={open}
            destroyOnClose
            forceRender
            onOk={() => handleSubmit()}
            onCancel={() => onClose()}
        >
            <Form
                form={form}
                preserve={false}
            >
                <Form.Item
                    name="displayedColumns"
                >
                    <Checkbox.Group>
                        <Row gutter={[8, 8]}>
                            {columns.map(column => (
                                <Col
                                    key={column.key}
                                    span={24}
                                >
                                    <Checkbox
                                        value={column.key}
                                    >
                                        {column.title}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
}
import { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Col, Row } from "antd";
import { endOfMonth, endOfYear, format, formatISO, isEqual, isSameMonth, startOfMonth, startOfToday, startOfYear } from "date-fns";
import classNames from "classnames";
import InlineCalendar from "components/InlineCalendar";
import "./style.css";
import { dateRange } from "common/common";

const QUERY = gql`
    query GetApartmentCalendarForAvailability($apartmentId: ID! $dateFrom: Date!, $dateTo: Date!) {
        apartment(apartmentId: $apartmentId) {
            id
            calendar(filter: {dateFrom: $dateFrom, dateTo: $dateTo}) {
                id
                date
                available
                availableForRent
            }
        }
    }
`;

const MUTATION = gql`
    mutation UpdateApartmentCalendar($input: UpdateApartmentCalendarInput!) {
        updateApartmentCalendar(input: $input) {
            error {
                type
                message
            }
            apartmentCalendar {
                id
                date
                available
                availableForRent
            }
        }
    }
`;

export default function ApartmentAvailabilityCalendar(props) {
    const {
        apartmentId,
    } = props;

    const [mode, setMode] = useState('month');
    const [selectedDate, setSelectedDate] = useState(startOfToday());

    const fetchDateFrom = mode === 'month'
        ? startOfMonth(selectedDate)
        : startOfYear(selectedDate)
    const fetchDateTo = mode === 'month'
        ? endOfMonth(selectedDate)
        : endOfYear(selectedDate);

    const { data } = useQuery(QUERY, {
        variables: {
            apartmentId,
            dateFrom: formatISO(fetchDateFrom, { representation: 'date' }),
            dateTo: formatISO(fetchDateTo, { representation: 'date' }),
        },
    });
    const [updateApartmentCalendar] = useMutation(MUTATION);

    function handleSetAvailableForRent() {
        const updateDateFrom = mode === 'month'
            ? selectedDate
            : startOfMonth(selectedDate)
        const updateDateTo = mode === 'month'
            ? selectedDate
            : endOfMonth(selectedDate);

        updateApartmentCalendar({
            variables: {
                input: {
                    apartmentId,
                    changes: dateRange(updateDateFrom, updateDateTo).map(date => ({
                        date: formatISO(date, { representation: 'date' }),
                        availableForRent: true,
                    })),
                },
            },
        });
    }

    function handleSetUnavailableForRent() {
        const updateDateFrom = mode === 'month'
            ? selectedDate
            : startOfMonth(selectedDate)
        const updateDateTo = mode === 'month'
            ? selectedDate
            : endOfMonth(selectedDate);

        updateApartmentCalendar({
            variables: {
                input: {
                    apartmentId,
                    changes: dateRange(updateDateFrom, updateDateTo).map(date => ({
                        date: formatISO(date, { representation: 'date' }),
                        availableForRent: false,
                    })),
                },
            },
        });
    }

    function renderDateCell(date) {
        const calendarItem = [...data?.apartment?.calendar ?? []]
            .find(item => isEqual(item.date, date));

        return (
            <div className="apartment-availability-calendar-cell">
                <div
                    className={classNames({
                        "apartment-availability-calendar-day-box": true,
                        "apartment-availability-calendar-box-selected": isEqual(date, selectedDate),
                        "apartment-availability-calendar-box-available": calendarItem?.availableForRent === true,
                        "apartment-availability-calendar-box-unavailable": calendarItem?.availableForRent === false,
                    })}
                >
                    <span>
                        {format(date, 'dd')}
                    </span>
                </div>
            </div>
        );
    }

    function renderMonthCell(date) {
        const calendarItems = [...data?.apartment?.calendar ?? []]
            .filter(item => isSameMonth(item.date, date));

        return (
            <div className="apartment-availability-calendar-cell">
                <div
                    className={classNames({
                        "apartment-availability-calendar-month-box": true,
                        "apartment-availability-calendar-box-selected": isSameMonth(date, selectedDate),
                        "apartment-availability-calendar-box-available": calendarItems.length > 0 && calendarItems.every(item => item.availableForRent === true),
                        "apartment-availability-calendar-box-unavailable": calendarItems.length > 0 && calendarItems.every(item => item.availableForRent === false),
                        "apartment-availability-calendar-box-undefined": calendarItems.length > 0 && calendarItems.some(item => item.availableForRent === true) && calendarItems.some(item => item.availableForRent === false),
                    })}
                >
                    <span>
                        {format(date, 'MMMM')}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <InlineCalendar
                    value={selectedDate}
                    onChange={value => setSelectedDate(value)}
                    onPanelChange={(date, mode) => { setSelectedDate(date); setMode(mode); }}
                    dateFullCellRender={date => renderDateCell(date)}
                    monthFullCellRender={date => renderMonthCell(date)}
                    fullscreen={false}
                />
            </Col>
            <Col span={12}>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Button
                            onClick={() => handleSetAvailableForRent()}
                            block
                        >
                            Set available for rent
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Button
                            onClick={() => handleSetUnavailableForRent()}
                            block
                        >
                            Set unavailable for rent
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
import { gql, useQuery } from "@apollo/client";
import { Alert, Checkbox, Col, Row, Skeleton } from "antd";

const QUERY = gql`
    query GetPermissionsForUserPermissionsSelect {
        permissions
        userGroups {
            id
            permissions
        }
    }
`;

export default function UserPermissionsSelect(props) {
    const {
        value,
        inheritPermissionsFromGroupIds,
        onChange,
    } = props;

    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load permissions"
            />
        );
    }

    const userGroupPermissions = [...data.userGroups ?? []]
        .filter(userGroup => [...inheritPermissionsFromGroupIds ?? []].includes(userGroup.id))
        .map(userGroup => userGroup.permissions)
        .flat()
        .filter((permission, index, permissions) => permissions.indexOf(permission) === index);


    return (
        <Checkbox.Group
            value={value}
            onChange={value => onChange(value)}
        >
            <Row gutter={[8, 8]}>
                {data.permissions.map(permission => (
                    <Col
                        key={permission}
                        span={24}
                    >
                        {inheritPermissionsFromGroupIds && userGroupPermissions.includes(permission) && (
                            <Checkbox
                                value={permission}
                                disabled
                                defaultChecked={true}
                            >
                                {permission}
                            </Checkbox>
                        )}
                        {(!inheritPermissionsFromGroupIds || !userGroupPermissions.includes(permission)) && (
                            <Checkbox
                                value={permission}
                            >
                                {permission}
                            </Checkbox>
                        )}
                    </Col>
                ))}
            </Row>
        </Checkbox.Group>
    );
}
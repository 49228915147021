import { Form } from "antd";
import { useEffect } from "react";
import VariableValueInput from "stayql/components/variable-value-input/VariableValueInput";

export default function QueryVariableValuesForm(props) {
    const {
        variables,
        variableValues,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue(variableValues);
    }, [form, variableValues]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            {[...variables ?? []].map(variable => (
                <Form.Item
                    name={variable.name}
                    label={variable.name}
                    rules={[{ required: true, message: `${variable.name} is required` }]}
                    key={variable.name}
                >
                    <VariableValueInput
                        type={variable.type}
                        name={variable.name}
                    />
                </Form.Item>
            ))}
        </Form>
    );
}
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { addDays, subDays } from "date-fns";
import DatePicker from "components/DatePicker";

export default function DateSelectWithArrows(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row gutter={[4, 4]}>
            <Col>
                <Button
                    icon={<LeftOutlined />}
                    onClick={() => onChange(subDays(value, 1))}
                />
            </Col>
            <Col>
                <DatePicker
                    value={value}
                    onChange={value => onChange(value)}
                    allowClear={false}
                />
            </Col>
            <Col>
                <Button
                    icon={<RightOutlined />}
                    onClick={() => onChange(addDays(value, 1))}
                />
            </Col>
        </Row>
    );
}
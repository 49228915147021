import { Col, Divider, Form, InputNumber, Row } from "antd";
import TimeSelect from "components/TimeSelect";
import { useEffect } from "react";

export default function OrganizationDefaultsForm(props) {
    const {
        organization,
        form,
        currency,
        ...otherProps
    } = props;

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                defaultApartmentCalendarLength: organization?.defaultApartmentCalendarLength,
                defaultApartmentCutOffTime: organization?.defaultApartmentCutOffTime,
                defaultApartmentDefaultPrice: organization?.defaultApartmentDefaultPrice,
                defaultApartmentMinPrice: organization?.defaultApartmentMinPrice,
                defaultApartmentIncreasePriceAbove: organization?.defaultApartmentIncreasePriceAbove,
                defaultApartmentIncreasePriceBy: organization?.defaultApartmentIncreasePriceBy,
            });
        }
    }, [form, organization]);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form
                    form={form}
                    {...otherProps}
                >
                    <Divider orientation="left">
                        Apartment defaults
                    </Divider>
                    <Form.Item
                        name="defaultApartmentCalendarLength"
                        label="Calendar length"
                    >
                        <InputNumber
                            addonAfter="days"
                        />
                    </Form.Item>
                    <Form.Item
                        name="defaultApartmentCutOffTime"
                        label="Cut-off time"
                    >
                        <TimeSelect
                            startTime="15:00"
                            endTime="24:00"
                            minutesInterval={30}
                        />
                    </Form.Item>
                    <Form.Item
                        name="defaultApartmentIncreasePriceBy"
                        label="Extra guest fee"
                    >
                        <InputNumber
                            addonAfter={currency}
                        />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}
import { formatISO } from 'date-fns';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';


export function generateExcelFile({ columns, data, sheetName }) {
    let localData = data.map(row => ({ ...row }));
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(sheetName || 'Worksheet');

    sheet.views = [{}];

    sheet.columns = columns.map(column => {
        const columnDefinition = {
            header: column.label,
            key: column.key,
            width: column.width || 15,
            style: {
                font: {
                    name: 'Helvetica',
                    size: 10,
                }
            }
        };

        if (column.number) {
            columnDefinition.style.numFmt = '0.00';
            localData = localData.map(row => {
                if (typeof row[column.key] === 'string') {
                    row[column.key] = parseFloat(row[column.key]);
                }
                return row;
            });
        }
        if (column.date) {
            columnDefinition.style.numFmt = 'yyyy-mm-dd';
            localData = localData.map(row => {
                row[column.key] = formatISO(row[column.key], { representation: 'date' });
                return row;
            });
        }

        return columnDefinition;
    });

    sheet.addRows(localData);

    sheet.eachRow(row => {
        row.height = 20;
        row.eachCell(cell => {
            cell.alignment = {
                vertical: 'middle',
            };
        });
    });

    sheet.getRow(1).eachCell(cell => {
        cell.alignment = {
            horizontal: 'center',
            vertical: 'middle',
        };
    });

    sheet.getRow(1).eachCell(cell => {
        cell.font = {
            name: 'Helvetica',
            size: 10,
            bold: true,
        }
        cell.border = {
            top: { style: 'thin', color: { argb: 'FF808080' } },
            left: { style: 'thin', color: { argb: 'FF808080' } },
            bottom: { style: 'thin', color: { argb: 'FF808080' } },
            right: { style: 'thin', color: { argb: 'FF808080' } },
        };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD0D0D0' }
        };
    });

    sheet.getColumn(1).eachCell(cell => {
        cell.font = {
            name: 'Helvetica',
            size: 10,
            bold: true,
        };
        cell.border = {
            top: { style: 'thin', color: { argb: 'FF808080' } },
            left: { style: 'thin', color: { argb: 'FF808080' } },
            bottom: { style: 'thin', color: { argb: 'FF808080' } },
            right: { style: 'thin', color: { argb: 'FF808080' } },
        };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD0D0D0' }
        };
    });

    return workbook.xlsx
        .writeBuffer({ base64: true })
        .then(buffer => {
            const data = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            return data;
        });
}

export function downloadData({ columns, data, filename, sheetName }) {
    generateExcelFile({ columns, data, sheetName })
        .then(data => {
            saveAs(data, filename);
        });
}